import { ClassroomRequest, IncludedRequest } from './types';

export const normalizeChatClassrooms = (
  data: ClassroomRequest[],
  included: IncludedRequest[]
) => {
  const includedMap = included.reduce(
    (acc, inc) => {
      if (inc.type === 'headquarter') {
        acc.headquarters[inc.id] = {
          id: inc.id,
          name: inc.attributes.name,
        };
      } else if (inc.type === 'studentProfile') {
        acc.students[inc.id] = {
          id: inc.id,
          name: inc.attributes.name,
          nameInitials: inc.attributes.nameInitials,
          avatarColor: inc.attributes.avatarColor,
          avatarUrl: inc.attributes.avatarUrl,
          classrooms: inc.attributes.classroomIds,
          role: inc.attributes.role,
          responsibles: inc.relationships.responsibleProfiles.data.map(
            (responsible) => {
              const responsibleData = included.find(
                (inc) =>
                  inc.id === responsible.id && inc.type === 'responsibleProfile'
              );
              return {
                id: responsibleData.id,
                name: responsibleData.attributes.name,
                ...responsibleData.attributes,
              };
            }
          ),
        };
      }
      return acc;
    },
    { headquarters: {}, students: {} }
  );

  return included
    .filter((inc) => inc.type === 'headquarter')
    .map((inc) => {
      const headquarterId = inc.id;
      const headquarterName = inc.attributes.name;

      const classrooms = data
        .filter(
          (classroom) =>
            classroom.relationships.headquarter.data.id === headquarterId
        )
        .map((classroom) => {
          const students = classroom.relationships.studentProfiles.data
            .map((student) => includedMap.students[student.id])
            .filter(Boolean)
            .map((studentData) => ({
              id: studentData.id,
              isSelected: false,
              name: studentData.name,
              nameInitials: studentData.nameInitials,
              avatarColor: studentData.avatarColor,
              avatarUrl: studentData.avatarUrl,
              classrooms: studentData.classrooms,
              responsibles: studentData.responsibles,
              role: studentData.role,
            }));

          return {
            id: classroom.id,
            name: classroom.attributes.name,
            kind: classroom.type,
            sentTo: null,
            students,
          };
        });

      return {
        id: headquarterId,
        name: headquarterName,
        classrooms,
      };
    });
};
