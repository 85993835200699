import React, { useMemo, useRef, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
  Box,
  FlexGrid,
  FlexGridItem,
  RecipientTag,
  SubtleIconButton,
  Tag,
  Text,
  Tooltip,
} from '@agendaedu/ae-web-components';

import SummarydayClassroomsActions from 'store/summarydayClassrooms/actions';
import * as S from './styles';

const InfoData = ({ dataRow, history }) => {
  const titleRef = useRef(null);
  const disciplineRef = useRef(null);
  const [istitleOverflowing, setIsTitleOverflowing] = useState(false);
  const [isDisciplineOverflowing, setIsDisciplineOverflowing] = useState(false);

  const { t } = useTranslation(['summaryday_classrooms']);

  const dispatch = useDispatch();

  const {
    id,
    attributes: { classrooms, student_profiles, discipline, created_for },
  } = dataRow;

  const { activityIdDuplicate } = SummarydayClassroomsActions;

  const { dataArea, policies } = useSelector(
    (state: RootStateOrAny) => state.root
  );

  const createdAt = moment(dataRow.date, 'YYYY-MM-DD').format(
    'DD [de] MMMM[,] YYYY'
  );

  const editData = (e) => {
    e?.stopPropagation?.();
    const win = window.open(
      `/${dataArea}/summaryday_classrooms/${id}/edit`,
      '_self'
    );
    win.focus();
  };

  const showData = (e) => {
    e?.stopPropagation?.();
    history.push(`/${dataArea}/summaryday_classrooms/${id}`);
  };

  const duplicateData = (e) => {
    e?.stopPropagation?.();
    dispatch(activityIdDuplicate(id));
    history.push(`/${dataArea}/summaryday_classrooms/new`);
  };

  const newWindow = (e) => {
    e?.stopPropagation?.();
    const win = window.open(
      `/${dataArea}/summaryday_classrooms/${id}`,
      '_blank'
    );
    win.focus();
  };

  const getActions = () => {
    const actions = [];

    actions.push({
      as: 'button',
      title: 'Abrir',
      onClick: newWindow,
    });

    actions.push({
      as: 'button',
      title: 'Visualizar',
      onClick: showData,
    });

    policies?.can_create_activity &&
      actions.push({
        as: 'button',
        title: 'Duplicar',
        onClick: duplicateData,
      });

    dataRow.summayday_classroom_permissions.can_edit &&
      ['pending', 'rejected', 'approved'].includes(dataRow.status) &&
      actions.push({
        as: 'button',
        title: 'Editar',
        onClick: editData,
      });

    return actions;
  };

  const renderStatus = () => {
    const status = {
      pending: 'Pendente',
      opened: 'Em aberto',
      closed: 'Encerrada',
      rejected: 'Reprovada',
      deleted: 'Excluída',
      processing: 'Processando',
      approved: 'Aprovado',
      sent: 'Enviado',
      scheduled: 'Agendada',
      updated: 'Atualizada',
      pending_schedule: 'Agendamento pendente',
    };

    const statusVariant = {
      pending: 'warning',
      opened: 'positive',
      closed: 'neutral',
      rejected: 'negative',
      deleted: 'neutral',
      processing: 'warning',
      approved: 'positive',
      sent: 'positive',
      scheduled: 'informative',
      updated: 'informative',
      pending_schedule: 'warning',
    };

    return (
      <Tag
        name={status[dataRow.status] || dataRow.status}
        variant={statusVariant[dataRow.status]}
        size="small"
      />
    );
  };

  const bodyGridCols = useMemo((): Record<
    'disciplineAndConfirmations' | 'classrooms' | 'status',
    { desktopLG: 1 | 2; mobileXS: 10 | 5; tabletMD: 10 | 5 }
  > => {
    const validateDiscipline = (minQuantity: number) =>
      discipline.some((values) => values.attributes.name.length >= minQuantity);

    const validateCreatedFor = (minQuantity: number) => {
      if (created_for === 'classrooms')
        return (
          classrooms.length === 1 &&
          classrooms[0].attributes.name.length >= minQuantity
        );

      return (
        student_profiles.length === 1 &&
        student_profiles[0].attributes.name.length >= minQuantity
      );
    };

    return {
      disciplineAndConfirmations: {
        desktopLG: 2,
        mobileXS: validateDiscipline(18) ? 10 : 5,
        tabletMD: validateDiscipline(32) ? 10 : 5,
      },
      classrooms: {
        desktopLG: 2,
        mobileXS: validateCreatedFor(18) ? 10 : 5,
        tabletMD: validateCreatedFor(32) ? 10 : 5,
      },
      status: {
        desktopLG: 1,
        mobileXS: validateCreatedFor(18) ? 10 : 5,
        tabletMD: validateCreatedFor(32) ? 10 : 5,
      },
    };
  }, [discipline, created_for, classrooms, student_profiles]);

  const renderBody = () => {
    const {
      attributes: {
        confirmed_and_total: { confirmed, total },
      },
    } = dataRow;

    const recipientType = dataRow.attributes.classrooms
      ? 'classrooms'
      : 'student_profiles';

    const recipientTag = {
      type: t(recipientType),
      recipients:
        recipientType === 'classrooms'
          ? dataRow.attributes.classrooms?.map(({ attributes }) => attributes)
          : dataRow.attributes.student_profiles.data.map(
              ({ attributes }) => attributes
            ),
    };

    return (
      <>
        <FlexGridItem cols={bodyGridCols.disciplineAndConfirmations}>
          <Box mr={S.columnsMargins}>
            <Text variant="subtitle-medium-12" color="neutral.gray1">
              {t('discipline')}
            </Text>
            <Tooltip
              content={discipline.map((values) => values.attributes.name)}
              elementRef={
                <Text
                  variant="subtitle-medium-16"
                  textAlign="left"
                  mt="sm"
                  color="neutral.gray1"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  ref={disciplineRef}
                >
                  {discipline.map((values) => values.attributes.name)}
                </Text>
              }
              width="100%"
              disabled={!isDisciplineOverflowing}
            />
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={bodyGridCols.disciplineAndConfirmations}>
          <Box mr={S.columnsMargins}>
            <Text variant="subtitle-medium-12" color="neutral.gray1">
              {t('confirmations')}
            </Text>
            <Text variant="subtitle-medium-16" mt="sm" color="neutral.gray1">
              {t('total_confirmations', {
                confirmed,
                total,
              })}
            </Text>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={bodyGridCols.classrooms}>
          <Box {...S.statusBoxProps} mr={S.columnsMargins}>
            <RecipientTag
              variant="neutral"
              recipientType={recipientTag.type}
              recipients={recipientTag.recipients}
              modalTitle={recipientTag.type}
              size="small"
              enableEllipsis
            />
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={bodyGridCols.status}>
          <Box {...S.statusBoxProps} mr={S.columnsMargins}>
            {renderStatus()}
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 1 }}>
          <Box position="absolute" top={28} right={S.subtleIconSpace}>
            <SubtleIconButton actions={getActions()} align="right" />
          </Box>
        </FlexGridItem>
      </>
    );
  };

  useEffect(() => {
    const title = titleRef.current;
    const discipline = disciplineRef.current;
    setIsTitleOverflowing(title.scrollWidth > title.clientWidth);
    setIsDisciplineOverflowing(discipline.scrollWidth > discipline.clientWidth);
  }, []);

  return (
    <Box width="100%" cursor="pointer" onClick={showData}>
      <FlexGrid>
        <FlexGridItem cols={{ desktopLG: 4, mobileXS: 10 }}>
          <Box mr={S.columnsMargins}>
            <Text variant="subtitle-medium-12" color="neutral.gray1">
              {t('created_at', {
                date: createdAt,
              })}
            </Text>
            <Tooltip
              content={dataRow.title}
              elementRef={
                <Text
                  ref={titleRef}
                  variant="subtitle-medium-16"
                  color="neutral.gray1"
                  textAlign="left"
                  mt="sm"
                >
                  {dataRow.title}
                </Text>
              }
              width="100%"
              disabled={!istitleOverflowing}
            />
          </Box>
        </FlexGridItem>
        {renderBody()}
      </FlexGrid>
    </Box>
  );
};

const DatarowShape = {};

InfoData.propTypes = {
  dataRow: PropTypes.shape(DatarowShape).isRequired,
  history: PropTypes.object,
  activeFlag: PropTypes.bool,
};

export default withRouter(InfoData);
