import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import moment from 'moment';
import { Text, RangeDate } from '@agendaedu/ae-web-components';

import { trackEvent } from 'config/amplitude';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';
import { formatRangeDate } from 'core/utils/date';
import validations from './validations';
import FormFieldset from 'components/Form/Fieldset';
import Alert from 'components/Alert';
import Toast from 'components/Toast';
import Field from 'components/Form/Field';
import CheckPaymentMethods from 'components/Form/CheckPaymentMethods';
import InstallmentFeesConfiguration from 'components/Payments/PaymentsContainer/FormSections/InstallmentFeesConfiguration';
import SimpleSummary from 'components/Payments/PaymentsContainer/FormSections/SimpleSummary';
import Title from 'components/WizardForm/Title';
import Tooltip from 'components/Tooltip';
import FormCheckbox from 'components/Form/Checkbox';
import AgendaIcon from 'components/AgendaIcon';
import { NewOfferFormOfferTabProps } from 'components/Payments/Shop/OfferForm/types';
import * as S from './styles';

const OfferFormOfferTab = ({
  formContext: { form, formMeta, updateAttribute },
}: NewOfferFormOfferTabProps) => {
  const {
    allowed_payment_method,
    original_price,
    max_installments,
    status,
    addable_to_cart,
    sell_period,
  } = form;
  const { meta_max_installments } = formMeta;

  const { t } = useTranslation(['payments']);

  const {
    policies: {
      school_plan,
      can_config_offer_payment_methods,
      edupay_provider,
      can_absorb_tax,
    },
  } = useSelector((state) => state.root);
  const { cartSettings } = useSelector((state) => state.shop);

  const [startRangeDate, setStartRangeDate] = useState(
    sell_period?.startDate ? moment(sell_period?.startDate) : null
  );
  const [endRangeDate, setEndRangeDate] = useState(
    sell_period?.endDate ? moment(sell_period?.endDate) : null
  );

  const isOutsideRange = (day) => {
    const oneDaysAgo = moment().subtract(1, 'days');

    return day < oneDaysAgo;
  };

  const handleRangeDateChange = ({ startDate, endDate }) => {
    setStartRangeDate(startDate);
    setEndRangeDate(endDate);
  };

  const toggleOfferAvailableInCart = () => {
    updateAttribute('addable_to_cart', !addable_to_cart);
  };

  useEffect(() => {
    if (Boolean(startRangeDate) === Boolean(endRangeDate)) {
      updateAttribute('sell_period', {
        startDate: formatRangeDate(startRangeDate),
        endDate: formatRangeDate(endRangeDate),
      });
    }
  }, [endRangeDate, startRangeDate]);

  return (
    <S.OfferWrapper>
      <FormFieldset>
        <S.ValueWrapper>
          <S.ValueField
            attributeName="original_price"
            type="currency"
            label={t('marketplace.new_offer_form.value_field')}
          />

          <S.RangeDateWrapper>
            <RangeDate
              id="date-range"
              value={{
                startDate: startRangeDate,
                endDate: endRangeDate,
              }}
              handleChange={handleRangeDateChange}
              handleOutsideRange={isOutsideRange}
              label={
                <S.RangeDateLabel>
                  <Text variant="label-regular-14" mb={0} color="neutral.gray1">
                    {t('marketplace.new_offer_form.period_field')}
                  </Text>

                  <Tooltip
                    content={parse(
                      t('marketplace.new_offer_form.tooltip_period_field')
                    )}
                  >
                    <AgendaIcon
                      name="help"
                      size="small"
                      className="custom-size"
                    />
                  </Tooltip>
                </S.RangeDateLabel>
              }
            />
          </S.RangeDateWrapper>
        </S.ValueWrapper>

        {!status && !!cartSettings?.attributes?.enabled && (
          <div>
            <Text variant="label-regular-16" mb="24px">
              {t('marketplace.new_offer_form.offer_available')}
            </Text>
            <S.CheckboxWrapper>
              <FormCheckbox
                testid={`checkbox-offer-available-in-cart`}
                checked={addable_to_cart}
                onChange={() => toggleOfferAvailableInCart()}
                disabled={false}
              />
              <S.CheckboxSpan onClick={() => toggleOfferAvailableInCart()}>
                <Text variant="body-regular-14" color="neutral.gray2" mb={0}>
                  {t('marketplace.new_offer_form.offer_available_checkbox')}
                </Text>
              </S.CheckboxSpan>
            </S.CheckboxWrapper>

            <Alert variation="info">
              <Link
                to="/schools/shopping_carts/configuration"
                onClick={() => trackEvent('shopping-carts-configuration')}
              >
                <S.CartConfig variant="subtitle-medium-14" mb={0}>
                  {t('marketplace.new_offer_form.access_cart_settings')}
                </S.CartConfig>
              </Link>
            </Alert>
          </div>
        )}

        <S.TitlePaymentMethod
          name={t('marketplace.new_offer_form.title_payment_method')}
        />
        <div className="row">
          <CheckPaymentMethods
            withPix
            attributeName="allowed_payment_method"
            disabled={!can_config_offer_payment_methods || status === 'active'}
            canConfigPaymentMethods={can_config_offer_payment_methods}
            isNewLayout={edupay_provider === 'bemobi'}
          />

          {can_absorb_tax && (
            <S.AbsorbTaxWrapper>
              <InstallmentFeesConfiguration
                disabled={
                  !can_config_offer_payment_methods || status === 'active'
                }
              />
            </S.AbsorbTaxWrapper>
          )}

          {school_plan === 'trial' && (
            <Alert variation="info">
              <p>
                Durante o período de testes, pagamentos via cartão de crédito
                ficam desabilitados.
              </p>
              Caso queira habilitar o recebimento por esse método de pagamento,
              entre em contato com nosso time de atendimento:{' '}
              <a
                href="https://atendimento.agendaedu.com/hc/pt-br/articles/360022221754-Conhecendo-sua-carteira"
                target="_blank"
                rel="noreferrer"
              >
                atendimento@agendaedu.com
              </a>
            </Alert>
          )}
        </div>
        {allowed_payment_method.includes('credit_card') &&
          edupay_provider !== 'bemobi' && (
            <div className="row">
              <Field
                size="small"
                fullWidth
                withArrowSelect
                type="selectWithIcon"
                label="Nº de parcelas*"
                attributeName="max_installments"
                options={meta_max_installments}
                value={max_installments}
                onChange={(e) => {
                  updateAttribute('max_installments', e.value);
                }}
              />
            </div>
          )}

        <S.AccordionsWrapper>
          <Title name={t('form_section.simple_summary_title')} />
          <SimpleSummary price={original_price} />
        </S.AccordionsWrapper>

        <S.Paragraph>
          {t('marketplace.new_offer_form.required_fields')}
        </S.Paragraph>
      </FormFieldset>
      <Toast />
    </S.OfferWrapper>
  );
};

export default tabifyForWizard({
  title: '2. Oferta',
  subTitle: 'Valor e período',
  validations,
})(withFormContext(OfferFormOfferTab));
