import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@agendaedu/ae-web-components';

import CenterModal from 'pages/storage/Modals/CenterModal';

import { Props } from './types';

const DiscardModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
}): React.ReactElement => {
  const { t } = useTranslation(['calendar']);

  const tBase = useCallback(
    (key: string) => t(`forms.event.modals.discard.${key}`),
    [t]
  );

  return (
    <CenterModal
      isOpen={isOpen}
      onClose={onClose}
      body={
        <Text variant="body-regular-16" lineHeight="lg" color="neutral.gray1">
          {tBase('content')}
        </Text>
      }
      footer={
        <Box display="flex" flexDirection="row-reverse" gap="sm">
          <Button
            data-testid="submit-button"
            variant="secondary"
            contextVariant="danger"
            onClick={onSubmit}
          >
            {tBase('submit_button_text')}
          </Button>

          <Button
            data-testid="cancel-button"
            variant="secondary"
            onClick={onClose}
          >
            {tBase('cancel_button_text')}
          </Button>
        </Box>
      }
      title={tBase('title')}
    />
  );
};

export default DiscardModal;
