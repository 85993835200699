import styled, { css } from 'styled-components';
import { Text } from '@agendaedu/ae-web-components';

export const QuestionWrapper = styled.div`
  display: flex;

  > div {
    height: fit-content;

    label {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

export const StyledText = styled(Text)<{
  enableEllipsis: boolean;
  widthDimensions: string;
}>`
  ${({ enableEllipsis, widthDimensions }) =>
    enableEllipsis &&
    css`
      max-width: 688px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: 890px) {
        max-width: ${widthDimensions};
      }
    `}
`;
