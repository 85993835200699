import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import messagesActions from 'store/messages/actions';
import { MessageState } from 'store/messages/types';

export const GroupDeleteModal: React.FC = () => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const [confirmSubmitText, setConfirmSubmitText] = useState('');

  const { toggleDeleteGroupModal, setDeleteGroupRequest } = messagesActions;

  const { showDeleteGroupModal, currentGroup } = useSelector(
    (state: MessageState) => state.messages
  );

  const handleToggleModal = useCallback((): void => {
    dispatch(toggleDeleteGroupModal());
  }, [dispatch, toggleDeleteGroupModal]);

  const handleDeleteGroupRequest = useCallback((): void => {
    dispatch(setDeleteGroupRequest(currentGroup.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setDeleteGroupRequest]);

  const isDisabled =
    confirmSubmitText !== t('groups.modals.delete_group.input_placeholder');

  useEffect(() => {
    setConfirmSubmitText('');
  }, [showDeleteGroupModal]);

  return (
    <Modal
      isOpen={showDeleteGroupModal}
      title={t('groups.modals.delete_group.title')}
      onClose={handleToggleModal}
    >
      <Box maxWidth="336px" paddingLeft={4}>
        <Text variant="body-regular-16" mb={32} mt={12} lineHeight="lg">
          {t('groups.modals.delete_group.description')}
        </Text>

        <TextField
          id="input-delete-chanel-confirmation"
          label={t('groups.modals.delete_group.input_label')}
          fullWidth
          placeholder={t('groups.modals.delete_group.input_placeholder')}
          value={confirmSubmitText}
          onChange={(event) => setConfirmSubmitText(event.currentTarget.value)}
        />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="end"
          mt={32}
          gap="16px"
        >
          <Button
            data-testid="cancel-delete-button"
            variant="secondary"
            onClick={handleToggleModal}
          >
            {t('groups.modals.delete_group.back_button_label')}
          </Button>
          <Button
            data-testid="submit-delete-button"
            disabled={isDisabled}
            contextVariant="danger"
            onClick={handleDeleteGroupRequest}
          >
            {t('groups.modals.delete_group.submit_button_label')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
