import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const MessageCountWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23.33px;
    height: 23.33px;
    cursor: pointer;

    > button {
      width: 24px;
      height: 24px;
      padding: 0;
      border-color: transparent;

      > main {
        background: ${colors.context.danger.default};
      }

      &:hover {
        border: 1px solid ${colors.neutral.gray5};
        background: ${colors.neutral.gray6} !important;

        > svg {
          path {
            fill: rgba(0, 0, 0, 0.3) !important;
          }
        }
      }

      > svg {
        width: 22px;
        height: 22px;

        path {
          fill: ${colors.neutral.gray1} !important;
        }
      }
    }

    @media screen and (max-width: 992px) {
      > button {
        border-color: transparent;
        background-color: transparent !important;

        &:hover {
          border: 1px solid transparent;
          background: transparent !important;

          > svg {
            path {
              fill: ${colors.neutral.white} !important;
            }
          }
        }

        > svg {
          path {
            fill: ${colors.neutral.white} !important;
          }
        }
      }
    }
  `}
`;

export const UnreadModal = styled.div`
  ${({ theme: { space, colors } }: ThemeProps) => css`
    z-index: 1;
    position: absolute;
    width: 400px;
    top: ${space.xl5};
    border-radius: ${space.xs};
    background: ${colors.neutral.white};
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

    button {
      width: 100%;
      height: 55px;
      border-radius: 0 0 ${space.xs} ${space.xs};
    }

    @media screen and (max-width: 992px) {
      width: 320px;
      right: ${space.sm};
    }
  `}
`;

export const InformationWrapper = styled.div`
  ${({ theme: { space, colors, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.sm};
    padding: ${space.md};
    border-bottom: ${border.style.solid} ${border.width.sm}
      ${colors.neutral.gray4};
  `}
`;

export const ListMessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardWrapper = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    height: max-content;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;
    padding: ${space.sm};
    gap: ${space.md};
    border-bottom: ${border.style.solid} ${border.width.sm}
      ${colors.neutral.gray4};
    transition: background-color 0.3s;

    &:hover {
      background-color: ${colors.brand.primary.op10};
    }
  `}
`;

export const CardTopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 40px;

  > main {
    width: 40px;
    height: 40px;
  }
`;
export const UserContentWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: ${space.xs};
  `}
`;

export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;
