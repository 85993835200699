import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { colors, space, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
      border-radius: ${space.xs2};
      margin-bottom: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray3};
    }
  `}
`;

export const HeadquarterWrapper = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    background-color: ${colors.neutral.gray6};
    width: 100%;
    align-items: center;
    border-bottom: ${border.style.solid} ${border.width.sm}
      ${colors.neutral.gray4};
  `}
`;
