/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Text } from '@agendaedu/ae-web-components';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import Accordion from 'components/Accordion';

import { Props } from './types';
import { ChatContext } from 'core/contexts/OmniChannel/Chat';
import { SectionList } from './SectionList';

export const Section: React.FC<Props> = ({ recipient, data, classroomId }) => {
  const { t } = useTranslation(['messages', 'common']);
  const { id, name } = recipient;

  const {
    recipients: selectedRecipientsList,
    handleToggleResponsibleSelection,
  } = useContext(ChatContext);

  const selectedCount = Object.values(
    selectedRecipientsList.responsibleTab?.[`${classroomId}-${id}`]
      ?.responsibles || {}
  ).filter(Boolean).length;

  const totalSelect = data?.length;

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.chat.${key}`, params),
    [t]
  );

  const recipientsCount = useMemo(
    () => (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        backgroundColor={selectedCount ? 'brand.primary.op10' : 'neutral.gray5'}
        padding="xs2"
        borderRadius="24px"
      >
        <Text
          variant="subtitle-medium-12"
          mb={0}
          color={selectedCount ? 'brand.primary.default' : 'neutral.gray3'}
        >
          {`${selectedCount}/${totalSelect} ${tBase(
            `${
              totalSelect === 1
                ? 'recipients_count_unique_label'
                : 'recipients_count_label'
            }.responsible`
          )}`}
        </Text>
      </Box>
    ),
    [selectedCount, totalSelect, tBase]
  );

  const paddingRepientCard = {
    classroom: '48px',
    responsible: '32px',
  };

  const title = useMemo(
    () => (
      <Text
        variant="body-regular-14"
        color={selectedCount ? 'brand.primary.default' : 'neutral.black'}
        mb={0}
      >
        {name}
      </Text>
    ),
    [name, selectedCount]
  );

  const recipients = useMemo(() => {
    return (
      <Box display="flex" flexDirection="column">
        <SectionList
          data={data}
          classroomId={classroomId}
          handleToggleResponsibleSelection={handleToggleResponsibleSelection}
          handleToggleResponsible={handleToggleResponsibleSelection}
          selectedRecipientsList={selectedRecipientsList}
          recipient={recipient}
        />
      </Box>
    );
  }, [tBase, data, paddingRepientCard]);

  return (
    <S.Wrapper isSelected={!!selectedCount}>
      <Accordion
        key={id}
        allowZeroExpanded
        variation="rightButton"
        header={
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            marginRight="xs"
            mb={0}
          >
            <Box
              display="flex"
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                e.stopPropagation()
              }
            >
              {title}
            </Box>

            {recipientsCount}
          </Box>
        }
        body={recipients}
      />
    </S.Wrapper>
  );
};
