import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { IMAGES_PAYMENT_URL } from 'core/constants/index';

import smoothScrollTo from 'core/utils/smoothScrollTo';
import { canEditRecurrentBill } from 'core/utils/recurrentBill';
import { flags } from 'core/constants/flags';

import AgendaIcon from 'components/AgendaIcon';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import MoneySummaryContainer from 'components/Payments/PaymentsContainer/MoneySummaryContainer';
import MoneySummary from 'components/Payments/MoneySummary';
import Currency from 'components/Currency';
import ShowBillModal from 'components/Payments/Recurrent/ShowBillModal';
import HandleModalByMethod from 'components/Payments/HandleModalByMethod';
import LinkModal from 'components/Payments/LinkModal';
import DelayModal from 'components/Payments/DelayModal';
import ColoredText from 'components/ColoredText';
import EmptyState from 'components/EmptyState';
import ManualDealModal from 'components/Payments/ManualDealModal';

import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';
import actionRecurrentBills from 'store/edupay/recurrentBills/actions';

import table from './table';

const FILTERS = [
  'status',
  'headquarter_id',
  'educational_stage_id',
  'classroom_id',
  'recurrent_title_or_student_name',
  'recurrent_search_type',
  'recurrent_product_ids',
];

const RecurrentPlansList = () => {
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState();
  const [activePage, setActivePage] = useState(1);
  const [hasDislabedSelectToEdit, setHasDislabedSelectToEdit] = useState(false);

  const {
    fetchRecurrentPlans,
    setBillrequest,
    setCurrentBill,
    toggleModal,
    toggleDelay,
    setAllIdsElegibleToEdit,
    toggleManualDealModal,
    clearResendBillingInfo,
  } = actionRecurrentPlans;

  const {
    loadInformationToPaid,
    toggleManuallyPaidModal,
    toggleBilletOrPixModal,
    toggleBilletOrPixLinkModal,
    unsetBill,
    delayBillResquest,
    checkout,
  } = actionRecurrentBills;

  const {
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
    policies: {
      can_display_balance: canDisplayBalance,
      edupay_active_flags: edupayActiveFlags,
    },
  } = useSelector((state) => state.root);

  const {
    currentWallet: { type: currentWalletType },
  } = useSelector((state) => state.wallets);

  const {
    balance,
    currentBill,
    currentBillPlan,
    delayModal,
    hasBatchSelection,
    filters,
    isLoading,
    isLoadingPlan,
    modalOpen,
    paginate,
    recurrentPlans,
    selectedAmount,
    totalIdsElegibleToEdit,
    wasSelectedAllIdsElegibleToEdit,
    manualDealModal,
  } = useSelector((state) => state.recurrentPlans);

  const {
    paymentModalByMethod,
    paymentLinkModalByMethod,
    isLoadingManuallyPaid,
    errorMessage,
  } = useSelector((state) => state.recurrentBills);

  const link = `/schools/recurrent/recurrent_plans/${currentBillPlan?.attributes.recurrent_plan_id}/recurrent_bills/${currentBillPlan?.id}/edit`;

  const studentProfile = useSelector(
    (state) => (state.recurrentPlans.currentBillPlan || {}).studentProfile
  );

  const order = useSelector(
    (state) => (state.recurrentPlans.currentBillPlan || {}).order
  );

  const responsibleProfiles = useSelector(
    (state) => (state.recurrentBills.current || {}).responsibleProfiles
  );

  const totalCurrentElegible = recurrentPlans.filter((bill) =>
    canEditRecurrentBill(
      bill.attributes.status,
      bill.attributes.is_wallet_fidc,
      bill.attributes.is_wallet_guaranteed_monthly_fee,
      true
    )
  ).length;

  const enableSetAllElegibleButton =
    totalIdsElegibleToEdit > totalCurrentElegible;

  const disabledEditInBatchForUsersWithoutPermission = () => {
    return (
      (!isDebugUser && currentWalletType === 'securitizer') ||
      (!isDebugUser &&
        !edupayActiveFlags.includes(flags.RECURRENT_EDIT_IN_BATCH))
    );
  };

  const setAlertEditInBatchInformation = useCallback(() => {
    setHasDislabedSelectToEdit(false);

    recurrentPlans?.map((current) => {
      if (
        !canEditRecurrentBill(
          current.attributes.status,
          current.attributes.is_wallet_fidc,
          current.attributes.is_wallet_guaranteed_monthly_fee
        )
      ) {
        setHasDislabedSelectToEdit(true);
      }
    });
  }, [recurrentPlans]);

  useEffect(() => {
    setActivePage(filters.page);
    setAlertEditInBatchInformation();
  }, [filters.page, setAlertEditInBatchInformation]);

  const getRecurrentPlans = useCallback(
    (params) => {
      dispatch(clearResendBillingInfo());
      dispatch(fetchRecurrentPlans(params));
    },
    [dispatch, fetchRecurrentPlans, clearResendBillingInfo]
  );

  const handlePageChange = useCallback(
    (page) => {
      const params = {
        ...filters,
        page,
      };

      setActivePage(page);
      getRecurrentPlans(params);
      smoothScrollTo(0, 0);
    },
    [filters, setActivePage, getRecurrentPlans]
  );

  const toggleShowModal = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch, toggleModal]);

  const toggleShowManualDealModal = useCallback(() => {
    dispatch(toggleManualDealModal());
  }, [dispatch, toggleManualDealModal]);

  const setBillPlan = useCallback(
    (planId, billId) => {
      dispatch(setBillrequest(planId, billId));
    },
    [dispatch, setBillrequest]
  );

  const setInformationToPaid = useCallback(
    (planId, billId) => {
      dispatch(setBillrequest(planId, billId));
      dispatch(loadInformationToPaid(planId, billId));
    },
    [dispatch, loadInformationToPaid, setBillrequest]
  );

  const toggleManullayPaid = useCallback(() => {
    dispatch(toggleManuallyPaidModal());
    dispatch(unsetBill());
  }, [dispatch, toggleManuallyPaidModal, unsetBill]);

  const toggleDelayModal = useCallback(() => {
    dispatch(toggleDelay());
  }, [dispatch, toggleDelay]);

  const togglePaymentMethod = useCallback(() => {
    dispatch(toggleBilletOrPixModal());
    dispatch(unsetBill());
  }, [dispatch, toggleBilletOrPixModal, unsetBill]);

  const togglePaymentMethodLink = useCallback(() => {
    dispatch(toggleBilletOrPixLinkModal());
    dispatch(unsetBill());
  }, [dispatch, toggleBilletOrPixLinkModal, unsetBill]);

  const setDelay = useCallback(
    (id) => {
      dispatch(setCurrentBill(id));
      toggleDelayModal();
    },
    [dispatch, toggleDelayModal, setCurrentBill]
  );

  const markDelayBill = useCallback(
    (planId, id) => {
      dispatch(delayBillResquest(planId, id, 'plan'));
      toggleDelayModal();
    },
    [dispatch, delayBillResquest, toggleDelayModal]
  );

  const onCheckout = (form) => {
    const {
      id: billId,
      attributes: { order_id: orderId },
    } = currentBillPlan;

    dispatch(checkout(billId, orderId, 'plan', form, paymentMethod));
  };

  const handleSetAllIdsElegibleToEdit = useCallback(() => {
    dispatch(setAllIdsElegibleToEdit());
  }, [dispatch, setAllIdsElegibleToEdit]);

  const prepareTableData = () =>
    recurrentPlans.map((recurrentPlan) => {
      recurrentPlan.actions = {};
      recurrentPlan.actions.show = () => {
        toggleShowModal();
        setBillPlan(
          recurrentPlan.attributes.recurrent_plan_id,
          recurrentPlan.id
        );
      };

      recurrentPlan.actions.manuallyPay = () => {
        toggleManullayPaid();
        setInformationToPaid(
          recurrentPlan.attributes.recurrent_plan_id,
          recurrentPlan.id
        );
      };

      recurrentPlan.actions.billetModal = () => {
        setPaymentMethod('billet');
        togglePaymentMethod();
        setInformationToPaid(
          recurrentPlan.attributes.recurrent_plan_id,
          recurrentPlan.id
        );
      };
      recurrentPlan.actions.pixModal = () => {
        setPaymentMethod('pix');
        togglePaymentMethod();
        setInformationToPaid(
          recurrentPlan.attributes.recurrent_plan_id,
          recurrentPlan.id
        );
      };

      recurrentPlan.actions.linkModal = () => {
        setPaymentMethod('link');
        togglePaymentMethod();
        setInformationToPaid(
          recurrentPlan.attributes.recurrent_plan_id,
          recurrentPlan.id
        );
      };

      recurrentPlan.actions.billetLinkModal = () => {
        setPaymentMethod('billet');
        togglePaymentMethodLink();
        setInformationToPaid(
          recurrentPlan.attributes.recurrent_plan_id,
          recurrentPlan.id
        );
      };
      recurrentPlan.actions.pixLinkModal = () => {
        setPaymentMethod('pix');
        togglePaymentMethodLink();
        setInformationToPaid(
          recurrentPlan.attributes.recurrent_plan_id,
          recurrentPlan.id
        );
      };

      recurrentPlan.actions.manualDealModal = () => {
        toggleShowManualDealModal();
        setBillPlan(
          recurrentPlan.attributes.recurrent_plan_id,
          recurrentPlan.id
        );
      };

      recurrentPlan.actions.delay = () => {
        setDelay(recurrentPlan.id);
      };

      return recurrentPlan;
    });

  const subTitleToDelayModal = () => (
    <>
      {currentBill && (
        <p>
          A cobrança que expira no dia{' '}
          <ColoredText variation="bold">
            {currentBill.attributes.due_date}
          </ColoredText>{' '}
          será
          <br />
          marcada como atrasada
        </p>
      )}
    </>
  );

  const reportParams = () => {
    let query = '?';
    query += `range=${JSON.stringify(filters.range)}`;
    Object.keys(filters).forEach((key) => {
      if (FILTERS.includes(key) && filters[key]) {
        query += `&${key}=${filters[key]}`;
      }
    });

    return query;
  };

  const paidComplement = () => (
    <div>
      <div className="summary-complement">
        <strong>
          {' '}
          <Currency value={balance.billet_paid} />{' '}
        </strong>{' '}
        pago via boleto
      </div>
      <div className="summary-complement">
        <strong>
          {' '}
          <Currency value={balance.credit_card_paid} />{' '}
        </strong>{' '}
        pago via cartão de crédito
      </div>
      <div className="summary-complement">
        <strong>
          {' '}
          <Currency value={balance.pix_paid} />{' '}
        </strong>{' '}
        pago via PIX
      </div>
      <div className="summary-complement">
        <strong>
          {' '}
          <Currency value={balance.manually_paid} />{' '}
        </strong>{' '}
        pago na escola
      </div>
    </div>
  );

  return (
    <>
      <div className="recurrent-list">
        {isLoading ? (
          <div className="container-content">
            <Loader />
          </div>
        ) : (
          <>
            {canDisplayBalance && (
              <MoneySummaryContainer>
                <MoneySummary
                  title="Previsto no período"
                  price={
                    balance.billet_paid +
                    balance.credit_card_paid +
                    balance.pix_paid +
                    balance.manually_paid +
                    balance.pending +
                    balance.delayed +
                    balance.released
                  }
                  tooltipMessage="Previsão de recebimentos no período selecionado"
                />
                <MoneySummary
                  title="Pago"
                  price={
                    balance.billet_paid +
                    balance.credit_card_paid +
                    balance.pix_paid +
                    balance.manually_paid
                  }
                  tooltipMessage="Valor total pago via boleto, cartão de crédito, PIX e na escola no período selecionado"
                  variation="success"
                  complement={paidComplement()}
                />
                <MoneySummary
                  title="Em aberto"
                  price={balance.released}
                  tooltipMessage="Valor total de pagamentos em aberto no período selecionado"
                  variation="warning"
                />
                <MoneySummary
                  title="Atrasado"
                  price={balance.delayed}
                  tooltipMessage="Valor total em pagamentos atrasados no período selecionado"
                  variation="danger"
                />
              </MoneySummaryContainer>
            )}
            {!disabledEditInBatchForUsersWithoutPermission() &&
              hasDislabedSelectToEdit && (
                <Alert variation="info">
                  Algumas cobranças não podem ser editadas por terem o pagamento
                  em aberto ou já efetuado. Somente cobranças não enviadas ou em
                  atraso poderão ser editadas.
                </Alert>
              )}
            {recurrentPlans.length > 0 ? (
              <>
                <Table
                  columns={
                    disabledEditInBatchForUsersWithoutPermission()
                      ? table.slice(1, table.length)
                      : table
                  }
                  data={prepareTableData()}
                  metadata={{
                    page: activePage,
                    totalItemsCount: paginate.totalItemsCount,
                    itemsCountPerPage: paginate.itemsCountPerPage,
                    singular: 'cobrança',
                    plural: 'cobranças',
                  }}
                  listOf="recurrentPlans"
                  customMetadata={
                    hasBatchSelection && (
                      <div className="customMetadata">
                        <span>{selectedAmount} selecionado(s)</span>

                        {enableSetAllElegibleButton && (
                          <Button
                            variation="secondary"
                            onClick={handleSetAllIdsElegibleToEdit}
                          >
                            {!wasSelectedAllIdsElegibleToEdit
                              ? `Selecionar todas as ${totalIdsElegibleToEdit} cobranças`
                              : `Limpar seleção`}
                          </Button>
                        )}
                      </div>
                    )
                  }
                  otherOptions={
                    hasBatchSelection && (
                      <Link to="/schools/recurrent/recurrent_bills/edit_in_batches">
                        <Button
                          testID="edit-in-batches-button"
                          variation="secondary"
                        >
                          <AgendaIcon name="edit-square" size="small" />
                          Editar
                        </Button>
                      </Link>
                    )
                  }
                  exportOptions={
                    !hasBatchSelection && [
                      {
                        path: `/schools/recurrent/recurrent_plans/report.xls${reportParams()}`,
                        text: 'Planilha(.xls)',
                      },
                    ]
                  }
                />
                <Pagination
                  onChange={handlePageChange}
                  activePage={activePage}
                  totalItemsCount={paginate.totalItemsCount}
                  itemsCountPerPage={paginate.itemsCountPerPage}
                />
              </>
            ) : (
              <>
                <EmptyState
                  imgUrl={IMAGES_PAYMENT_URL.availabilityUrl}
                  message={
                    <p>
                      Não foram encontrados registros <br />
                      para o termo buscado
                    </p>
                  }
                />
              </>
            )}
          </>
        )}
      </div>

      {modalOpen && (
        <ShowBillModal
          isOpen={modalOpen}
          toggleModal={toggleShowModal}
          bill={currentBillPlan}
        />
      )}

      <HandleModalByMethod
        method={paymentMethod}
        showModal={paymentModalByMethod}
        link={link}
        toggleModal={togglePaymentMethod}
        isLoading={isLoadingManuallyPaid || isLoadingPlan}
        student={studentProfile}
        responsibles={responsibleProfiles}
        onSubmit={onCheckout}
        checkoutError={errorMessage}
      />

      <LinkModal
        method={paymentMethod}
        showModal={paymentLinkModalByMethod}
        toggleModal={togglePaymentMethodLink}
        responsibles={responsibleProfiles}
        isLoading={isLoadingManuallyPaid || isLoadingPlan}
        order={order}
      />

      <DelayModal
        isOpen={!!delayModal}
        title="Atrasar cobrança"
        subTitle={subTitleToDelayModal()}
        toggleModal={toggleDelayModal}
        onDelay={() =>
          markDelayBill(
            currentBill.attributes.recurrent_plan_id,
            currentBill.id
          )
        }
      />

      <ManualDealModal
        isOpen={manualDealModal}
        toggleModal={toggleShowManualDealModal}
        bill={currentBillPlan}
        kind="recurrent_plan"
      />
    </>
  );
};

export default RecurrentPlansList;
