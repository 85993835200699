import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';
import { Text } from '@agendaedu/ae-web-components';

import { channelKind } from 'core/constants/omniChannel';

import omniChannelActions from 'store/messages/omniChannel/actions';

import * as S from './styles';

import { ClassroomSelect } from 'components/Messages/ClassroomSelect';
import FormFieldset from 'components/Form/Fieldset';
import { RecipientsSelect } from 'components/Messages/RecipientsSelect';
import { RecipientsSelected } from 'components/Messages/RecipientsSelected';
import { StudentSelect } from 'components/Messages/StudentSelect';

import {
  Classroom,
  OmniChannelState,
  Recipient,
  StudentProfile,
} from 'store/messages/omniChannel/types';

import { RecipientsStepProps } from './types';

const RecipientsStep = ({
  formContext: {
    changeMeta,
    updateAttribute,
    form: { recipients },
  },
}: RecipientsStepProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages']);

  const [groupFamilyStudent, setGroupFamilyStudent] = useState<Recipient>(null);
  const [selectedClassroomId, setSelectedClassroomId] = useState<string>(null);
  const [selectedStudent, setSelectedStudent] = useState<StudentProfile>(null);

  const {
    channel,
    chatClassrooms,
    chatConfirmedResponsibles,
    chatStudentProfiles,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const {
    fetchChatStudentProfilesRequest,
    fetchChatConfirmedResponsiblesRequest,
  } = omniChannelActions;

  const fetchStudentProfiles = useCallback(() => {
    dispatch(fetchChatStudentProfilesRequest(selectedClassroomId));
  }, [dispatch, fetchChatStudentProfilesRequest, selectedClassroomId]);

  const fetchConfirmedResponsibles = useCallback(() => {
    dispatch(fetchChatConfirmedResponsiblesRequest(selectedStudent.id));
  }, [dispatch, selectedStudent, fetchChatConfirmedResponsiblesRequest]);

  const canGoToNextStep = recipients?.length;
  const kind = channel?.attributes.kind;

  useEffect(() => {
    canGoToNextStep
      ? changeMeta('nextStepDisabled', false)
      : changeMeta('nextStepDisabled', true);
  }, [changeMeta, canGoToNextStep]);

  const handleOnChangeSelectedClassroom = (value: string) =>
    setSelectedClassroomId(value);

  const handleOnChangeSelectedStudent = (student: StudentProfile) => {
    setSelectedStudent(student);

    if (kind === channelKind.privateFamily) {
      setGroupFamilyStudent({
        ...student,
        type: channelKind.family,
      });
    }

    if (student && kind === channelKind.family) {
      setSelectedStudent(null);
      const hasRecipient = recipients.find(
        (recipient) => recipient.id === student.id
      );
      !hasRecipient &&
        updateAttribute('recipients', [
          ...recipients,
          {
            id: student.id,
            type: channelKind.family,
            attributes: {
              ...student.attributes,
            },
            studentProfileId: student.id,
          },
        ]);
    }
  };

  const getClassroomOptions = (data: Classroom[]) =>
    data.map(({ label, options }) => ({
      label,
      options: options?.map(({ attributes: { name }, id }) => ({
        label: name,
        value: id,
      })),
    }));

  const handleChangeRecipients = (
    recipientsList: Recipient[],
    removeRecipients: boolean
  ) => {
    if (removeRecipients) {
      const newRecipientsList = recipients?.filter(
        (item) => !recipientsList?.some((recipient) => recipient.id === item.id)
      );

      updateAttribute('recipients', newRecipientsList);
    } else {
      updateAttribute('recipients', [...recipients, ...recipientsList]);
    }
  };

  const handleAddAllRecipients = (recipientsList: Recipient[]) => {
    const newListRecipients = [
      ...recipients,
      ...recipientsList
        .filter((recipient) => {
          return (
            !recipients.some((item) => item.id === recipient.id) &&
            recipient.type !== channelKind.family
          );
        })
        .map((recipient) => ({
          ...recipient,
          studentProfileId: selectedStudent.id,
        })),
    ];

    updateAttribute('recipients', newListRecipients);
  };

  const handleRemoveAllRecipients = () => updateAttribute('recipients', []);

  useEffect(() => {
    !!selectedClassroomId && fetchStudentProfiles();
    handleOnChangeSelectedStudent(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClassroomId, fetchStudentProfiles]);

  useEffect(() => {
    !!selectedStudent &&
      kind !== channelKind.family &&
      fetchConfirmedResponsibles();
  }, [selectedStudent, fetchConfirmedResponsibles, kind]);

  return (
    <FormFieldset>
      <S.RowWrapper>
        <S.StepTitle>
          {t('omni_channel.forms.message_chat.create.title')}
        </S.StepTitle>

        <S.SelectOptionsWrapper>
          <ClassroomSelect
            label={t('omni_channel.forms.message_chat.fields.classroom_text')}
            options={getClassroomOptions(chatClassrooms)}
            placeholder={t(
              'omni_channel.forms.message_chat.fields.select_classroom_text'
            )}
            onChange={(option) =>
              handleOnChangeSelectedClassroom(option?.value)
            }
            emptyText={t(
              'omni_channel.forms.message_chat.fields.not_find_classroom_text'
            )}
          />

          <StudentSelect
            label={t('omni_channel.forms.message_chat.fields.student_text')}
            options={chatStudentProfiles}
            placeholder={t(
              'omni_channel.forms.message_chat.fields.select_student_text'
            )}
            onChange={(student) => handleOnChangeSelectedStudent(student)}
            emptyText={t(
              'omni_channel.forms.message_chat.fields.not_find_student_text'
            )}
            selectedStudent={selectedStudent}
          />
        </S.SelectOptionsWrapper>

        <S.RecipientsAlert variant="informative">
          {t('omni_channel.forms.message_chat.form.alert_text')}
        </S.RecipientsAlert>

        {selectedStudent &&
          !!chatConfirmedResponsibles &&
          kind !== channelKind.family && (
            <>
              <Text color="neutral.black" variant="body-regular-16">
                {t(
                  'omni_channel.forms.message_chat.form.select_recipients_title'
                )}
              </Text>

              <RecipientsSelect
                onChange={handleChangeRecipients}
                addAllRecipients={handleAddAllRecipients}
                selectedRecipients={recipients}
                selectedStudent={selectedStudent}
                recipientsList={
                  groupFamilyStudent
                    ? [
                        groupFamilyStudent,
                        selectedStudent,
                        ...chatConfirmedResponsibles,
                      ]
                    : [selectedStudent, ...chatConfirmedResponsibles]
                }
              />
            </>
          )}

        {recipients?.length > 0 && (
          <>
            <Text variant="body-regular-16" color="neutral.black">
              {t(
                'omni_channel.forms.message_chat.form.selected_recipients_title'
              )}
            </Text>

            <RecipientsSelected
              recipientsList={recipients}
              onChange={handleChangeRecipients}
              removeAllRecipients={handleRemoveAllRecipients}
            />
          </>
        )}
      </S.RowWrapper>
    </FormFieldset>
  );
};

export default tabifyForWizard({
  title: '1 Participantes',
})(withAppContext(withFormContext(RecipientsStep)));
