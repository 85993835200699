import { Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { WrapperStyleProps } from './types';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ selected, theme: { colors, border, space } }) => css`
    display: flex;
    flex-direction: column;
    height: max-content;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;
    padding: ${space.sm};
    gap: ${space.md};
    border-bottom: ${border.style.solid} ${border.width.sm}
      ${colors.neutral.gray4};
    background-color: ${selected ? colors.brand.primary.op10 : 'white'};
    transition: background-color 0.3s;

    :hover {
      background-color: ${colorWithAlpha(colors.brand.primary.default, 0.05)};
    }
  `}
`;

export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const UserContentWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: ${space.xs};
  `}
`;

export const CardTopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Message = styled(Text)`
  ${({ theme: { font } }: ThemeProps) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: ${font.height.lg} !important;
    margin: 0px;
  `}
`;

export const SkeletonWrapper = styled.div`
  ${({ theme: { space, border, colors } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${space.sm};
    gap: ${space.xs};
    border-bottom: ${border.style.solid} ${border.width.sm}
      ${colors.neutral.gray4};
  `}
`;

export const SkeletonLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

export const Recipients = styled.div`
  display: flex;
`;

export const TagsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${space.xs};
  `}
`;
