import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import actions from './actions';

import { deleteApi, fetchApi, postApi } from 'core/utils/api';
import { toastCss } from 'core/hoc/toastMessageProvider';

import { buildToast, ToastTypes } from 'store/middlewares/toast/actions';

import i18n from 'config/i18n';
import { normalizeFetchEventDetails, normalizeFetchEvents } from './normalize';

import { ActionsSaga } from './types/actions';
import { CalendarState } from './types/store';
import { fetchEventsQueryUrl } from './queries';

function* fetchClassroomsRequestSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_SUCCESS,
      classrooms: history,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('calendar:toast.event_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchEventDetailsSaga({ id }: ActionsSaga['fetchEventDetails']) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/calendar_events/${id}.json?include=versions,studentProfiles,classrooms`
    );

    const event = normalizeFetchEventDetails(data, included);

    yield put({
      type: actions.FETCH_EVENT_DETAILS_SUCCESS,
      event,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('calendar:toast.event_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchEventsSaga({ page }: ActionsSaga['fetchEvents']) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      list: { filters },
    } = yield select((state: CalendarState) => state.calendar.event);

    const queryParams = fetchEventsQueryUrl(filters);

    const {
      data,
      included,
      meta: pagination,
    } = yield call(
      fetchApi,
      `/${dataArea}/calendar_events.json?page[size]=20&page[number]=${page}&${queryParams}`
    );

    const events = normalizeFetchEvents(data, included);

    yield put({
      type: actions.FETCH_EVENTS_SUCCESS,
      events,
      pagination,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('calendar:toast.event_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* setApproveEventSaga({
  actionContext,
  id,
  notify,
}: ActionsSaga['setApproveEvent']) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      postApi,
      `/${dataArea}/calendar_events/${id}/approve?notify=${notify}`
    );

    if (actionContext === 'approve') {
      yield put({
        type: actions.SET_APPROVE_EVENT_SUCCESS,
        actionContext,
      });
    } else {
      yield put({
        type: actions.SET_APPROVE_EVENT_SUCCESS,
        actionContext,
        toast: buildToast(
          i18n.t('calendar:toast.resend_event_sucess'),
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('calendar:toast.event_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

// it will be necessary to update the redux state with the deleted event
function* setDeleteEventSaga({
  id,
  actionContext,
}: ActionsSaga['setDeleteEvent']) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(deleteApi, `/${dataArea}/calendar_events/${id}`);

    yield put({
      type: actions.SET_DELETE_EVENT_SUCCESS,
      actionContext,
      eventId: id,
      toast: buildToast(
        i18n.t('calendar:toast.delete_event_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('calendar:toast.delete_event_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}
function* calendar() {
  yield all([
    takeLatest(actions.FETCH_CLASSROOMS_REQUEST, fetchClassroomsRequestSaga),
    takeLatest(actions.FETCH_EVENT_DETAILS_REQUEST, fetchEventDetailsSaga),
    takeLatest(actions.FETCH_EVENTS_REQUEST, fetchEventsSaga),
    takeLatest(actions.SET_APPROVE_EVENT_REQUEST, setApproveEventSaga),
    takeLatest(actions.SET_DELETE_EVENT_REQUEST, setDeleteEventSaga),
  ]);
}

export default calendar;
