import { FetchSubjectsSagaProps, OmniChannelActionProps } from './types';

const actions: OmniChannelActionProps = {
  ERROR: 'ERROR/omniChannels',
  FETCH_CHANNEL_REQUEST: 'FETCH_CHANNEL_REQUEST/omniChannels',
  FETCH_CHANNEL_SUCCESS: 'FETCH_CHANNEL_SUCCESS/omniChannels',
  FETCH_CHANNEL_SUBJECTS_REQUEST: 'FETCH_CHANNEL_SUBJECTS_REQUEST/omniChannels',
  FETCH_CHANNEL_SUBJECTS_SUCCESS: 'FETCH_CHANNEL_SUBJECTS_SUCCESS/omniChannels',
  FETCH_CHANNELS_REQUEST: 'FETCH_CHANNELS_REQUEST/omniChannels',
  FETCH_CHANNELS_SUCCESS: 'FETCH_CHANNELS_SUCCESS/omniChannels',
  FETCH_CHANNELS_BY_STUDENT_PROFILE_REQUEST:
    'FETCH_CHANNELS_BY_STUDENT_PROFILE_REQUEST/omniChannels',
  FETCH_CHANNELS_BY_STUDENT_PROFILE_SUCCESS:
    'FETCH_CHANNELS_BY_STUDENT_PROFILE_SUCCESS/omniChannels',
  FETCH_CHAT_CLASSROOMS_REQUEST: 'FETCH_CHAT_CLASSROOMS_REQUEST/omniChannels',
  FETCH_CHAT_CLASSROOMS_SUCCESS: 'FETCH_CHAT_CLASSROOMS_SUCCESS/omniChannels',
  FETCH_CHAT_CONFIRMED_RESPONSIBLES_REQUEST:
    'FETCH_CHAT_CONFIRMED_RESPONSIBLES_REQUEST/omniChannel',
  FETCH_CHAT_CONFIRMED_RESPONSIBLES_SUCCESS:
    'FETCH_CHAT_CONFIRMED_RESPONSIBLES_SUCCESS/omniChannel',
  FETCH_CHAT_PENDING_COUNT_REQUEST:
    'FETCH_CHAT_PENDING_COUNT_REQUEST/omniChannel',
  FETCH_CHAT_PENDING_COUNT_SUCCESS:
    'FETCH_CHAT_PENDING_COUNT_SUCCESS/omniChannel',
  FETCH_CHAT_STUDENT_PROFILES_REQUEST:
    'FETCH_CHAT_STUDENT_PROFILES_REQUEST/omniChannels',
  FETCH_CHAT_STUDENT_PROFILES_SUCCESS:
    'FETCH_CHAT_STUDENT_PROFILES_SUCCESS/omniChannels',
  FETCH_CHAT_SEARCH_USERS_REQUEST:
    'FETCH_CHAT_SEARCH_USERS_REQUEST/omniChannels',
  FETCH_CHAT_SEARCH_USERS_SUCCESS:
    'FETCH_CHAT_SEARCH_USERS_SUCCESS/omniChannels',
  FETCH_CHATS_REQUEST: 'FETCH_CHATS_REQUEST/omniChannels',
  FETCH_CHATS_SUCCESS: 'FETCH_CHATS_SUCCESS/omniChannels',
  FETCH_CHANNEL_FORM_REQUEST: 'FETCH_CHANNEL_FORM_REQUEST/omniChannels',
  FETCH_CHANNEL_FORM_SUCCESS: 'FETCH_CHANNEL_FORM_SUCCESS/omniChannels',
  FETCH_CURRENT_CHAT_REQUEST: 'FETCH_CURRENT_CHAT_REQUEST/omniChannels',
  FETCH_CURRENT_CHAT_SUCCESS: 'FETCH_CURRENT_CHAT_SUCCESS/omniChannels',
  FETCH_DELETED_MESSAGE_REQUEST: 'FETCH_DELETED_MESSAGE_REQUEST/omniChannels',
  FETCH_DELETED_MESSAGE_SUCCESS: 'FETCH_DELETED_MESSAGE_SUCCESS/omniChannels',
  FETCH_EDIT_MESSAGE_REQUEST: 'FETCH_EDIT_MESSAGE_REQUEST/omniChannels',
  FETCH_EDIT_MESSAGE_SUCCESS: 'FETCH_EDIT_MESSAGE_SUCCESS/omniChannels',
  FETCH_INITIAL_CHANNEL_FORM_REQUEST:
    'FETCH_INITIAL_CHANNEL_FORM_REQUEST/omniChannels',
  FETCH_INITIAL_CHANNEL_FORM_SUCCESS:
    'FETCH_INITIAL_CHANNEL_FORM_SUCCESS/omniChannels',
  FETCH_APPROVED_MESSAGE_REQUEST: 'FETCH_APPROVED_MESSAGE_REQUEST/omniChannels',
  FETCH_APPROVED_MESSAGE_SUCCESS: 'FETCH_APPROVED_MESSAGE_SUCCESS/omniChannels',
  FETCH_MESSAGE_REQUEST: 'FETCH_MESSAGE_REQUEST/omniChannels',
  FETCH_MESSAGE_SUCCESS: 'FETCH_MESSAGE_SUCCESS/omniChannels',
  FETCH_MESSAGES_REQUEST: 'FETCH_MESSAGES_REQUEST/omniChannels',
  FETCH_MESSAGES_SUCCESS: 'FETCH_MESSAGES_SUCCESS/omniChannels',
  FETCH_NEW_CHAT_CLASSROOMS_REQUEST:
    'FETCH_NEW_CHAT_CLASSROOMS_REQUEST/omniChannels',
  FETCH_NEW_CHAT_CLASSROOMS_SUCCESS:
    'FETCH_NEW_CHAT_CLASSROOMS_SUCCESS/omniChannels',
  FETCH_NEW_CHAT_FAMILY_REQUEST: 'FETCH_NEW_CHAT_FAMILY_REQUEST/omniChannels',
  FETCH_NEW_CHAT_FAMILY_SUCCESS: 'FETCH_NEW_CHAT_FAMILY_SUCCESS/omniChannels',
  FETCH_NEW_CHAT_RESPONSIBLES_REQUEST:
    'FETCH_NEW_CHAT_RESPONSIBLES_REQUEST/omniChannels',
  FETCH_NEW_CHAT_RESPONSIBLES_SUCCESS:
    'FETCH_NEW_CHAT_RESPONSIBLES_SUCCESS/omniChannels',
  FETCH_NEW_CHAT_STUDENTS_REQUEST:
    'FETCH_NEW_CHAT_STUDENTS_REQUEST/omniChannels',
  FETCH_NEW_CHAT_STUDENTS_SUCCESS:
    'FETCH_NEW_CHAT_STUDENTS_SUCCESS/omniChannels',
  FETCH_SCHOOL_USERS_REQUEST: 'FETCH_SCHOOL_USERS_REQUEST/omniChannels',
  FETCH_SCHOOL_USERS_SUCCESS: 'FETCH_SCHOOL_USERS_SUCCESS/omniChannels',
  FETCH_TICKET_DETAIL_REQUEST: 'FETCH_TICKET_DETAIL_REQUEST/omniChannels',
  FETCH_TICKET_DETAIL_SUCCESS: 'FETCH_TICKET_DETAIL_SUCCESS/omniChannels',
  FETCH_TICKET_PENDING_COUNT_REQUEST:
    'FETCH_TICKET_PENDING_COUNT_REQUEST/omniChannels',
  FETCH_TICKET_PENDING_COUNT_SUCCESS:
    'FETCH_TICKET_PENDING_COUNT_SUCCESS/omniChannels',
  FETCH_TICKETS_REQUEST: 'FETCH_TICKETS_REQUEST/omniChannels',
  FETCH_TICKETS_SUCCESS: 'FETCH_TICKETS_SUCCESS/omniChannels',
  FETCH_TICKET_STUDENT_CHANNELS_REQUEST:
    'FETCH_TICKET_STUDENT_CHANNELS_REQUEST/omniChannels',
  FETCH_TICKET_STUDENT_CHANNELS_SUCCESS:
    'FETCH_TICKET_STUDENT_CHANNELS_SUCCESS/omniChannels',
  FETCH_UNAPPROVED_MESSAGE_REQUEST:
    'FETCH_UNAPPROVED_MESSAGE_REQUEST/omniChannels',
  FETCH_UNAPPROVED_MESSAGE_SUCCESS:
    'FETCH_UNAPPROVED_MESSAGE_SUCCESS/omniChannels',
  FETCH_UNREAD_CHATS_REQUEST: 'FETCH_UNREAD_CHATS_REQUEST/omniChannels',
  FETCH_UNREAD_CHATS_SUCCESS: 'FETCH_UNREAD_CHATS_SUCCESS/omniChannels',
  SET_APPROVE_MESSAGE_REQUEST: 'SET_APPROVE_MESSAGE_REQUEST/omniChannels',
  SET_APPROVE_MESSAGE_SUCCESS: 'SET_APPROVE_MESSAGE_SUCCESS/omniChannels',
  SET_CHAT_CURRENT_TAB: 'SET_CHAT_CURRENT_TAB/omniChannels',
  SET_CHAT_FILTERS: 'SET_CHAT_FILTERS/omniChannels',
  SET_CHAT_FORM_FILTERS: 'SET_CHAT_FORM_FILTERS/omniChannels',
  SET_CLEAN_CHANNEL_FORM: 'SET_CLEAN_CHANNEL_FORM/omniChannels',
  SET_CHAT_SEARCH_CURRENT_TAB: 'SET_CHAT_SEARCH_CURRENT_TAB/omniChannels',
  SET_CREATE_NEW_CHANNEL_REQUEST: 'SET_CREATE_NEW_CHANNEL_REQUEST/omniChannels',
  SET_CREATE_NEW_CHANNEL_SUCCESS: 'SET_CREATE_NEW_CHANNEL_SUCCESS/omniChannels',
  SET_CREATE_OLD_NEW_CHAT_REQUEST:
    'SET_CREATE_OLD_NEW_CHAT_REQUEST/omniChannels',
  SET_CREATE_OLD_NEW_CHAT_SUCCESS:
    'SET_CREATE_OLD_NEW_CHAT_SUCCESS/omniChannels',
  SET_CREATE_NEW_CHAT_REQUEST: 'SET_CREATE_NEW_CHAT_REQUEST/omniChannels',
  SET_CREATE_NEW_CHAT_SUCCESS: 'SET_CREATE_NEW_CHAT_SUCCESS/omniChannels',
  SET_CREATE_NEW_CHAT_USER_REQUEST:
    'SET_CREATE_NEW_CHAT_USER_REQUEST/omniChannels',
  SET_CREATE_NEW_CHAT_USER_SUCCESS:
    'SET_CREATE_NEW_CHAT_USER_SUCCESS/omniChannels',
  SET_CREATE_NEW_MESSAGE_REQUEST: 'SET_CREATE_NEW_MESSAGE_REQUEST/omniChannels',
  SET_CREATE_NEW_MESSAGE_SUCCESS: 'SET_CREATE_NEW_MESSAGE_SUCCESS/omniChannels',
  SET_CREATE_NEW_TICKET_BY_SCHOOL_REQUEST:
    'SET_CREATE_NEW_TICKET_BY_SCHOOL_REQUEST/omniChannels',
  SET_CREATE_NEW_TICKET_BY_SCHOOL_SUCCESS:
    'SET_CREATE_NEW_TICKET_BY_SCHOOL_SUCCESS/omniChannels',
  SET_CURRENT_CHANNEL: 'SET_CURRENT_CHANNEL/omniChannels',
  SET_CURRENT_CHAT: 'SET_CURRENT_CHAT/omniChannels',
  SET_CURRENT_CHAT_USER: 'SET_CURRENT_CHAT_USER/omniChannels',
  SET_CURRENT_MESSAGE: 'SET_CURRENT_MESSAGE/omniChannels',
  SET_DELETE_CHANNEL_REQUEST: 'SET_DELETE_CHANNEL_REQUEST/omniChannels',
  SET_DELETE_CHANNEL_SUCCESS: 'SET_DELETE_CHANNEL_SUCCESS/omniChannels',
  SET_DELETE_MESSAGE_REQUEST: 'SET_DELETE_MESSAGE_REQUEST/omniChannels',
  SET_DELETE_MESSAGE_SUCCESS: 'SET_DELETE_MESSAGE_SUCCESS/omniChannels',
  SET_EDIT_MESSAGE_REQUEST: 'SET_EDIT_MESSAGE_REQUEST/omniChannels',
  SET_EDIT_MESSAGE_SUCCESS: 'SET_EDIT_MESSAGE_SUCCESS/omniChannels',
  SET_EDIT_CHANNEL_REQUEST: 'SET_EDIT_CHANNEL_REQUEST',
  SET_EDIT_CHANNEL_SUCCESS: 'SET_EDIT_CHANNEL_SUCCESS',
  SET_MESSAGES_MARK_READ_STATUS_REQUEST:
    'SET_MESSAGES_MARK_READ_STATUS_REQUEST',
  SET_MESSAGES_MARK_READ_STATUS_SUCCESS:
    'SET_MESSAGES_MARK_READ_STATUS_SUCCESS',
  SET_UPDATE_CHANNELS_ORDER_REQUEST:
    'SET_UPDATE_CHANNELS_ORDER_REQUEST/omniChannels',
  SET_UPDATE_CHANNELS_ORDER_SUCCESS:
    'SET_UPDATE_CHANNELS_ORDER_SUCCESS/omniChannels',
  SET_REDIRECT_CHANNEL_PAGE: 'SET_REDIRECT_CHANNEL_PAGE/omniChannels',
  SET_REDIRECT_CHANNEL_PAGE_SUCCESS:
    'SET_REDIRECT_CHANNEL_PAGE_SUCCESS/omniChannels',
  SET_REDIRECT_CHANNEL_ERROR: 'SET_REDIRECT_CHANNEL_ERROR/omniChannels',
  SET_REDIRECT_MESSAGE_REQUEST: 'SET_REDIRECT_MESSAGE_REQUEST',
  SET_REDIRECT_MESSAGE_SUCCESS: 'SET_REDIRECT_MESSAGE_SUCCESS',
  SET_REPLY_MESSAGE_SUCCESS: 'SET_REPLY_MESSAGE_SUCCESS/omniChannels',
  SET_START_OR_CLOSE_ATTENDANCE_REQUEST:
    'SET_START_OR_CLOSE_ATTENDANCE_REQUEST/omniChannels',
  SET_START_OR_CLOSE_ATTENDANCE_SUCCESS:
    'SET_START_OR_CLOSE_ATTENDANCE_SUCCESS/omniChannels',
  SET_TRANSFER_TICKET_REQUEST: 'SET_TRANSFER_TICKET_REQUEST/omniChannels',
  SET_TRANSFER_TICKET_SUCCESS: 'SET_TRANSFER_TICKET_SUCCESS/omniChannels',
  SOCKET_LEAVE_CHAT_ROOM_REQUEST: 'SOCKET_LEAVE_CHAT_ROOM_REQUEST/omniChannels',
  SOCKET_CONNECT_SERVER_REQUEST: 'SOCKET_CONNECT_SERVER_REQUEST/omniChannels',
  SOCKET_CONNECT_SERVER_SUCCESS: 'SOCKET_CONNECT_SERVER_SUCCESS/omniChannels',
  SOCKET_ENTER_CHAT_ROOM_REQUEST: 'SOCKET_ENTER_CHAT_ROOM_REQUEST/omniChannels',
  TOGGLE_ATTACHMENT_MODAL: 'TOGGLE_ATTACHMENT_MODAL/omniChannels',
  TOGGLE_CHAT_DISCARD_MODAL: 'TOGGLE_CHAT_DISCARD_MODAL/omniChannels',
  TOGGLE_CHAT_IS_SEARCH_TAB: 'TOGGLE_CHAT_IS_SEARCH_TAB/omniChannels',
  TOGGLE_DELETE_CHANNEL_MODAL: 'TOGGLE_DELETE_CHANNEL_MODAL/omniChannels',
  TOGGLE_EDIT_MESSAGE: 'TOGGLE_EDIT_MESSAGE/omniChannels',
  TOGGLE_FILTER_CHAT_MODAL: 'TOGGLE_FILTER_CHAT_MODAL/omniChannels',
  TOGGLE_NEW_CHANNEL_MODAL: 'TOGGLE_NEW_CHANNEL_MODAL/omniChannels',
  TOGGLE_REPLY_MESSAGE: 'TOGGLE_REPLY_MESSAGE/omniChannels',
  TOGGLE_RESET_ACTIVE_CHANNEL: 'TOGGLE_RESET_ACTIVE_CHANNEL/omniChannels',
  TOGGLE_RESET_ACTIVE_CHAT: 'TOGGLE_RESET_ACTIVE_CHAT/omniChannels',
  TOGGLE_REDIRECT_MESSAGE_MODAL: 'TOGGLE_REDIRECT_MESSAGE_MODAL/omniChannels',
  TOGGLE_REPORT_MESSAGES_MODAL: 'TOGGLE_REPORT_MESSAGES_MODAL/omniChannels',
  TOGGLE_SIDE_DETAILS_MODAL: 'TOGGLE_SIDE_DETAILS_MODAL/omniChannels',
  TOGGLE_TRANSFER_TICKET_MODAL: 'TOGGLE_TRANSFER_TICKET_MODAL/omniChannels',
  TOGGLE_PICKER_OPENING_HOURS_MODAL:
    'TOGGLE_PICKER_OPENING_HOURS_MODAL/omniChannels',

  fetchChannelRequest: (channelId) => ({
    type: actions.FETCH_CHANNEL_REQUEST,
    channelId,
  }),

  fetchChannelSubjectsRequest: ({
    channelId,
    withInactive,
  }: FetchSubjectsSagaProps['params']) => ({
    type: actions.FETCH_CHANNEL_SUBJECTS_REQUEST,
    channelId,
    withInactive,
  }),

  fetchChannelsRequest: (channelId) => ({
    type: actions.FETCH_CHANNELS_REQUEST,
    channelId,
  }),

  fetchChannelsByStudentProfileRequest: (studentProfileId) => ({
    type: actions.FETCH_CHANNELS_BY_STUDENT_PROFILE_REQUEST,
    studentProfileId,
  }),

  fetchChatClassroomsRequest: (channelId) => ({
    type: actions.FETCH_CHAT_CLASSROOMS_REQUEST,
    channelId,
  }),

  fetchChatConfirmedResponsiblesRequest: (studentId) => ({
    type: actions.FETCH_CHAT_CONFIRMED_RESPONSIBLES_REQUEST,
    studentId,
  }),

  fetchChatPendingCountRequest: (params) => ({
    type: actions.FETCH_CHAT_PENDING_COUNT_REQUEST,
    params,
  }),

  fetchChatStudentProfilesRequest: (classroomId) => ({
    type: actions.FETCH_CHAT_STUDENT_PROFILES_REQUEST,
    classroomId,
  }),

  fetchCurrentChatRequest: (params) => ({
    type: actions.FETCH_CURRENT_CHAT_REQUEST,
    params,
  }),

  fetchChatsRequest: (params) => ({
    type: actions.FETCH_CHATS_REQUEST,
    params,
  }),

  fetchChannelFormRequest: (channelId) => ({
    type: actions.FETCH_CHANNEL_FORM_REQUEST,
    channelId,
  }),

  fetchInitialChannelFormRequest: () => ({
    type: actions.FETCH_INITIAL_CHANNEL_FORM_REQUEST,
  }),

  fetchSchoolUsersRequest: (classroomsIds) => ({
    type: actions.FETCH_SCHOOL_USERS_REQUEST,
    classroomsIds,
  }),

  fetchChatSearchUsersRequest: (params) => ({
    type: actions.FETCH_CHAT_SEARCH_USERS_REQUEST,
    params,
  }),

  fetchMessageRequest: (params) => ({
    type: actions.FETCH_MESSAGE_REQUEST,
    params,
  }),

  fetchMessagesRequest: (params) => ({
    type: actions.FETCH_MESSAGES_REQUEST,
    params,
  }),

  fetchNewChatClassroomsRequest: (channelId, page) => ({
    type: actions.FETCH_NEW_CHAT_CLASSROOMS_REQUEST,
    channelId,
    page,
  }),

  fetchNewChatFamilyRequest: (channelId, page) => ({
    type: actions.FETCH_NEW_CHAT_FAMILY_REQUEST,
    channelId,
    page,
  }),

  fetchNewChatResponsiblesRequest: (channelId, page) => ({
    type: actions.FETCH_NEW_CHAT_RESPONSIBLES_REQUEST,
    channelId,
    page,
  }),

  fetchNewChatStudentsRequest: (channelId, page) => ({
    type: actions.FETCH_NEW_CHAT_STUDENTS_REQUEST,
    channelId,
    page,
  }),

  fetchTicketDetailRequest: (params) => ({
    type: actions.FETCH_TICKET_DETAIL_REQUEST,
    params,
  }),

  fetchTicketPendingCountRequest: (params) => ({
    type: actions.FETCH_TICKET_PENDING_COUNT_REQUEST,
    params,
  }),

  fetchTicketsRequest: (params) => ({
    type: actions.FETCH_TICKETS_REQUEST,
    params,
  }),

  fetchTicketStudentChannelsRequest: (params) => ({
    type: actions.FETCH_TICKET_STUDENT_CHANNELS_REQUEST,
    params,
  }),

  fetchUnreadChatsrequest: () => ({
    type: actions.FETCH_UNREAD_CHATS_REQUEST,
  }),

  setChatCurrentTab: (tab) => ({
    type: actions.SET_CHAT_CURRENT_TAB,
    tab,
  }),

  setChatFilters: (filters) => ({
    type: actions.SET_CHAT_FILTERS,
    filters,
  }),

  setChatSearchCurrentTab: (tab) => ({
    type: actions.SET_CHAT_SEARCH_CURRENT_TAB,
    tab,
  }),

  setChatFormFilters: (filters) => ({
    type: actions.SET_CHAT_FORM_FILTERS,
    filters,
  }),

  setCleanChannelForm: () => ({
    type: actions.SET_CLEAN_CHANNEL_FORM,
  }),

  setCreateNewChannelRequest: (params) => ({
    type: actions.SET_CREATE_NEW_CHANNEL_REQUEST,
    params,
  }),

  setCreateOldNewChatRequest: (params) => ({
    type: actions.SET_CREATE_OLD_NEW_CHAT_REQUEST,
    params,
  }),

  setCreateNewChatRequest: (params) => ({
    type: actions.SET_CREATE_NEW_CHAT_REQUEST,
    params,
  }),

  setCreateNewChatUserRequest: (params) => ({
    type: actions.SET_CREATE_NEW_CHAT_USER_REQUEST,
    params,
  }),

  setCreateNewMessageRequest: (params) => ({
    type: actions.SET_CREATE_NEW_MESSAGE_REQUEST,
    params,
  }),

  setCreateNewTicketBySchoolRequest: (params) => ({
    type: actions.SET_CREATE_NEW_TICKET_BY_SCHOOL_REQUEST,
    params,
  }),

  setCurrentChannel: (params) => ({
    type: actions.SET_CURRENT_CHANNEL,
    params,
  }),

  setCurrentChat: (params) => ({
    type: actions.SET_CURRENT_CHAT,
    params,
  }),

  setCurrentChatUser: (chatUserId) => ({
    type: actions.SET_CURRENT_CHAT_USER,
    chatUserId,
  }),

  setCurrentMessage: (message) => ({
    type: actions.SET_CURRENT_MESSAGE,
    message,
  }),

  toggleChatIsSearchTab: () => ({
    type: actions.TOGGLE_CHAT_IS_SEARCH_TAB,
  }),

  setApproveMessageRequest: (params) => ({
    type: actions.SET_APPROVE_MESSAGE_REQUEST,
    params,
  }),

  setDeleteChannelRequest: (channelId: string) => ({
    type: actions.SET_DELETE_CHANNEL_REQUEST,
    channelId,
  }),

  setDeleteMessageRequest: (params) => ({
    type: actions.SET_DELETE_MESSAGE_REQUEST,
    params,
  }),

  setEditMessageRequest: (params) => ({
    type: actions.SET_EDIT_MESSAGE_REQUEST,
    params,
  }),

  setEditChannelRequest: (params) => ({
    type: actions.SET_EDIT_CHANNEL_REQUEST,
    params,
  }),

  setMessagesMarkReadStatusRequest: (params) => ({
    type: actions.SET_MESSAGES_MARK_READ_STATUS_REQUEST,
    params,
  }),

  setUpdateChannelsOrderRequest: (channelsIds) => ({
    type: actions.SET_UPDATE_CHANNELS_ORDER_REQUEST,
    channelsIds,
  }),

  setRedirectChannelPage: (params) => ({
    type: actions.SET_REDIRECT_CHANNEL_PAGE,
    params,
  }),

  setRedirectMessageRequest: (params) => ({
    type: actions.SET_REDIRECT_MESSAGE_REQUEST,
    params,
  }),

  setStartOrCloseAttendanceRequest: (params) => ({
    type: actions.SET_START_OR_CLOSE_ATTENDANCE_REQUEST,
    params,
  }),

  setTransferTicketRequest: (params) => ({
    type: actions.SET_TRANSFER_TICKET_REQUEST,
    params,
  }),

  socketEnterChatRoomRequest: (chatId) => ({
    type: actions.SOCKET_ENTER_CHAT_ROOM_REQUEST,
    chatId,
  }),

  socketLeaveChatRoomRequest: (chatId) => ({
    type: actions.SOCKET_LEAVE_CHAT_ROOM_REQUEST,
    chatId,
  }),

  socketConnectRequest: () => ({
    type: actions.SOCKET_CONNECT_SERVER_REQUEST,
  }),

  toggleAttachmentModal: () => ({
    type: actions.TOGGLE_ATTACHMENT_MODAL,
  }),

  toggleDeleteChannelModal: () => ({
    type: actions.TOGGLE_DELETE_CHANNEL_MODAL,
  }),

  toggleChatDiscardModal: () => ({
    type: actions.TOGGLE_CHAT_DISCARD_MODAL,
  }),

  toggleEditMessage: () => ({
    type: actions.TOGGLE_EDIT_MESSAGE,
  }),

  toggleFilterChatModal: () => ({
    type: actions.TOGGLE_FILTER_CHAT_MODAL,
  }),

  toggleNewChannelModal: () => ({
    type: actions.TOGGLE_NEW_CHANNEL_MODAL,
  }),

  toggleReplyMessage: () => ({
    type: actions.TOGGLE_REPLY_MESSAGE,
  }),

  toggleResetActiveChannel: () => ({
    type: actions.TOGGLE_RESET_ACTIVE_CHANNEL,
  }),

  toggleResetActiveChat: () => ({
    type: actions.TOGGLE_RESET_ACTIVE_CHAT,
  }),

  toggleRedirectMessageModal: () => ({
    type: actions.TOGGLE_REDIRECT_MESSAGE_MODAL,
  }),

  toggleReportMessagesModal: () => ({
    type: actions.TOGGLE_REPORT_MESSAGES_MODAL,
  }),

  toggleSideDetailsModal: () => ({
    type: actions.TOGGLE_SIDE_DETAILS_MODAL,
  }),

  toggleTransferTicketModal: () => ({
    type: actions.TOGGLE_TRANSFER_TICKET_MODAL,
  }),

  toggleOpeningHoursModal: () => ({
    type: actions.TOGGLE_PICKER_OPENING_HOURS_MODAL,
  }),
};

export default actions;
