import i18n from 'config/i18n';

export const REMINDER_UNITS = Object.freeze({
  '1': 'minutes',
  '2': 'hours',
  '3': 'days',
  '4': 'weeks',
});

export const SCHEDULE_UNITS = Object.freeze({
  '1': 'weeks',
  '2': 'days',
  '3': 'hours',
});

export const REMINDER_MESSAGES = Object.freeze({
  INVALID_MESSAGE: 'invalid_message_text',
  VALUE_RANGE_INPUT: 'value_range_input_text',
});

export const REMINDER_DATE_OPTIONS = [
  {
    value: '1',
    label: i18n.t('calendar:forms.event.modals.reminder.options.minute_text'),
  },
  {
    value: '2',
    label: i18n.t('calendar:forms.event.modals.reminder.options.hour_text'),
  },
  {
    value: '3',
    label: i18n.t('calendar:forms.event.modals.reminder.options.day_text'),
  },
  {
    value: '4',
    label: i18n.t('calendar:forms.event.modals.reminder.options.week_text'),
  },
];

export const REMINDER_SCHEDULE_OPTIONS = [
  {
    value: '1',
    label: i18n.t(
      'calendar:forms.event.steps.schedule.options_select.one_week_text'
    ),
  },
  {
    value: '2',
    label: i18n.t(
      'calendar:forms.event.steps.schedule.options_select.one_day_text'
    ),
  },
  {
    value: '3',
    label: i18n.t(
      'calendar:forms.event.steps.schedule.options_select.one_hour_text'
    ),
  },
  {
    value: '4',
    label: i18n.t(
      'calendar:forms.event.steps.schedule.options_select.custom_text'
    ),
  },
];

export const DETAILS_TABS_OPTIONS = [
  {
    title: i18n.t('calendar:details.tabs.informations.title'),
    tabName: 'details',
  },
  {
    title: i18n.t('calendar:details.tabs.engagement.title'),
    tabName: 'engagement',
  },
];

export const EVENT_STATUS = Object.freeze({
  approved: i18n.t('calendar:common.event.status.approved'),
  canceled: i18n.t('calendar:common.event.status.canceled'),
  not_approved: i18n.t('calendar:common.event.status.not_approved'),
  processing: i18n.t('calendar:common.event.status.processing'),
  sent: i18n.t('calendar:common.event.status.sent'),
  waiting: i18n.t('calendar:common.event.status.waiting'),
});

export const EVENT_TAG_STATUS_VARIANT = Object.freeze({
  approved: 'positive',
  canceled: 'neutral',
  not_approved: 'warning',
  processing: 'informative',
  sent: 'positive',
  waiting: 'warning',
  sending: 'informative',
} as const);

export const AVAILABLE_TO = Object.freeze({
  both: i18n.t('calendar:common.event.available_to.both'),
  family: i18n.t('calendar:common.event.available_to.family'),
  responsible: i18n.t('calendar:common.event.available_to.responsible'),
});

export const STATUS_FILTER_OPTIONS = [
  {
    value: '',
    label: i18n.t('calendar:list.filters.event_status.all_text'),
  },
  {
    value: 'waiting',
    label: i18n.t('calendar:list.filters.event_status.waiting_text'),
  },
  {
    value: 'sent',
    label: i18n.t('calendar:list.filters.event_status.sent_text'),
  },
  {
    value: 'canceled',
    label: i18n.t('calendar:list.filters.event_status.canceled_text'),
  },
  {
    value: 'closed',
    label: i18n.t('calendar:list.filters.event_status.closed_text'),
  },
];

export const EMPTY_STATE_LIST_URL =
  'https://static.agendaedu.com/survey/app_error.png';
