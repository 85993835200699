import styled from 'styled-components';

export const AllDayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 0px !important;

  label {
    margin-top: 2px;
  }

  span:nth-of-type(2) {
    margin-top: 3px;
  }
`;
