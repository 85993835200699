import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Icon } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { FileUpload } from 'store/messages/omniChannel/types';

import useOutsideClick from 'core/hooks/useOutsideClick';
import { UploadFilesContext } from 'core/contexts/UploadFiles';
import { validFilesTypes } from 'core/constants/omniChannel';
import { LIMIT_SIZE_40_MB } from 'core/contexts/UploadFiles/constants';

import { FileItem } from 'pages/storage/Forms/File/components/FileUploader/FileItem';
import { SelectorEmotes } from 'components/Messages/EmojiPicker';

import { Props } from './types';

const InputMessage: React.FC<Props> = ({
  onChange,
  message,
  disabled,
}): JSX.Element => {
  const { t } = useTranslation(['messages', 'common']);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiRef = useRef(null);

  useOutsideClick(emojiRef, () => showEmojiPicker && setShowEmojiPicker(false));

  const { uploadFile, destroyFile, openSelectLocalFiles, selectedFiles } =
    useContext(UploadFilesContext);

  const handleAddEmoji = useCallback(
    ({ native }): void => {
      const newMessage = message.concat(native);

      onChange(newMessage);
    },
    [message, onChange]
  );

  const disabledAttachmentButton = !!selectedFiles.length;

  const validateSelectedFiles: FileUpload[] = selectedFiles.reduce(
    (prev, file: FileUpload) => {
      const invalidType =
        !validFilesTypes.includes(file.type) &&
        t('file_uploader.file_errors.invalid_file');

      const invalidSize =
        file.size > LIMIT_SIZE_40_MB &&
        t('common:file_uploader.file_errors.invalid_size');

      const invalidReasons = [invalidType, invalidSize].filter(Boolean);

      return [...prev, Object.assign(file, { invalidReasons })];
    },
    []
  );

  return (
    <Box display="flex" flexDirection="column" gap="xs" width="100%">
      <S.Wrapper>
        <S.EmoteButton
          data-testid="emote-button"
          disabled={disabled}
          onClick={() => setShowEmojiPicker(true)}
        >
          <Icon name="emoji" />
        </S.EmoteButton>

        <S.EmoteWrapper ref={emojiRef}>
          <SelectorEmotes
            onSelectEmoji={handleAddEmoji}
            disabled={!showEmojiPicker}
          />
        </S.EmoteWrapper>
        <S.InputField
          data-testid="input-field"
          disabled={disabled}
          maxRows={2}
          placeholder={t('omni_channel.messages.new_message_chat_input_text')}
          onChange={(e) => onChange(e.target.value)}
          value={message}
        />

        <S.AttachmentWrapper>
          <S.AttachmentButton
            data-testid="attachment-button"
            disabled={disabledAttachmentButton}
            onClick={() =>
              !disabledAttachmentButton && openSelectLocalFiles(validFilesTypes)
            }
          >
            <Icon name="attachment" size="md" />
          </S.AttachmentButton>
        </S.AttachmentWrapper>
      </S.Wrapper>

      <Box display="flex" flexDirection="column" gap="xs">
        {validateSelectedFiles.map((file) => (
          <FileItem
            key={file.fileId}
            file={file}
            uploadFile={uploadFile}
            destroyFile={destroyFile}
            isRemoveDisabled={file?.isForm}
          />
        ))}
      </Box>
    </Box>
  );
};

export default InputMessage;
