import { Event } from '../types';

export const informationsStepValid = (
  values: Event['form'],
  errors: Event['form']
) => {
  return (
    Boolean(values.title) &&
    !errors.title &&
    Boolean(values.description) &&
    !errors.description &&
    !errors.location &&
    (!values.hasConfirmUntil || !errors.confirmUntil)
  );
};

export const scheduleStepValid = (
  values: Event['form'],
  errors: Event['form']
) => {
  if (values.allDay) {
    return Boolean(values.reminder) && Boolean(values.startDate);
  }
  return (
    Boolean(values.reminder) &&
    Boolean(values.startDate) &&
    Boolean(!errors.endDate) &&
    Boolean(!errors.endDatetime)
  );
};

export const recipientsStepValid = (values: Event['form']) => {
  const {
    personaType,
    sentKind,
    classroomIds,
    classroomWithStudentProfileIds,
  } = values;

  if (!personaType) {
    return false;
  }

  const validationRules = {
    classrooms: Boolean(classroomIds.length),
    students: Boolean(Object.keys(classroomWithStudentProfileIds).length !== 0),
  };

  if (sentKind in validationRules) {
    return validationRules[sentKind];
  }

  return (
    Boolean(values.sentKind) &&
    Boolean(values.personaType) &&
    Boolean(values.classroomIds.length) &&
    Boolean(values.classroomWithStudentProfileIds.length)
  );
};
