import { all } from 'redux-saga/effects';
import albumsSagas from '../albums/sagas';
import permissionSagas from '../permissions/sagas';
import sophiaFiltersSagas from '../sophia/sagas';
import sponteFiltersSagas from '../sponte/sagas';
import integrationsSagas from '../integrations/sagas';
import availabilitySagas from '../edupay/availabilities/sagas';
import recurrentProductSagas from '../edupay/recurrentProducts/sagas';
import recurrentPlanSagas from '../edupay/recurrentPlans/sagas';
import enrollmentPlanSagas from '../edupay/enrollmentPlans/sagas';
import schoolProductSagas from '../edupay/schoolProducts/sagas';
import walletsSagas from '../edupay/wallets/sagas';
import recurrentBillSagas from '../edupay/recurrentBills/sagas';
import eventSagas from '../events/sagas';
import filterSagas from '../filters/sagas';
import surveySagas from '../surveys/sagas';
import myAccountSagas from '../myAccount/sagas';
import homeSagas from '../home/sagas';
import panelSagas from '../panel/sagas';
import summarydayClassroomsSagas from '../summarydayClassrooms/sagas';
import reportsSagas from '../reports/sagas';
import headquartersSagas from '../headquarters/sagas';
import dailySummaries from '../dailySummaries/sagas';
import responsibleProfilesSagas from '../responsibleProfiles/sagas';
import studentProfilesSagas from '../studentProfiles/sagas';
import shopSagas from '../edupay/shop/sagas';
import importWebSagas from '../importWeb/sagas';
import editDynamicModulesSagas from '../editDynamicModules/sagas';
import dynamicModuleSagas from '../dynamicModule/sagas';
import inviteEmptyStateSagas from '../invite/emptyState/sagas';
import registerAccountSagas from '../registerAccount/sagas';
import messagesSagas from '../messages/sagas';
import omniChannelSagas from '../messages/omniChannel/sagas';
import negotiationsSagas from 'store/edupay/negotiations/sagas';
import webCheckoutSagas from 'store/edupay/webCheckout/sagas';
import onboardingSagas from 'store/onboarding/sagas';
import billingPanelSagas from 'store/billingPanel/sagas';
import moodTrackersSagas from 'store/moodTrackers/sagas';
import handoutsSagas from '../handouts/sagas';
import hubstoreSagas from '../hubstore/sagas';
import quickAccessSagas from '../quickAccess/sagas';
import integrationsMonitorSagas from '../integrationsMonitor/sagas';
import storageSagas from '../storage/sagas';
import twoAuthFactorSagas from '../twoAuthFactor/sagas';
import schoolTermsSagas from '../schoolTerms/sagas';
import calendarSagas from '../calendar/sagas';

export default function* rootSagas() {
  yield all([
    albumsSagas(),
    permissionSagas(),
    sophiaFiltersSagas(),
    sponteFiltersSagas(),
    integrationsSagas(),
    availabilitySagas(),
    recurrentProductSagas(),
    recurrentPlanSagas(),
    enrollmentPlanSagas(),
    schoolProductSagas(),
    walletsSagas(),
    recurrentBillSagas(),
    eventSagas(),
    filterSagas(),
    surveySagas(),
    myAccountSagas(),
    homeSagas(),
    panelSagas(),
    summarydayClassroomsSagas(),
    reportsSagas(),
    headquartersSagas(),
    dailySummaries(),
    responsibleProfilesSagas(),
    studentProfilesSagas(),
    shopSagas(),
    importWebSagas(),
    editDynamicModulesSagas(),
    dynamicModuleSagas(),
    inviteEmptyStateSagas(),
    registerAccountSagas(),
    messagesSagas(),
    omniChannelSagas(),
    negotiationsSagas(),
    webCheckoutSagas(),
    onboardingSagas(),
    billingPanelSagas(),
    moodTrackersSagas(),
    handoutsSagas(),
    hubstoreSagas(),
    quickAccessSagas(),
    integrationsMonitorSagas(),
    storageSagas(),
    twoAuthFactorSagas(),
    schoolTermsSagas(),
    calendarSagas(),
  ]);
}
