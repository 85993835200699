import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  DefaultThemeProps,
  Icon,
  Text,
} from '@agendaedu/ae-web-components';
import { useTheme } from 'styled-components';

import { UploadStatus } from 'core/contexts/UploadFiles/types';
import { normalizeFileSize } from 'core/utils/formatBytes';

import * as S from './styles';

import { Props } from './types';

const FileItemComponent: React.FC<Props> = ({
  file,
  uploadFile,
  destroyFile,
  isRemoveDisabled,
}): React.ReactElement => {
  const isValidFile = !file.invalidReasons?.length;

  const { colors } = useTheme() as DefaultThemeProps;

  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    !isValidFile ? 'invalid' : file.status
  );

  const fileStatusIcon = {
    uploading: 'file-generic',
    deleting: 'check-circle',
    invalid: 'warning',
    success: 'check-circle',
  };

  const handleUpload = () => {
    if (isValidFile && !file.signedId) {
      setUploadStatus('uploading');

      uploadFile({
        file,
        onSuccess: () => setUploadStatus('success'),
        onFailed: () => setUploadStatus('failed'),
      });
    }
  };

  const handleDeleteFile = () => {
    setUploadStatus('deleting');

    destroyFile({ fileId: file.fileId, signedId: file.signedId });
  };

  const statusIcon = fileStatusIcon[uploadStatus] ?? 'check-circle';

  useEffect(() => {
    handleUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.UploaderFile key={file.fileId}>
      <Box display="flex" flexDirection="row" gap="xs2" alignItems="center">
        <Icon
          data-testid={`icon-${statusIcon}`}
          name={statusIcon}
          color={isValidFile ? colors.neutral.gray1 : colors.neutral.gray3}
        />
        <Box display="flex" maxWidth="250px">
          <Text
            mb={0}
            variant="label-regular-14"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            color={isValidFile ? colors.neutral.gray1 : colors.neutral.gray3}
          >
            {file.name}
          </Text>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" gap="xs2" alignItems="center">
        {!isValidFile ? (
          <Text
            mb={0}
            variant="label-regular-14"
            color={colors.context.danger.default}
          >
            {file.invalidReasons[0]}
          </Text>
        ) : (
          <Text mb={0} variant="label-regular-14">
            {normalizeFileSize(file.size)}
          </Text>
        )}

        {!isRemoveDisabled && (
          <>
            {uploadStatus === 'deleting' || uploadStatus === 'uploading' ? (
              <S.LoadSpinner
                data-testid="loading-spinner"
                uploadStatus={uploadStatus}
              />
            ) : (
              <S.DeleteFileButton>
                <Icon
                  data-testid="delete-file"
                  name="trash-bin"
                  onClick={handleDeleteFile}
                  color={
                    isValidFile
                      ? colors.neutral.gray1
                      : colors.context.danger.default
                  }
                />
              </S.DeleteFileButton>
            )}
          </>
        )}
      </Box>
    </S.UploaderFile>
  );
};

export const FileItem = memo(
  FileItemComponent,
  (prev, next) => prev.file.status === next.file.status
);
