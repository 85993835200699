import Field from 'components/Form/Field';
import styled, { css } from 'styled-components';
import { StyleProps } from './types';
import { ThemeProps } from 'config/themes/types';

export const TextAreaField = styled(Field)<StyleProps>`
  ${({ theme: { colors }, error }) => css`
    margin-bottom: 0px !important;

    padding: 0;

    .ql-container {
      border-color: ${error && colors.context.danger.default};
    }

    .ql-snow {
      border-color: ${error && colors.context.danger.default} !important;
    }
  `}
`;

export const BoxConfigurationWrapper = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
  `}
`;

export const AttendanceWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs2};
    justify-content: space-between;
    align-items: center;
    padding: ${space.lg} ${space.lg} 0px ${space.lg};
  `}
`;
