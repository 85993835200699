import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Text, Grid, Tag } from '@agendaedu/ae-web-components';
import Accordion from 'components/Accordion';
import Currency from 'components/Currency';

import { TRANSFERS_STATUS } from 'core/constants/wallet';

import { TransferAccordionProps } from './types';

import * as S from './styles';

function TransferAccordion({
  transfer: { attributes },
}: TransferAccordionProps) {
  const { t } = useTranslation(['payments', 'common']);

  const { formatted_date, bank_name, provider, amount, status } = attributes;

  const valueColor = () =>
    amount > 0 ? 'context.success.default' : 'context.danger.default';

  const renderAccordionHeader = () => (
    <S.WrapperHeaderAccordion>
      <Grid item gridArea="date">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('wallet.accordion_transfer.date')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {formatted_date}
          </Text>
        </Box>
      </Grid>

      <Grid item gridArea="transferred">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('wallet.accordion_transfer.transferred')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
          >
            {bank_name}
          </Text>
        </Box>
      </Grid>

      <Grid item gridArea="provider">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('wallet.accordion_transfer.provider')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {provider}
          </Text>
        </Box>
      </Grid>

      <Grid item gridArea="value">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('wallet.accordion_transfer.value')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color={valueColor()}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            <Currency value={amount} />
          </Text>
        </Box>
      </Grid>

      <Grid item gridArea="status">
        <Box alignItems={'center'}>
          <Tag
            name={TRANSFERS_STATUS[status]?.label}
            variant={TRANSFERS_STATUS[status]?.variant}
            size="small"
          />
        </Box>
      </Grid>
    </S.WrapperHeaderAccordion>
  );

  return (
    <S.WrapperAccordion className="AvailabilityAccordion">
      <Accordion header={renderAccordionHeader()} allowZeroExpanded />
    </S.WrapperAccordion>
  );
}

export default TransferAccordion;
