import React from 'react';

import { StudentRecipient } from 'store/messages/omniChannel/types';

import { RecipientCard } from 'components/RecipientSelectList/RecipientCard';

import { Props } from './types';

export const SectionListComponent = ({
  data: responsibles,
  selectedRecipientsList,
  handleToggleResponsible,
  classroomId,
  recipient: student,
}: Props) => {
  return (
    <>
      {responsibles?.map((responsible: StudentRecipient, index: number) => {
        const isSelected =
          selectedRecipientsList.responsibleTab[`${classroomId}-${student.id}`]
            ?.responsibles?.[responsible.id];

        return (
          <RecipientCard
            key={index}
            recipient={responsible}
            hasRecipientTag={true}
            paddingLeft="32px"
            isSelected={isSelected}
            toggleCheckbox={() => handleToggleResponsible(student, responsible)}
          />
        );
      })}
    </>
  );
};

export const SectionList = React.memo(SectionListComponent);
