import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Text, TextField } from '@agendaedu/ae-web-components';

import { StorageRoot } from 'store/storage/types';

import CenterModal from 'pages/storage/Modals/CenterModal';

import { Props } from './types';

const DeleteModal: React.FC<Props> = ({
  onClose,
  onClick,
}): React.ReactElement => {
  const { t } = useTranslation(['storage', 'common']);

  const tBase = useCallback((key: string) => t(`storage_list.${key}`), [t]);

  const [confirmSubmitText, setConfirmSubmitText] = useState('');

  const {
    common: {
      showDeleteStorageModal,
      selectedStorage: {
        attributes: { title: storageTitle, kind },
      },
    },
  } = useSelector((state: StorageRoot) => state.storage);

  const modalTitle = {
    folder: t('storage_list.modal.delete_folder.title'),
    document: t('storage_list.modal.delete_document.title'),
    file: t('storage_list.modal.delete_file.title'),
  };

  const modalDescription = {
    folder: t('storage_list.modal.delete_folder.description', {
      folderName: storageTitle,
    }),
    document: t('storage_list.modal.delete_document.description'),
    file: t('storage_list.modal.delete_file.description'),
  };

  const requireValidation = kind === 'folder';
  const isDisabled =
    !!requireValidation &&
    confirmSubmitText !== tBase('modal.common.validation_text');

  return (
    <CenterModal
      isOpen={showDeleteStorageModal}
      onClose={onClose}
      body={
        <>
          <Trans>
            <Text
              variant="body-regular-16"
              lineHeight="lg"
              color="neutral.gray1"
            >
              {modalDescription[kind]}
            </Text>

            {requireValidation && (
              <Box display="flex" flexDirection="column">
                <Text
                  variant="body-regular-14"
                  lineHeight="lg"
                  color="neutral.gray1"
                  mb={8}
                  mt={-12}
                  data-testid="validation-label"
                >
                  {t('storage_list.modal.common.validation_label')}
                </Text>

                <TextField
                  data-testid="input-field"
                  placeholder={t(
                    'storage_list.modal.common.validation_placeholder'
                  )}
                  onChange={(event) =>
                    setConfirmSubmitText(event.currentTarget.value)
                  }
                  value={confirmSubmitText}
                />
              </Box>
            )}
          </Trans>
        </>
      }
      footer={
        <Box display="flex" flexDirection="row-reverse" gap="sm">
          <Button
            data-testid="submit-button"
            variant="secondary"
            contextVariant="danger"
            disabled={isDisabled}
            onClick={onClick}
          >
            {tBase('modal.common.submit_button')}
          </Button>

          <Button
            data-testid="close-button"
            variant="secondary"
            onClick={onClose}
          >
            {tBase('modal.common.back_button')}
          </Button>
        </Box>
      }
      title={modalTitle[kind]}
    />
  );
};

export default DeleteModal;
