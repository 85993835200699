import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    max-width: 606px;
    padding: 0 ${space.xs3};
  `}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
