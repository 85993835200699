import { CalendarState } from '../types/store';

export const fetchEventsQueryUrl = (
  filters: CalendarState['calendar']['event']['list']['filters']
) => {
  const {
    headquarterId,
    activeSchoolTermId,
    classroomId,
    educationalStageId,
    status,
    name,
  } = filters;

  const classroomFields = '&fields[classroom]=name';

  const filterStatus = status?.length ? `&filter[status]=${status}` : '';

  const filterTitle = name?.length ? `&filter[title]=${name}` : '';

  const included = '&include=studentProfiles,classrooms';

  const filterHeadquarter = headquarterId
    ? `&filter[headquarter_ids][]=${headquarterId}`
    : '';
  const filterEducationalStage = educationalStageId
    ? `&filter[educational_stage_ids][]=${educationalStageId}`
    : '';
  const filterClassRoom = classroomId
    ? `&filter[classroom_ids][]=${classroomId}`
    : '';
  const filterSchoolTerm = activeSchoolTermId
    ? `&filter[school_term_names][]=${activeSchoolTermId}`
    : '';

  const queryParams = `${classroomFields}${filterHeadquarter}${filterEducationalStage}${filterClassRoom}${filterSchoolTerm}${filterStatus}${filterTitle}${included}`;

  return queryParams;
};
