import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import table from './table';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import smoothScrollTo from 'core/utils/smoothScrollTo';
import tabify from 'core/hoc/tabify';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';

import ColoredText from 'components/ColoredText';
import Currency from 'components/Currency';
import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import ManualDealModal from 'components/Payments/ManualDealModal';
import MoneySummaryContainer from 'components/Payments/PaymentsContainer/MoneySummaryContainer';
import MoneySummary from 'components/Payments/MoneySummary';
import PageSubTitle from 'components/PageSubTitle';
import Pagination from 'components/Pagination';
import SchoolProductOrdersFilter from 'components/Payments/PaymentsContainer/SchoolProductOrdersFilter';
import Table from 'components/Table';
import Toast from 'components/Toast';
import { TOP_CENTER, TOP_RIGHT } from 'components/Tooltip';

import LinkModal from 'components/Payments/LinkModal';
import CancelModal from 'components/Payments/CancelModal';
import ManuallyPaidModal from 'components/Payments/ManuallyPaidModal';
import HandleModalByMethod from 'components/Payments/HandleModalByMethod';
import RefundModal from 'components/Payments/RefundModal';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';

import './style.scss';

const paidComplement = ({ balance }) => (
  <div>
    <div className="summary-complement">
      <strong>
        {' '}
        <Currency value={balance.credit_card_paid} />{' '}
      </strong>{' '}
      pago no cartão
    </div>
    <div className="summary-complement">
      <strong>
        {' '}
        <Currency value={balance.billet_paid} />{' '}
      </strong>{' '}
      pago no boleto
    </div>
    <div className="summary-complement">
      <strong>
        {' '}
        <Currency value={balance.pix_paid} />{' '}
      </strong>{' '}
      pago no PIX
    </div>
  </div>
);

const SUMMARY_MESSAGES = {
  total: (
    <div>
      Somatório dos valores Pago
      <br />
      online e Pago na escola.
    </div>
  ),
  paid: (
    <div>
      Valor total pago online, via
      <br />
      cartão de crédito, boleto bancário
      <br /> e PIX
    </div>
  ),
  manually_paid: (
    <div>
      Valor total pago na escola por
      <br />
      responsáveis
    </div>
  ),
  refunded: (
    <div>
      Valor reembolsado para
      <br />
      responsáveis
    </div>
  ),
};

const SchoolProductOrdersTab = ({
  appContext: { policies },
  schoolProductId,
}) => {
  const dispatch = useDispatch();
  const width = window.innerWidth;

  const {
    currentSchoolProduct,
    currentOrder,
    currentStudent,
    currentResponsibles,
    orders,
    filters,
    paginate,
    visualizations,
    balance,
    isLoading,
    isLoadingCheckout,
    isLoadingManuallyPaidOrder,
    isLoadingRefundOrder,
    showModalCancelOrder,
    showManualDealModal,
    showModalCheckout,
    showModalCheckoutLink,
    showModalManuallyPaid,
    showModalRefundOrder,
    showModalRefundOrderSuccess,
    error,
    errorMessage,
  } = useSelector((state) => state.schoolProducts);

  const {
    fetchCurrentSchoolProduct,
    fetchOrdersRequest,
    fetchBalance,
    setCancelOrder,
    setCancelOrderLD,
    setCloseRefundOrderSuccessModal,
    setInformationToPaid,
    setCheckout,
    setManuallyPaidOrder,
    setPaidAtSchoolOrder,
    setRefundOrder,
    toggleCancelOrderModal,
    toggleCheckoutModal,
    toggleCheckoutLinkModal,
    toggleManuallyPaidModal,
    toggleManualDealModal,
    toggleRefundOrderModal,
  } = actionSchoolProducts;

  const [modalType, setModalType] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState();

  const handleGetCurrentSchoolProduct = useCallback(
    (params) => {
      dispatch(fetchCurrentSchoolProduct(params));
    },
    [dispatch, fetchCurrentSchoolProduct]
  );

  const handleGetOrdersRequest = useCallback(
    (params) => {
      dispatch(fetchOrdersRequest(params));
    },
    [dispatch, fetchOrdersRequest]
  );

  const handleFetchBalance = useCallback(
    (params) => {
      dispatch(fetchBalance(params));
    },
    [dispatch, fetchBalance]
  );

  const handleSubmitFilter = (params) => {
    const newParamsFilter = {
      schoolProductId,
      filters: { ...filters, page: 1, ...params },
    };

    handleGetOrdersRequest(newParamsFilter);
  };

  const handlePageChange = useCallback(
    (page) => {
      const params = { page };

      handleGetOrdersRequest({
        schoolProductId,
        filters: { ...filters, ...params },
      });

      smoothScrollTo(0, 0);
    },
    [handleGetOrdersRequest, schoolProductId, filters]
  );

  useEffect(() => {
    handleGetCurrentSchoolProduct({ schoolProductId });
    handleGetOrdersRequest({ schoolProductId, filters });
    handleFetchBalance({ schoolProductId });
  }, []);

  const handleToggleCancelOrderModal = useCallback(() => {
    dispatch(toggleCancelOrderModal());
  }, [dispatch, toggleCancelOrderModal]);

  const handleToggleCheckout = useCallback(() => {
    dispatch(toggleCheckoutModal());
  }, [dispatch, toggleCheckoutModal]);

  const handleToggleLinkModal = useCallback(() => {
    dispatch(toggleCheckoutLinkModal());
  }, [dispatch, toggleCheckoutLinkModal]);

  const handleToggleManuallyPaidModal = useCallback(() => {
    dispatch(toggleManuallyPaidModal());
  }, [dispatch, toggleManuallyPaidModal]);

  const handleToggleManualDealModal = useCallback(() => {
    dispatch(toggleManualDealModal());
  }, [dispatch, toggleManualDealModal]);

  const handleToggleRefundOrderModal = useCallback(() => {
    dispatch(toggleRefundOrderModal());
  }, [dispatch, toggleRefundOrderModal]);

  const handleSetCancelOrder = useCallback(() => {
    const { id: orderId } = currentOrder;

    dispatch(setCancelOrder({ schoolProductId, orderId }));
  }, [dispatch, setCancelOrder, schoolProductId, currentOrder]);

  const handleSetManualDeal = useCallback(
    (form) => {
      const { id: orderId } = currentOrder;

      modalType == 'cancel'
        ? dispatch(setCancelOrderLD({ schoolProductId, orderId, form }))
        : dispatch(setPaidAtSchoolOrder({ form, schoolProductId, orderId }));
    },
    [
      dispatch,
      setCancelOrderLD,
      schoolProductId,
      currentOrder,
      modalType,
      setPaidAtSchoolOrder,
    ]
  );

  const handleSetCloseRefundOrderSuccessModal = useCallback(() => {
    dispatch(setCloseRefundOrderSuccessModal());
  }, [dispatch, setCloseRefundOrderSuccessModal]);

  const handleCheckout = useCallback(
    (responsibleData) => {
      const { id: orderId } = currentOrder;

      dispatch(
        setCheckout({ orderId, responsibleData, method: paymentMethod })
      );
    },
    [dispatch, setCheckout, currentOrder, paymentMethod]
  );

  const handleSetInformationToPaid = useCallback(
    (orderId) => {
      dispatch(setInformationToPaid({ orders, orderId }));
    },
    [dispatch, setInformationToPaid, orders]
  );

  const handleSetManuallyPaidOrder = useCallback(
    (responsibleId) => {
      const { id: orderId } = currentOrder;

      dispatch(
        setManuallyPaidOrder({
          schoolProductId,
          orderId,
          responsibleId,
        })
      );
    },
    [dispatch, setManuallyPaidOrder, schoolProductId, currentOrder]
  );

  const handleSetRefundOrder = useCallback(
    (orderId) => {
      dispatch(setRefundOrder({ schoolProductId, orderId }));
    },
    [dispatch, setRefundOrder, schoolProductId]
  );

  const prepareTableData = () => {
    const listOrders = orders.map((order) => {
      order.actions = {};

      if (
        [
          'waiting_payment',
          'generated_billet',
          'generated_pix',
          'rejected_by_responsible',
          'expired',
          'refused',
        ].includes(order.attributes.status)
      ) {
        order.actions.manuallyModal = () => {
          handleSetInformationToPaid(order.attributes.id);
          handleToggleManuallyPaidModal();
        };
        order.actions.paidAtSchool = () => {
          handleSetInformationToPaid(order.attributes.id);
          setModalType('paidAtSchool');
          handleToggleManualDealModal();
        };
        order.actions.generateBilletModal = () => {
          setPaymentMethod('billet');
          handleSetInformationToPaid(order.attributes.id);
          handleToggleCheckout();
        };
        order.actions.billetLinkModal = () => {
          setPaymentMethod('billet');
          handleSetInformationToPaid(order.attributes.id);
          handleToggleLinkModal();
        };
        order.actions.generatePixModal = () => {
          setPaymentMethod('pix');
          handleSetInformationToPaid(order.attributes.id);
          handleToggleCheckout();
        };
        order.actions.pixLinkModal = () => {
          setPaymentMethod('pix');
          handleSetInformationToPaid(order.attributes.id);
          handleToggleLinkModal();
        };
        order.actions.generateLinkModal = () => {
          setPaymentMethod('link');
          handleSetInformationToPaid(order.attributes.id);
          handleToggleCheckout();
        };
        order.actions.cancelModal = () => {
          handleSetInformationToPaid(order.attributes.id);
          handleToggleCancelOrderModal();
        };
        order.actions.cancelModalLD = () => {
          handleSetInformationToPaid(order.attributes.id);
          setModalType('cancel');
          handleToggleManualDealModal();
        };
      }

      if (
        ['credit_card_paid', 'manually_paid', 'paid'].includes(
          order.attributes.status
        )
      ) {
        order.actions.refundOrder = () => {
          handleSetInformationToPaid(order.attributes.id);
          handleSetRefundOrder(order.attributes.id);
        };
        order.actions.refundModal = () => {
          handleSetInformationToPaid(order.attributes.id);
          handleToggleRefundOrderModal();
        };
      }

      order.visualizations = visualizations.filter(
        (view) => view.id === order.id
      );

      return {
        order,
        policies,
        schoolProduct: currentSchoolProduct,
        showActionButtons: !['pending', 'rejected'].includes(
          currentSchoolProduct.attributes.status
        ),
      };
    });

    return listOrders;
  };

  const reportParams = () => {
    const params = Object.entries(filters || {}).reduce(
      (accumulator, [key, value]) => {
        if (value !== null) {
          accumulator[key] = value;
        }
        return accumulator;
      },
      {}
    );

    return `?${new URLSearchParams(params)}`;
  };

  const renderSubTitleCancelModal = () => (
    <>
      {currentSchoolProduct && (
        <p>
          A emissão da cobrança do dia{' '}
          <ColoredText variation="bold">
            {currentSchoolProduct.attributes.expires_at}
          </ColoredText>{' '}
          será
          <br />
          cancelada e não será enviada para o responsável
          <br />
          do aluno.
        </p>
      )}
    </>
  );

  if (error) throw error;

  if (isLoading) return <Loader />;

  return (
    <div className="SchoolProductOrdersTab">
      {currentSchoolProduct && (
        <>
          <div className="tab-name">PAGAMENTOS</div>
          <div className="school-product-info">
            <PageSubTitle variation="small">
              {currentSchoolProduct?.attributes.title}
            </PageSubTitle>
          </div>
          <MoneySummaryContainer>
            {isLoading ? (
              <div className="loader-container">
                <Loader />
              </div>
            ) : (
              <>
                <MoneySummary
                  title="Total pago"
                  price={balance.total}
                  variation="success"
                  tooltipMessage={SUMMARY_MESSAGES.total}
                />
                <MoneySummary
                  title="Pago online"
                  price={balance.paid}
                  complement={paidComplement({ balance })}
                  variation="success"
                  tooltipMessage={SUMMARY_MESSAGES.paid}
                  tooltipPosition={width < 768 ? TOP_RIGHT : TOP_CENTER}
                />
                <MoneySummary
                  title="Pago na escola"
                  price={balance.manually_paid}
                  variation="success"
                  tooltipMessage={SUMMARY_MESSAGES.manually_paid}
                />
                <MoneySummary
                  title="Reembolsado"
                  price={balance.refunded}
                  tooltipMessage={SUMMARY_MESSAGES.refunded}
                  tooltipPosition={width < 1200 ? TOP_RIGHT : TOP_CENTER}
                />
              </>
            )}
          </MoneySummaryContainer>
          <hr />
          <SchoolProductOrdersFilter
            filters={filters}
            schoolProduct={currentSchoolProduct}
            handleFilterSubmit={handleSubmitFilter}
          />

          <div className="SchoolProductOrdersTable">
            {orders.length > 0 ? (
              <>
                <Table
                  columns={table}
                  data={prepareTableData()}
                  metadata={{
                    page: paginate.activePage,
                    totalItemsCount: paginate.totalItemsCount,
                    itemsCountPerPage: paginate.itemsCountPerPage,
                    singular: 'aluno',
                    plural: 'alunos',
                  }}
                  exportOptions={[
                    {
                      path: `/schools/school_products/${schoolProductId}/orders.xls${reportParams()}`,
                      text: 'Planilha (.xls)',
                    },
                  ]}
                />
                <Pagination
                  onChange={(page) => handlePageChange(page)}
                  activePage={paginate.activePage}
                  totalItemsCount={paginate.totalItemsCount}
                  itemsCountPerPage={paginate.itemsCountPerPage}
                />
              </>
            ) : (
              <>
                <EmptyState
                  imgUrl={IMAGES_PAYMENT_URL.availabilityUrl}
                  message={
                    <p>
                      Não foram encontrados registros <br />
                      para o termo buscado
                    </p>
                  }
                />
              </>
            )}
          </div>
        </>
      )}
      <ManuallyPaidModal
        showManuallyPaidModal={showModalManuallyPaid}
        toggleManuallyPaidModal={handleToggleManuallyPaidModal}
        isModalLoading={isLoadingManuallyPaidOrder}
        student={currentStudent}
        responsibles={currentResponsibles}
        onSubmit={handleSetManuallyPaidOrder}
      />
      <HandleModalByMethod
        method={paymentMethod}
        showModal={showModalCheckout}
        toggleModal={handleToggleCheckout}
        isLoading={isLoadingCheckout}
        student={currentStudent}
        responsibles={currentResponsibles}
        onSubmit={handleCheckout}
        checkoutError={errorMessage}
      />
      <LinkModal
        method={paymentMethod}
        showModal={showModalCheckoutLink}
        toggleModal={handleToggleLinkModal}
        responsibles={currentResponsibles}
        isLoading={isLoadingCheckout}
        order={currentOrder}
      />
      <CancelModal
        isOpen={showModalCancelOrder}
        toggleModal={handleToggleCancelOrderModal}
        title="Cancelar cobrança"
        subTitle={renderSubTitleCancelModal()}
        onCancel={handleSetCancelOrder}
      />
      <ManualDealModal
        order={currentOrder}
        isOpen={showManualDealModal}
        toggleModal={handleToggleManualDealModal}
        bill={currentSchoolProduct}
        isRecurrent={false}
        title={modalType == 'cancel' ? 'Cancelar cobrança' : 'Baixa manual'}
        onSubmit={(form) => handleSetManualDeal(form)}
        modalType={modalType}
      />
      <RefundModal
        showModalRefundOrder={showModalRefundOrder}
        toggleRefundOrderModal={handleToggleRefundOrderModal}
        isModalLoading={isLoadingRefundOrder}
        studentName={currentStudent?.attributes.name}
        orderId={currentOrder?.id}
        onSubmit={handleSetRefundOrder}
        showModalRefundSuccess={showModalRefundOrderSuccess}
        closeAlertModal={handleSetCloseRefundOrderSuccessModal}
      />
      <Toast />
    </div>
  );
};

SchoolProductOrdersTab.propTypes = {
  schoolProductId: PropTypes.string.isRequired,
  ...appPropTypes,
};

export default tabify({
  title: 'Pagamentos',
  icon: 'money',
})(withAppContext(SchoolProductOrdersTab));
