import { CalendarState } from './types/store';

const actions = {
  ERROR: 'ERROR/calendar',

  FETCH_CLASSROOMS_REQUEST: 'FETCH_CLASSROOMS_REQUEST/calendar',
  FETCH_CLASSROOMS_SUCCESS: 'FETCH_CLASSROOMS_SUCCESS/calendar',
  FETCH_EVENT_DETAILS_REQUEST: 'FETCH_EVENT_DETAILS_REQUEST/calendar',
  FETCH_EVENT_DETAILS_SUCCESS: 'FETCH_EVENT_DETAILS_SUCCESS/calendar',
  FETCH_EVENTS_REQUEST: 'FETCH_EVENTS_REQUEST/calendar',
  FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS/calendar',

  RESET_EVENT_DETAILS: 'RESET_EVENT_DETAILS/calendar',
  RESET_EVENTS: 'RESET_EVENTS/calendar',

  SET_APPROVE_EVENT_REQUEST: 'SET_APPROVE_EVENT_REQUEST/calendar',
  SET_APPROVE_EVENT_SUCCESS: 'SET_APPROVE_EVENT_SUCCESS/calendar',
  SET_DELETE_EVENT_REQUEST: 'SET_DELETE_EVENT_REQUEST/calendar',
  SET_DELETE_EVENT_SUCCESS: 'SET_DELETE_EVENT_SUCCESS/calendar',

  SET_EVENT_FILTERS: 'SET_EVENT_FILTERS/calendar',

  error: () => ({
    type: actions.ERROR,
  }),
  fetchClassroomsRequest: () => ({
    type: actions.FETCH_CLASSROOMS_REQUEST,
  }),
  fetchEventDetailsRequest: (id: string) => ({
    type: actions.FETCH_EVENT_DETAILS_REQUEST,
    id,
  }),
  fetchEventsRequest: (
    page: number,
    filters?: CalendarState['calendar']['event']['list']['filters']
  ) => ({
    type: actions.FETCH_EVENTS_REQUEST,
    page,
    filters,
  }),
  resetEventDetails: () => ({
    type: actions.RESET_EVENT_DETAILS,
  }),
  resetEvents: () => ({
    type: actions.RESET_EVENTS,
  }),
  setApproveEventRequest: (
    actionContext: 'approve' | 'resend',
    id: string,
    notify: boolean
  ) => ({
    type: actions.SET_APPROVE_EVENT_REQUEST,
    actionContext,
    id,
    notify,
  }),
  setDeleteEventRequest: (id: string, action: 'list' | 'details') => ({
    type: actions.SET_DELETE_EVENT_REQUEST,
    action,
    id,
  }),
  setEventFilters: (
    filters: CalendarState['calendar']['event']['list']['filters']
  ) => ({
    type: actions.SET_EVENT_FILTERS,
    filters,
  }),
};

export default actions;
