import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const RecipientsWrapper = styled.div`
  ${({ theme: { typography, space } }: ThemeProps) => css`
    h3 {
      ${typography.title.Bold20}
      font-weight: 700;
      margin-bottom: ${space.lg};
      margin-top: 0;
    }
  `}
`;
