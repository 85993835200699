import { Button } from '@agendaedu/ae-web-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AIButton = ({ onPress }: { onPress: VoidFunction }) => {
  const { t } = useTranslation(['handouts']);

  return (
    <Button icon="ai" variant="secondary" onClick={onPress}>
      {t('ai_modal.ask_to_ai')}
    </Button>
  );
};

export default AIButton;
