import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { Text as AeText } from '@agendaedu/ae-web-components';
import Text from 'components/Text';
import Title from 'components/WizardForm/Title';
import Field from 'components/Form/Field';

export const OfferWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    .checkboxes-wrapper {
      padding-left: 15px;
    }

    .double-input {
      .dateRange {
        padding: 0px;
      }
      .Field:last-child {
        padding: 0px;
      }
    }

    .DateInput {
      height: 38px;
    }

    .Alert {
      height: auto;
      margin-bottom: ${space.md};
    }

    .DayPicker {
      div > div:not(:last-child) > button:nth-last-child(-n + 3) {
        display: none;
      }
      div > div:last-child > button {
        margin-top: -${space.lg};
      }
    }
  `}
`;

export const ValueWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    gap: ${space.lg};
    align-items: flex-start;
  `}
`;

export const ValueField = styled(Field)`
  flex: 0.5;
  padding: 0;
`;

export const RangeDateWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    flex: 0.5;
    margin-top: ${space.xs3};
  `}
`;

export const RangeDateLabel = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.xs2};
  `}
`;

export const AccordionsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${space.lg};
    margin-top: -${space.lg};
    .Title {
      margin-bottom: 0px !important;
    }
  `}
`;

export const Paragraph = styled.p`
  ${({ theme: { space, font } }: ThemeProps) => css`
    font-family: 'Roboto';
    font-size: ${font.size.xs};
    font-weight: 400;
    margin-top: ${space.xl2};
  `}
`;

export const NotificationDescription = styled(Text)`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.sm};
  `}
`;

export const TitlePaymentMethod = styled(Title)`
  margin-bottom: 0 !important;
`;

export const CheckboxWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    gap: 10px;
    margin-bottom: ${space.sm};
    align-items: center;
  `}
`;

export const CheckboxSpan = styled.span`
  margin-left: 6px;
  cursor: pointer;
`;

export const CheckboxTooltip = {
  width: '176px',
};

export const CartConfig = styled(AeText)`
  ${({ theme: { colors } }: ThemeProps) => css`
    text-decoration: underline;
    color: ${colors.context.info.default};
  `}
`;

export const AbsorbTaxWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 100%;
    padding: 0 15px !important;
    margin-bottom: ${space.lg};
  `}
`;
