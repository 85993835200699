import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import withAppContext from 'core/hoc/withAppContext';

import { Modal } from 'components/Handouts/Modal';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import { NewChannelModalProps } from './types';

const NewChannelModal = ({
  appContext: {
    dataArea,
    policies: { can_access_tickets: canAccessTickets },
  },
}: NewChannelModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['messages']);

  const { showNewChannelModal } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const { toggleNewChannelModal } = omniChannelActions;

  const handleToggleNewChannelModal = useCallback(() => {
    dispatch(toggleNewChannelModal());
  }, [dispatch, toggleNewChannelModal]);

  return (
    <Modal.Root
      dataTestId="omni-new-channel"
      isOpen={showNewChannelModal}
      title={t('omni_channel.modals.new_channel.title')}
      handleToggleModal={handleToggleNewChannelModal}
      maxWidth="fit-content"
      overlapModal={false}
      marginAuto
    >
      <Modal.Content>
        <S.ButtonsWrappers>
          <S.Button
            data-testId="new-channel-button"
            to={`/${dataArea}/messages/channels/new`}
          >
            <S.Image
              src="https://static.agendaedu.com/message/chat.png"
              alt={t(
                'omni_channel.modals.new_channel.channel_button.image_descirption'
              )}
            />

            <S.InfomationWrapper>
              <S.Title>
                {t('omni_channel.modals.new_channel.channel_button.title')}
              </S.Title>
              <S.Description>
                {t(
                  'omni_channel.modals.new_channel.channel_button.description'
                )}
              </S.Description>
            </S.InfomationWrapper>
          </S.Button>

          {canAccessTickets && (
            <S.Button
              data-testId="new-ticket-button"
              to={`/${dataArea}/messages/channels/tickets/new`}
            >
              <S.Image
                src="https://static.agendaedu.com/message/ticket.png"
                alt={t(
                  'omni_channel.modals.new_channel.ticket_button.image_descirption'
                )}
              />

              <S.InfomationWrapper>
                <S.TitleWrapper>
                  <S.Title>
                    {t('omni_channel.modals.new_channel.ticket_button.title')}
                  </S.Title>
                </S.TitleWrapper>

                <S.Description>
                  {t(
                    'omni_channel.modals.new_channel.ticket_button.description'
                  )}
                </S.Description>
              </S.InfomationWrapper>
            </S.Button>
          )}
        </S.ButtonsWrappers>
      </Modal.Content>
    </Modal.Root>
  );
};

export default withAppContext(NewChannelModal);
