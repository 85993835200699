import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import * as S from './styles';
import { Props } from './types';

import { Button, Icon, Text, SingleDate } from '@agendaedu/ae-web-components';

import { DiaryDetailsTab } from './component/DetailsTab';
import StudentsList from '../DiaryForm/StudentsList';

import withAppContext from 'core/hoc/withAppContext';

import diaryActions from 'store/dailySummaries/actions';
import { DiaryState } from 'store/dailySummaries/types';

const DiaryDetails = ({
  appContext: {
    policies: { can_upsert: canUpset },
  },
  classroomId,
  history: { goBack, push },
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['diary', 'common']);

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const [date, setDate] = useState(moment(new Date()));
  const [studentIdSelected, setStudentIdSelected] = useState<string[]>([]);

  const tBase = useCallback((key: string) => t(`details.${key}`), [t]);

  const { fetchClassroom, fetchStudentSummary } = diaryActions;

  const {
    classroom: {
      attributes: {
        name,
        student_profiles: { data: studentProfileList },
      },
    },
    classroomIncluded,
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const getClassroomIncludedNames = (type) => {
    return classroomIncluded.find((info) => info.type === type)?.attributes
      .name;
  };

  const educationalStageName = getClassroomIncludedNames('educational_stage');
  const headquarterName = getClassroomIncludedNames('headquarter');

  const schoolSubtitle =
    !educationalStageName || !headquarterName
      ? ''
      : `${headquarterName} / ${educationalStageName}`;

  const isWithinCurrentYear = (day: moment.Moment) => {
    const currentYearStart = moment().startOf('year');
    const currentDayOfYear = moment().add(1, 'days');

    return !day.isBetween(currentYearStart, currentDayOfYear, 'day');
  };

  const handleChangeDate = (date: moment.Moment) => {
    if (!date) return setDate(moment(new Date()));

    return setDate(date);
  };

  useEffect(() => {
    dispatch(fetchClassroom(classroomId, date.format('YYYY-MM-DD')));
  }, [classroomId, date, dispatch, fetchClassroom]);

  useEffect(() => {
    if (studentIdSelected.length)
      dispatch(fetchStudentSummary(studentIdSelected[0], classroomId, date));
  }, [studentIdSelected]);

  useEffect(() => {
    if (studentProfileList.length)
      setStudentIdSelected([studentProfileList[0].id]);
  }, [studentProfileList]);

  return (
    <S.DiaryDetailsWrapper>
      <S.HeaderWrapper>
        <S.TitleInfoWrapper>
          <S.Breadcrumbs onClick={goBack}>
            <Icon name="chevron-left" />
            <Text variant="title-bold-14" color="brand.primary.default" mb={0}>
              {tBase('breadcrumb')}
            </Text>
          </S.Breadcrumbs>
          <Text variant="headline-h2-bold-24" color="neutral.black" mb={0}>
            {tBase('title')}
          </Text>
          <Text variant="subtitle-medium-14" mb={0}>
            {schoolSubtitle}
          </Text>
        </S.TitleInfoWrapper>

        <S.ActionWrapper>
          <SingleDate
            id="diary-day"
            value={moment(date)}
            handleChange={handleChangeDate}
            handleOutsideRange={(day) => isWithinCurrentYear(day)}
          />

          {canUpset && (
            <Button
              onClick={() =>
                push(`/${dataArea}/daily_summaries/classrooms/${classroomId}`)
              }
            >
              {tBase('set_diary_button')}
            </Button>
          )}
        </S.ActionWrapper>
      </S.HeaderWrapper>

      <S.ColumnsWrapper>
        <S.StudentListWrapper>
          <Text variant="headline-h2-bold-24" color="neutral.black" mb="lg">
            {name}
          </Text>

          <StudentsList
            placeholder={t('details.search_placeholder', {
              number: studentProfileList.length,
            })}
            studentsIdsSelecteds={studentIdSelected}
            setStudentsIdsSelecteds={setStudentIdSelected}
            students={studentProfileList}
            isCheckable={false}
            multiSelect={false}
          />
        </S.StudentListWrapper>

        <DiaryDetailsTab />
      </S.ColumnsWrapper>
    </S.DiaryDetailsWrapper>
  );
};

export default withRouter(withAppContext(DiaryDetails));
