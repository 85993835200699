import {
  FetchChatsParams,
  FetchPendingCountParams,
} from 'store/messages/omniChannel/types';

export const queryUrlFiltered = ({
  filters: {
    isInactiveChats,
    studentStatus,
    educationalStageId,
    headquarterId,
    classroomId,
    chatKind,
  },
}: FetchChatsParams | FetchPendingCountParams) => {
  const filterIsInactiveChat = `&inactive_chats=${isInactiveChats}`;
  const filterStudentStatus = studentStatus
    ? `&student_status=${studentStatus}`
    : '';
  const filterHeadquarter = headquarterId
    ? `&headquarter_id=${headquarterId}`
    : '';
  const filterEducationalStage = educationalStageId
    ? `&educational_stage_id=${educationalStageId}`
    : '';
  const filterClassRoom = classroomId ? `&classroom_id=${classroomId}` : '';

  const chatKindParam = chatKind ? `&chat_kind=${chatKind}` : '';

  const queryParamsFilters = `${filterIsInactiveChat}${filterStudentStatus}${filterHeadquarter}${filterEducationalStage}${filterClassRoom}${chatKindParam}`;

  return queryParamsFilters;
};
