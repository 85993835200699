import React from 'react';
import { Box } from '@agendaedu/ae-web-components';
import { Skeleton as SkeletonComponent } from '@agendaedu/ae-web-components';

import useMobileScreen from 'core/hooks/useMobileScreen';

import CardSkeleton from 'pages/Calendar/components/Event/List/Card/skeleton';

const SkeletonPage = (): React.ReactElement => {
  const isMobileScreen = useMobileScreen();

  return (
    <Box data-testid="event-card-list-skeleton">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        paddingTop="sm"
        paddingX={{ _: 'xs2', desktopLG: 'xl3' }}
      >
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
        >
          <SkeletonComponent
            height={40}
            width={126}
            primaryColor="neutral.gray4"
            borderRadius={6}
          />

          <SkeletonComponent
            height={40}
            width={131}
            primaryColor="neutral.gray4"
            borderRadius={8}
          />
        </Box>

        <Box
          display="flex"
          width="100%"
          mt="xl"
          justifyContent="space-between"
          flexDirection={{ _: 'column', desktopLG: 'row' }}
        >
          <Box
            display="flex"
            gap="sm"
            flexDirection={{ _: 'column', desktopLG: 'row' }}
          >
            <SkeletonComponent
              height={40}
              width={'100%'}
              primaryColor="neutral.gray4"
              borderRadius={8}
            />
            <Box display={{ _: 'none', desktopLG: 'flex' }}>
              <SkeletonComponent
                height={40}
                width={260}
                primaryColor="neutral.gray4"
                borderRadius={8}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            gap="sm"
            justifyContent="space-between"
            mt="xs"
          >
            <Box display={{ _: 'flex', desktopLG: 'none' }}>
              <SkeletonComponent
                height={40}
                width={200}
                primaryColor="neutral.gray4"
                borderRadius={8}
              />
            </Box>

            <Box display="flex" flexDirection="row" gap="sm">
              <SkeletonComponent
                height={40}
                width={40}
                primaryColor="neutral.gray4"
                borderRadius={8}
              />

              <SkeletonComponent
                height={40}
                width={40}
                primaryColor="neutral.gray4"
                borderRadius={8}
              />
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={{ _: 'column', desktopLG: 'row' }}
          gap="lg"
          width="100%"
          mt="xl"
        >
          <Box display="flex" width={isMobileScreen ? '100%' : 317}>
            <SkeletonComponent
              height={isMobileScreen ? 312 : 360}
              width={isMobileScreen ? '100%' : 317}
              primaryColor="neutral.gray4"
              borderRadius={8}
            />
          </Box>

          <Box display="flex" flexDirection="column" width="100%">
            <Box
              display="flex"
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
            >
              <SkeletonComponent
                height={16}
                width={257}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />

              <SkeletonComponent
                height={16}
                width={69}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap="sm"
              mt="sm"
              width="100%"
            >
              {Array.from({ length: 5 }).map((_, index) => (
                <SkeletonComponent
                  key={index}
                  height={isMobileScreen ? 158 : 80}
                  width="100%"
                  primaryColor="neutral.gray4"
                  borderRadius={8}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SkeletonFilter = (): React.ReactElement => (
  <Box
    data-testid="skeleton-filter"
    display="flex"
    width="100%"
    mt="sm"
    gap="sm"
    flexDirection="column"
  >
    {Array.from({ length: 5 }, (_, index) => (
      <CardSkeleton key={index} />
    ))}
  </Box>
);

const SkeletonLoadingMore = (): React.ReactElement => (
  <Box data-testid="skeleton-loading-more" display="flex" width="100%" mt="sm">
    <CardSkeleton />
  </Box>
);

export { SkeletonFilter, SkeletonPage, SkeletonLoadingMore };
