import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import parse from 'html-react-parser';
import {
  Avatar,
  Box,
  Button,
  Icon,
  RecipientTag,
  Text,
} from '@agendaedu/ae-web-components';

import formatDatetime from 'core/utils/formatDatetime';
import { calendarDateDescription } from 'core/utils/date';

import StorageActions from 'store/storage/actions';
import { Recipient, StorageRoot } from 'store/storage/types';

import * as S from './styles';

import AttachmentPreview from '../AttachmentPreview';

import { Props } from './types';

const DetailsTab = ({ history }: Props): React.ReactElement => {
  const { t } = useTranslation(['storage']);
  const dispatch = useDispatch();

  const { toggleShowDeleteStorageModal, setSelectedStorage } = StorageActions;

  const {
    common: { currentStorage },
  } = useSelector((state: StorageRoot) => state.storage);

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const {
    attributes: {
      classrooms,
      receiverType,
      studentProfiles,
      title,
      createdAt,
      updatedAt,
      createdBy,
      availableTo,
      description,
      attachments,
      userPermissions,
    },
  } = currentStorage;

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`details.${key}`, params),
    [t]
  );

  const recipientsTag = useMemo((): {
    recipients: Recipient[];
    type: 'classrooms' | 'students';
  } => {
    const isClassroom = receiverType === 'classroom';
    const recipients = isClassroom ? classrooms : studentProfiles;

    const type = isClassroom
      ? tBase('recipients_classrooms_tag_type')
      : tBase('recipients_students_tag_type');

    return { recipients, type };
  }, [classrooms, receiverType, studentProfiles, tBase]);

  const handleToggleDeleteModal = useCallback(() => {
    dispatch(setSelectedStorage(currentStorage));
    dispatch(toggleShowDeleteStorageModal());
  }, [
    currentStorage,
    dispatch,
    setSelectedStorage,
    toggleShowDeleteStorageModal,
  ]);

  return (
    <Box display="flex" padding="40px" gap="xl" flexDirection="column">
      <Box display="flex" flexDirection="column" gap="md" mt="xs2">
        <Box display="flex" alignItems="center" justifyContent="start">
          <RecipientTag
            recipientType={recipientsTag.type}
            modalTitle={tBase('recipients_modal_tag')}
            recipients={recipientsTag.recipients}
            variant="neutral"
            enableEllipsis
            size="large"
          />
        </Box>

        <Text
          variant="headline-h2-bold-24"
          fontWeight="bold"
          color="neutral.black"
          mb={0}
        >
          {title}
        </Text>

        <S.DetailsWrapper>
          <S.CardDetailsWrapper data-testid="created-at">
            <S.Card>
              <Icon name="calendar" />
            </S.Card>
            <Box
              display="flex"
              flexDirection="column"
              gap="xs"
              justifyContent="center"
            >
              <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                {tBase('created_at')}
              </Text>
              <Text variant="label-regular-16" color="neutral.black" mb={0}>
                {formatDatetime(createdAt)}
              </Text>
            </Box>
          </S.CardDetailsWrapper>

          {updatedAt && (
            <S.CardDetailsWrapper data-testid="updated-at">
              <S.Card>
                <Icon name="calendar" />
              </S.Card>
              <Box
                display="flex"
                flexDirection="column"
                gap="xs"
                justifyContent="center"
              >
                <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                  {tBase('updated_at')}
                </Text>
                <Text variant="label-regular-16" color="neutral.black" mb={0}>
                  {formatDatetime(updatedAt)}
                </Text>
              </Box>
            </S.CardDetailsWrapper>
          )}

          <S.CardDetailsWrapper data-testid="available-to">
            <S.Card>
              <Icon name="send" />
            </S.Card>
            <Box
              display="flex"
              flexDirection="column"
              gap="xs"
              justifyContent="center"
            >
              <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                {tBase('recipints_sent_to_label')}
              </Text>
              <Text variant="label-regular-16" color="neutral.black" mb={0}>
                {tBase(`recipints_sent_to.${availableTo}`)}
              </Text>
            </Box>
          </S.CardDetailsWrapper>
        </S.DetailsWrapper>
      </Box>
      <Box display="flex" flexDirection="column" gap="lg">
        <Box display="flex" flexDirection="column">
          <Text variant="subtitle-medium-14" color="neutral.gray2">
            {tBase('description_title')}
          </Text>

          <Text variant="body-regular-16" mb={0}>
            {parse(description)}
          </Text>
        </Box>

        {!!attachments.length && (
          <Box
            data-testid="attachments-wrapper"
            display="flex"
            flexDirection="column"
          >
            <Text variant="subtitle-medium-14" color="neutral.gray2">
              {tBase('attachment_label')}
            </Text>
            <Box display="flex" flexDirection="column" gap="xs">
              {attachments?.map((attachment) => (
                <AttachmentPreview
                  key={attachment.id}
                  attachment={attachment}
                />
              ))}
            </Box>
          </Box>
        )}

        <S.HistoryCreatorWrapper>
          <Avatar
            avatarColor={createdBy.avatarColor}
            size="xs"
            nameInitials={createdBy.nameInitials}
            avatarUrl={createdBy.avatarUrl}
          />
          <Box display="flex" flexDirection="column" gap="xs3">
            <Text
              variant="title-bold-12"
              fontWeight={700}
              color="neutral.gray1"
              mb={0}
            >
              {tBase('created_by', {
                name: createdBy.name,
              })}
            </Text>

            <Text color="neutral.gray2" variant="subtitle-medium-12" mb={0}>
              {calendarDateDescription(createdAt)}
            </Text>
          </Box>
        </S.HistoryCreatorWrapper>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {userPermissions.edit && (
          <Button
            data-testid="edit-storage-button"
            variant="secondary"
            icon="pencil"
            isOnlyIcon
            onClick={() =>
              history.push(
                `/${dataArea}/artifacts/${currentStorage.id}/documents/edit`
              )
            }
          />
        )}

        {userPermissions.delete && (
          <Button
            data-testid="delete-storage-button"
            variant="secondary"
            icon="trash-bin"
            isOnlyIcon
            contextVariant="danger"
            onClick={handleToggleDeleteModal}
          />
        )}
      </Box>
    </Box>
  );
};

export default withRouter(DetailsTab);
