import React, { useCallback, useState } from 'react';
import { Box, Button, Radio, Text } from '@agendaedu/ae-web-components';
import CenterModal from 'pages/storage/Modals/CenterModal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CalendarActions from 'store/calendar/actions';

import { Props } from './types';

const Approve: React.FC<Props> = ({
  action,
  eventId,
  isOpen,
  onClose,
}): React.ReactElement => {
  const { t } = useTranslation(['calendar']);
  const dispatch = useDispatch();

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`details.modals.${key}`, params),
    [t]
  );

  const [notify, setNotify] = useState<boolean>(true);

  const { setApproveEventRequest } = CalendarActions;

  const isApprove = action === 'approve';

  const handleOnClose = useCallback((): void => {
    onClose();
    setNotify(true);
  }, [onClose]);

  const handleSubmit = useCallback((): void => {
    dispatch(setApproveEventRequest(action, eventId, notify));
    handleOnClose();
  }, [
    action,
    dispatch,
    eventId,
    handleOnClose,
    notify,
    setApproveEventRequest,
  ]);

  const content = {
    approve: {
      title: tBase('approve.title'),
      content: tBase('approve.content'),
      submitButton: tBase('approve.submit_button_text'),
    },
    resend: {
      title: tBase('resend.title'),
      content: tBase('resend.content'),
      submitButton: tBase('resend.submit_button_text'),
    },
  };

  return (
    <CenterModal
      isOpen={isOpen}
      onClose={handleOnClose}
      body={
        <Box
          data-testid="approve-event-modal"
          display="flex"
          flexDirection="column"
          mt="xs"
        >
          <Text
            variant="body-regular-16"
            color="neutral.gray1"
            lineHeight="lg"
            mb={0}
          >
            {content[action].content}
          </Text>

          {isApprove && (
            <Box
              data-testid="radio-button-wrapper"
              py="md"
              display="flex"
              flexDirection="column"
              gap="xs"
            >
              <Radio
                onChange={() => setNotify((prev) => !prev)}
                checked={notify}
              >
                {tBase('approve.aprove_and_notify_option')}
              </Radio>

              <Radio
                onChange={() => setNotify((prev) => !prev)}
                checked={!notify}
              >
                {tBase('approve.aprove_without_notify_option')}
              </Radio>
            </Box>
          )}
        </Box>
      }
      footer={
        <Box display="flex" flexDirection="row-reverse" gap="sm">
          <Button
            data-testid="submit-button"
            variant="primary"
            onClick={handleSubmit}
          >
            {content[action].submitButton}
          </Button>

          <Button
            data-testid="cancel-button"
            variant="secondary"
            onClick={handleOnClose}
          >
            {tBase('approve.cancel_button_text')}
          </Button>
        </Box>
      }
      title={content[action].title}
    />
  );
};

export default Approve;
