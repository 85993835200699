import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAppContext from 'core/hoc/withAppContext';
import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import ClassroomsStep from 'components/Messages/OmniChannel/Forms/MessageChannel/ClassroomsStep';
import { GeneralInformationStepSkeleton } from 'components/Messages/OmniChannel/Forms/MessageChannel/GeneralInformationStep/skeleton';
import GeneralInformationStep from 'components/Messages/OmniChannel/Forms/MessageChannel/GeneralInformationStep';
import SchoolProfileStep from 'components/Messages/OmniChannel/Forms/MessageChannel/SchoolProfileStep';
import Toast from 'components/Toast';
import FormFullScreen from 'components/FormFullScreen';

import * as S from './styles';

import { NewTicketChannelFormProps } from './types';

const WIZARD_STEPS = [
  GeneralInformationStep,
  ClassroomsStep,
  SchoolProfileStep,
];

const NewTicketChannelForm = ({
  appContext: { dataArea },
}: NewTicketChannelFormProps): JSX.Element => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const { fetchInitialChannelFormRequest, setCreateNewChannelRequest } =
    omniChannelActions;

  const { avatarIconsList, isLoading, isSubmittingChannelForm } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const handleCreateNewChannel = useCallback(
    ({ form: params }) => {
      dispatch(setCreateNewChannelRequest(params));
    },
    [dispatch, setCreateNewChannelRequest]
  );

  const getForm = () => {
    if (!avatarIconsList) return;
    return {
      name: '',
      description: '',
      subjects: initialSubjects,
      kind: 'ticket',
      icon: avatarIconsList?.length && avatarIconsList[0].name,
      classroom_ids: [],
      school_user_ids: [],
      attending_hours: null,
    };
  };

  const initialSubjects = [
    {
      slug: 'others',
      title: t('omni_channel.forms.message_channel.fields.others'),
    },
  ];

  useEffect(() => {
    dispatch(fetchInitialChannelFormRequest());
  }, [fetchInitialChannelFormRequest, dispatch]);

  return (
    <S.TicketChannelFormWrapper>
      <FormFullScreen
        action="new"
        id="NewTicketChannel"
        form={getForm()}
        initialAttributes={{}}
        formMeta={{
          nextStepDisabled: true,
        }}
        backTo={`/${dataArea}/messages`}
        backToExternal
        isSubmitting={isSubmittingChannelForm}
        steps={
          avatarIconsList && !isLoading
            ? WIZARD_STEPS
            : [GeneralInformationStepSkeleton]
        }
        onSubmit={handleCreateNewChannel}
        titlePage={t('omni_channel.forms.message_channel.create.title')}
        titleModal={t('omni_channel.forms.message_channel.create.cancel_title')}
        contentModal={t(
          'omni_channel.forms.message_channel.create.cancel_description'
        )}
      />

      <Toast />
    </S.TicketChannelFormWrapper>
  );
};

export default withAppContext(NewTicketChannelForm);
