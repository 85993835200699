import { useState, useEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

const isMobile = (width: number) => width <= 768;

const useMobileScreen = () => {
  const { screenWidth } = useWindowDimensions();
  const [isMobileScreen, setIsMobileScreen] = useState(isMobile(screenWidth));

  useEffect(() => {
    if (isMobileScreen !== isMobile(screenWidth)) {
      setIsMobileScreen(isMobile(screenWidth));
    }
  }, [isMobileScreen, screenWidth]);

  return isMobileScreen;
};

export default useMobileScreen;
