import React from 'react';

import { RecipientCard } from 'components/RecipientSelectList/RecipientCard';
import { Props } from './types';

export const StudentListComponent = ({
  classroom,
  selectedRecipients,
  onSelect,
  onRemove,
}: Props) => {
  return (
    <>
      {classroom.students.map((student, index) => (
        <RecipientCard
          key={index}
          recipient={student}
          toggleCheckbox={(checked) =>
            checked ? onRemove(student) : onSelect(student)
          }
          isSelected={
            !!selectedRecipients.studentTab[classroom.id]?.students?.[
              student.id
            ]
          }
        />
      ))}
    </>
  );
};

export const StudentList = React.memo(
  StudentListComponent,
  (prev, next) =>
    prev.selectedRecipients.studentTab[prev.classroom.id] ===
    next.selectedRecipients.studentTab[prev.classroom.id]
);
