import { Box, Select, TextField } from '@agendaedu/ae-web-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CalendarState } from 'store/calendar/types/store';
import CalendarActions from 'store/calendar/actions';
import FilterActions from 'store/filters/actions';

import useMobileScreen from 'core/hooks/useMobileScreen';
import { STATUS_FILTER_OPTIONS } from 'core/constants/calendar/event';
import { SideFilter } from '../SideFilter';
import { FilterStatesProps } from 'store/filters/types';

const Filters: React.FC = (): React.ReactElement => {
  const { t } = useTranslation(['calendar']);
  const dispatch = useDispatch();

  const isMobileScreen = useMobileScreen();

  const { setEventFilters } = CalendarActions;

  const { fetchHeadquartersRequest, fetchActiveSchoolTermsRequest } =
    FilterActions;

  const {
    list: { filters },
  } = useSelector((state: CalendarState) => state.calendar.event);

  const { headquarters } = useSelector(
    (state: FilterStatesProps) => state.filters
  );

  const [inputValue, setInputValue] = useState(filters?.name || '');
  const [timeoutId, setTimeoutId] = useState(null);

  const handleChangeSearchFilter = useCallback(
    (value: string) => {
      dispatch(
        setEventFilters({
          ...filters,
          name: value,
        })
      );
    },
    [dispatch, filters, setEventFilters]
  );

  const handleChangeFilters = useCallback(
    (value: string) => {
      dispatch(
        setEventFilters({
          ...filters,
          status: value,
        })
      );
    },
    [dispatch, filters, setEventFilters]
  );

  const handleChange = (value: string) => {
    setInputValue(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      handleChangeSearchFilter(value);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`list.filters.${key}`, params),
    [t]
  );

  useEffect(() => {
    if (!headquarters) {
      dispatch(fetchHeadquartersRequest());
      dispatch(fetchActiveSchoolTermsRequest());
    }
  }, [
    dispatch,
    fetchActiveSchoolTermsRequest,
    fetchHeadquartersRequest,
    headquarters,
  ]);

  return (
    <Box
      display="flex"
      flexDirection={{ _sm: 'column', desktopLG: 'row' }}
      paddingY="xl"
      gap={isMobileScreen ? 'xs' : 'sm'}
    >
      <Box display="flex" width={isMobileScreen ? '100%' : 345}>
        <TextField
          clearable
          data-testid="search-field"
          fullWidth={isMobileScreen}
          icon="search"
          onChange={(e) => handleChange(e.target.value)}
          placeholder={tBase('search_field_placeholder_text')}
          value={inputValue}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        gap="xs"
      >
        <Box display="flex" width={260}>
          <Select
            data-testid="status-select"
            fullWidth
            onChange={({ value }) => handleChangeFilters(value)}
            value={filters.status}
            options={STATUS_FILTER_OPTIONS}
          />
        </Box>

        <SideFilter />
      </Box>
    </Box>
  );
};

export default Filters;
