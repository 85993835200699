import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import Field from 'components/Form/Field';

export const InforContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-top: ${space.xl};

    > .Alert {
      height: max-content;
    }
  `}
`;

export const Responsibles = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-top: ${space.sm};
  `}
`;

export const UserItemWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-top: ${space.sm};
  `}
`;

export const CpfWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-top: ${space.xl};
  `}
`;

export const FieldWrapper = styled(Field)`
  margin-top: 0 !important;
  padding: 0 !important;
`;

export const ButtonsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-top: ${space.xl};
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `}
`;
