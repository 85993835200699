import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Select,
  Text,
  ToggleSwitch,
} from '@agendaedu/ae-web-components';

import * as S from './styles';

import withAppContext from 'core/hoc/withAppContext';
import { channelKind } from 'core/constants/omniChannel';

import omniChannelActions from 'store/messages/omniChannel/actions';
import {
  ChatStudentStatus,
  OmniChannelState,
} from 'store/messages/omniChannel/types';
import actionFilters from 'store/filters/actions';

import Accordion from 'components/Accordion';
import { SideModal } from 'components/Messages/OmniChannel/Modals/SideModal';

import { FilterStatesProps } from 'store/filters/types';
import {
  ChatKindOption,
  FormValues,
  Kind,
  Source,
  SourceOption,
  StatusOption,
} from './types';

const ChatFilterModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.modals.filter_chat.${key}`, params),
    [t]
  );

  const { chatFilters, showFilterChatModal, activeChannel } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const { headquarters, educationalStages, classrooms } = useSelector(
    (state: FilterStatesProps) => state.filters
  );

  const [formValues, setFormValues] = useState<FormValues>({
    source: chatFilters.source,
    headquarter: chatFilters.headquarterId,
    educationalStage: chatFilters.educationalStageId,
    classroom: chatFilters.classroomId,
    isInactiveChats: chatFilters.isInactiveChats,
    chatKind: chatFilters.chatKind,
    studentStatus: chatFilters.studentStatus,
  });

  const disabledButton = !Object.values(formValues).some(
    (value) => value !== null && value !== false
  );

  const kind = activeChannel.kind;

  const statusOptions: StatusOption[] = [
    {
      label: tBase('status.placeholder_text'),
      value: null,
    },
    {
      label: tBase('status.not_progress'),
      value: 'not_progress',
    },
  ];

  const chatKindOptions: ChatKindOption[] = [
    {
      label: tBase('chat_kind.placeholder_text'),
      value: null,
    },
    {
      label: tBase('chat_kind.private'),
      value: channelKind.private,
    },
    {
      label: tBase('chat_kind.family'),
      value: channelKind.family,
    },
  ];

  const sourceOptions: SourceOption[] = [
    {
      label: tBase('source.placeholder_text'),
      value: null,
    },
    {
      label: tBase('source.options.school'),
      value: 'school',
    },
    {
      label: tBase('source.options.responsible'),
      value: 'responsible',
    },
    {
      label: tBase('source.options.student'),
      value: 'student',
    },
  ];

  const { setChatFilters, toggleFilterChatModal } = omniChannelActions;

  const {
    fetchEducationalStagesRequest,
    fetchClassRoomsRequest,
    fetchHeadquartersRequest,
  } = actionFilters;

  const handleToggleFilter = useCallback(() => {
    dispatch(toggleFilterChatModal());
  }, [dispatch, toggleFilterChatModal]);

  const handleFetchEducationalStages = useCallback(
    (headquarterId) => {
      dispatch(fetchEducationalStagesRequest(headquarterId));
    },
    [dispatch, fetchEducationalStagesRequest]
  );

  const handleFetchClassroomFilter = useCallback(
    (headquarterId, educationalStageId) => {
      dispatch(fetchClassRoomsRequest(headquarterId, educationalStageId));
    },
    [dispatch, fetchClassRoomsRequest]
  );

  const handleSetFilters = useCallback(() => {
    dispatch(
      setChatFilters({
        ...chatFilters,
        headquarterId: formValues.headquarter,
        educationalStageId: formValues.educationalStage,
        classroomId: formValues.classroom,
        source: formValues.source,
        isInactiveChats: formValues.isInactiveChats,
        chatKind: formValues.chatKind,
        studentStatus: formValues.studentStatus,
      })
    );
    handleToggleFilter();
  }, [chatFilters, formValues, dispatch, handleToggleFilter]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      setChatFilters({
        ...chatFilters,
        headquarterId: null,
        educationalStageId: null,
        classroomId: null,
        source: null,
        isInactiveChats: false,
        chatKind: null,
        studentStatus: null,
      })
    );
    handleToggleFilter();
  }, [chatFilters, formValues, dispatch, handleToggleFilter]);

  const hasHeadquarters = () => {
    return headquarters && headquarters?.length > 0;
  };

  const hasEducationalStages = () => {
    return (
      hasHeadquarters() &&
      formValues.headquarter &&
      educationalStages?.length > 0
    );
  };

  const hasClassrooms = () => {
    return (
      hasEducationalStages() &&
      formValues.educationalStage &&
      classrooms?.length > 0
    );
  };

  const handleFetchHeadquarters = useCallback(() => {
    dispatch(fetchHeadquartersRequest());
  }, [dispatch, fetchHeadquartersRequest]);

  useEffect(() => {
    setFormValues({
      headquarter: chatFilters.headquarterId,
      educationalStage: chatFilters.educationalStageId,
      classroom: chatFilters.classroomId,
      source: chatFilters.source,
      isInactiveChats: chatFilters.isInactiveChats,
      chatKind: chatFilters.chatKind,
      studentStatus: chatFilters.studentStatus,
    });
  }, [chatFilters]);

  useEffect(() => {
    handleFetchHeadquarters();
  }, [handleFetchHeadquarters]);

  return (
    <SideModal.Root
      isOpen={showFilterChatModal}
      title={tBase('title')}
      handleClose={handleToggleFilter}
    >
      <S.FieldWrapper>
        {kind !== channelKind.ticket && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              gap="sm"
              margin="lg"
              alignItems="center"
            >
              <ToggleSwitch
                id="toggle_is_inactive_chats"
                on={formValues.isInactiveChats}
                handleChange={(value) =>
                  setFormValues({
                    ...formValues,
                    isInactiveChats: value,
                  })
                }
              />
              <Text variant="body-regular-14" mb={0}>
                {tBase('inactive_filter_title')}
              </Text>
            </Box>

            <S.Line />
          </>
        )}

        {kind !== channelKind.ticket && (
          <Accordion
            header={
              <Text variant="subtitle-medium-16" mb={0}>
                {tBase('status.label_text')}
              </Text>
            }
            body={
              <S.AccordionWrapper>
                <Select
                  fullWidth
                  placeholder={tBase('status.placeholder_text')}
                  value={formValues.studentStatus}
                  options={statusOptions}
                  onChange={({ value }) => {
                    setFormValues({
                      ...formValues,
                      studentStatus: value as ChatStudentStatus,
                    });
                  }}
                />
              </S.AccordionWrapper>
            }
            expanded="omniChannel"
            allowZeroExpanded
          />
        )}

        {kind === channelKind.privateFamily && (
          <Accordion
            header={
              <Text variant="subtitle-medium-16" mb={0}>
                {tBase('general_chat_kind_label')}
              </Text>
            }
            body={
              <S.AccordionWrapper>
                <Select
                  fullWidth
                  placeholder={tBase('chat_kind.placeholder_text')}
                  value={formValues.chatKind}
                  options={chatKindOptions}
                  onChange={({ value }) => {
                    setFormValues({
                      ...formValues,
                      chatKind: value as Kind,
                    });
                  }}
                />
              </S.AccordionWrapper>
            }
            expanded="omniChannel"
            allowZeroExpanded
          />
        )}

        {kind === channelKind.ticket && (
          <Accordion
            header={
              <Text variant="subtitle-medium-16" mb={0}>
                {tBase('source_filter_text')}
              </Text>
            }
            body={
              <S.AccordionWrapper>
                <Select
                  fullWidth
                  label={tBase('source.label_text')}
                  placeholder={tBase('source.placeholder_text')}
                  value={formValues.source}
                  options={sourceOptions}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      source: event.value as Source,
                    })
                  }
                />
              </S.AccordionWrapper>
            }
            expanded="omniChannel"
            allowZeroExpanded
          />
        )}
        <Accordion
          header={
            <Text variant="subtitle-medium-16" mb={0}>
              {tBase('general_filter_title')}
            </Text>
          }
          body={
            <S.AccordionWrapper>
              <Select
                fullWidth
                label={tBase('headquarter.label_text')}
                placeholder={tBase('headquarter.placeholder_text')}
                value={formValues.headquarter}
                options={headquarters}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    headquarter: event.value,
                    educationalStage: null,
                    classroom: null,
                  });
                  handleFetchEducationalStages(event.value);
                }}
              />
              <Select
                fullWidth
                label={tBase('educational.label_text')}
                placeholder={tBase('educational.placeholder_text')}
                value={formValues.educationalStage}
                options={educationalStages}
                disabled={!hasEducationalStages()}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    educationalStage: event.value,
                    classroom: null,
                  });
                  handleFetchClassroomFilter(
                    formValues.headquarter,
                    event.value
                  );
                }}
              />
              <Select
                fullWidth
                label={tBase('classroom.label_text')}
                placeholder={tBase('classroom.placeholder_text')}
                value={formValues.classroom}
                options={classrooms}
                disabled={!hasClassrooms()}
                onChange={(event) =>
                  setFormValues({ ...formValues, classroom: event.value })
                }
              />
            </S.AccordionWrapper>
          }
          expanded="omniChannel"
          allowZeroExpanded
        />
      </S.FieldWrapper>
      <SideModal.Actions>
        <Button
          data-testId="submit_button"
          disabled={kind === channelKind.ticket ? disabledButton : false}
          onClick={handleSetFilters}
        >
          {tBase('button_submit')}
        </Button>

        <Button
          data-testId="clear_button"
          variant="secondary"
          onClick={handleClearFilter}
        >
          {tBase('button_clear')}
        </Button>
      </SideModal.Actions>
    </SideModal.Root>
  );
};

export default withAppContext(ChatFilterModal);
