import { ClassroomRecipient } from 'store/messages/omniChannel/types';
import {
  ResponsibleProfile,
  StudentProfile,
} from '../normalizeChatClassrooms/types';
import { ClassroomRequest, IncludedRequest } from './types';

export const normalizeChatResponsibles = (
  data: ClassroomRequest[],
  included: IncludedRequest[]
): ClassroomRecipient[] => {
  const includedMap = included.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<string, IncludedRequest>);

  return data
    .map((item) => {
      const students = item.relationships.studentProfiles.data
        .map((student) => {
          const foundStudent = includedMap[student.id] as StudentProfile;
          if (!foundStudent) return null;

          const responsibles =
            foundStudent.relationships.responsibleProfiles.data
              .map((responsible) => {
                const foundResponsible = includedMap[
                  responsible.id
                ] as ResponsibleProfile;
                if (!foundResponsible) return null;
                return {
                  id: foundResponsible.id,
                  name: foundResponsible.attributes.name,
                  nameInitials: foundResponsible.attributes.nameInitials,
                  avatarColor: foundResponsible.attributes.avatarColor,
                  avatarUrl: foundResponsible.attributes.avatarUrl,
                  role: foundResponsible.attributes.role,
                };
              })
              .filter(Boolean);

          if (responsibles.length === 0) return null;

          return {
            channelKind: 'private' as const,
            id: foundStudent.id,
            name: foundStudent.attributes.name,
            isSelected: false,
            nameInitials: foundStudent.attributes.nameInitials,
            avatarColor: foundStudent.attributes.avatarColor,
            avatarUrl: foundStudent.attributes.avatarUrl,
            responsibles,
            classrooms: foundStudent.attributes.classroomIds,
          };
        })
        .filter(Boolean);

      if (students.length === 0) return null;

      return {
        id: item.id,
        name: item.attributes.name,
        sentTo: null,
        students,
      };
    })
    .filter(Boolean);
};
