import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { MessageContainerStyleProps, VariantMessageType } from './types';
import { Icon, Text } from '@agendaedu/ae-web-components';

const wrapperModifiers = {
  sent: ({ approvalStatus, theme: { colors, space, border } }) => css`
    grid-template-areas: '. message avatar' '. info .';
    grid-template-columns: 1fr 70% 0fr;
    ${MessageContainer} {
      background: ${colors.brand.primary.default};

      background: ${approvalStatus === 'pending' &&
      colors.context.warning.default};

      background: ${approvalStatus === 'rejected' &&
      colors.context.danger.default};

      grid-template-areas: 'actions content';
      grid-template-columns: ${space.sm} 1fr;
      border-radius: ${border.radius.lg} ${border.radius.lg} 2px
        ${border.radius.lg};
      margin-left: auto;
    }

    ${ContentWrapper} {
      padding: ${space.xs} ${space.xs} ${space.xs} 0;
    }

    ${InfoContainer} {
      justify-content: flex-end;
    }
  `,
  received: ({ approvalStatus, theme: { colors, border, space } }) => css`
    grid-template-areas: 'avatar message .' '. info .';
    grid-template-columns: 0fr 70% 1fr;

    ${MessageContainer} {
      background: ${approvalStatus === 'rejected'
        ? colors.context.danger.default
        : colors.neutral.gray6};

      grid-template-areas: 'content actions';
      grid-template-columns: 1fr ${space.sm};
      border-radius: ${border.radius.lg} ${border.radius.lg} ${border.radius.lg}
        2px;
      margin-right: auto;
    }

    ${MessageUser} {
      color: ${approvalStatus === 'rejected' && colors.neutral.white};
    }

    ${ContentWrapper} {
      padding: ${space.sm} 0 ${space.sm} ${space.xs};
    }

    ${InfoContainer} {
      justify-content: flex-start;
    }
  `,
};

export const AvatarContainer = styled.div`
  display: flex;
  grid-area: avatar;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const MessageContainer = styled.div<MessageContainerStyleProps>`
  ${({ hasAction }) => css`
    display: grid;
    grid-area: message;

    ${hasAction &&
    css`
      &:hover {
        cursor: pointer;
        #action {
          display: flex;
        }
      }
    `}
  `}
`;

export const MessageUser = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    color: ${colors.brand.primary.default};
    margin-bottom: ${space.xs4};
  `}
`;

export const DeletedMessageIcon = styled(Icon)`
  display: none;
`;

export const MessageContent = styled(Text)`
  ${({ theme: { colors, space, font } }: ThemeProps) => css`
    white-space: pre-line;
    word-break: break-word;
    color: ${colors.neutral.black};
    margin: ${space.xs4};
    line-height: ${font.height.lg} !important;
  `}
`;

export const InfoContainer = styled.div`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    display: flex;
    grid-area: info;
    ${typography.caption.Regular12};
    color: ${colors.neutral.gray2};
    align-items: center;
    gap: ${space.xs3};

    & .MessagesInfo {
      color: ${colors.neutral.gray2};
      ${typography.body.Regular12};
    }
  `}
`;

export const WrapperMessage = styled.div<VariantMessageType>`
  ${({ isChatRedirection, theme, variantType, approvalStatus }) => css`
    display: grid;
    width: 100%;
    padding: 0 0 ${theme.space.md} 0;
    gap: ${theme.space.xs2};

    ${isChatRedirection &&
    css`
      ${MessageContainer} {
        max-width: 295px;
      }
      ${ContentWrapper} {
        flex-direction: row;

        :hover {
          cursor: pointer;
        }

        ::before {
          content: '';
          width: 2px;
          background-color: ${theme.colors.neutral.white};
        }
      }
    `}

    ${wrapperModifiers[variantType]({ theme, approvalStatus })};

    &:not(:first-child) {
      margin: ${theme.space.md} 0 0 0;
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme: { space, typography } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    grid-area: content;
    gap: ${space.xs};

    :hover: {
      cursor: pointer;
    }

    .fileName {
      ${typography.body.Regular12}
    }
  `}
`;

const contentLoaderWrapperModifiers = {
  received: () => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      width: 240px;
      height: 96px;
    }
  `,
  sent: () => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      width: 176px;
      height: 96px;
    }
  `,
};

export const ContentLoaderWrapper = styled.li<VariantMessageType>`
  ${({ variantType }) => css`
    ${contentLoaderWrapperModifiers[variantType]};
  `}
`;

export const MessageBalloonSkeletonWrapper = styled.ul`
  ${({ theme: { space } }: ThemeProps) => css`
    padding: ${space.xs4} ${space.xs4} ${space.xs2} ${space.xs4};
    list-style: none;
  `}
`;

export const SendMessageIcon = styled(Icon)`
  ${({ theme: { colors } }: ThemeProps) => css`
    width: 10px !important;
    height: 10px !important;
    color: ${colors.neutral.gray3};
  `}
`;

export const BotMessageWrapper = styled.div`
  ${({ theme: { border, colors, space, font } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: ${border.radius.lg};
    background: ${colors.neutral.gray6};
    margin-bottom: ${space.md};
    margin-right: ${space.xs2};
    padding: ${space.md};

    p {
      line-height: ${font.height.lg} !important;
    }
  `}
`;
