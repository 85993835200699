import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  Modal,
  Select,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';

import { getDateSchedule, hasInvalidDate } from 'core/helper/calendar/event';
import {
  REMINDER_DATE_OPTIONS,
  REMINDER_MESSAGES,
  REMINDER_UNITS,
} from 'core/constants/calendar/event';

import { Props } from './types';

export const Reminder = ({
  isOpen,
  onClose,
  onSubmit,
  startDate,
  startDatetime,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['calendar']);

  const [value, setValue] = useState('');
  const [currentOption, setCurrentOption] = useState('');

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`forms.event.modals.reminder.${key}`, params),
    [t]
  );

  const dateSchedule = getDateSchedule({
    datetime: startDatetime,
    date: startDate,
  });

  const invalidDate = useMemo(() => {
    const date = hasInvalidDate({
      time: value,
      currentOption,
    });

    if (!date) return false;

    if (date === REMINDER_MESSAGES.INVALID_MESSAGE) {
      return tBase(REMINDER_MESSAGES.INVALID_MESSAGE);
    } else {
      return tBase(REMINDER_MESSAGES.VALUE_RANGE_INPUT, { value: date });
    }
  }, [currentOption, tBase, value]);

  const reminderValue = useMemo(() => {
    if (!currentOption || !value || invalidDate) return '';

    const date = moment(dateSchedule, 'DD/MM/YYYY HH:mm');

    return REMINDER_UNITS[currentOption]
      ? date.subtract(value, REMINDER_UNITS[currentOption])
      : '';
  }, [currentOption, value, invalidDate, dateSchedule]);

  const isValidReminderDate = moment(reminderValue).isAfter(moment());

  const renderReminderAlert = useMemo(() => {
    if (!reminderValue) return null;

    if (isValidReminderDate)
      return (
        <Alert variant="informative">
          {tBase('alert_reminder_value_text', {
            date: reminderValue.format('dddd, DD/MM/YYYY [às] HH:mm'),
          })}
        </Alert>
      );

    return (
      <Alert variant="negative">
        {tBase('alert_reminder_value_error_text')}
      </Alert>
    );
  }, [isValidReminderDate, reminderValue, tBase]);

  const onCloseModal = useCallback(() => {
    setValue('');
    setCurrentOption('');
    onClose();
  }, [onClose]);

  const onSubmitModal = useCallback(() => {
    onSubmit(REMINDER_UNITS[currentOption], value);
    onCloseModal();
  }, [currentOption, onCloseModal, onSubmit, value]);

  return (
    <Modal
      data-testid="reminder-modal"
      isOpen={isOpen}
      title={tBase('title')}
      onClose={onCloseModal}
    >
      <Box width={396} mt="sm" display="flex" flexDirection="column" gap="lg">
        {renderReminderAlert}

        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" gap="sm">
            <Box display="flex" width={134}>
              <TextField
                data-testid="input-value-field"
                onChange={(event) => setValue(event.target.value)}
                placeholder="10"
                value={value}
                error={!!invalidDate}
                maxLength={2}
              />
            </Box>
            <Box display="flex" width="100%">
              <Select
                fullWidth
                onChange={({ value }) => setCurrentOption(value)}
                options={REMINDER_DATE_OPTIONS}
                placeholder={tBase('select_options_placeholder')}
                value={currentOption}
              />
            </Box>
          </Box>
          {!!invalidDate && (
            <Text
              color="context.danger.default"
              data-testid="invalid-date-text"
              mb={0}
              mt="xs"
              variant="label-medium-14"
            >
              {invalidDate}
            </Text>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt="md">
          <Button
            disabled={
              !!invalidDate ||
              !value.length ||
              !currentOption.length ||
              !isValidReminderDate
            }
            testId="submit-button"
            variant="primary"
            onClick={onSubmitModal}
          >
            {tBase('submit_button_text')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
