import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import {
  Box,
  Button,
  DefaultThemeProps,
  Icon,
  Modal,
  Text,
  Tooltip,
} from '@agendaedu/ae-web-components';
import { UploadFilesContext } from 'core/contexts/UploadFiles';
import { FileItem } from './FileItem';

import AlbumActions from 'store/albums/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/albums/types';
import { MEDIA_LIMIT_PER_ALBUM, validFilesTypes } from 'core/constants/albums';
import { FileMediaUpload } from './FileItem/types';
import { useTheme } from 'styled-components';

const ONE_MB_VALUE_IN_KB = 1024;
const LIMIT_SIZE_10_MB = 10 * Math.pow(ONE_MB_VALUE_IN_KB, 2);

const translation_base = 'album_details.add_multiple_media_modal';

const AddMultipleMediaModal = (): JSX.Element => {
  const { addMedia } = AlbumActions;
  const dispatch = useDispatch();
  const { colors } = useTheme() as DefaultThemeProps;

  const { albumDetails, isUploadingMedias } = useSelector(
    (state: RootState) => state.albums
  );
  const { t } = useTranslation(['albums', 'common']);
  const { selectedFiles, destroyMultipleFiles, uploadFile, destroyFile } =
    useContext(UploadFilesContext);
  const [isOpen, setIsOpen] = useState(false);

  const limitToSelect =
    MEDIA_LIMIT_PER_ALBUM - albumDetails.attributes.totalNumberOfPhotos;
  const isExceededLimitToSelect = selectedFiles.length > limitToSelect;

  const validateExtension = (file: FileMediaUpload): string => {
    const getExtensionRegex = /(?:\.([^.]+))?$/;

    const extension = getExtensionRegex.exec(file.name)[1]?.toLocaleLowerCase();

    const extensionMimeType = {
      heic: 'image/heic',
      heif: 'image/heif',
    };

    return extensionMimeType[extension];
  };

  const validateSelectedFiles = selectedFiles.reduce(
    (prev, file: FileMediaUpload) => {
      let adjustedFile = file;

      if (!file.type) {
        adjustedFile = new File([file as unknown as Blob], file.name, {
          type: validateExtension(file),
        }) as unknown as FileMediaUpload;

        Object.assign(adjustedFile, file);
      }

      const invalidType =
        !validFilesTypes.includes(adjustedFile.type) &&
        t(`${translation_base}.invalid_file`);

      const invalidSize =
        adjustedFile.size > LIMIT_SIZE_10_MB &&
        t(`${translation_base}.invalid_size`);

      const invalidReasons = [invalidType, invalidSize].filter(Boolean);

      if (invalidReasons.length) {
        return {
          ...prev,
          invalid: [
            ...prev.invalid,
            Object.assign(adjustedFile, { invalidReasons }),
          ],
        };
      } else {
        return { ...prev, valid: [...prev.valid, adjustedFile] };
      }
    },
    {
      valid: [],
      invalid: [],
    }
  );

  const isUploadedMedias = selectedFiles.every(
    (file) => file.status === 'success'
  );

  const onSendMedias = () => {
    const signedIds = selectedFiles.map(({ signedId }) => signedId);

    dispatch(
      addMedia({
        albumId: albumDetails.id,
        signedIds,
        callbackSuccess: () => onClose(true),
      })
    );
  };

  const onClose = (isSuccess?: boolean) => {
    if (!isSuccess) {
      const filesToDestroy = selectedFiles.map(({ fileId, signedId }) => ({
        fileId,
        signedId,
      }));

      destroyMultipleFiles({ filesToDestroyInfos: filesToDestroy });
    }

    setIsOpen(false);
  };

  const destroyInvalidFiles = () => {
    const filesToDestroy = validateSelectedFiles.invalid.map(
      ({ fileId, signedId }) => ({
        fileId,
        signedId,
      })
    );

    destroyMultipleFiles({ filesToDestroyInfos: filesToDestroy });
  };

  const renderFiles = (
    file: FileMediaUpload,
    index: number,
    array: FileMediaUpload[]
  ) => (
    <Box key={file.fileId}>
      <FileItem
        file={file}
        isUploadingMedias={isUploadingMedias}
        canUploadMedia={!isExceededLimitToSelect}
        uploadFile={uploadFile}
        destroyFile={destroyFile}
      />
      {index !== array.length - 1 && <S.Separator />}
    </Box>
  );

  const invalidFilesWrapper = (
    <Box mt="md">
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
        mb="md"
      >
        <Box display="flex" alignItems="center">
          <Text
            data-testid="selected-invalid-count-text"
            variant="subtitle-medium-14"
            mb={0}
            mr="xs2"
          >
            {t(`${translation_base}.total_invalid_selected`, {
              count: validateSelectedFiles.invalid.length,
            })}
          </Text>

          <Tooltip
            align="top-end"
            content={t('album_details.add_info')}
            elementRef={
              <Box display="flex" alignItems="center">
                <Icon
                  name="help-circle"
                  size="sm"
                  color={colors.neutral.gray1}
                />
              </Box>
            }
          />
        </Box>

        {validateSelectedFiles.invalid.length > 1 && (
          <Button
            data-testid="destroy-invalid-files"
            variant="secondary"
            contextVariant="danger"
            onClick={destroyInvalidFiles}
            size="xs"
            ml="md"
          >
            {t(`${translation_base}.delete_invalid_files`, {
              count: validateSelectedFiles.invalid.length,
            })}
          </Button>
        )}
      </Box>

      {validateSelectedFiles.invalid.map(renderFiles)}
    </Box>
  );

  const validFilesWrapper = (
    <Box mt={validateSelectedFiles.invalid.length ? 'xl' : 'md'}>
      <Box display="flex">
        <Text
          data-testid="selected-valid-count-text"
          variant="subtitle-medium-14"
          mb="md"
          mr="xs2"
        >
          {t(`${translation_base}.total_selected`, {
            count: validateSelectedFiles.valid.length,
          })}
        </Text>

        <Tooltip
          align="top-end"
          content={t(`${translation_base}.limit_album_info`)}
          elementRef={
            <Box display="flex" alignItems="center">
              <Icon name="help-circle" size="sm" color={colors.neutral.gray1} />
            </Box>
          }
        />
      </Box>

      {validateSelectedFiles.valid.map(renderFiles)}
    </Box>
  );

  useEffect(() => {
    if (isExceededLimitToSelect) {
      if (limitToSelect >= 0) {
        toast.error(
          t(`${translation_base}.limit_selected_medias`, {
            count: limitToSelect,
          })
        );
      }

      return;
    }

    setIsOpen(!!selectedFiles.length);
  }, [isExceededLimitToSelect, limitToSelect, selectedFiles.length, t]);

  useEffect(() => {
    if (isOpen && !selectedFiles.length) {
      toast.info(t(`${translation_base}.upload_files_cancel`));
    }
  }, [isOpen, selectedFiles.length, t]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      title={
        isUploadedMedias
          ? t(`${translation_base}.title_uploaded`, {
              count: selectedFiles.length,
            })
          : t(`${translation_base}.title_uploading`, {
              count: selectedFiles.length,
            })
      }
    >
      <S.ContentWrapper>
        <Box maxHeight="40vh" overflowY="auto" mb="md">
          {!!validateSelectedFiles.invalid.length && invalidFilesWrapper}
          {!!validateSelectedFiles.valid.length && validFilesWrapper}
        </Box>

        <S.ButtonsWrapper>
          <Button
            id="cancel-upload-modal-button"
            testId="cancel-button"
            variant="secondary"
            onClick={() => onClose()}
          >
            {t('common:button.cancel')}
          </Button>
          <Button
            id="confirm-upload-modal-button"
            testId="send-button"
            disabled={isUploadingMedias || !isUploadedMedias}
            onClick={onSendMedias}
          >
            {t(`common:button.${isUploadingMedias ? 'sending' : 'send'}`)}
          </Button>
        </S.ButtonsWrapper>
      </S.ContentWrapper>
    </Modal>
  );
};

export default AddMultipleMediaModal;
