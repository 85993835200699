import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import withFormContext from 'core/hoc/withFormContext';
import walletsActions from 'store/edupay/wallets/actions';
import { RootState, WalletConfigurationProps } from './types';
import Field from 'components/Form/Field';
import * as S from './styles';

import {
  Text,
  theme,
  DefaultThemeProps,
  Icon,
} from '@agendaedu/ae-web-components';

const WalletConfiguration = ({
  formContext: {
    updateAttribute,
    form: { recipient_wallet_id: recipientWalletId },
    formMeta: { status },
  },
  isRecurrent = false,
  recurrentBill = false,
  disabled = false,
}: WalletConfigurationProps) => {
  const { t } = useTranslation(['payments']);
  const defaultTheme: DefaultThemeProps = theme;

  const dispatch = useDispatch();
  const [currentRecipientWalletId, setCurrentRecipientWalletId] =
    useState(recipientWalletId);
  const { fetchRecipientsWalletsOptionsRequest } = walletsActions;
  const { walletsOptions } = useSelector((state) => state.wallets);

  const canEdit = ['active', 'not_started'].includes(status);

  const {
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
    policies,
  } = useSelector((state: RootState) => state.root);

  const canSetRecipientWalletId = () => {
    if (isRecurrent && recurrentBill) {
      return true;
    } else if (isRecurrent) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!canSetRecipientWalletId()) setCurrentRecipientWalletId(null);
  }, []);

  useEffect(() => {
    updateAttribute(
      'recipient_wallet_id',
      currentRecipientWalletId !== null ? currentRecipientWalletId : null
    );
  }, [currentRecipientWalletId, updateAttribute, disabled]);

  useEffect(() => {
    dispatch(fetchRecipientsWalletsOptionsRequest());
  }, [dispatch, fetchRecipientsWalletsOptionsRequest]);

  useEffect(() => {
    // We get the second because the first one is a placeholder.
    if (walletsOptions.length === 2)
      setCurrentRecipientWalletId(walletsOptions[1].value);
  }, [walletsOptions]);

  return (
    <S.Wrapper>
      <S.WrapperTitle>
        <Text
          variant="title-bold-20"
          fontWeight={700}
          mr="8px"
          mb={0}
          color={defaultTheme.colors.neutral.black}
        >
          {t('marketplace.new_offer_form.wallet_title')}
        </Text>
        <S.WrapperTooltip
          content={parse(t('marketplace.new_offer_form.wallet_title_tooltip'))}
        >
          <Icon
            name="help-circle"
            size="sm"
            color={defaultTheme.colors.neutral.gray1}
          />
        </S.WrapperTooltip>
      </S.WrapperTitle>
      <Field
        fullWidth
        withArrowSelect
        label={t('marketplace.new_offer_form.wallet_field')}
        type="selectWithIcon"
        attributeName="recipient_wallet_id"
        classNamePrefix="wallet_configuration"
        options={
          isRecurrent && isDebugUser && policies.guaranteed_monthly_fee_enabled
            ? walletsOptions
            : walletsOptions.filter(
                (option) => !option.label?.includes('Mensalidade Garantida')
              )
        }
        value={currentRecipientWalletId}
        onChange={(e) => {
          setCurrentRecipientWalletId(e.value);
        }}
        disabled={!canEdit || disabled}
      />
    </S.Wrapper>
  );
};

export default withFormContext(WalletConfiguration);
