import {
  ActiveChannel,
  AttendingHours,
  AttendingHoursRequest,
  ChanelIncluded,
  Channel,
  ChannelRequest,
} from 'store/messages/omniChannel/types';

export const normalizeAttendingHoursRequest = (
  attendingHours: AttendingHours
  // eslint-disable-next-line @typescript-eslint/ban-types
): AttendingHoursRequest | object => {
  if (!attendingHours) return {};

  const days = Object.keys(attendingHours.days).reduce((acc, key) => {
    acc[key] = {
      ...attendingHours.days[key],
      active: attendingHours.days[key].active ? '1' : '0',
    };
    return acc;
  }, {});

  return {
    blockOnlyNotification: attendingHours.blockOnlyNotification ? '1' : '0',
    days,
  };
};

export const normalizeAttendingHours = (
  attendingHours: AttendingHoursRequest
): AttendingHours | null => {
  if (Object.keys(attendingHours).length === 0) return null;

  const days = Object.keys(attendingHours.days).reduce((acc, key) => {
    acc[key] = {
      ...attendingHours.days[key],
      active: attendingHours.days[key].active === '1',
    };
    return acc;
  }, {});

  const blockOnlyNotification = attendingHours.blockOnlyNotification === '1';

  return {
    blockOnlyNotification,
    days,
  };
};

export const normalizeCurrentChannel = (
  channel: ChannelRequest,
  included: ChanelIncluded
): Channel => {
  const newChannel: Channel & ChannelRequest = {
    ...channel,
    attributes: {
      ...channel.attributes,
      attendingHours: normalizeAttendingHours(
        channel.attributes.attending_hours
      ),
      attendingClosed: channel?.attributes.attending_closed,
      subjects: included.reduce(
        (subjects, include): Channel['attributes']['subjects'] => {
          if (include.type === 'subject') {
            const subject = channel.relationships.subjects.data.find(
              (subject) => subject.id === include.id
            );

            if (subject) {
              return [...subjects, { ...include.attributes }];
            }
          }
        },
        []
      ),
    },
  };

  delete newChannel.attributes.attending_closed;
  delete newChannel.attributes.attending_hours;
  delete newChannel.relationships.subjects;

  return newChannel;
};

export const normalizeChannels = (channels: ChannelRequest[]): Channel[] => {
  if (channels.length < 0) return channels;

  const newChannels = channels.map((channel) => {
    const newChannel = {
      ...channel,
      attributes: {
        ...channel.attributes,
        attendingHours: normalizeAttendingHours(
          channel.attributes.attending_hours
        ),
        attendingClosed: channel?.attributes.attending_closed,
      },
    };

    delete newChannel.attributes.attending_closed;
    delete newChannel.attributes.attending_hours;
    return newChannel;
  });

  return newChannels;
};

export const normalizeActiveChannel = (channel: Channel): ActiveChannel => {
  return {
    id: channel.id,
    name: channel.attributes.name,
    iconUrl: channel.attributes.icon_url,
    kind: channel.attributes.kind,
    attendingHours: channel.attributes.attendingHours,
    attendingClosed: channel.attributes.attendingClosed,
  };
};
