import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import Tabs from 'components/WizardForm/Tabs';
import Title from 'components/WizardForm/Title';
import AgendaIcon from 'components/AgendaIcon';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import FormBackendErrorWarning from 'components/Form/BackendErrorWarning';
import FormValidationErrors from 'components/Form/ValidationErrors';
import Loader from 'components/Loader';

import withFormContext from 'core/hoc/withFormContext';

import './style.scss';

class WizardForm extends Component {
  static propTypes = {
    finishText: PropTypes.string.isRequired,
    finishAndNewText: PropTypes.string.isRequired,
    formContext: PropTypes.shape({
      formMeta: PropTypes.shape({
        nextStepDisabled: PropTypes.bool,
        resetFormTabs: PropTypes.bool,
      }),
    }).isRequired,
    isSubmitting: PropTypes.bool,
    steps: PropTypes.arrayOf(PropTypes.func).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    onSubmitAndNewRegister: PropTypes.func,
    actionComponent: PropTypes.node,
    backTo: PropTypes.string,
    titlePage: PropTypes.string,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    finishText: 'Salvar',
    finishAndNewText: 'Salvar e continuar',
    isSubmitting: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      CurrentStep: props.steps[0],
      submitting: false,
      completedSteps: [props.steps[0]],
    };
  }

  inFirstStep() {
    const { steps } = this.props;
    const { CurrentStep } = this.state;

    return steps[0] === CurrentStep;
  }

  inLastStep() {
    const { steps } = this.props;
    const { CurrentStep } = this.state;

    return steps[steps.length - 1] === CurrentStep;
  }

  @autobind
  goToFirstStep() {
    const { steps } = this.props;

    this.setState({ CurrentStep: steps[0] });
  }

  @autobind
  goToNextStep() {
    if (this.inLastStep()) return;

    this.setState((prevState, props) => {
      const { steps, formContext } = props;
      const { CurrentStep, completedSteps } = prevState;
      const { validateFormState, onChangeValidations } = formContext;
      const { validations, onChangeValidationsAttributeNames } = CurrentStep;
      const currentStepOnChangeValidations = onChangeValidations(
        formContext
      ).filter((validation) =>
        onChangeValidationsAttributeNames.includes(validation.attributeName)
      );

      const { isValid: isStepValid } = validateFormState(
        validations,
        currentStepOnChangeValidations
      );

      if (!isStepValid) return { CurrentStep };

      const currentStepIndex = steps.indexOf(CurrentStep);
      const NextStep = steps[currentStepIndex + 1];

      return {
        CurrentStep: NextStep,
        completedSteps: [...completedSteps, NextStep],
      };
    });
  }

  @autobind
  goToPreviousStep() {
    if (this.inFirstStep()) return;

    this.setState((prevState) => {
      const { steps } = this.props;
      const { CurrentStep } = prevState;

      const currentStepIndex = steps.indexOf(CurrentStep);
      const PreviousStep = steps[currentStepIndex - 1];

      return { CurrentStep: PreviousStep };
    });
  }

  @autobind
  goToStep(step) {
    const { steps } = this.props;
    const current = steps[step];
    return this.setState({ CurrentStep: current });
  }

  @autobind
  onSubmit(_event) {
    const { onSubmit, formContext } = this.props;
    const { validateFormState } = formContext;
    const { CurrentStep } = this.state;

    const { isValid: isStepValid } = validateFormState(CurrentStep.validations);

    if (isStepValid) {
      onSubmit(formContext, {
        goToFirstStep: this.goToFirstStep,
        setSubmitting: this.setSubmitting,
      });
    }
  }

  @autobind
  onSubmitAndNewRegister() {
    const { onSubmitAndNewRegister, formContext } = this.props;
    const { validateFormState } = formContext;
    const { CurrentStep } = this.state;

    const { isValid: isStepValid } = validateFormState(CurrentStep.validations);

    if (isStepValid) {
      onSubmitAndNewRegister(formContext);
    }
  }

  @autobind
  setSubmitting(value) {
    this.setState({ submitting: value });
  }

  @autobind
  isStepActive(step) {
    const { completedSteps } = this.state;
    return completedSteps.includes(step);
  }

  resetFormTabs = () => {
    const { steps } = this.props;
    this.setState({ completedSteps: [steps[0]] });
  };

  @autobind
  subTitle() {
    const { CurrentStep } = this.state;
    const { subTitle } = CurrentStep;

    if (!subTitle) return null;

    return <Title name={subTitle} />;
  }

  goBackButton() {
    if (this.inFirstStep()) {
      const { actionComponent } = this.props;
      return <div className="action-component">{actionComponent}</div>;
    }
    return (
      <Button
        variation="secondary"
        className="back"
        onClick={this.goToPreviousStep}
      >
        Voltar
      </Button>
    );
  }

  removeButton() {
    const { onRemove } = this.props;

    if (onRemove !== undefined) {
      return (
        <Button variation="danger" className="back" onClick={onRemove}>
          Remover
        </Button>
      );
    }

    return null;
  }

  @autobind
  canSave() {
    if (
      this.props.formContext.form.canSave !== undefined &&
      this.inLastStep()
    ) {
      return !this.props.formContext.form.canSave;
    } else {
      return false;
    }
  }

  validateFormAttributes(form) {
    return Object.values(form).every((value) => {
      if (!value || !value.length) {
        return false;
      }
      return true;
    });
  }

  formWithRequiredFields(form, attributesArray) {
    var newForm = {};
    attributesArray.map((attribute) => {
      newForm = Object.assign(newForm, { [attribute]: form[attribute] });
    });
    return newForm;
  }

  nextButtonEnabledByFields = () => {
    const {
      action,
      form,
      formMeta: { nextStepRequiredFields },
      hasUpdatesOnForm,
    } = this.props.formContext;

    if (nextStepRequiredFields !== undefined) {
      const slicedForm = this.formWithRequiredFields(
        form,
        nextStepRequiredFields
      );
      const hasUpdates = hasUpdatesOnForm();
      const allRequiredFieldsAreFilled =
        this.validateFormAttributes(slicedForm);

      return action === 'edit'
        ? !(allRequiredFieldsAreFilled && hasUpdates)
        : !allRequiredFieldsAreFilled;
    }
    return false;
  };

  @autobind
  nextButtonDisabled() {
    return (
      this.props.formContext.formMeta.nextStepDisabled ||
      this.state.submitting ||
      this.canSave()
    );
  }

  @autobind
  componentDidUpdate(prevProps) {
    const { formContext, steps } = this.props;
    const {
      formMeta: { resetFormTabs },
    } = formContext;

    if (
      resetFormTabs &&
      prevProps.formContext.formMeta.resetFormTabs !== resetFormTabs
    ) {
      this.resetFormTabs();
    }

    if (prevProps.steps.length !== steps.length) {
      this.setState({
        CurrentStep: steps[0],
        completedSteps: [steps[0]],
      });
    }
  }

  nextOrSubmitButton() {
    const { isSubmitting } = this.props;

    if (this.inLastStep()) {
      return (
        <div className="ButtonRow">
          <Button
            type="submit"
            className="submit"
            onClick={this.onSubmit}
            disabled={
              this.nextButtonDisabled() ||
              this.nextButtonEnabledByFields() ||
              isSubmitting
            }
          >
            <Loader
              isLoading={isSubmitting}
              variation="content"
              color="white"
              size={20}
            >
              {this.props.finishText}
            </Loader>
          </Button>

          {this.props.onSubmitAndNewRegister && (
            <Button
              type="submit"
              className="submit"
              onClick={this.onSubmitAndNewRegister}
              disabled={
                this.nextButtonDisabled() ||
                this.nextButtonEnabledByFields() ||
                isSubmitting
              }
            >
              <Loader
                isLoading={isSubmitting}
                variation="content"
                color="white"
                size={20}
              >
                {this.props.finishAndNewText}
              </Loader>
            </Button>
          )}
        </div>
      );
    }

    return (
      <Button
        className="submit"
        onClick={this.goToNextStep}
        disabled={this.nextButtonDisabled() || this.nextButtonEnabledByFields()}
      >
        Próximo
      </Button>
    );
  }

  render() {
    const { steps, formContext, backTo, titlePage, toggleModal } = this.props;
    const { CurrentStep } = this.state;
    const { hasBackendValidationError, hasErrorOnAttribute } = formContext;

    return (
      <div className="WizardForm">
        <div className="wizard-content">
          <div className="wizard-taps-header">
            {titlePage ? <PageTitle>{titlePage}</PageTitle> : null}
            <Tabs
              steps={steps}
              CurrentStep={CurrentStep}
              isStepActive={this.isStepActive}
              goToStep={this.goToStep}
            />
            {backTo ? (
              <AgendaIcon
                name="close"
                size="x-medium"
                onClick={() => toggleModal(formContext)}
              />
            ) : null}
          </div>
          {titlePage && backTo ? <div className="Separator" /> : null}
          {hasBackendValidationError ? <FormBackendErrorWarning /> : null}
          {hasErrorOnAttribute('base') ? (
            <FormValidationErrors attributeName="base" multiple />
          ) : null}
          <fieldset>
            <fieldset className="FormFieldset col-xs-12 col-lg-8 col-lg-offset-2">
              {this.subTitle()}
            </fieldset>
            <CurrentStep />
          </fieldset>
        </div>
        <div className="wizard-footer">
          {this.removeButton()}
          {this.goBackButton()}
          {this.nextOrSubmitButton()}
        </div>
      </div>
    );
  }
}

export default withFormContext(WizardForm);
