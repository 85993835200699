import React, { useEffect, useState } from 'react';
import useWindowDimensions from 'core/hooks/useWindowDimensions';
import withAppContext from 'core/hoc/withAppContext';
import { Badge } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { TabsProps } from './types';

const Tabs = ({
  badges,
  currentTab,
  hasBorder = true,
  onChangeTab,
  tabs,
}: TabsProps): JSX.Element => {
  const [widthTabs, setWidthTabs] = useState<number[]>([]);

  const { screenWidth } = useWindowDimensions();

  useEffect(() => {
    const elements = document.querySelectorAll('div[id="tab"]');
    setWidthTabs(
      Array.from(elements).map(
        (element) => element.getBoundingClientRect().width
      )
    );
  }, [currentTab, screenWidth, badges]);

  return (
    <S.Wrapper hasBorder={hasBorder}>
      <S.Tabs>
        {tabs.map((tab, index: number) => {
          const isCurrentTab = currentTab === index;

          return (
            <S.TabWrapper
              aria-selected={isCurrentTab}
              data-testid={`tab-${index}`}
              id="tab"
              key={index}
              onClick={() => onChangeTab(index)}
            >
              <S.Tab>
                <S.Title currentTab={isCurrentTab} variant="button-bold-16">
                  {tab.title}
                </S.Title>
                {badges && badges[tab.key] > 0 && (
                  <Badge value={badges[tab.key]} />
                )}
              </S.Tab>
            </S.TabWrapper>
          );
        })}
      </S.Tabs>
      {widthTabs.length > 0 && (
        <S.TabSlider
          data-testid="slider-tab"
          widthTabs={widthTabs}
          index={currentTab}
        />
      )}
    </S.Wrapper>
  );
};

export default withAppContext(Tabs);
