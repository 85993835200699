import React from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';

export const DiscardModal = ({
  isOpen,
  onClose,
  onConfirmDiscard,
}: Props): JSX.Element => {
  const { t } = useTranslation(['diary', 'common']);

  return (
    <Modal
      isOpen={isOpen}
      title={t('form.discard_modal.title')}
      onClose={onClose}
    >
      <Box width="100%" mt="sm">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          {t('form.discard_modal.content')}
        </Text>

        <Box display="flex" justifyContent="flex-end" mt="md">
          <Button
            id="back-button"
            testId="back-button"
            variant="secondary"
            mr="sm"
            onClick={onClose}
          >
            {t('common:button.back')}
          </Button>
          <Button
            id="confirm-button"
            testId="confirm-button"
            contextVariant="danger"
            onClick={onConfirmDiscard}
          >
            {t('form.discard_modal.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
