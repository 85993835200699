import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from '../styles';

import { OmniChannelState } from 'store/messages/omniChannel/types';
import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';

export const TicketInformationDetail = () => {
  const { t } = useTranslation(['messages']);

  const { isActiveFlag: isChannelSubjectFieldActive } = useLaunchDarkly({
    flag: 'CHANNEL_SUBJECT',
  });

  const { activeTicket, activeChannel } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const days = activeChannel?.attendingHours?.days;

  return (
    <S.InformationWrapper data-testid="ticket-modal">
      <S.Description>
        {t('omni_channel.modals.ticket_detail.description')}
      </S.Description>

      <S.InfoBlock>
        <S.Label>{t('omni_channel.modals.ticket_detail.id_label')}</S.Label>
        <S.Text>
          {t('omni_channel.modals.ticket_detail.id_text', {
            id: activeTicket.id,
          })}
        </S.Text>
      </S.InfoBlock>

      {isChannelSubjectFieldActive && (
        <S.InfoBlock>
          <S.Label>{t('omni_channel.modals.ticket_detail.subject')}</S.Label>
          <S.Text>{activeTicket.attributes.subject}</S.Text>
        </S.InfoBlock>
      )}

      <S.InfoBlock>
        <S.Label>{t('omni_channel.modals.ticket_detail.status_label')}</S.Label>
        <S.Text>
          {t(
            `omni_channel.modals.ticket_detail.status_${activeTicket.attributes.status}_text`
          )}
        </S.Text>
      </S.InfoBlock>

      <S.InfoBlock>
        <S.Label>
          {t('omni_channel.modals.ticket_detail.source_channel')}
        </S.Label>
        <S.Text>{activeTicket.attributes.sourceChannel}</S.Text>
      </S.InfoBlock>

      <S.InfoBlock>
        <S.Label>
          {t('omni_channel.modals.ticket_detail.support_started_at')}
        </S.Label>
        <S.Text>{activeTicket.attributes.supportStartedAt}</S.Text>
      </S.InfoBlock>

      <S.InfoBlock>
        <S.Label>
          {t('omni_channel.modals.ticket_detail.support_ended_at')}
        </S.Label>
        <S.Text>{activeTicket.attributes.supportEndedAt}</S.Text>
      </S.InfoBlock>

      <S.InfoBlock>
        <S.Label>
          {t('omni_channel.modals.ticket_detail.current_attendant')}
        </S.Label>
        <S.Text>{activeTicket.attributes.currentAttendant}</S.Text>
      </S.InfoBlock>

      {days && (
        <S.InfoBlock>
          <S.Label>
            {t('omni_channel.modals.details_chat.attending_hours_label')}
          </S.Label>
          <S.AttendingHoursWrapper>
            {[
              ...Object.entries(days).filter(([day]) => day !== '0'),
              Object.entries(days).find(([day]) => day === '0'),
            ].map(([day, { active, hours }]) => {
              if (!active) return;

              return (
                <S.Text key={day}>
                  {t(`omni_channel.modals.attending_hours_common.days.${day}`, {
                    openHour: hours[0],
                    closeHour: hours[1],
                  })}
                </S.Text>
              );
            })}
          </S.AttendingHoursWrapper>
        </S.InfoBlock>
      )}
    </S.InformationWrapper>
  );
};
