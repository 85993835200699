import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';

import Toast from 'components/Toast';
import List from 'components/SummarydayClassrooms/components/ClassroomsList';
import SkeletonClassroomsPage from 'components/SummarydayClassrooms/components/SkeletonClassrooms';
import SideSheetFilters from 'pages/SummarydayClassrooms/components/SideSheetFilters';
import SummarydayClassroomsActions from 'store/summarydayClassrooms/actions';

import { checkProperties } from '../../../core/utils/dataObject';
import moment from 'moment';
import {
  Box,
  Button,
  FlexGrid,
  FlexGridItem,
  Select,
  SingleDate,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';

const SummarydayClassrooms = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['summaryday_classrooms']);

  const policies = useSelector((state: RootStateOrAny) => state.root.policies);

  const [openSideSheet, setOpenSideSheet] = useState(false);
  const [amountFilters, setAmountFilters] = useState(0);

  const {
    setClassrooms,
    fetchClassroomsList,
    fetchDisciplines,
    fetchEducationalStages,
    fetchClassroomFilter,
  } = SummarydayClassroomsActions;

  const {
    classrooms,
    isLoading,
    selectedSituation,
    selectedDiscipline,
    selectedMonth,
    selectedStartDate,
    selectedEndDate,
    selectedSchoolTerm,
    title_cont,
    selectedHeadquarter,
    selectedEducationalStage,
    selectedClassroomFilter,
    disciplines,
    educationalStages,
    headquarters,
    classroomList,
    schoolTerms,
    classroomFilter,
    classroomsPagination,
  } = useSelector((state: Record<string, any>) => state.summarydayClassrooms);

  const callFetchClassroomsList = useCallback(
    (parameters) => {
      const queryParams = {
        params: {
          situation: selectedSituation,
          title_cont: title_cont,
          headquarter: selectedHeadquarter,
          educational_stage: selectedEducationalStage,
          classroom: selectedClassroomFilter,
          discipline: selectedDiscipline,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          school_term: selectedSchoolTerm || null,
          ...parameters,
        },
      };

      setAmountFilters(checkProperties(queryParams.params, ['page']));

      dispatch(fetchClassroomsList(queryParams));
    },
    [
      dispatch,
      fetchClassroomsList,
      selectedSituation,
      selectedSchoolTerm,
      title_cont,
      selectedHeadquarter,
      selectedEducationalStage,
      selectedClassroomFilter,
      selectedDiscipline,
      selectedStartDate,
      selectedEndDate,
    ]
  );

  useEffect(() => {
    if (!classrooms.length) callFetchClassroomsList({});
    dispatch(fetchDisciplines());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const fetchClassrooms = useCallback(() => {
    dispatch(setClassrooms());
  }, [dispatch, setClassrooms]);

  useEffect(() => {
    fetchClassrooms();
  }, [dispatch, fetchClassrooms]);

  const sideSheetToggle = () => setOpenSideSheet(!openSideSheet);

  if (isLoading)
    return (
      <Box mt="sm" mb="xl" mx={{ _: 'sm', desktopLG: 'xl' }}>
        <SkeletonClassroomsPage />;
      </Box>
    );

  return (
    <>
      <Box mt="sm" mb="xl" mx={{ _: 'sm', desktopLG: 'xl' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="md"
        >
          <Text
            variant="headline-h2-bold-24"
            fontWeight="bold"
            color="neutral.black"
          >
            {t('list.title')}
          </Text>

          {policies?.can_create_activity && (
            <Link to="/schools/summaryday_classrooms/new">
              <Button size="md">{t('list.new_summaryday_classroom')}</Button>
            </Link>
          )}
        </Box>
      </Box>

      <Box mx={{ _: 'sm', desktopLG: 'xl' }}>
        <FlexGrid mb="sm">
          <FlexGridItem filled cols={{ desktopLG: 3, desktopXL: 2 }}>
            <TextField
              placeholder={t('list.search')}
              icon="search"
              fullWidth
              onChange={(value) =>
                callFetchClassroomsList({ title_cont: value.target.value })
              }
            />
          </FlexGridItem>
          <FlexGridItem filled cols={{ desktopLG: 3, desktopXL: 2 }}>
            <SingleDate
              id="start-date"
              value={selectedStartDate && moment(selectedStartDate)}
              handleChange={(date) => {
                callFetchClassroomsList({
                  startDate: date || null,
                });
              }}
              fullWidth
              placeholder={t('list.start_date')}
              handleOutsideRange={() => false}
            />
          </FlexGridItem>
          <FlexGridItem filled cols={{ desktopLG: 3, desktopXL: 2 }}>
            <SingleDate
              id="end-date"
              value={selectedEndDate && moment(selectedEndDate)}
              handleChange={(date) => {
                callFetchClassroomsList({
                  endDate: date || null,
                });
              }}
              fullWidth
              placeholder={t('list.end_date')}
              handleOutsideRange={() => false}
            />
          </FlexGridItem>

          <FlexGridItem filled cols={{ desktopLG: 3, desktopXL: 3 }}>
            <Select
              options={[
                { value: '', label: t('list.disciplines_default_option') },
                ...disciplines,
              ]}
              fullWidth
              value={selectedDiscipline}
              onChange={(e) => callFetchClassroomsList({ discipline: e.value })}
            />
          </FlexGridItem>
          <FlexGridItem filled cols={{ desktopLG: 12, desktopXL: 3 }}>
            <Button
              ml="auto"
              icon="filter"
              variant="secondary"
              isOnlyIcon
              badges={amountFilters}
              onClick={sideSheetToggle}
            />
          </FlexGridItem>
        </FlexGrid>

        <SideSheetFilters
          isOpen={openSideSheet}
          onDismiss={sideSheetToggle}
          title={t('list.filters_title')}
          onApplyFilters={callFetchClassroomsList}
          headquarters={headquarters}
          dataList={classroomList}
          educationalStages={educationalStages}
          actions={{ fetchClassroomFilter, fetchEducationalStages }}
          selecteds={{
            selectedSituation,
            selectedHeadquarter,
            selectedEducationalStage,
            selectedClassroomFilter,
            selectedDiscipline,
            selectedMonth,
            selectedSchoolTerm,
            classroomFilter,
            schoolTerms,
          }}
        />

        {!!classroomsPagination.totalItemsCount && (
          <Text
            width="fit-content"
            ml="auto"
            variant="subtitle-medium-12"
            color="neutral.gray2"
          >
            {t('list.counter', { count: classroomsPagination.totalItemsCount })}
          </Text>
        )}

        <List callFetch={callFetchClassroomsList} />
        <Toast />
      </Box>

      <Toast />
    </>
  );
};

export default withAppContext(withFormContext(SummarydayClassrooms));
