import { all, call, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi } from 'core/utils/api';
// import { IntegrationsProescMockData } from '__mocks__/IntegrationsMonitor'; //run tests

function* getIntegrationSaga() {
  try {
    const { data } = yield call(
      fetchApi,
      `/schools/erp_integrations/monitor.json`
    );

    yield put({
      type: actions.GET_INTEGRATION_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter as informações da integração.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* getIntegrationInconsistenciesSaga(action) {
  try {
    const { data, included, meta } = yield call(
      fetchApi,
      `/schools/erp_integrations/monitor/${action.idIntegration}.json?entity=${action.entity}`,
      { params: { page: action.page, per_page: 10 } }
    );

    yield put({
      type: actions.GET_INTEGRATION_INCONSISTENCIES_SUCCESS,
      data,
      included,
      meta,
      page: action.page,
    });
  } catch (error) {
    yield put({
      type: actions.GET_INTEGRATION_INCONSISTENCIES_SUCCESS,
      data: null,
      included: [],
      meta: { page: 1, per_page: 10, total: 0 },
      page: 1,
    });

    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter as inconsistências da integração.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* reRunIntegrationSaga(action) {
  try {
    yield call(
      postApi,
      `/schools/erp_integrations/monitor/run`
    );

    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        'Integração iniciada com sucesso!',
        ToastTypes.error,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Aguarde! A integração só pode ser executada a cada 5 horas. Tente novamente em instantes.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* integrationsMonitorSagas() {
  yield all([
    takeLatest(actions.GET_INTEGRATION, getIntegrationSaga),
    takeLatest(actions.RE_RUN_INTEGRATION, reRunIntegrationSaga),
    takeLatest(
      actions.GET_INTEGRATION_INCONSISTENCIES,
      getIntegrationInconsistenciesSaga
    ),
  ]);
}

export default integrationsMonitorSagas;
