import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import Tooltip from 'components/Tooltip';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .Field {
    padding: 0px;
    .wallet_configuration__option,
    .wallet_configuration__single-value {
      white-space: pre-wrap;
    }
  }
`;

export const WrapperTitle = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${space.lg};
  `}
`;

export const WrapperTooltip = styled(Tooltip)`
  margin-bottom: -6px;
`;
