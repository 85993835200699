import styled, { css } from 'styled-components';

import FormFieldset from 'components/Form/Fieldset';

import { ThemeProps } from 'config/themes/types';
import { TextField } from '@agendaedu/ae-web-components';

export const GeneralInformationStepWrapper = styled(FormFieldset)``;

export const RowWrapper = styled.div`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs2};
    padding: ${space.xs4};
    margin-top: ${space.md};

    [data-testid='alert'] {
      width: 100%;
      justify-content: flex-start;

      svg.ae-warning-icon {
        min-width: 24px;
        min-height: 24px;

        path {
          fill: ${colors.context.info.default};
        }
      }
    }

    .Field {
      display: flex;
      padding: ${space.xs4};
      margin: ${space.xs4} !important;
    }
  `}
`;

export const TextArea = styled(TextField)`
  textarea {
    min-height: 120px !important;
  }
`;

export const ToggleWrapper = styled.div`
  ${({ theme: { typography, colors, space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.lg};
    color: ${colors.neutral.gray2};
    ${typography.body.Regular14}
  `}
`;

export const SubjectRow = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.sm};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  `}
`;

export const FieldRow = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.xs2};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  `}
`;

export const FormSkeletonWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    height: 100%;
    max-width: 100%;
    padding: ${space.xs4} 0;
    margin: ${space.xl3} 0;
    background-color: ${colors.neutral.white};

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin: ${space.xl3} 0;
    }

    @media only screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs4} 0;
    }
  `}
`;

export const FormSkeletonInputs = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs};
  `}
`;

export const OnlyNotificationsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    justify-content: space-between;
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: ${space.xs3};
  `}
`;

export const OnlyNotificationsCheckWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.lg};
    align-items: center;
  `}
`;

export const ConfigurationWrapper = styled.div`
  ${({ theme: { typography, colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${space.sm};
    color: ${colors.neutral.gray1};
    ${typography.label.Regular16}
  `}
`;

export const GroupCheckWrapper = styled.div`
  ${({ theme: { typography, colors, space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.lg};
    color: ${colors.neutral.gray2};
    ${typography.body.Regular14}
  `}
`;

export const GroupCheckText = styled.label`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    color: ${colors.neutral.gray2};
    margin: 0px;
    ${typography.body.Regular14}
  `}
`;
