import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import Field from 'components/Form/Field';

export const EdupayDiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .FormValidationErrors {
    display: flex;
    align-items: flex-start;
  }
`;

export const ContainerWrapper = styled.div`
  ${({ theme: { space, colors, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    border: ${border.width.sm} solid ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    margin-top: ${space.sm};
    padding: ${space.lg};
  `}
`;

export const TitleWrapper = styled.p`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${space.lg};
  `}
`;

export const Title = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    ${typography.title.Bold16}
  `}
`;

export const EdupayDiscount = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.xs2};
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  `}
`;

export const QuantityField = styled.div`
  ${({ theme: { colors, space, border, font } }: ThemeProps) => css`
    height: ${space.xl2};
    width: ${space.xl5};
    padding: 0;
    margin: 0;
    margin-bottom: 0;

    input {
      height: ${space.xl2};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
      border-radius: ${space.xs2};
      font-size: ${font.size.md};
    }

    > div {
      padding: 0;
      margin: 0;
    }
  `}
`;

export const SelectedDays = styled(Field)`
  width: 155px;
  margin: 0 !important;
  padding: 0 !important;
`;

export const Text = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    color: ${colors.neutral.gray1};
    margin-bottom: 0;
    ${typography.label.Regular14}
  `}
`;

export const TypeDiscountWrapper = styled.div`
  display: flex;
  margin-bottom: 0;

  > div {
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;
  }
`;

export const DeleteButtonWrapper = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    display: flex;

    .Delete {
      .OutlineBox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${space.xl};
        width: ${space.xl};
        padding: ${space.xs2};
        border-radius: ${border.radius.sm};
        margin-right: 0;
        cursor: pointer;

        .AgendaIcon {
          height: ${space.sm};
          width: ${space.sm};
          color: ${colors.context.danger.default};
          font-size: 16px !important;
        }

        &:hover {
          background-color: ${colors.context.danger.op10};
          border-color: ${colors.context.danger.op10};
        }
      }
    }
  `}
`;
