import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  DefaultThemeProps,
  ImageUploader,
  SingleDate,
  Text,
  TextField,
  ToggleSwitch,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import * as S from './styles';

import { EventFormContext } from 'core/contexts/Calendar/Forms/Event';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';

import FileUploader from '../../FileUploader';
import FormContainer from 'components/Form/FormContainer';

const Informations = (): React.ReactElement => {
  const { t } = useTranslation(['calendar']);

  const {
    form: {
      errors,
      values: {
        attendance,
        conferenceLink,
        confirmUntil,
        description,
        hasConfirmUntil,
        image,
        location,
        title,
      },
      setFieldValue,
    },
    isInformationsStepValid,
    setCurrentStep,
  } = useContext(EventFormContext);

  const { breakpoints, colors } = useTheme() as DefaultThemeProps;

  const nextStep = () => setCurrentStep(2);

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`forms.event.steps.informations.${key}`, params),
    [t]
  );

  const footer = (
    <Box
      py="sm"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button
        data-testid="next-step-button"
        ml="auto"
        disabled={!isInformationsStepValid}
        onClick={nextStep}
      >
        {t('common:button.next')}
      </Button>
    </Box>
  );

  return (
    <FormContainer initialAttributes={{}}>
      <Box
        id="informations-step"
        display="flex"
        position="relative"
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        mx={{ _: 'xs', mobileSM: 'lg', desktopLG: 'xl5' }}
        mb="10vh"
      >
        <Box width="100%" height="100%" mt="xl3" pb="md" overflow="auto">
          <Box
            display="flex"
            flexDirection="column"
            maxWidth={breakpoints.tabletMD}
            mx="auto"
          >
            <Text
              data-testid="title-label"
              variant="title-bold-20"
              fontWeight={700}
              color="neutral.black"
              mb={0}
            >
              {tBase('title')}
            </Text>
            <Box display="flex" flexDirection="column" paddingY="xl">
              <Box display="flex" flexDirection="column" gap="lg">
                <ImageUploader
                  data-testid="image-uploader-field"
                  label={tBase('image_uploader_label')}
                  onChange={(file: File) => setFieldValue('image', file)}
                  value={image}
                />
                <TextField
                  clearable
                  data-testid="title-field"
                  fullWidth
                  label={tBase('title_field_label')}
                  onChange={(e) => setFieldValue('title', e.target.value)}
                  placeholder={tBase('title_field_placeholder')}
                  value={title}
                  error={!!errors.title}
                  errorMessage={errors.title}
                />
                <TextField
                  clearable
                  data-testid="location-field"
                  fullWidth
                  label={tBase('location_field_label')}
                  onChange={(e) => setFieldValue('location', e.target.value)}
                  placeholder={tBase('location_field_placeholder')}
                  value={location}
                  error={!!errors.location}
                  errorMessage={errors.location}
                />
                <Box display="flex" flexDirection="column">
                  <Text
                    variant="label-regular-14"
                    mb={12}
                    lineHeight="lg"
                    color="neutral.gray1"
                  >
                    {tBase('description_field_label')}
                  </Text>
                  <S.TextAreaField
                    quillProps={{
                      onChange: (value: string) =>
                        description !== value &&
                        setFieldValue(
                          'description',
                          validateTextAreaPresence(value) ? value : ''
                        ),
                      value: description,
                    }}
                    type="textArea"
                    useImage
                    error={!!errors.description}
                  />

                  {errors.description && (
                    <Text
                      variant="label-regular-14"
                      lineHeight="lg"
                      color="context.danger.default"
                      mt={12}
                    >
                      {errors.description}
                    </Text>
                  )}
                </Box>
                <FileUploader />
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap="lg">
              <Text
                variant="title-bold-20"
                fontWeight={700}
                color="neutral.black"
                mb={0}
              >
                {tBase('more_configurations_label')}
              </Text>

              <S.BoxConfigurationWrapper>
                <S.AttendanceWrapper>
                  <Box display="flex" flexDirection="column" gap="xs">
                    <Text
                      variant="label-regular-14"
                      color="neutral.gray1"
                      mb={0}
                    >
                      {tBase('confirm_presence_label')}
                    </Text>

                    <Text
                      variant="label-regular-14"
                      color="neutral.gray1"
                      mb={0}
                      lineHeight="lg"
                    >
                      {tBase('confirm_presence_details')}
                    </Text>
                  </Box>

                  <Box display="flex">
                    <ToggleSwitch
                      id="confirm-until-toggle"
                      on={confirmUntil}
                      handleChange={() =>
                        setFieldValue('hasConfirmUntil', !hasConfirmUntil)
                      }
                    />
                  </Box>
                </S.AttendanceWrapper>

                {hasConfirmUntil && (
                  <Box display="flex" mt="xs2" paddingX="lg">
                    <SingleDate
                      id="confirm-until-date-picker"
                      value={!!confirmUntil && moment(confirmUntil)}
                      handleChange={(date) =>
                        setFieldValue('confirmUntil', date)
                      }
                      label={tBase('confirm_presence_date_label')}
                      errorText={errors.confirmUntil as string}
                    />
                  </Box>
                )}

                <Box
                  display="flex"
                  mt="lg"
                  padding="lg"
                  borderTopColor={colors.neutral.gray4}
                  borderTopStyle="solid"
                  borderTopWidth={1}
                  gap="sm"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="column" gap="sm">
                    <Text
                      variant="label-regular-14"
                      color="neutral.gray1"
                      mb={0}
                    >
                      {tBase('attendance_label')}
                    </Text>
                    <Text
                      variant="label-regular-14"
                      color="neutral.gray1"
                      mb={0}
                    >
                      {tBase('attendance_details')}
                    </Text>
                  </Box>
                  <Box display="flex">
                    <ToggleSwitch
                      id="attendance-toggle"
                      on={attendance}
                      handleChange={() =>
                        setFieldValue('attendance', !attendance)
                      }
                    />
                  </Box>
                </Box>
              </S.BoxConfigurationWrapper>

              <S.BoxConfigurationWrapper>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="sm"
                  padding="lg"
                >
                  <Box display="flex" flexDirection="column" gap="sm">
                    <Text
                      variant="label-regular-14"
                      color="neutral.gray1"
                      mb={0}
                    >
                      {tBase('conference_link_label')}
                    </Text>

                    <Text
                      variant="label-regular-14"
                      color="neutral.gray1"
                      lineHeight="lg"
                      mb={0}
                    >
                      {tBase('conference_link_details')}
                    </Text>
                  </Box>
                  <Box display="flex">
                    <ToggleSwitch
                      id="conference-link-toggle"
                      on={conferenceLink}
                      handleChange={() =>
                        setFieldValue('conferenceLink', !conferenceLink)
                      }
                    />
                  </Box>
                </Box>
              </S.BoxConfigurationWrapper>

              <Text
                variant="label-regular-14"
                color="neutral.gray1"
                mb={0}
                mt="xs2"
              >
                {tBase('required_field_label')}
              </Text>
            </Box>
          </Box>
        </Box>
        {footer}
      </Box>
    </FormContainer>
  );
};

export default Informations;
