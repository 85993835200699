import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import {
  ContextParams,
  Props,
  RecipientSelection,
  RecipientType,
} from './types';
import {
  ChatForm,
  ResponsibleRecipient,
  StudentRecipient,
} from 'store/messages/omniChannel/types';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { useDispatch } from 'react-redux';
import { UploadFilesContext } from 'core/contexts/UploadFiles';
import { useTranslation } from 'react-i18next';

const initialValuesInfo: ChatForm['form'] = {
  message: '',
};

export const ChatContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

const ChatProvider: React.FC<ContextParams> = ({ children }) => {
  const { t } = useTranslation(['messages']);

  const dispatch = useDispatch();

  const { setCreateNewChatRequest } = omniChannelActions;

  const [currentStep, setCurrentStep] = useState(1);
  const [recipients, setRecipients] = useState<RecipientType>({
    classroomTab: {},
    studentTab: {},
    responsibleTab: {},
    familyTab: {},
  });

  const { selectedFiles } = useContext(UploadFilesContext);

  const handleAddStudent = useCallback((student: StudentRecipient) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => {
        const currentClassroom = prev.studentTab[classroomId];
        return {
          ...prev,
          studentTab: {
            ...prev.studentTab,
            [classroomId]: {
              actions: {
                ...currentClassroom?.actions,
                [student.id]: {
                  removeRecipient: () => handleRemoveStudent(student),
                },
              },
              data: {
                ...currentClassroom?.data,
                [student.id]: student,
              },
              students: {
                ...currentClassroom?.students,
                [student.id]: true,
              },
            },
          },
        };
      });
    });
  }, []);

  const handleRemoveStudent = (student: StudentRecipient) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => {
        const { [student.id]: _, ...rest } =
          prev.studentTab[classroomId]?.students;

        return {
          ...prev,
          studentTab: {
            ...prev.studentTab,
            [classroomId]: {
              ...prev.studentTab[classroomId],
              students: rest,
            },
          },
        };
      });
    });
  };

  const handleToggleResponsibleSelection = useCallback(
    (
      student: StudentRecipient,
      responsible: ResponsibleRecipient,
      actionSelection?: boolean
    ) => {
      student.classrooms.map((classroomId: string) => {
        setRecipients((prev) => {
          const responsibleTabSelection =
            prev.responsibleTab[`${classroomId}-${student.id}`];

          return {
            ...prev,
            responsibleTab: {
              ...prev.responsibleTab,
              [`${classroomId}-${student.id}`]: {
                actions: {
                  ...responsibleTabSelection?.actions,
                  [responsible.id]: {
                    removeRecipient: () =>
                      handleToggleResponsibleSelection(
                        student,
                        responsible,
                        false
                      ),
                  },
                },
                data: {
                  student,
                  responsibles: {
                    ...responsibleTabSelection?.data?.responsibles,
                    [responsible.id]: responsible,
                  },
                },
                responsibles: {
                  ...responsibleTabSelection?.responsibles,
                  [responsible.id]:
                    actionSelection ??
                    !responsibleTabSelection?.responsibles?.[responsible.id],
                },
              },
            },
          };
        });
      });
    },
    []
  );

  const handleAddRecipient = (
    student: StudentRecipient,
    classroomId: string
  ) => {
    setRecipients((prev) => {
      const currentClassroom = prev.classroomTab[classroomId];
      const updatedRecipients: RecipientType = {
        ...prev,
        classroomTab: {
          ...prev.classroomTab,
          [classroomId]: {
            ...currentClassroom,
            actions: {
              ...currentClassroom?.actions,
              [student.id]: {
                removeRecipient: () =>
                  handleRemoveRecipient(student, classroomId),
              },
            },
            data: {
              ...currentClassroom?.data,
              [student.id]: student,
            },
            students: {
              ...currentClassroom?.students,
              [student.id]: true,
            },
          },
        },
      };

      return updatedRecipients;
    });
  };

  const handleRemoveRecipient = (
    student: StudentRecipient,
    classroomId: string
  ) => {
    setRecipients((prev) => {
      const { [student.id]: _, ...rest } =
        prev.classroomTab[classroomId]?.students;

      return {
        ...prev,
        classroomTab: {
          ...prev.classroomTab,
          [classroomId]: {
            ...prev.classroomTab[classroomId],
            students: rest,
          },
        },
      };
    });
  };

  const handleAddAllRecipients = (
    students: StudentRecipient[],
    classroomId: string
  ) => {
    students.map((student) => {
      setRecipients((prev) => {
        const currentClassroom = prev.classroomTab[classroomId];

        const updatedRecipients = {
          ...prev,
          classroomTab: {
            ...prev.classroomTab,
            [classroomId]: {
              ...currentClassroom,
              actions: {
                ...currentClassroom?.actions,
                [student.id]: {
                  removeRecipient: () =>
                    handleRemoveRecipient(student, classroomId),
                },
              },
              data: {
                ...currentClassroom?.data,
                [student.id]: student,
              },
              students: {
                ...currentClassroom?.students,
                [student.id]: true,
              },
            },
          },
        };

        return updatedRecipients;
      });
    });
  };

  const handleRemoveAllRecipients = (
    students: StudentRecipient[],
    classroomId: string
  ) => {
    students.map((student) => {
      setRecipients((prev) => {
        const { [student.id]: _, ...rest } =
          prev.classroomTab[classroomId]?.students;
        return {
          ...prev,
          classroomTab: {
            ...prev.classroomTab,
            [classroomId]: {
              ...prev.classroomTab[classroomId],
              students: rest,
              classroomSendTo: {
                student: false,
                responsible: false,
                family: false,
              },
            },
          },
        };
      });
    });
  };

  const handleClassroomSendTo = (
    classroomId: string,
    sendTo: 'student' | 'responsible' | 'family'
  ) => {
    setRecipients((prev) => {
      const updatedRecipients = {
        ...prev,
        classroomTab: {
          ...prev.classroomTab,
          [classroomId]: {
            ...prev?.classroomTab[classroomId],
            classroomSendTo: {
              ...prev?.classroomTab[classroomId]?.classroomSendTo,
              [sendTo]:
                !prev?.classroomTab[classroomId]?.classroomSendTo?.[sendTo],
            },
          },
        },
      };

      return updatedRecipients;
    });
  };

  const handleAddFamily = useCallback((student: StudentRecipient) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => ({
        ...prev,
        familyTab: {
          ...prev.familyTab,
          [classroomId]: {
            actions: {
              [student.id]: {
                removeRecipient: () => handleRemoveFamily(student),
              },
            },
            data: {
              ...prev.familyTab[classroomId]?.data,
              [student.id]: student,
            },
            students: {
              ...prev.familyTab[classroomId]?.students,
              [student.id]: true,
            },
          },
        },
      }));
    });
  }, []);

  const handleRemoveAllFamily = (students: StudentRecipient[]) => {
    students.map((student) => handleRemoveFamily(student));
  };

  const handleAddAllFamily = (students: StudentRecipient[]) => {
    students.map((student) => handleAddFamily(student));
  };

  const handleRemoveFamily = (student: StudentRecipient) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => {
        const { [student.id]: _, ...rest } =
          prev.familyTab[classroomId]?.students;

        return {
          ...prev,
          familyTab: {
            ...prev.familyTab,
            [classroomId]: {
              ...prev.familyTab[classroomId],
              students: rest,
            },
          },
        };
      });
    });
  };

  const handleRemoveAll = () => {
    setRecipients((prev) => ({
      ...prev,
      classroomTab: {},
      studentTab: {},
      responsibleTab: {},
      familyTab: {},
    }));
  };

  const getSelectedRecipients = useMemo(() => {
    const studentTab = Object.values(recipients.studentTab).reduce(
      (prevSelections: RecipientSelection[], classroomSelection) => {
        const { data, students, actions } = classroomSelection;

        const selectedStudents = Object.keys(students).filter(
          (studentId) => students[studentId]
        );

        selectedStudents.forEach((studentId) => {
          const studentData = data[studentId];
          const studentsAlreadySelected = prevSelections.find(
            (prevSelection) => prevSelection.student.id === studentId
          );

          if (!studentsAlreadySelected)
            prevSelections.push({
              tab: 'student',
              student: studentData,
              removeTag: actions[studentId].removeRecipient,
            });
        });

        return prevSelections;
      },
      []
    );

    const responsibleTab = Object.values(recipients.responsibleTab).reduce(
      (prevSelections: RecipientSelection[], classroomSelection) => {
        const { data, responsibles, actions } = classroomSelection;

        const selectedResponsibles = Object.keys(responsibles).filter(
          (responsibleId) => responsibles[responsibleId]
        );
        selectedResponsibles.forEach((responsibleId) => {
          const responsiblesAlreadySelected = prevSelections.find(
            (prevSelection) => prevSelection.responsible.id === responsibleId
          );

          if (!responsiblesAlreadySelected)
            prevSelections.push({
              tab: 'responsible',
              responsible: data.responsibles[responsibleId],
              student: data.student,
              removeTag: actions[responsibleId].removeRecipient,
            });
        });

        return prevSelections;
      },
      []
    );

    const familyTab = Object.values(recipients.familyTab).reduce(
      (prevSelections: RecipientSelection[], classroomSelection) => {
        const { data, students } = classroomSelection;

        const selectedStudents = Object.keys(students).filter(
          (studentId) => students[studentId]
        );
        selectedStudents.forEach((studentId) => {
          const studentData = data[studentId];

          const studentsAlreadySelected = prevSelections.find(
            (prevSelection) => prevSelection.student.id === studentId
          );

          if (!studentsAlreadySelected)
            prevSelections.push({
              tab: 'family',
              student: studentData,
              removeTag: () => handleRemoveFamily(studentData),
            });
        });

        return prevSelections;
      },
      []
    );

    const classroomTab = Object.values(recipients.classroomTab).reduce(
      (prevSelections: RecipientSelection[], classroomSelection) => {
        const { data, students, classroomSendTo, actions } = classroomSelection;

        if (classroomSendTo?.student) {
          const selectedStudents = Object.keys(students || {}).filter(
            (studentId) => students[studentId]
          );

          selectedStudents.forEach((studentId) => {
            const studentsAlreadySelected = studentTab.find(
              (prevSelection) => prevSelection.student.id === studentId
            );

            if (!studentsAlreadySelected) {
              const studentData = data[studentId];

              prevSelections.push({
                tab: 'student',
                student: studentData,
                removeTag: () => {
                  // Remove student from current classroom tab
                  actions[studentId].removeRecipient();

                  // Move all student responsibles to responsible tab
                  if (classroomSendTo.responsible) {
                    studentData.responsibles.forEach((responsibleOfStudent) => {
                      handleToggleResponsibleSelection(
                        studentData,
                        responsibleOfStudent,
                        true
                      );
                    });
                  }

                  // Move student family to family tab
                  if (classroomSendTo.family) {
                    handleAddFamily(studentData);
                  }
                },
              });
            }
          });
        }

        if (classroomSendTo?.responsible) {
          const selectedStudents = Object.keys(students || {}).filter(
            (studentId) => students[studentId]
          );
          selectedStudents.forEach((studentId) => {
            const studentData = data[studentId];

            studentData.responsibles.forEach((responsible) => {
              const responsiblesAlreadySelected = responsibleTab.find(
                (prevSelection) =>
                  prevSelection.responsible.id === responsible.id
              );

              if (!responsiblesAlreadySelected)
                prevSelections.push({
                  tab: 'responsible',
                  student: studentData,
                  responsible,
                  removeTag: () => {
                    // Remove student from current classroom tab
                    actions[studentId].removeRecipient();

                    // Move all student responsibles to responsible tab, except the one that was removed
                    studentData.responsibles.forEach((responsibleOfStudent) => {
                      if (responsibleOfStudent.id !== responsible.id) {
                        handleToggleResponsibleSelection(
                          studentData,
                          responsibleOfStudent,
                          true
                        );
                      }
                    });

                    // Add student from student tab
                    if (classroomSendTo.student) {
                      handleAddStudent(data[studentId]);
                    }

                    // Move student family to family tab
                    if (classroomSendTo.family) {
                      handleAddFamily(studentData);
                    }
                  },
                });
            });
          });
        }

        if (classroomSendTo?.family) {
          const selectedStudents = Object.keys(students || {}).filter(
            (studentId) => students[studentId]
          );

          selectedStudents.forEach((studentId) => {
            const studentsAlreadySelected = familyTab.find(
              (prevSelection) => prevSelection.student.id === studentId
            );

            if (!studentsAlreadySelected) {
              const studentData = data[studentId];

              prevSelections.push({
                tab: 'family',
                student: studentData,
                removeTag: () => {
                  // Remove student from current classroom tab
                  actions[studentId].removeRecipient();

                  // Move all student responsibles to responsible tab
                  if (classroomSendTo.responsible) {
                    studentData.responsibles.forEach((responsibleOfStudent) => {
                      handleToggleResponsibleSelection(
                        studentData,
                        responsibleOfStudent,
                        true
                      );
                    });
                  }

                  // Add student from student tab
                  if (classroomSendTo.student) {
                    handleAddStudent(studentData);
                  }
                },
              });
            }
          });
        }

        return prevSelections;
      },
      []
    );

    return [...classroomTab, ...studentTab, ...responsibleTab, ...familyTab];
  }, [
    handleAddFamily,
    handleAddStudent,
    handleToggleResponsibleSelection,
    recipients.classroomTab,
    recipients.familyTab,
    recipients.responsibleTab,
    recipients.studentTab,
  ]);

  const onSubmit = (values: ChatForm['form']) => {
    dispatch(
      setCreateNewChatRequest({
        ...values,
        fileSignedId: selectedFiles?.[0]?.signedId,
        recipients: getSelectedRecipients,
      })
    );
    toast.info(t(`omni_channel.request_toasts.info_new_chat_message`));
  };

  const form = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: onSubmit,
    validateOnChange: true,
    isInitialValid: false,
  });

  const isInfoStepValid = getSelectedRecipients.length > 0;

  return (
    <ChatContext.Provider
      value={{
        form,
        currentStep,
        setCurrentStep,
        recipients,
        isInfoStepValid,
        handleAddRecipient,
        handleAddStudent,
        handleRemoveRecipient,
        handleAddAllRecipients,
        handleRemoveAllRecipients,
        handleClassroomSendTo,
        handleRemoveStudent,
        handleToggleResponsibleSelection,
        handleAddFamily,
        handleRemoveFamily,
        handleRemoveAllFamily,
        handleAddAllFamily,
        handleRemoveAll,
        getSelectedRecipients,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
