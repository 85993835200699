import React from 'react';

import useResizableSidebar from 'core/hooks/useResizableSidebar';
import withAppContext from 'core/hoc/withAppContext';
import { useSelector } from 'react-redux';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import ChannelHeader from 'components/Messages/OmniChannel/Channel/ChannelHeader';
import ChannelList from 'components/Messages/OmniChannel/Channel/ChannelList';

import { DeleteChannelModal } from 'components/Messages/OmniChannel/Modals';
import NewChannelModal from '../../Modals/NewChannelModal';

import * as S from './styles';

const ChannelWrapper = (): JSX.Element => {
  const { sidebarRef, startResizing } = useResizableSidebar();

  const { channels, activeChannel } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  return (
    <S.Wrapper activeChannel={!!activeChannel}>
      <S.ChannelWrapper activeChannel={!!activeChannel}>
        <S.ChannelResizeWrapper id="resize-wrapper" ref={sidebarRef}>
          <ChannelHeader />
          <ChannelList />
        </S.ChannelResizeWrapper>
        {channels && (
          <S.ResizeWindow id="resize-window" onMouseDown={startResizing} />
        )}
      </S.ChannelWrapper>
      <DeleteChannelModal />
      <NewChannelModal />
    </S.Wrapper>
  );
};

export default withAppContext(ChannelWrapper);
