import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { colors, space, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 30vh;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray3};
    }
  `}
`;

export const RecipientSelecteds = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 64px;
    padding: ${space.xs} ${space.sm};
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-bottom: none;
    flex-wrap: wrap;

    &:only-child {
      border-radius: ${border.radius.md};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }

    &:first-child {
      border-top-left-radius: ${border.radius.md};
      border-top-right-radius: ${border.radius.md};
    }

    &:last-child {
      border-bottom-left-radius: ${border.radius.md};
      border-bottom-right-radius: ${border.radius.md};
      border-bottom: ${border.width.sm} ${border.style.solid}
        ${colors.neutral.gray4};
    }
  `}
`;

export const RemoveButton = styled.button`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${space.lg};
    width: ${space.lg};
    border: none;
    border-radius: ${border.radius.sm};
    background-color: transparent;

    transition: background-color 0.5s;

    &:hover {
      background-color: ${colors.neutral.gray5};
    }
  `}
`;
