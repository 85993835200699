import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Text,
  Modal,
  Icon,
  theme,
  DefaultThemeProps,
} from '@agendaedu/ae-web-components';

import ButtonRow from 'components/ButtonRow';
import Loader from 'components/Loader';

import { trackEvent } from 'config/amplitude';

import { LinkModalProps } from './types';
import * as S from './styles';

const LinkModal = ({
  showModal = false,
  toggleModal,
  isLoading = false,
  order,
  responsibles = [],
  method = 'billet',
}: LinkModalProps) => {
  const { t } = useTranslation(['payments']);
  const defaultTheme: DefaultThemeProps = theme;

  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);

  const toggleLinkModal = useCallback(() => {
    setCopySuccess(false);
    toggleModal();
  }, [toggleModal]);

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');

    e.target.focus();
    setCopySuccess(true);
  };

  const renderGeneratedFor = () => {
    const {
      attributes: {
        last_payment: lastPayment,
        responsible_name: responsibleName,
      },
    } = order;

    if (responsibleName) return responsibleName;

    const responsible = responsibles.find(
      (responsible) =>
        responsible.id === lastPayment.data.attributes.responsible_profile_id
    );

    if (!responsible) return;

    return responsible.attributes.name;
  };

  const getCheckoutValue = () => {
    const {
      attributes: {
        status,
        pix_qrcode: pixQrCode,
        billet_url,
        generated_link: generatedLink,
      },
    } = order;

    if (generatedLink) return generatedLink;
    if (status === 'generated_pix') return pixQrCode;
    return billet_url;
  };

  useEffect(() => {
    if (method === 'link') {
      trackEvent('generated-link');
    }
  }, [method]);

  return (
    <div>
      {showModal && (
        <Modal
          isOpen={showModal}
          title={
            <Text variant="title-bold-20" fontWeight={700} color="black" mb={0}>
              {t(`modals.link.${method}.title`)}
            </Text>
          }
          onClose={toggleLinkModal}
        >
          {isLoading ? (
            <S.LoaderWrapper>
              <Loader />
            </S.LoaderWrapper>
          ) : (
            <S.ModalWrapper>
              <Text
                variant="body-regular-16"
                textAlign="left"
                lineHeight="lg"
                mt={16}
                mb={0}
              >
                {t(`modals.link.${method}.subtitle`)}
              </Text>

              {order !== undefined && (
                <>
                  <S.ResponsibleWrapper>
                    <Text
                      variant="body-regular-16"
                      fontWeight={700}
                      my={0}
                      mr={8}
                    >
                      {t(`modals.link.responsible`)}:
                    </Text>
                    <Text variant="body-regular-16" my={0}>
                      {renderGeneratedFor()}
                    </Text>
                  </S.ResponsibleWrapper>
                  <S.LinkWrapper copySuccess={copySuccess}>
                    <S.Link
                      ref={textAreaRef}
                      value={getCheckoutValue()}
                      readOnly
                    />
                    {document.queryCommandSupported('copy') && copySuccess && (
                      <Icon
                        data-testid="icon-check"
                        name="check"
                        size="md"
                        color={defaultTheme.colors.context.success.default}
                      />
                    )}
                  </S.LinkWrapper>
                  <S.ButtonsWrapper>
                    <ButtonRow
                      buttons={[
                        {
                          text: `${t(`modals.link.button_back`)}`,
                          variation: 'secondary',
                          onClick: toggleLinkModal,
                        },
                        {
                          text: `${t(`modals.link.button_copy`)}`,
                          onClick: copyToClipboard,
                        },
                      ]}
                    />
                  </S.ButtonsWrapper>
                </>
              )}
            </S.ModalWrapper>
          )}
        </Modal>
      )}
    </div>
  );
};

export default LinkModal;
