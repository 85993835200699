import styled, { css } from 'styled-components';
import { StyleProps } from '../Section/types';

export const Wrapper = styled.div<StyleProps>`
  ${({ theme: { colors, space, border }, isSelected, paddingLeft }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${space.sm};
    padding: ${space.xs} ${space.sm} ${space.xs} ${space.sm};
    padding-left: ${paddingLeft && paddingLeft};
    justify-content: space-between;
    border-bottom: ${border.style.solid} ${border.width.sm}
      ${colors.neutral.gray4};
    width: 100%;
    border-top: 0px;
    background-color: ${isSelected
      ? colors.brand.primary.op10
      : 'transparent'} !important;

    label {
      margin-top: 0 !important;
    }
  `}
`;
