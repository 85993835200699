import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAppContext from 'core/hoc/withAppContext';
import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import ClassroomsStep from 'components/Messages/OmniChannel/Forms/MessageChannel/ClassroomsStep';
import GeneralInformationStep from 'components/Messages/OmniChannel/Forms/MessageChannel/GeneralInformationStep';
import Toast from 'components/Toast';
import SchoolProfileStep from 'components/Messages/OmniChannel/Forms/MessageChannel/SchoolProfileStep';
import { GeneralInformationStepSkeleton } from 'components/Messages/OmniChannel/Forms/MessageChannel/GeneralInformationStep/skeleton';
import FormFullScreen from 'components/FormFullScreen';

import { EditMessageChannelProps } from './types';

const WIZARD_STEPS = [
  GeneralInformationStep,
  ClassroomsStep,
  SchoolProfileStep,
];

const EditMessageChannel = ({
  match,
  appContext: { dataArea },
}: EditMessageChannelProps): JSX.Element => {
  const { channelId } = match?.params;

  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const {
    fetchInitialChannelFormRequest,
    setEditChannelRequest,
    fetchChannelRequest,
    setCleanChannelForm,
  } = omniChannelActions;

  const {
    avatarIconsList,
    isLoading,
    isSubmittingChannelForm,
    classrooms,
    channel,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const hasChannelFormData =
    classrooms && avatarIconsList && !isLoading && channel;

  const handleEditChannel = useCallback(
    ({ form }) => {
      const params = {
        form,
        channelId,
      };
      dispatch(setEditChannelRequest(params));
    },
    [dispatch, setEditChannelRequest, channelId]
  );

  const fetchEditChannel = useCallback(() => {
    dispatch(fetchChannelRequest(channelId));
  }, [fetchChannelRequest, channelId, dispatch]);

  const fetchInitialChannelForm = useCallback(() => {
    dispatch(fetchInitialChannelFormRequest());
  }, [fetchInitialChannelFormRequest, dispatch]);

  useEffect(() => {
    fetchInitialChannelForm();
    fetchEditChannel();
  }, [fetchInitialChannelForm, fetchEditChannel]);

  useEffect(() => {
    return () => {
      dispatch(setCleanChannelForm());
    };
  }, []);

  const getEditForm = () => {
    return {
      name: channel.attributes.name,
      description: channel.attributes.description,
      kind: channel.attributes.kind,
      icon: channel.attributes.icon,
      classroom_ids: channel.attributes.classroom_ids,
      school_user_ids: channel.attributes.school_user_ids,
      attending_hours: channel.attributes.attendingHours,
    };
  };

  return (
    <S.MessageChannelFormWrapper>
      <FormFullScreen
        action="edit"
        id="EditMessageChannel"
        form={channel && getEditForm()}
        initialAttributes={{}}
        formMeta={{
          nextStepDisabled: true,
        }}
        backTo={`/${dataArea}/messages`}
        backToExternal
        isSubmitting={isSubmittingChannelForm}
        steps={
          hasChannelFormData ? WIZARD_STEPS : [GeneralInformationStepSkeleton]
        }
        onSubmit={handleEditChannel}
        titlePage={t('omni_channel.forms.message_channel.edit.title')}
        titleModal={t('omni_channel.forms.message_channel.create.cancel_title')}
        contentModal={t(
          'omni_channel.forms.message_channel.create.cancel_description'
        )}
      />
      <Toast />
    </S.MessageChannelFormWrapper>
  );
};

export default withAppContext(EditMessageChannel);
