import { Box, Card, Text } from '@agendaedu/ae-web-components';
import EmptyState from 'components/EmptyState';
import { EMPTY_STATE_LIST_URL } from 'core/constants/calendar/event';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const EmptyStatePage = (): React.ReactElement => {
  const { t } = useTranslation(['calendar']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`list.card.${key}`, params),
    [t]
  );

  return (
    <Card>
      <Box
        data-testid="empty-state"
        display="flex"
        flexDirection="column"
        gap="sm"
        paddingY="xs"
      >
        <Text variant="subtitle-medium-14" color="neutral.black" mb={0}>
          {tBase('empty_state_title_text')}
        </Text>

        <Text variant="subtitle-medium-12" mb={0}>
          {tBase('empty_state_description_text')}
        </Text>
      </Box>
    </Card>
  );
};

const EmptyStateFilter = (): React.ReactElement => {
  const { t } = useTranslation(['calendar']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`list.${key}`, params),
    [t]
  );

  return (
    <EmptyState
      imgUrl={EMPTY_STATE_LIST_URL}
      imgWidth="316px"
      message={tBase('empty_state_text')}
    />
  );
};

export { EmptyStateFilter, EmptyStatePage };
