import styled, { css } from 'styled-components';
import Button from 'components/Button';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    margin: 0 0 32px;
    border-top: 1px solid ${theme.gray5};
    border-bottom: 1px solid ${theme.gray5};
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoArea = styled.div`
  margin: 5px 0 5px 10px;
  font-family: Quicksand;
  font-size: 12px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    display: inline-block;
    width: 270px;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0px;
    font-family: 'Quicksand';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.gray2};
  `}
`;

export const Date = styled.p`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.gray1};
    margin: 0;

    &::first-letter {
      text-transform: uppercase;
    }
  `}
`;

export const HistoryButton = styled(Button)`
  ${() => css`
    display: flex;
    align-items: center;
    margin-left: auto;

    color: #733dd3;
    background-color: #ffffff;
    border-color: transparent;
    border-radius: 6px;

    .AgendaIcon {
      margin-right: 8px;
      font-size: 19px;
    }

    &:hover {
      color: #733dd3;
      border-color: transparent;
      background-color: rgba(104, 55, 172, 0.1);
    }
  `}
`;

export const ButtonTitle = styled.span`
  font-family: Quicksand;
  font-size: 14px;
  font-weight: bold;

  display: none;

  @media screen and (min-width: 992px) {
    display: flex;
  }
`;

export const AvatarWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    min-width: 173px;
    padding: 10px;

    padding: 16px 0;
    border-bottom: 1px solid #eeeeee;

    .head-avatar {
      padding-right: 10px;
      flex-direction: column;
      align-items: center;
    }

    .head-info {
      display: flex;
      flex-direction: column;

      p {
        display: inline-block;
        width: 270px;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 0px;
        font-family: 'Quicksand';
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: left;
        color: ${theme.black};
      }

      span {
        margin-top: 4px;
        font-family: 'Quicksand';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: ${theme.gray2};
      }
    }
  `}
`;
