import { Event } from '../types/models';
import { EventListRequest, EventRequest } from '../types/request';

export const normalizeFetchEventDetails = (
  data: EventRequest['data'],
  included: EventRequest['included']
): Event => {
  return {
    ...data,
    relationships: {
      versions: {
        data: included
          .filter((item) => item.type === 'calendarEventVersion')
          .map((item) => {
            return {
              id: item.id,
              type: item.type,
              attributes: {
                created_at: item.attributes.createdAt,
                event: item.attributes.event,
                school_user: {
                  data: {
                    id: item.id,
                    type: item.type,
                    attributes: {
                      name_initials: item.attributes.nameInitials,
                      avatar_color: item.attributes.avatarColor,
                      name: item.attributes.name,
                      avatar_url: item.attributes.avatarUrl,
                    },
                  },
                },
              },
            };
          }),
      },
      studentProfiles: {
        data: included.filter((item) => item.type === 'studentProfile'),
      },
      classrooms: {
        data: included
          .filter((item) => item.type === 'classroom')
          .map((item) => {
            return {
              attributes: item.attributes,
              id: item.id,
              type: item.type,
            };
          }),
      },
    },
  };
};

export const normalizeFetchEvents = (
  data: EventListRequest['data'][],
  included: EventListRequest['included']
): Event[] => {
  const newData = [];

  data.map((current) => {
    newData.push({
      ...current,
      relationships: {
        studentProfiles: {
          data: included.filter(
            (item) =>
              item.type === 'studentProfile' &&
              current.relationships.studentProfiles.data.some(
                (c) => c.id === item.id
              )
          ),
        },
        classrooms: {
          data: included
            .filter(
              (item) =>
                item.type === 'classroom' &&
                current.relationships.classrooms.data.some(
                  (c) => c.id === item.id
                )
            )
            .map((item) => {
              return {
                attributes: item.attributes,
                id: item.id,
                type: item.type,
              };
            }),
        },
      },
    });
  });

  return newData;
};
