import axios from 'axios';

export default class DirectUpload {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  async upload(file, handleUploadProgress) {
    const url = `/${this.dataArea}/direct_uploads.json`;
    const formData = new FormData();
    let config = {};

    if (handleUploadProgress) {
      config = {
        onUploadProgress: (event) => handleUploadProgress(event),
        cancelToken: file.cancelSource.token,
      };
    }

    formData.append('blob[io]', file, file.name);
    formData.append('blob[filename]', file.name);

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.post(url, formData, config);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async googleDriveUpload(oAuthToken, file) {
    const url = `/${this.dataArea}/direct_uploads/create_from_google_drive.json`;

    const { id: file_id, name: filename, mimeType: content_type } = file;

    const params = {
      oauth_token: oAuthToken,
      blob: {
        file_id,
        filename,
        content_type,
      },
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.post(url, params);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async destroyFile(signedId) {
    const url = `/${this.dataArea}/direct_uploads/${signedId}.json`;

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.delete(url);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async destroyFileBatch(signedIds) {
    const url = `/${this.dataArea}/direct_uploads/destroy_batch.json`;

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.patch(url, { signed_ids: signedIds });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async destroyAttachment(id) {
    const url = `/${this.dataArea}/attachments/${id}.json`;

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.delete(url);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
