import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { fetchApi, patchApi, postApi, handleError } from 'core/utils/api';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import actions from './actions';
import recurrentPlanActions from '../recurrentPlans/actions';
import { CHECKOUT_METHODS } from 'core/constants';

export function* fetchRecurrentBills(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.id}/recurrent_bills.json`
    );

    yield put({
      type: actions.FETCH_SUCCESS,
      data,
      visualizations: included,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchRecurrentBill(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/recurrent_bills/${action.billId}/edit.json`
    );

    yield put({
      type: actions.FETCH_BILL_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* loadInformationToPaid(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/recurrent_bills/${action.billId}/order.json`
    );

    yield put({
      type: actions.LOAD_INFORMATION_TO_PAID_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* setBill(action) {
  try {
    const plan = yield select((state) => state.recurrentPlans.current);

    yield put({
      type: actions.SET_BILL_SUCCESS,
      id: action.id,
      plan,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* cancelBill(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { planId, billId, note, kind } = action.params;

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${planId}/recurrent_bills/${billId}/manual_cancel?kind=${kind}`,
      {
        message: note,
      }
    );

    if (kind === 'recurrent_plan') {
      yield put({
        type: recurrentPlanActions.CANCEL_BILL_SUCCESS,
        data,
        id: billId,
        toast: buildToast(
          'Cobrança cancelada com sucesso.',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    } else {
      yield put({
        type: actions.CANCEL_BILL_SUCCESS,
        data,
        id: billId,
        toast: buildToast(
          'Cobrança cancelada com sucesso.',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel cancelar a cobrança',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* cancelAndCreateNewBill(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const params = action.params;

    yield call(
      postApi,
      `/${dataArea}/recurrent/recurrent_bills/cancel_and_create`,
      params
    );

    yield put({
      type: actions.FETCH_REQUEST,
    });

    yield put(
      push({
        pathname: `/schools/recurrent/recurrent_plans/${params.recurrent_bill.recurrent_plan_id}`,
        tabs: 1,
      })
    );

    yield put({
      type: actions.CANCEL_AND_CREATE_NEW_BILL_SUCCESS,
      toast: buildToast(
        'Cobrança cancelada e criada uma nova com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel cancelar a cobrança e criar uma nova',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* editRecurrentBill(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.params.planId}/recurrent_bills/${action.params.id}`,
      { recurrent_bill: action.params }
    );

    yield put(
      push({
        pathname: `/schools/recurrent/recurrent_plans/${action.params.planId}/`,
        tabs: 1,
      })
    );

    yield put({
      type: actions.EDIT_BILL_SUCCESS,
      data,
      toast: buildToast(
        'Cobrança atualizada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Houve um erro na atualização.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* releaseRecurrentBill(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/recurrent_bills/${action.billId}/release.json`
    );

    yield put({
      type: actions.RELEASE_BILL_SUCCESS,
      toast: buildToast(
        'Cobrança enviada, atualize a página.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Houve um erro no envio.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* manuallyPayBill(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/recurrent_bills/${action.billId}/manually_pay`,
      { responsible_profile_id: action.responsibleId, kind: action.kind }
    );

    if (action.kind === 'bill') {
      yield put({
        type: actions.MANUALLY_PAY_SUCCESS,
        data,
        id: action.billId,
        toast: buildToast(
          'Cobrança marcada como paga com sucesso.',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    } else {
      yield put({
        type: recurrentPlanActions.MANUALLY_PAY_SUCCESS,
        data,
        id: action.billId,
        toast: buildToast(
          'Cobrança marcada como paga com sucesso.',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
      yield put({
        type: actions.TOGGLE_MANUALLY_PAID_MODAL,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel marcar a cobrança como paga',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* manuallyPayBillLD(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const {
      planId,
      billId,
      kind,
      note,
      paymentmethod,
      divergentValue,
      date,
      responsibleId,
      toggleDivergentValue,
      attachments,
    } = action.params;

    const signedId = [attachments[0]?.signed_id] || null;

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${planId}/recurrent_bills/${billId}/manual_payment`,
      {
        responsible_profile_id: responsibleId,
        observation: note,
        method_payment: paymentmethod,
        paid_amount_cents: divergentValue,
        paid_at: date,
        paid_with_different_amount: toggleDivergentValue,
        attachments: signedId,
      }
    );

    if (kind === 'bill') {
      yield put({
        type: actions.MANUALLY_PAY_LD_SUCCESS,
        data,
        id: billId,
        toast: buildToast(
          'Cobrança marcada como paga com sucesso.',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
      yield put({
        type: actions.TOGGLE_MANUAL_DEAL_MODAL,
      });
    } else {
      yield put({
        type: recurrentPlanActions.MANUALLY_PAY_LD_SUCCESS,
        data,
        id: billId,
        toast: buildToast(
          'Cobrança marcada como paga com sucesso.',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
      yield put({
        type: recurrentPlanActions.TOGGLE_MANUAL_DEAL_MODAL,
      });

      yield put({
        type: actions.TOGGLE_IS_LOADING_MANUAL_DEAL,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* negotiationBillRequest(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { planId, billId, date, note, kind } = action.params;

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${planId}/recurrent_bills/${billId}/negotiate`,
      { date, message: note }
    );

    if (kind === 'recurrent_plan') {
      yield put({
        type: recurrentPlanActions.NEGOTIATION_PLAN_SUCCESS,
        data,
        id: billId,
        toast: buildToast(
          'Cobrança negociada com sucesso',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    } else {
      yield put({
        type: actions.NEGOTIATION_BILL_SUCCESS,
        data,
        id: billId,
        toast: buildToast(
          'Cobrança negociada com sucesso',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível fazer a negociação da cobrança',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* checkout(action) {
  console.log('checkout');

  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const url =
      action.method === 'link'
        ? `/${dataArea}/edupay/orders/${action.orderId}}/link`
        : `/${dataArea}/edupay/orders/${action.orderId}/checkout`;

    const data = yield call(postApi, url, {
      edupay: {
        kind: action.kind,
        ...action.form,
      },
      event: `school_generated_${action.method}`,
    });

    if (action.kind === 'bill') {
      yield put({
        type: actions.CHECKOUT_SUCCESS,
        data,
        id: action.billId,
        method: action.method,
        toast: buildToast(
          `${
            CHECKOUT_METHODS[action.method]
          } gerado com sucesso. Atualize a página.`,
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    } else {
      yield put({
        type: recurrentPlanActions.CHECKOUT_SUCCESS,
        data,
        id: action.billId,
        method: action.method,
        toast: buildToast(
          `${CHECKOUT_METHODS[action.method]} gerado com sucesso.`,
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    }

    yield put({
      type: actions.TOGGLE_BILLET_OR_PIX_MODAL,
    });
    yield put({
      type: actions.TOGGLE_BILLET_OR_PIX_LINK_MODAL,
    });
    yield put({
      type: actions.CANCEL_LOADING,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR_CHECKOUT,
      toast: buildToast(
        `Não foi possivel gerar o ${CHECKOUT_METHODS[action.method]}`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* delayBillet(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/recurrent_bills/${action.billId}/delay?kind=bill`
    );

    if (action.kind === 'bill') {
      yield put({
        type: actions.DELAY_BILL_SUCCESS,
        data,
        id: action.billId,
        toast: buildToast(
          'Cobrança atrasada com sucesso.',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    } else {
      yield put({
        type: recurrentPlanActions.DELAY_BILL_SUCCESS,
        data,
        id: action.billId,
        toast: buildToast(
          'Cobrança atrasada com sucesso.',
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel atrasar a cobrança',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* refundBill(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/recurrent_bills/${action.billId}/refund`
    );

    yield put({
      type: actions.REFUND_BILL_SUCCESS,
      data,
      id: action.billId,
      toast: buildToast(
        'Processando reembolso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel reembolsar a cobrança',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* revertManualPayment(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/recurrent_bills/${action.billId}/revert_manual_payment`
    );

    yield put({
      type: actions.REVERT_MANUAL_PAYMENT_SUCCESS,
      data,
      id: action.billId,
      toast: buildToast(
        'Pagamento manual removido com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel remover pagamento manual.',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* updateLegacyId(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { id, currentEditlegacyList } = action.params;

    yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_bills/add_legacy_ids`,
      {
        recurrent_bills: [...currentEditlegacyList],
      }
    );

    yield put({
      type: actions.FETCH_REQUEST,
      id,
      toast: buildToast(
        'Identificadores das cobranças foram atualizados com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel atualizar os Identificadores das cobranças',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* recurrentBillSagas() {
  yield all([
    takeEvery(actions.CANCEL_BILL_REQUEST, cancelBill),
    takeEvery(
      actions.CANCEL_AND_CREATE_NEW_BILL_REQUEST,
      cancelAndCreateNewBill
    ),
    takeLatest(actions.FETCH_REQUEST, fetchRecurrentBills),
    takeLatest(actions.FETCH_BILL, fetchRecurrentBill),
    takeLatest(actions.RELEASE_BILL, releaseRecurrentBill),
    takeLatest(actions.LOAD_INFORMATION_TO_PAID, loadInformationToPaid),
    takeLatest(actions.MANUALLY_PAY, manuallyPayBill),
    takeLatest(actions.MANUALLY_PAY_LD_REQUEST, manuallyPayBillLD),
    takeLatest(actions.CHECKOUT, checkout),
    takeLatest(actions.DELAY_BILL, delayBillet),
    takeLatest(actions.REFUND_BILL, refundBill),
    takeLatest(actions.REVERT_MANUAL_PAYMENT, revertManualPayment),
    takeLatest(actions.EDIT_BILL, editRecurrentBill),
    takeLatest(actions.SET_BILL, setBill),
    takeLatest(actions.NEGOTIATION_BILL_REQUEST, negotiationBillRequest),
    takeLatest(actions.UPDATE_LEGACY_ID, updateLegacyId),
  ]);
}

export default recurrentBillSagas;
