import React, { useReducer, useRef } from 'react';

import { Box, TextField } from '@agendaedu/ae-web-components';
import { Props } from './types';

export const TextQuestion = ({
  question,
  onChange,
  value,
}: Props): React.ReactElement => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [_textValue, _setTextValue] = useReducer((_, newTextValue: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(
      () => {
        onChange({
          type: 'text',
          value: newTextValue,
          questionId: question.id,
        });
      },
      newTextValue ? 500 : 0
    );

    return newTextValue;
  }, value);

  const textValue = _textValue || value;

  return (
    <Box>
      <TextField
        label={question.title}
        onChange={(value) => _setTextValue(value.target.value)}
        value={textValue}
        multiline
        fullWidth
      />
    </Box>
  );
};
