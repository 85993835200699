import React, { useEffect, useState } from 'react';
import useWindowDimensions from 'core/hooks/useWindowDimensions';
import withAppContext from 'core/hoc/withAppContext';
import { Badge } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { TabsProps } from './types';

const Tabs = ({
  badges,
  currentTab,
  onChangeTab,
  tabs,
}: TabsProps): JSX.Element => {
  const [widthTabs, setWidthTabs] = useState<number[]>([]);

  const { screenWidth } = useWindowDimensions();

  useEffect(() => {
    const elements = document.querySelectorAll('div[id="tab"]');
    setWidthTabs(
      Array.from(elements).map(
        (element) => element.getBoundingClientRect().width
      )
    );
  }, [currentTab, screenWidth, badges]);

  return (
    <S.Wrapper>
      <S.content>
        <S.Tabs>
          {tabs.map((tab, index: number) => (
            <S.TabWrapper
              key={index}
              id="tab"
              data-testid={`tab-${index}`}
              onClick={() => onChangeTab(index)}
            >
              <S.Tab>
                <S.Title
                  currentTab={currentTab === index}
                  variant={
                    currentTab === index
                      ? 'title-bold-16'
                      : 'subtitle-medium-16'
                  }
                >
                  {tab.title}
                </S.Title>
                {badges && badges[tab.key] > 0 && (
                  <Badge value={badges[tab.key]} />
                )}
              </S.Tab>
            </S.TabWrapper>
          ))}
        </S.Tabs>
        {widthTabs.length > 0 && (
          <S.TabSlider
            data-testid={`slider-tab-${currentTab}`}
            widthTabs={widthTabs}
            index={currentTab}
          />
        )}
      </S.content>
    </S.Wrapper>
  );
};

export default withAppContext(Tabs);
