import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './styles';

import useWindowDimensions from 'core/hooks/useWindowDimensions';
import withAppContext from 'core/hoc/withAppContext';

import actionOmniChannels from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import ChannelWrapper from 'components/Messages/OmniChannel/Channel/ChannelWrapper';
import ChatWrapper from 'components/Messages/OmniChannel/Chat/ChatWrapper';
import MessagesWrapper from 'components/Messages/OmniChannel/Messages/MessagesWrapper';
import Toast from 'components/Toast';

import { OmniChannelScreenProps } from './types';

const OmniChannelScreen = ({
  match: { params },
}: OmniChannelScreenProps): JSX.Element => {
  const { screenHeight } = useWindowDimensions();

  const dispatch = useDispatch();

  const { activeChannel, channels } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const [isInitialPage, setIsInitialPage] = useState(true);

  const { fetchChannelsRequest, fetchCurrentChatRequest } = actionOmniChannels;

  const FetchChannels = useCallback(() => {
    dispatch(fetchChannelsRequest(params.channelId));
  }, [dispatch, fetchChannelsRequest, params]);

  const handleFetchCurrentChat = useCallback(() => {
    const requestParams =
      activeChannel?.kind === 'ticket'
        ? {
            channelId: params.channelId,
            ticketId: params.chatId,
          }
        : {
            channelId: params.channelId,
            chatId: params.chatId,
          };

    dispatch(fetchCurrentChatRequest(requestParams));
  }, [activeChannel, dispatch, fetchCurrentChatRequest, params]);

  useEffect(() => {
    !channels && FetchChannels();
  }, [FetchChannels, channels]);

  useEffect(() => {
    if (isInitialPage && activeChannel && params.channelId && params.chatId) {
      handleFetchCurrentChat();
      setIsInitialPage(false);
    }
  }, [activeChannel]);

  return (
    <S.Wrapper height={screenHeight}>
      <ChannelWrapper />
      {activeChannel && (
        <>
          <ChatWrapper />
          <MessagesWrapper />
        </>
      )}
      <Toast />
    </S.Wrapper>
  );
};

export default withAppContext(OmniChannelScreen);
