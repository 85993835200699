import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';

import {
  Alert,
  Box,
  Button,
  Icon,
  TagButton,
  Text,
  TextField,
  Radio,
} from '@agendaedu/ae-web-components';

import { OmniChannelState, Subject } from 'store/messages/omniChannel/types';
import { attendingHoursDays, attendingHoursDaysWeek } from 'core/constants';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { validateFormErrors } from 'core/helper/handouts';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';
import { createSlug } from 'core/utils/createSlug';
import launchDarkly from 'core/helper/launchDarkly';
import { flags } from 'core/constants/flags';

import AttendingHoursAccordion from 'components/Messages/OmniChannel/Forms/MessageChannel/AttendingHoursAccordion';
import Checkbox from 'components/Form/Checkbox';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import { SelectAvatarField } from 'components/Messages/SelectAvatarField';
import { PickerOpeningHoursModal } from 'components/Messages/OmniChannel/Modals';
import { PickerDayAndHours } from 'components/Messages/OmniChannel/Modals/PickerOpeningHoursModal/types';

import validations from './validations';

import * as S from './styles';

import { GeneralInformationStepProps } from './types';

const GeneralInformationStep = ({
  formContext: {
    action,
    form: {
      name,
      icon,
      description,
      subjects,
      kind,
      attending_hours: attendingHours,
    },
    updateAttribute,
    hasErrorOnAttribute,
    getFormValidationResult,
    changeMeta,
  },
}: GeneralInformationStepProps) => {
  const { t } = useTranslation(['messages', 'common']);

  const [subjectText, setSubjectText] = useState('');

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.forms.${key}`, params),
    [t]
  );

  const [cookies] = useCookies(['agendaedu_launch_darkly']);

  const activeFlags = cookies.agendaedu_launch_darkly?.active_flags;

  const hasSubjectField = launchDarkly.checkFlagActive(
    activeFlags,
    flags.CHANNEL_SUBJECT
  );

  const { avatarIconsList } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const isTicketChannel = kind === 'ticket';
  const isEditForm = action === 'edit';

  const hoursModalValues = {
    sunday: attendingHours?.days[0].active,
    monday: attendingHours?.days[1].active,
    tuesday: attendingHours?.days[2].active,
    wednesday: attendingHours?.days[3].active,
    thursday: attendingHours?.days[4].active,
    friday: attendingHours?.days[5].active,
    saturday: attendingHours?.days[6].active,
  };

  const handleChangeAttribute = (
    attributeName: string,
    value: string | number | boolean
  ) => updateAttribute(attributeName, value);

  const handleGetErrors = (name: string) =>
    validateFormErrors(name, getFormValidationResult, hasErrorOnAttribute);

  const handleChangeAttendantToggle = useCallback(() => {
    const newAttendingHoursDays = JSON.parse(
      JSON.stringify(attendingHoursDays)
    );
    updateAttribute(
      'attending_hours',
      !attendingHours ? newAttendingHoursDays : null
    );
  }, [attendingHours, updateAttribute]);

  const handleChangeOnlyNotifications = useCallback(() => {
    updateAttribute('attending_hours', {
      ...attendingHours,
      blockOnlyNotification: !attendingHours.blockOnlyNotification,
    });
  }, [attendingHours, updateAttribute]);

  const handleSubmitOpeningHoursModal = useCallback(
    (modalHours: PickerDayAndHours) => {
      const days = attendingHours.days;

      for (const key in days) {
        const dayWeek = attendingHoursDaysWeek[key];
        const isActive = modalHours.daysOfTheWeek[dayWeek];

        days[key].active = isActive;
        days[key].hours = isActive
          ? [`${modalHours.openingHour}`, `${modalHours.closeHour}`]
          : ['', ''];
      }

      updateAttribute('attending_hours', {
        ...attendingHours,
        days,
      });
    },
    [attendingHours, updateAttribute]
  );

  const addSubject = useCallback(() => {
    const newSubject: Partial<Subject['attributes']> = {
      slug: createSlug(subjectText),
      title: subjectText,
      active: true,
    };

    updateAttribute('subjects', [...subjects, newSubject]);

    setSubjectText('');
  }, [subjectText, subjects, updateAttribute]);

  const removeSubject = useCallback(
    (slug: string) => {
      updateAttribute(
        'subjects',
        subjects.filter((subject) => subject.slug !== slug)
      );
    },
    [subjects, updateAttribute]
  );

  const toggleActiveSubject = useCallback(
    ({ slug, active, title }: Subject['attributes']) => {
      updateAttribute(
        'subjects',
        subjects.map((subject) => ({
          ...subject,
          active: slug === subject.slug ? !subject.active : subject.active,
        }))
      );

      toast.info(
        t(
          `omni_channel.forms.message_channel.fields.subject_active_${!active}`,
          { title }
        )
      );
    },
    [subjects, t, updateAttribute]
  );

  const disableAddSubject =
    !subjectText ||
    !!subjects.find(
      (subject) =>
        subject.slug === createSlug(subjectText) ||
        subject.title.trim().toLocaleLowerCase() ===
          subjectText.trim().toLocaleLowerCase()
    );

  useEffect(() => {
    changeMeta('nextStepDisabled', false);
  }, [changeMeta]);

  return (
    <>
      <S.GeneralInformationStepWrapper>
        <S.RowWrapper>
          <Text variant="title-bold-20" fontWeight={700} color="neutral.black">
            {tBase('message_channel.steps.first')}
          </Text>

          <Box
            gap="xs2"
            display="flex"
            flexDirection="row"
            alignItems="baseline"
          >
            <SelectAvatarField
              icons={avatarIconsList}
              selectedIconName={icon}
              onChange={(icon) => handleChangeAttribute('icon', icon)}
              label={tBase('message_channel.fields.avatar_select_label_name')}
            />
            <TextField
              date-testid="field-name"
              label={tBase('message_channel.fields.title_name')}
              placeholder={tBase('message_channel.fields.title_placeholder')}
              value={name}
              onChange={({ target: { value } }) => {
                handleChangeAttribute('name', value);
              }}
              fullWidth
              error={!name.length && handleGetErrors('name').hasError}
              errorMessage={tBase(`${handleGetErrors('name').errorMessages}`)}
            />
          </Box>
          <Box display="flex" mt="xs">
            <S.TextArea
              multiline
              fullWidth
              label={tBase('message_channel.fields.description_name')}
              value={description}
              onChange={({ target: { value } }) =>
                handleChangeAttribute('description', value)
              }
              error={
                !description.length && handleGetErrors('description').hasError
              }
              errorMessage={tBase(
                `${handleGetErrors('description').errorMessages}`
              )}
            />
          </Box>

          {hasSubjectField && isTicketChannel && (
            <Box>
              <S.SubjectRow>
                <TextField
                  date-testid="field-subject"
                  label={t('omni_channel.forms.message_channel.fields.subject')}
                  placeholder={t(
                    'omni_channel.forms.message_channel.fields.subject_placeholder'
                  )}
                  value={subjectText}
                  onChange={({ target: { value } }) => {
                    setSubjectText(value);
                  }}
                  error={!!subjectText && disableAddSubject}
                  errorMessage={t(
                    'omni_channel.forms.message_channel.fields.subject_error'
                  )}
                  fullWidth
                />

                <Button
                  testId="add-subject-button"
                  variant="secondary"
                  onClick={addSubject}
                  disabled={disableAddSubject}
                >
                  {t('common:button.add')}
                </Button>
              </S.SubjectRow>

              <Text variant="label-regular-14" color="neutral.gray2" mt="xs">
                {t(
                  `omni_channel.forms.message_channel.fields.subject_${action}_text`
                )}
              </Text>

              <Box gap="sm" display="flex" flexWrap="wrap" mt="sm">
                {subjects.map((subject) => {
                  const { title, slug, active, createdAt } = subject;
                  const isOthersSubject = slug === 'others';
                  const isDisabled = action === 'edit' && !active;
                  const iconName = isDisabled ? 'check' : 'multiply';
                  const slugAlreadyExist = createdAt;

                  const onClick = () => {
                    if (isOthersSubject) return;
                    if (slugAlreadyExist) return toggleActiveSubject(subject);
                    return !isOthersSubject && removeSubject(slug);
                  };

                  return (
                    <Box key={slug} onClick={onClick} cursor="pointer">
                      <TagButton
                        name={title}
                        hasIcon={!isOthersSubject}
                        icon={iconName}
                        iconPosition="right"
                        disabled={isDisabled}
                        style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}

          {!isTicketChannel && (
            <>
              <Box display="flex" flexDirection="column" mt="sm">
                <Text variant="label-regular-14" color="neutral.gray1">
                  {tBase('message_channel.steps_subtitle.channel_type_label')}
                </Text>

                <Box display="flex" flexDirection="column" gap="sm">
                  <Radio
                    disabled={isEditForm}
                    data-testId="private_radio_button"
                    checked={kind === 'private'}
                    onChange={() => handleChangeAttribute('kind', 'private')}
                  >
                    <Text variant="label-regular-16" mb={0}>
                      {tBase(
                        'message_channel.steps_subtitle.private_channel_label'
                      )}
                    </Text>
                  </Radio>

                  <Radio
                    disabled={isEditForm}
                    data-testId="family_radio_button"
                    checked={kind === 'family'}
                    onChange={() => handleChangeAttribute('kind', 'family')}
                  >
                    <Text variant="label-regular-16" mb={0}>
                      {tBase(
                        'message_channel.steps_subtitle.family_channel_label'
                      )}
                    </Text>
                  </Radio>

                  <Radio
                    disabled={isEditForm}
                    data-testId="private_and_family_radio_button"
                    checked={kind === 'private_and_family'}
                    onChange={() =>
                      handleChangeAttribute('kind', 'private_and_family')
                    }
                  >
                    <Text variant="label-regular-16" mb={0}>
                      {tBase(
                        'message_channel.steps_subtitle.private_and_family_label'
                      )}
                    </Text>
                  </Radio>
                </Box>

                <Text variant="body-regular-12" mt="xl3">
                  {tBase('message_channel.steps_subtitle.required_field_label')}
                </Text>
              </Box>
            </>
          )}
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap="sm"
            >
              <Text
                variant="title-bold-20"
                color="neutral.black"
                fontWeight={700}
                my="sm"
              >
                {tBase('attending_hours.toggle_title_text')}
              </Text>

              <Alert variant="informative">
                <Box display="flex" alignItems="center" gap="sm">
                  <Icon name="warning" />
                  {tBase('attending_hours.alert_title_text')}
                </Box>
              </Alert>

              <S.ToggleWrapper>
                <ToggleSwitch
                  testId="toggle-attendant-time"
                  toggled={!!attendingHours}
                  onChange={handleChangeAttendantToggle}
                />
                {tBase('attending_hours.toggle_description_text')}
              </S.ToggleWrapper>
            </Box>

            {!!attendingHours && (
              <>
                <S.OnlyNotificationsWrapper>
                  <Text color="neutral.gray1" variant="body-regular-16" mt="sm">
                    {tBase('attending_hours.only_notifications_alert_title')}
                  </Text>

                  <S.OnlyNotificationsCheckWrapper>
                    <Checkbox
                      testId={`checkbox-only-notifications`}
                      checked={!!attendingHours?.blockOnlyNotification}
                      onChange={handleChangeOnlyNotifications}
                    />

                    <Text
                      mb="xs4"
                      lineHeight="lg"
                      color="neutral.gray2"
                      variant="body-regular-14"
                    >
                      {tBase(
                        'attending_hours.only_notifications_alert_description'
                      )}
                    </Text>
                  </S.OnlyNotificationsCheckWrapper>
                </S.OnlyNotificationsWrapper>

                <AttendingHoursAccordion />
              </>
            )}
          </>
        </S.RowWrapper>
      </S.GeneralInformationStepWrapper>

      <PickerOpeningHoursModal
        handleSubmit={handleSubmitOpeningHoursModal}
        initialDays={hoursModalValues}
      />
    </>
  );
};

export default tabifyForWizard({
  title: '1 Informações',
  validations,
})(withAppContext(withFormContext(GeneralInformationStep)));
