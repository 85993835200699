import React, { memo, useEffect, useState } from 'react';
import { FilePreview } from '@agendaedu/ae-web-components';

import { UploadStatus } from 'core/contexts/UploadFiles/types';

import { Props } from './types';

const FileItemComponent: React.FC<Props> = ({
  file,
  uploadFile,
  destroyFile,
}): React.ReactElement => {
  const isValidFile = !file.invalidReasons?.length;

  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    !isValidFile ? 'invalid' : file.status
  );

  const [uploadProgress, setUploadProgress] = useState(0);

  const handleUpload = () => {
    if (isValidFile && !file.signedId) {
      setUploadStatus('uploading');

      uploadFile({
        file,
        progressCallback(progressEvent: ProgressEvent) {
          setUploadProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
        onSuccess: () => setUploadStatus('success'),
        onFailed: () => setUploadStatus('failed'),
      });
    }
  };

  const handleDeleteFile = () => {
    setUploadStatus('deleting');

    destroyFile({ fileId: file.fileId, signedId: file.signedId });
  };

  useEffect(() => {
    handleUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilePreview
      file={{
        ...file,
        id: file.fileId,
        name: file.name,
        size: file.size,
        uploadStatus,
        onDelete: handleDeleteFile,
        progress: uploadProgress,
        uploadMessageError: file.invalidReasons[0],
      }}
    />
  );
};

export const FileItem = memo(
  FileItemComponent,
  (prev, next) => prev.file.status === next.file.status
);
