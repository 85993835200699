import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Event, ContextParams, Props } from './types';
import {
  informationsStepValid,
  recipientsStepValid,
  scheduleStepValid,
} from './validations';

const initialValuesInfo: Event['form'] = {
  allDay: false,
  attachments: [],
  attendance: false,
  classroomIds: [],
  classroomWithStudentProfileIds: {},
  conferenceLink: false,
  confirmUntil: null,
  description: '',
  endDate: '',
  endDatetime: '',
  hasConfirmUntil: false,
  image: null,
  location: '',
  personaType: 'both',
  reminder: '',
  sentKind: 'classrooms',
  startDate: '',
  startDatetime: '',
  title: '',
};

export const EventFormContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

const EventFormProvider: React.FC<ContextParams> = ({ children }) => {
  const { t } = useTranslation('calendar');

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`forms.event.validations.${key}`, params),
    [t]
  );

  const [currentStep, setCurrentStep] = useState<number>(1);

  const onSubmit = (values: Calendar['form']) => {};

  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const formValidationSchema = yup.object().shape({
    description: yup
      .string()
      .optional()
      .max(2000, tBase('limit_character_error', { count: 2000 })),
    location: yup
      .string()
      .max(100, tBase('limit_character_error', { count: 100 })),
    title: yup
      .string()
      .required(tBase('required_field_error'))
      .max(100, tBase('limit_character_error', { count: 100 })),
    confirmUntil: yup.string().required(tBase('required_field_error')),
    startDate: yup.date().required(tBase('required_field_error')),
    endDate: yup
      .date()
      .nullable()
      .test(
        'end-after-start',
        tBase('end_date_must_be_after_start'),
        function (value) {
          const { startDate, allDay } = this.parent;
          if (allDay) return true;
          if (startDate && value) {
            return value > startDate;
          }
          return true;
        }
      ),
    startDatetime: yup
      .string()
      .matches(/^\d{2}:\d{2}$/, tBase('invalid_format_error')),
    endDatetime: yup
      .string()
      .matches(/^\d{2}:\d{2}$/, tBase('invalid_format_error'))
      .test(
        'end-after-start',
        tBase('end_datetime_must_be_after_start'),
        function (value) {
          const { startDatetime, endDate, allDay } = this.parent;
          if (allDay) return true;
          if (startDatetime && value && !endDate) {
            const startMinutes = timeToMinutes(startDatetime);
            const endMinutes = timeToMinutes(value);
            return endMinutes > startMinutes;
          }
          return true;
        }
      ),
    reminder: yup.string().required(tBase('required_field_error')),
    sentKind: yup.string().required(),
    personaType: yup.string().required(),
    classroomIds: yup
      .array()
      .test(
        'classroomIds-validation',
        'classroomIds-min-error',
        (value, context) => {
          const { sentKind } = context.parent;

          if (sentKind === 'classrooms') {
            return value.length > 0;
          }
          return true;
        }
      ),
    classroomWithStudentProfileIds: yup
      .object()
      .test(
        'classroomWithStudentProfileIds-validation',
        'classroomWithStudentProfileIds-min-error',
        (value, context) => {
          const { sentKind } = context.parent;

          if (sentKind === 'students') {
            return value && Object.keys(value).length > 0;
          }
          return true;
        }
      ),
  });

  const form = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: onSubmit,
    validateOnChange: true,
    isInitialValid: false,
    validationSchema: formValidationSchema,
  });

  const { values, errors } = form;

  const isInformationsStepValid = useMemo(
    () => informationsStepValid(values, errors),
    [values, errors]
  );

  const isScheduleStepValid = useMemo(
    () => scheduleStepValid(values, errors),
    [values, errors]
  );

  const isRecipientsStepValid = useMemo(
    () => recipientsStepValid(values),
    [values]
  );

  return (
    <EventFormContext.Provider
      value={{
        form,
        currentStep,
        isInformationsStepValid,
        isRecipientsStepValid,
        isScheduleStepValid,
        setCurrentStep,
      }}
    >
      {children}
    </EventFormContext.Provider>
  );
};

export default EventFormProvider;
