const actions = {
  ERROR: 'ERROR/filters',
  FETCH_ACTIVE_SCHOOL_TERMS_REQUEST:
    'FETCH_ACTIVE_SCHOOL_TERMS_REQUEST/filters',
  FETCH_ACTIVE_SCHOOL_TERMS_SUCCESS:
    'FETCH_ACTIVE_SCHOOL_TERMS_SUCCESS/filters',
  FETCH_EDUCATIONAL_STAGES_REQUEST: 'FETCH_EDUCATIONAL_STAGES_REQUEST/filters',
  FETCH_EDUCATIONAL_STAGES_SUCCESS: 'FETCH_EDUCATIONAL_STAGES_SUCCESS/filters',
  FETCH_CLASSROOMS_REQUEST: 'FETCH_CLASSROOMS_REQUEST/filters',
  FETCH_CLASSROOMS_SUCCESS: 'FETCH_CLASSROOMS_SUCCESS/filters',
  FETCH_HEADQUARTERS_REQUEST: 'FETCH_HEADQUARTERS_REQUEST/filters',
  FETCH_HEADQUARTERS_SUCCESS: 'FETCH_HEADQUARTERS_SUCCESS/filters',
  fetchActiveSchoolTermsRequest: () => ({
    type: actions.FETCH_ACTIVE_SCHOOL_TERMS_REQUEST,
  }),
  fetchEducationalStagesRequest: (headquarterId) => ({
    type: actions.FETCH_EDUCATIONAL_STAGES_REQUEST,
    headquarterId,
  }),
  fetchClassRoomsRequest: (headquarterId, educationalStageId, schoolTerm) => ({
    type: actions.FETCH_CLASSROOMS_REQUEST,
    headquarterId,
    educationalStageId,
    schoolTerm,
  }),
  fetchHeadquartersRequest: () => ({
    type: actions.FETCH_HEADQUARTERS_REQUEST,
  }),
};

export default actions;
