import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Select, Text } from '@agendaedu/ae-web-components';
import { useDispatch, useSelector } from 'react-redux';
import SideSheet from 'components/SideSheet';
import Accordion from 'components/Accordion';

import * as S from './styles';

import AlbumActions from 'store/albums/actions';
import FilterActions from 'store/filters/actions';
import { ActiveTermsFilters, FilterStatesProps } from 'store/filters/types';
import { RootState } from 'store/albums/types';
import { Props } from './types';

export const AlbumsSideFilters = ({
  isOpen,
  onDismiss,
}: Props): JSX.Element => {
  const { setFilters } = AlbumActions;
  const { fetchEducationalStagesRequest, fetchClassRoomsRequest } =
    FilterActions;

  const { t } = useTranslation(['albums', 'common']);
  const dispatch = useDispatch();
  const { headquarters, educationalStages, classrooms, activeSchoolTerms } =
    useSelector((state: FilterStatesProps) => state.filters);
  const { headquarterIds, educationalStageIds, classroomIds, year } =
    useSelector((state: RootState) => state.albums.filters);

  const { albumsMeta } = useSelector((state: RootState) => state.albums);

  const availableYears =
    albumsMeta?.availableYears?.map((year) => ({ label: year, value: year })) ||
    [];

  const defaultYear = availableYears?.[0]?.value;

  const defaultSchoolTermName = activeSchoolTerms
    .find((schoolTerm: ActiveTermsFilters) => schoolTerm.isActive)
    ?.value.toString();

  const [newHeadquarterId, setNewHeadquarterId] = useState(headquarterIds[0]);
  const headquartersOptions = headquarters || [];
  const hasHeadquarter = !!headquarters?.length;

  const [newEducationalStageId, setNewEducationalStageId] = useState(
    educationalStageIds[0]
  );
  const educationalStagesOptions = educationalStages || [];
  const hasEducationalStages = !!educationalStages?.length;

  const [newClassroomId, setNewClassroomId] = useState(classroomIds[0]);
  const classroomsOptions = classrooms || [];
  const hasClassrooms = !!classrooms?.length;

  const initialYear = year || defaultYear;

  const [newYearSelected, setNewYearSelected] = useState(initialYear);

  const renderGeneralFiltersTitle = (
    <Text m={0} variant="subtitle-medium-16">
      {t('list.side_filters.general_filter_title')}
    </Text>
  );

  const onChangeHeadquarter = (headquarterId: string) => {
    if (headquarterId) {
      dispatch(fetchEducationalStagesRequest(headquarterId));
    }

    setNewHeadquarterId(headquarterId);
    setNewEducationalStageId(null);
    setNewClassroomId(null);
  };

  const onChangeEducationalStage = (educationalStageId: string) => {
    if (newHeadquarterId && educationalStageId) {
      const schoolTerm = defaultSchoolTermName;
      dispatch(
        fetchClassRoomsRequest(newHeadquarterId, educationalStageId, schoolTerm)
      );
    }

    setNewEducationalStageId(educationalStageId);
    setNewClassroomId(null);
  };

  const applySideFilters = () => {
    onDismiss();
    dispatch(
      setFilters({
        filters: {
          headquarterIds: [newHeadquarterId],
          educationalStageIds: [newEducationalStageId],
          classroomIds: [newClassroomId],
          year: newYearSelected,
        },
      })
    );
  };

  const clearFilters = () => {
    setNewHeadquarterId(null);
    setNewEducationalStageId(null);
    setNewClassroomId(null);
    setNewYearSelected(null);
    onDismiss();
    dispatch(
      setFilters({
        filters: {
          headquarterIds: [],
          educationalStageIds: [],
          classroomIds: [],
          year: '',
        },
      })
    );
  };

  const renderGeneralFiltersAccordionBody = (
    <>
      <Select
        fullWidth
        label={t('list.side_filters.headquarter_label')}
        placeholder={t('list.side_filters.headquarter_default_option')}
        options={headquartersOptions}
        disabled={!hasHeadquarter}
        value={newHeadquarterId}
        mb="md"
        onChange={(option) => onChangeHeadquarter(option.value)}
      />

      <Select
        fullWidth
        label={t('list.side_filters.educational_stage_label')}
        placeholder={t('list.side_filters.educational_stage_default_option')}
        options={educationalStagesOptions}
        disabled={!hasEducationalStages}
        value={newEducationalStageId}
        mb="md"
        onChange={(option) => onChangeEducationalStage(option.value)}
      />

      <Select
        fullWidth
        label={t('list.side_filters.classroom_label')}
        placeholder={t('list.side_filters.classroom_default_option')}
        options={classroomsOptions}
        disabled={!hasClassrooms}
        value={newClassroomId}
        mb="md"
        onChange={(option) => setNewClassroomId(option.value)}
      />
    </>
  );

  const renderSchoolTermFiltersTitle = (
    <Text m={0} variant="subtitle-medium-16">
      {t('list.side_filters.school_terms_title')}
    </Text>
  );

  const renderSchoolTermFiltersAccordionBody = (
    <Select
      fullWidth
      label={t('list.side_filters.school_terms')}
      placeholder={t('list.side_filters.school_terms_placeholder')}
      options={availableYears}
      value={newYearSelected}
      mb="md"
      onChange={(event) => setNewYearSelected(event.value)}
    />
  );

  return (
    <SideSheet
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={t('list.side_filters.title')}
    >
      <S.WrapperContent>
        <div>
          <Accordion
            header={renderGeneralFiltersTitle}
            body={renderGeneralFiltersAccordionBody}
            allowZeroExpanded
            expanded="albums"
          />
          <S.Divider />
          <Accordion
            header={renderSchoolTermFiltersTitle}
            body={renderSchoolTermFiltersAccordionBody}
            allowZeroExpanded
            expanded="albums"
          />
          <S.Divider />
        </div>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          mb="md"
          px="sm"
        >
          <Button
            id="apply-filters-button"
            testId="apply-filters-button"
            width="45%"
            onClick={applySideFilters}
          >
            {t('common:button.apply')}
          </Button>

          <Button
            testId="clear-filters-button"
            id="clear-filters-button"
            width="45%"
            variant="secondary"
            onClick={clearFilters}
          >
            {t('common:button.clear')}
          </Button>
        </Box>
      </S.WrapperContent>
    </SideSheet>
  );
};
