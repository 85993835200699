import React, { memo, useContext, useEffect, useState } from 'react';
import { Props } from './types';
import {
  Box,
  Button,
  DefaultThemeProps,
  Icon,
  Text,
  Tooltip,
} from '@agendaedu/ae-web-components';
import formatBytes from 'core/utils/formatBytes';
import * as S from './styles';
import { UploadStatus } from 'core/contexts/UploadFiles/types';
import { useTheme } from 'styled-components';

const FileItemComponent = ({
  file,
  isUploadingMedias,
  canUploadMedia,
  uploadFile,
  destroyFile,
}: Props) => {
  const isValidFile = !file.invalidReasons?.length;
  const { colors, space } = useTheme() as DefaultThemeProps;
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    !isValidFile ? 'invalid' : 'uploading'
  );
  const [coverImageStatus, setCoverImageStatus] = useState<
    'loading' | 'failed' | 'success'
  >('loading');

  const imageUrl =
    file.origin === 'local'
      ? URL.createObjectURL(file as unknown as MediaSource)
      : `https://drive.google.com/thumbnail?sz=w32&id=${file.id}`;

  const handleUpload = () => {
    if (canUploadMedia && isValidFile) {
      setUploadStatus('uploading');

      uploadFile({
        file,
        onSuccess: () => setUploadStatus('success'),
        onFailed: () => setUploadStatus('failed'),
      });
    }
  };

  const handleDelete = () => {
    setUploadStatus('deleting');

    destroyFile({ fileId: file.fileId, signedId: file.signedId });
  };

  const variantStatus = {
    uploading: {
      color: colors.neutral.gray1,
      statusComponent: <S.LoadSpinner />,
    },
    success: {
      color: colors.neutral.gray1,
      statusComponent: (
        <Icon name="check" size="sm" color={colors.context.success.default} />
      ),
    },
    failed: {
      color: colors.context.warning.default,
      statusComponent: (
        <Button
          isOnlyIcon
          icon="cycle"
          variant="secondary"
          size="sm"
          contextVariant="warning"
          onClick={handleUpload}
          disabled={isUploadingMedias}
        />
      ),
    },
    deleting: {
      color: colors.neutral.gray1,
      statusComponent: <S.LoadSpinner uploadStatus={uploadStatus} />,
    },
    invalid: {
      color: colors.context.danger.default,
      statusComponent: (
        <Tooltip
          align="top-end"
          content={file.invalidReasons?.join('\n')}
          elementRef={
            <Box display="flex" alignItems="center">
              <Icon
                name="info-circle"
                size="sm"
                color={colors.context.danger.default}
              />
            </Box>
          }
        />
      ),
    },
  }[uploadStatus];

  useEffect(() => {
    handleUpload();
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <S.CoverWrapper>
        {coverImageStatus === 'failed' ? (
          <Box height="100%" size="32px" display="flex" alignItems="center">
            <Icon name="image" color={colors.neutral.gray2} />
          </Box>
        ) : (
          <S.CoverWrapper>
            <S.CoverImage
              src={imageUrl}
              alt={file.name}
              onLoad={() => setCoverImageStatus('success')}
              onError={() => setCoverImageStatus('failed')}
              loading="lazy"
            />
          </S.CoverWrapper>
        )}
      </S.CoverWrapper>

      <Text
        flex={1}
        color={variantStatus.color}
        variant="subtitle-medium-14"
        m={0}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {file.name}
      </Text>

      <Box display="flex" alignItems="center">
        <Text
          m={0}
          mx="sm"
          color={variantStatus.color}
          variant="subtitle-medium-14"
        >
          {formatBytes(file.size)}
        </Text>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth={space.xl}
        >
          {variantStatus.statusComponent}
        </Box>

        <Button
          id="delete-file-button"
          data-testid="delete-file"
          ml="sm"
          isOnlyIcon
          icon="trash-bin"
          variant="secondary"
          size="sm"
          isNegativeAction
          disabled={uploadStatus === 'deleting' || isUploadingMedias}
          onClick={handleDelete}
        />
      </Box>
    </Box>
  );
};

export const FileItem = memo(
  FileItemComponent,
  (prev, next) =>
    prev.file.status === next.file.status &&
    prev.isUploadingMedias === next.isUploadingMedias
);
