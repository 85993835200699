import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Props } from './types';

import AlbumActions from 'store/albums/actions';

import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';

export const RecoverAlbumModal = ({
  album,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const { t } = useTranslation(['albums', 'common']);
  const dispatch = useDispatch();

  const { recoverAlbum } = AlbumActions;

  const handleRecoverAlbum = useCallback(() => {
    dispatch(recoverAlbum(album.id));
    onClose();
  }, [dispatch, recoverAlbum, onClose, album.id]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('list.album_card.recover_album_modal.title')}
      onClose={onClose}
    >
      <Box width="100%" mt="sm">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          {t('list.album_card.recover_album_modal.content')}
        </Text>

        <Box display="flex" mt="lg" justifyContent="flex-end">
          <Button
            id="cancel-album-recover-button"
            testId="back-button"
            variant="secondary"
            mr="sm"
            onClick={onClose}
          >
            {t('common:button.back')}
          </Button>

          <Button
            id="confirm-album-recover-button"
            testId="recover-button"
            onClick={handleRecoverAlbum}
          >
            {t('common:button.recover')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
