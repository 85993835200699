import React from 'react';
import { Avatar, Box, Checkbox, Tag, Text } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { MessageGroupAvatar } from 'components/Messages/OmniChannel/MessageGroupAvatar';

import { Props } from './types';

export const RecipientCard: React.FC<Props> = ({
  recipient,
  paddingLeft,
  hasRecipientTag,
  toggleCheckbox,
  isSelected,
}): JSX.Element => {
  const { avatarColor, nameInitials, avatarUrl } = recipient;

  return (
    <S.Wrapper isSelected={isSelected} paddingLeft={paddingLeft}>
      <Box display="flex" flexDirection="row" alignItems="center" gap="sm">
        <Checkbox
          checked={isSelected}
          onChange={() => toggleCheckbox(isSelected)}
        />
        <Box
          gap="xs"
          display="flex"
          flexDirection="row"
          alignItems="center"
          ml="lg"
        >
          {recipient?.channelKind === 'family' ? (
            <MessageGroupAvatar
              avatarColor={avatarColor}
              size="xs"
              nameInitials={nameInitials}
              avatarUrl={avatarUrl}
            />
          ) : (
            <Avatar
              data-testid="avatar"
              size="xs"
              avatarColor={avatarColor}
              nameInitials={nameInitials}
              avatarUrl={avatarUrl}
            />
          )}

          <Text
            mb={0}
            color={isSelected ? 'brand.primary.default' : 'neutral.black'}
            variant="body-regular-14"
          >
            {recipient.name}
          </Text>
        </Box>
      </Box>
      {hasRecipientTag && (
        <Tag
          name={recipient.role}
          size="small"
          variant={isSelected ? 'brand' : 'neutral'}
          mr="xs2"
        />
      )}
    </S.Wrapper>
  );
};
