import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Checkbox,
  DefaultThemeProps,
  Select,
  SingleDate,
  Text,
  TimePicker,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import * as S from './styles';

import { EventFormContext } from 'core/contexts/Calendar/Forms/Event';
import { getDateSchedule } from 'core/helper/calendar/event';
import {
  REMINDER_SCHEDULE_OPTIONS,
  SCHEDULE_UNITS,
} from 'core/constants/calendar/event';

import { Reminder } from '../../modals/Reminder';

const Schedule = (): React.ReactElement => {
  const { t } = useTranslation(['calendar']);

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`forms.event.steps.schedule.${key}`, params),
    [t]
  );
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [reminderDateOptions, setReminderDateOptions] = useState(
    REMINDER_SCHEDULE_OPTIONS
  );

  const {
    form: {
      errors,
      values: {
        allDay,
        endDate,
        endDatetime,
        reminder,
        startDate,
        startDatetime,
      },
      setFieldValue,
    },
    isScheduleStepValid,
    setCurrentStep,
  } = useContext(EventFormContext);

  const { breakpoints, colors } = useTheme() as DefaultThemeProps;

  const nextStep = () => setCurrentStep(3);

  const CUSTOM_OPTIONS = '5';
  const LAST_OPTION = '4';

  const reminderError = useMemo(() => {
    if (!startDate || !reminder || reminder === LAST_OPTION) return '';

    let date = moment(startDate, 'DD/MM/YYYY HH:mm').startOf('day');

    if (startDatetime) {
      date = getDateSchedule({
        datetime: startDatetime,
        date: startDate,
      });
    }

    const reminderValue = SCHEDULE_UNITS[reminder]
      ? date.subtract(1, SCHEDULE_UNITS[reminder])
      : '';

    const invalidDate = !moment(reminderValue).isAfter(moment());

    if (invalidDate)
      return t('forms.event.modals.reminder.alert_reminder_value_error_text');

    return '';
  }, [reminder, startDate, startDatetime, t]);

  const handleChangeReminderSelect = useCallback(
    (option: { label: string; value: string }) => {
      if (option.value === reminderDateOptions?.slice(-1)[0]?.value) {
        return setShowReminderModal(true);
      }
      return setFieldValue('reminder', option.value);
    },
    [reminderDateOptions, setFieldValue]
  );

  const handleChangeReminderDate = useCallback(
    (label: string, value: string) => {
      const labelTranslate =
        Number(value) !== 1
          ? tBase(`custom_options_multiple.${label}`, {
              value,
            })
          : tBase(`custom_options_unique.${label}`, {
              value,
            });

      const hasCustomOptions = reminderDateOptions.some(
        (option) => option.value === CUSTOM_OPTIONS
      );

      const updatedOptions = reminderDateOptions.map((option) =>
        option.value === LAST_OPTION
          ? { ...option, label: labelTranslate }
          : option
      );

      if (!hasCustomOptions) {
        updatedOptions.push({
          label: tBase('options_select.custom_text'),
          value: CUSTOM_OPTIONS,
        });
      }

      setReminderDateOptions(updatedOptions);
      setFieldValue('reminder', LAST_OPTION);
    },
    [reminderDateOptions, setFieldValue, tBase]
  );

  const footer = (
    <Box
      py="sm"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button
        data-testid="next-step-button"
        ml="auto"
        disabled={!isScheduleStepValid || !!reminderError}
        onClick={nextStep}
      >
        {t('common:button.next')}
      </Button>
    </Box>
  );

  return (
    <>
      <Box
        id="reminder-step"
        display="flex"
        position="relative"
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        mx={{ _: 'xs', mobileSM: 'lg', desktopLG: 'xl5' }}
        mb="10vh"
      >
        <Box width="100%" height="100%" mt="xl3" pb="md" overflow="auto">
          <Box
            display="flex"
            flexDirection="column"
            maxWidth={breakpoints.tabletMD}
            mx="auto"
          >
            <Text
              data-testid="title-label"
              variant="title-bold-20"
              fontWeight={700}
              color="neutral.black"
              mb={0}
            >
              {tBase('title')}
            </Text>
            <Box display="flex" flexDirection="column" paddingY="xl" gap="lg">
              <Box
                display="flex"
                flexDirection="row"
                gap="lg"
                data-testid="box"
              >
                <SingleDate
                  errorText={!!errors.startDate && (errors.startDate as string)}
                  fullWidth
                  handleChange={(date) => setFieldValue('startDate', date)}
                  id="start-date-field"
                  label={tBase('start_date_label')}
                  placeholder="22/04/2024"
                  value={!!startDate && moment(startDate)}
                />
                <SingleDate
                  disabled={!startDate || allDay}
                  errorText={!!errors.endDate && (errors.endDate as string)}
                  fullWidth
                  handleChange={(date) => setFieldValue('endDate', date)}
                  id="end-date-field"
                  label={tBase('end_date_label')}
                  placeholder="22/04/2024"
                  value={!!endDate && moment(endDate)}
                />
              </Box>

              <Box display="flex" flexDirection="row" gap="lg">
                <TimePicker
                  data-testid="start-datatime-field"
                  disabled={allDay}
                  error={!!errors.startDatetime}
                  errorMessage={errors.startDatetime}
                  label={tBase('start_datetime_label')}
                  onChange={(datetime: string) =>
                    setFieldValue('startDatetime', datetime)
                  }
                  placeholder="00:00"
                  value={startDatetime}
                />
                <TimePicker
                  data-testid="end-datatime-field"
                  disabled={!startDatetime || allDay}
                  error={!!errors.endDatetime}
                  errorMessage={errors.endDatetime}
                  label={tBase('end_datetime_label')}
                  onChange={(datetime: string) =>
                    setFieldValue('endDatetime', datetime)
                  }
                  placeholder="00:00"
                  value={endDatetime}
                />
              </Box>
              <S.AllDayWrapper>
                <Checkbox
                  checked={allDay}
                  data-testid="all-day-checkbox"
                  onChange={() => setFieldValue('allDay', !allDay)}
                >
                  {tBase('all_day_label')}
                </Checkbox>
              </S.AllDayWrapper>

              <Box display="flex" mt="sm">
                <Select
                  data-testid="reminder-select"
                  disabled={!startDate}
                  errorText={reminderError}
                  icon="bell"
                  label={tBase('select_reminder_label')}
                  onChange={handleChangeReminderSelect}
                  options={reminderDateOptions}
                  placeholder={tBase('select_reminder_placeholder')}
                  value={reminder}
                />
              </Box>
              <Text variant="label-regular-12">
                {tBase('required_field_text')}
              </Text>
            </Box>
          </Box>
        </Box>
        {footer}
      </Box>
      {showReminderModal && (
        <Reminder
          isOpen={showReminderModal}
          onClose={() => setShowReminderModal(false)}
          onSubmit={handleChangeReminderDate}
          startDate={startDate}
          startDatetime={startDatetime}
        />
      )}
    </>
  );
};

export default Schedule;
