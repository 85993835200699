import React, { useContext } from 'react';
import { Box, Button, DefaultThemeProps } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { EventFormContext } from 'core/contexts/Calendar/Forms/Event';

import { CalendarState } from 'store/calendar/types/store';

import * as S from './styles';

import FormContainer from 'components/Form/FormContainer';
import RecipientsTab from 'components/Form/RecipientsTab';

const Recipients = (): React.ReactElement => {
  const { t } = useTranslation(['calendar']);

  const {
    form: {
      values: {
        sentKind,
        personaType,
        classroomIds,
        classroomWithStudentProfileIds,
      },
      setFieldValue,
    },
    isRecipientsStepValid,
    setCurrentStep,
  } = useContext(EventFormContext);

  const {
    form: {
      recipients: { formMeta },
    },
  } = useSelector((state: CalendarState) => state.calendar.event);

  const { breakpoints, colors } = useTheme() as DefaultThemeProps;

  const nextStep = () => setCurrentStep(2);

  const initialAttributes = {
    sent_kind: sentKind,
    persona_type: personaType,
    classroom_ids: classroomIds,
    classroom_with_student_profile_ids: classroomWithStudentProfileIds,
  };

  const handleUpdates = (formContainerValues) => {
    const {
      sent_kind,
      persona_type,
      classroom_ids,
      classroom_with_student_profile_ids,
    } = formContainerValues;

    const shouldUpdateSentKind = !Object.is(sentKind, sent_kind);

    const shouldUpdatePersonaType = !Object.is(personaType, persona_type);

    const shouldUpdateClassroomIds = !Object.is(classroomIds, classroom_ids);

    const shouldUpdateClassroomWithStudentProfileIds = !Object.is(
      classroomWithStudentProfileIds,
      classroom_with_student_profile_ids
    );

    if (shouldUpdateSentKind) {
      setFieldValue('sentKind', sent_kind);
    }

    if (shouldUpdatePersonaType) {
      setFieldValue('personaType', persona_type);
    }

    if (shouldUpdateClassroomIds) {
      setFieldValue('classroomIds', classroom_ids);
    }

    if (shouldUpdateClassroomWithStudentProfileIds) {
      setFieldValue(
        'classroomWithStudentProfileIds',
        classroom_with_student_profile_ids
      );
    }
  };

  const footer = (
    <Box
      py="sm"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button
        data-testid="next-step-button"
        ml="auto"
        disabled={!isRecipientsStepValid}
        onClick={nextStep}
      >
        {t('common:button.save')}
      </Button>
    </Box>
  );

  return (
    <FormContainer initialAttributes={{}}>
      <Box
        id="informations-step"
        display="flex"
        position="relative"
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        mx={{ _: 'xs', mobileSM: 'lg', desktopLG: 'xl5' }}
        mb="10vh"
      >
        <Box width="100%" height="100%" mt="md" pb="md" overflow="auto">
          <Box
            display="flex"
            flexDirection="column"
            maxWidth={breakpoints.tabletMD}
            mx="auto"
          >
            <FormContainer
              onUpdateAttribute={handleUpdates}
              initialAttributes={initialAttributes}
              formMeta={{
                ...formMeta,
              }}
            >
              <S.RecipientsWrapper>
                <RecipientsTab />
              </S.RecipientsWrapper>
            </FormContainer>
          </Box>
        </Box>
        {footer}
      </Box>
    </FormContainer>
  );
};

export default Recipients;
