import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, FullScreenModal, Stepper } from '@agendaedu/ae-web-components';
import { useDispatch, useSelector } from 'react-redux';

import CalendarActions from 'store/calendar/actions';

import { EventFormContext } from 'core/contexts/Calendar/Forms/Event';
import useMobileScreen from 'core/hooks/useMobileScreen';
import withAppContext from 'core/hoc/withAppContext';

import DiscardModal from 'pages/Calendar/components/Event/Form/modals/Discard';
import Informations from 'pages/Calendar/components/Event/Form/Steps/Informations';
import Recipients from 'pages/Calendar/components/Event/Form/Steps/Recipients';
import Schedule from 'pages/Calendar/components/Event/Form/Steps/Schedule';
import Toast from 'components/Toast';

import { Props } from './types';

const NewEvent = ({ match, history }: Props): React.ReactElement => {
  const { t } = useTranslation(['calendar']);
  const dispatch = useDispatch();

  const [showDiscardModal, setShowDiscardModal] = React.useState(false);

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const { fetchClassroomsRequest } = CalendarActions;

  const {
    currentStep,
    setCurrentStep,
    isInformationsStepValid,
    isScheduleStepValid,
  } = useContext(EventFormContext);

  const eventId = match.params.id;
  const kind = eventId ? 'edit' : 'new';

  const tBase = useCallback((key: string) => t(`forms.event.${key}`), [t]);

  const isMobileScreen = useMobileScreen();

  const stepInfos = useMemo(
    () => [
      {
        testId: 'informations-step',
        step: 1,
        title: tBase('steps.informations.steper_title'),
        isActive: currentStep === 1,
      },
      {
        testId: 'schedule-step',
        title: tBase('steps.schedule.steper_title'),
        isActive: currentStep === 2,
        isDisabled: !isInformationsStepValid,
        step: 2,
      },
      {
        testId: 'recipients-step',
        title: tBase('steps.recipients.steper_title'),
        isActive: currentStep === 3,
        isDisabled: !isScheduleStepValid || !isInformationsStepValid,
        step: 3,
      },
    ],
    [currentStep, isInformationsStepValid, isScheduleStepValid, tBase]
  );

  const getHeaderContent = useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="center"
        position="absolute"
        left={0}
        right={0}
        mx="auto"
        gap="sm"
        width="fit-content"
      >
        {stepInfos.map((item, index) => (
          <Stepper
            key={index}
            isMobile={isMobileScreen}
            testId={item.testId}
            onClick={() => setCurrentStep(item.step)}
            {...item}
          />
        ))}
      </Box>
    ),
    [isMobileScreen, stepInfos]
  );

  const getStepContent = {
    1: <Informations />,
    2: <Schedule />,
    3: <Recipients />,
  }[currentStep];

  useEffect(() => {
    dispatch(fetchClassroomsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="new-event-form" data-testid="new-event-form">
      <FullScreenModal
        isOpen
        title={
          kind === 'new' ? tBase('new_form_title') : tBase('edit_form_title')
        }
        headerContent={getHeaderContent}
        onClose={() => setShowDiscardModal(true)}
      >
        <Box
          data-testid="content"
          display="flex"
          flexDirection="column"
          flex={1}
          overflow="auto"
          mb={72}
        >
          {getStepContent}
        </Box>
      </FullScreenModal>

      <Toast />

      <DiscardModal
        isOpen={showDiscardModal}
        onClose={() => setShowDiscardModal(false)}
        onSubmit={() => {
          setShowDiscardModal(false);
          history.push(`/${dataArea}/calendar_events`);
        }}
      />
    </div>
  );
};

export default withAppContext(withRouter(NewEvent));
