import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const ShowBillModal = styled.div`
  ${({ theme: { colors, border, font } }: ThemeProps) => css`
    font-family: ${font.family.auxiliary};
    .modal-title,
    .OutlineBox {
      margin-bottom: 24px;
    }

    .tag-wrapper {
      display: flex;
    }

    .wrapper-alert {
      margin-bottom: 24px;
    }

    .Alert {
      height: auto;

      p {
        font-size: ${font.size.md};
        margin: 0;
      }
    }

    .name-responsibles {
      color: ${colors.neutral.black}
      font-size: ${font.size.md};
      line-height: 28.5px;
      margin: 0;
    }

    .message-no-responsible-found {
      color: ${colors.context.danger};
      font-size: ${font.size.md};
      font-style: italic;
      margin: 0;
    }

    .message-no-responsible-found.others-responsibles {
      color: ${colors.neutral.gray1};
    }

    .pagarme-transaction-id {
      color: ${colors.context.success}
      font-size: ${font.size.md};
    }

    .Currency {
      color: ${colors.neutral.black}
      font-size: ${font.size.md};
    }

    .penality {
      font-size: ${font.size.md};
    }

    .ButtonRow {
      margin-top: 8px;
    }

    .UserDisplay {
      .OutlineBox {
        margin-bottom: 0;
      }
    }

    .unique-column {
      font-size: ${font.size.md};

      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 16px;
        margin-bottom: 0;
      }
    }

    .summary-content {
      padding: 20px 20px 0;
    }

    .titleContent {
      font-size: ${font.size.md};
      font-weight: ${font.weight.bold};
      color: ${colors.neutral.black};
      margin-bottom: 24px;
    }

    .sub-title {
      font-size: ${font.size.md}
      font-weight: ${font.weight.bold}
      color: ${colors.neutral.black};
    }

    .resume {
      p {
        font-size: ${font.size.md};
      }
    }

    .LabeledContainer {
      .information-content {
        > span {
          font-size: ${font.size.md};
        }
        .attachment-wrapper {
          display: flex;
          flex-direction: row;
          padding: 12px;
          align-items: center;
          justify-content: space-between;
          background-color: ${colors.neutral.gray6};
          border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
          border-radius: ${border.radius.sm};
          color: ${colors.neutral.gray1};

          .pdf-name {
            display: flex;
            flex-direction: row;
            align-items: center;

            .AgendaIcon {
              margin: 0;
              margin-right: 8px;
            }

            p {
              max-width: 290px;
              margin: 0;
            }
          }

          > .size {
            font-weight: ${font.weight.bold};
            margin: 0;
          }
        }

        .responsibles {
          font-size: ${font.size.md};
        }
      }
    }

    .LabeledContent {
      overflow: hidden;
      padding-bottom: 20px;
      .LabeledContainer {
        margin-bottom: 10px;
      }
    }
  `}
`;
