import { Icon, Text } from '@agendaedu/ae-web-components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import * as S from './styles';
import { SkeletonProps } from './types';

export const EmptyState: React.FC<SkeletonProps> = ({
  tabName,
  hasFilter,
}): JSX.Element => {
  const { t } = useTranslation(['messages']);

  const message = {
    classroom: t('empty_state_classroom_label'),
    student: t('empty_state_student_label'),
    responsible: t('empty_state_responsible_label'),
    family: t('empty_state_family_label'),
  };

  const tBase = useCallback(
    (key: string) => t(`omni_channel.chat.${key}`),
    [t]
  );
  return (
    <S.EmptyStateWrapper>
      <Icon name="user-group" size="md" />
      <Text
        color="neutral.gray2"
        variant="body-regular-14"
        lineHeight="lg"
        textAlign="center"
      >
        {parse(tBase(hasFilter ? 'empty_state_label' : message[tabName]))}
      </Text>
    </S.EmptyStateWrapper>
  );
};
