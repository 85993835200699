import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { CardBalanceWrapperProps } from './types';

export const CardBalanceWrapper = styled.div<CardBalanceWrapperProps>`
  ${({ theme: { space, border, colors, typography, font }, variation }) => css`
    padding: ${space.sm};
    border: ${border.width.sm} solid ${colors.neutral.gray3};
    border-radius: ${border.radius.md};

    .Currency {
      margin-top: ${space.xs};
      font-family: ${font.family.brand};
      font-size: ${typography.headline.H2Bold24};
      font-weight: 700;
      color: ${variation === 'refund'
        ? colors.context.danger.default
        : variation === 'available'
        ? colors.context.success.default
        : variation === 'receivable'
        ? colors.context.info.default
        : colors.neutral.gray2};
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme: { space, colors } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.xs2};

    .tooltip-div {
      display: flex;
      align-items: center;
      margin: 0 !important;

      svg {
        fill: ${colors.neutral.gray1};
      }
    }
  `}
`;
