import React from 'react';
import { theme, DefaultThemeProps, Box } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const StepLoading = ({ count }: { count: number }) => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box display="flex" flexDirection="column">
      {Array.from(Array(count).keys()).map((n) => (
        <Box
          key={n}
          width="100%"
          paddingX="sm"
          paddingY="md"
          borderBottomColor={neutral.gray5}
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          display="flex"
          flexDirection="row"
          gap="md"
          alignItems="center"
        >
          <Skeleton
            width={20}
            height={20}
            primaryColor={neutral.gray5}
            borderRadius={radius.sm}
          />

          <Skeleton
            width={32}
            height={32}
            primaryColor={neutral.gray5}
            borderRadius={radius.circle}
          />

          <Skeleton
            width={110}
            height={12}
            primaryColor={neutral.gray5}
            borderRadius={radius.sm}
          />
        </Box>
      ))}
    </Box>
  );
};
