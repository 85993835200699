import { RequesterIncluded } from '../normalizeTicketParams/types';

export const normalizeFetchCurrentChat = (data, included) => {
  const chatIncluded = included.find((included) => included.type === 'chat');
  const subject = included.find((included) => included.type === 'subject');

  const requester = included?.find(
    (included) =>
      data.relationships.requester?.data.id === included.id &&
      (included.type === 'studentProfile' ||
        included.type === 'responsibleProfile')
  ) as RequesterIncluded;

  const chat = {
    id: chatIncluded.id,
    type: chatIncluded.type,
    attributes: {
      classrooms_names: chatIncluded.attributes.classroomsNames,
      read_status: chatIncluded.attributes.readStatus,
      last_message_preview: chatIncluded.attributes.lastMessagePreview,
      last_message_sent_at: chatIncluded.attributes.lastMessageSentAt,
      main_user_role: chatIncluded.attributes.mainUserRole,
      kind: chatIncluded.attributes.kind,
      name: chatIncluded.attributes.name,
      student_profile_id: chatIncluded.attributes.studentProfileId,
      role_message: chatIncluded.attributes.roleMessage,
      subject: subject.attributes.title,
      ticket: data,
    },
    relationships: {
      main_user: {
        data: {
          id: requester.id,
          type:
            requester?.type === 'responsibleProfile'
              ? 'responsible_profile'
              : 'student_profile',
        },
      },
    },
  };

  return {
    chat,
    requester: {
      ...requester,
      attributes: {
        ...requester.attributes,
        name_initials: requester.attributes.nameInitials,
        avatar_color: requester.attributes.avatarColor,
        avatar_url: requester.attributes.avatarUrl,
      },
    },
  };
};
