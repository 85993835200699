import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const TitleBold20 = styled.p`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    ${typography.title.Bold20}
    color: ${colors.neutral.black}
  `}
`;

export const LabelRegular14 = styled.span`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    ${typography.label.Regular14}
    color: ${colors.neutral.black}
  `}
`;

export const TitleBold12 = styled.span`
  ${({ theme: { typography, colors, font } }: ThemeProps) => css`
    ${typography.title.Bold12};
    weight: ${font.weight.bold};
    color: ${colors.neutral.black};
  `}
`;

export const InfoText = styled.p`
  ${({ theme: { typography } }: ThemeProps) => css`
    ${typography.body.Regular14}
  `}
`;

export const OptionComponentContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    height: ${space.xl4};
    padding: ${space.xs};
    justify-content: space-between;
  `}
`;

export const AvatarNameContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.xs};
    #responsible-name {
      padding-left: 0px;
    }
  `}
`;

export const Row = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.lg};
    display: flex;
  `}
`;

export const GroupContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    gap: ${space.xs2};
    padding-left: ${space.sm};
    > div > div > div:nth-child(1) {
      display: flex;
      align-items: center;
    }
  `}
`;

export const MassFormContainer = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    .selected-column.item-wrap .SelectableOption {
      background-color: ${colors.brand.primary.op10};
      #responsible-name {
        color: ${colors.brand.primary.default};
      }
      #responsible-role {
        color: ${colors.brand.primary.default};
        background-color: ${colors.brand.primary.op10};
      }
    }
  `}
`;

export const CustomRow = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.lg};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
