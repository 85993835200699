import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import reduxProvider from 'core/hoc/reduxProvider';

const CnabImportRoutes = () => {
  console.log("This log wasn't forgotten.");
  return (
    <div className="CnabImportRoutes">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={`/schools/impors/cnab`}
            component={() => <h1>:)</h1>}
          />
          <Route
            exact
            path={`/schools/impors/cnab/:id`}
            component={() => <h1>:)</h1>}
          />
          <Route
            exact
            path={`/schools/impors/cnab/new`}
            component={() => <h1>:)</h1>}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default reduxProvider(CnabImportRoutes);
