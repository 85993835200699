import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { DefaultThemeProps } from '@agendaedu/ae-web-components';

import {
  SelectAvatarFieldStyleProps,
  OptionButtonImageStyleProps,
  OptionButtonStyleProps,
} from './types';

const optionButtonImageModifiers = {
  sm: ({ space }: DefaultThemeProps) => css`
    width: ${space.lg};
    height: ${space.lg};
  `,
  md: ({ space }: DefaultThemeProps) => css`
    width: ${space.xl};
    height: ${space.xl};
  `,
};

export const SelectAvatar = styled.button<SelectAvatarFieldStyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  ${({ showOptions, theme: { colors, border, space } }) => css`
    height: ${space.xl2};
    gap: ${space.xs};
    padding: ${space.xs2} ${space.xs} ${space.xs2} ${space.xs};
    border-radius: ${border.radius.md};
    border: ${border.width.sm} ${border.style.solid}
      ${showOptions ? colors.brand.primary.default : colors.neutral.gray4};

    &:hover {
      border-color: ${colors.brand.primary.default};
    }
  `}
`;

export const OptionsWrapper = styled.ul`
  position: absolute;
  z-index: 1;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  user-select: none;
  max-height: 310px;
  overflow: auto;

  ${({ theme: { space, colors, border } }: ThemeProps) => css`
    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      margin: ${space.xs3} ${space.xs3};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }

    margin-top: ${space.xs2};
    gap: ${space.xs};
    padding: ${space.xs};
    background-color: ${colors.neutral.white};
    border-radius: ${border.radius.md};
    border: ${border.width.sm} ${border.style.solid}
      ${colors.brand.primary.default};
  `}
`;

export const OptionButton = styled.button<OptionButtonStyleProps>`
  border-color: transparent;
  justify-content: center;
  align-items: center;

  ${({ selected, theme: { colors, border } }) => css`
    border-radius: ${selected ? border.radius.sm : border.radius.none};
    background-color: ${selected ? colors.brand.primary.op10 : 'transparent'};

    :hover {
      border-radius: ${border.radius.sm};
      background-color: ${colors.brand.primary.op10};
    }
  `};
`;

export const OptionButtonImage = styled.img<OptionButtonImageStyleProps>`
  -webkit-user-drag: none;

  ${({ size, theme }) => css`
    border-radius: ${theme.border.radius.circle};
    ${!!size && optionButtonImageModifiers[size](theme)}
  `}
`;
