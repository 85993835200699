import {
  all,
  call,
  put,
  takeLatest,
  debounce,
  select,
} from 'redux-saga/effects';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { deleteApi, fetchApi, patchApi, postApi, putApi } from 'core/utils/api';

import filterActions from '../filters/actions';
import actions from './actions';
import {
  SaveAlbumRequestParams,
  DeleteAlbumSagaProps,
  FetchAlbumsSagaProps,
  FetchAlbumRequestParams,
  FetchAlbumDetailsMediaSagaProps,
  ApprovePendingAlbumSagaProps,
  ApprovePendingMediaSagaProps,
  DeleteMediaSagaProps,
  SetMediaCoverSagaProps,
  UpdateMediaSagaProps,
  PhotoVisibilityStudentsSagaProps,
  AddMediaSagaProps,
  RecoverAlbumSagaProps,
} from './types';
import i18n from 'config/i18n';

function* setSideFiltersInitialData() {
  yield put({
    type: filterActions.FETCH_HEADQUARTERS_REQUEST,
  });
  yield put({
    type: filterActions.FETCH_ACTIVE_SCHOOL_TERMS_REQUEST,
  });
}

function* fetchAlbumsSaga({ nextLink }: FetchAlbumsSagaProps) {
  try {
    const albumFields = [
      'name',
      'description',
      'category',
      'createdAt',
      'updatedAt',
      'user_permission',
      'totalNumberOfPendingPhotos',
      'totalNumberOfPhotos',
      'photoCoverUrl',
      'totalNumberOfStudents',
      'totalNumberOfClassrooms',
      'deleting',
      'approving',
      'recipients',
      'scheduledDeletionTime',
    ];

    const filters = yield select((state) => state.albums.filters);

    const filtersFields = Object.entries(filters).reduce(
      (prev, [key, value]) => {
        if (Array.isArray(value)) {
          return value.some(Boolean)
            ? prev.concat(`&filter[${key}][]=${value.join(',')}`)
            : prev;
        }

        return value ? prev.concat(`&filter[${key}]=${value}`) : prev;
      },
      ''
    );

    const urlToFetch = nextLink
      ? nextLink.split('albums.json?')[1]
      : `fields[album]=${albumFields}&${filtersFields}&include=userPermission,recipients&
         fields[userPermission]=canUpdate,canDestroy&fields[classroom]=name&fields[studentProfile]=name`;

    const { dataArea } = yield select((state) => state.root);

    const {
      data,
      meta,
      included,
      history: { links },
    } = yield call(fetchApi, `/${dataArea}/albums.json?${urlToFetch}`);

    yield put({
      type: actions.FETCH_ALBUMS_SUCCESS,
      data,
      included,
      meta,
      links,
      isPagination: !!nextLink,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:list.list_albums_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* deleteAlbumSaga({ id }: DeleteAlbumSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      deleteApi,
      `/${dataArea}/albums/${id}.json`
    );

    yield put({
      type: actions.DELETE_ALBUM_SUCCESS,
      data,
      destroyEvent: included.pop(),
    });

    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t('albums:list.delete_album_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:list.delete_album_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* recoverAlbumSaga({ id }: RecoverAlbumSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { included } = yield call(
      postApi,
      `/${dataArea}/albums/${id}/recover`
    );

    yield put({
      type: actions.RECOVER_ALBUM_SUCCESS,
      restoreEvent: included.pop(),
      id,
    });

    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t('albums:list.recover_album_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:list.recover_album_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* approvePendingsAlbumSaga({ id }: ApprovePendingAlbumSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(putApi, `/${dataArea}/albums/${id}/approve.json`);

    yield put({
      type: actions.APPROVE_PENDINGS_SUCCESS,
      id,
    });

    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t('albums:album_details.approve_pendings_album_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
    yield put({
      type: actions.FETCH_ALBUM,
      id,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:album_details.approve_pendings_album_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* downloadAlbumSaga({ id }: ApprovePendingAlbumSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(fetchApi, `/${dataArea}/albums/${id}/download_all_medias`);

    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t('albums:album_details.download_album_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:album_details.download_album_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* approvePendingMediaSaga({
  id,
  albumId,
}: ApprovePendingMediaSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      patchApi,
      `/${dataArea}/albums/${albumId}/photos/${id}/approve.json`
    );

    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t('albums:album_details.approve_pending_media_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:album_details.approve_pending_media_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* deleteMediaSaga({ id, albumId }: DeleteMediaSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(deleteApi, `/${dataArea}/albums/${albumId}/photos/${id}.json`);

    yield put({
      type: actions.DELETE_MEDIA_SUCCESS,
      id,
    });
    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t('albums:album_details.delete_media_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:album_details.delete_media_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* setMediaCoverSaga({ id, albumId }: SetMediaCoverSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      postApi,
      `/${dataArea}/albums/${albumId}/photos/${id}/set_cover.json`
    );

    yield put({
      type: actions.SET_MEDIA_COVER_SUCCESS,
      id,
    });
    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t('albums:album_details.set_media_cover_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:album_details.set_media_cover_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* updateMediaSaga({
  id,
  albumId,
  description,
  studentProfileIds,
  callbackSuccess,
}: UpdateMediaSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      patchApi,
      `/${dataArea}/albums/${albumId}/photos/${id}.json`,
      { description, student_profile_ids: studentProfileIds }
    );

    yield put({
      type: actions.UPDATE_MEDIA_SUCCESS,
      id,
      media: data,
    });
    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t(
          `albums:album_details.update_media_${
            description ? 'description' : 'recipients'
          }_success`
        ),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
    callbackSuccess?.();
  } catch (error) {
    yield put({
      type: actions.UPDATE_MEDIA_ERROR,
    });
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t(
          `albums:album_details.update_media_${
            description ? 'description' : 'recipients'
          }_error`
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* addMediaSaga({
  albumId,
  signedIds,
  callbackSuccess,
}: AddMediaSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(postApi, `/${dataArea}/albums/${albumId}/photos.json`, {
      signed_ids: signedIds,
    });

    yield put({
      type: actions.ADD_MEDIA_SUCCESS,
    });

    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t(`albums:album_details.add_media_success`, {
          count: signedIds.length,
        }),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    callbackSuccess?.();

    window.location.replace(`/${dataArea}/albums/${albumId}`);
  } catch (error) {
    yield put({
      type: actions.ADD_MEDIA_ERROR,
    });
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t(`albums:album_details.add_media_error`, {
          count: signedIds.length,
        }),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchFormClassroomsSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.FETCH_FORM_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* requestSaveAlbumSaga({ album }: SaveAlbumRequestParams) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data: { id },
    } = album.id
      ? yield call(patchApi, `/${dataArea}/albums/${album.id}.json`, album)
      : yield call(postApi, `/${dataArea}/albums.json`, album);

    window.location.replace(`/${dataArea}/albums/${id}`);

    yield put({
      type: actions.REQUEST_SAVE_ALBUM_SUCCESS,
    });
    yield put({
      type: actions.SUCCESS,
      toast: buildToast(
        i18n.t(`albums:list.${!album.id ? 'create' : 'edit'}_album_success`),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:list.save_album_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchAlbumSaga({ id }: FetchAlbumRequestParams) {
  try {
    const albumEditFields = [
      'name',
      'description',
      'category',
      'classroomWithStudentProfileIds',
      'recipients',
      'scheduledDeletionTime',
    ];

    const urlToFetch = `fields[album]=${albumEditFields}&include=recipients`;

    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/albums/${id}.json?${urlToFetch}`
    );

    yield put({
      type: actions.FETCH_ALBUM_SUCCESS,
      album: data,
      albumIncluded: included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:list.fetch_album_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchAlbumDetailsSaga({ id }: FetchAlbumRequestParams) {
  try {
    const albumEditFields = [
      'name',
      'description',
      'category',
      'createdAt',
      'updatedAt',
      'recipients',
      'totalNumberOfPendingPhotos',
      'totalNumberOfPhotos',
      'user_permission',
      'deleting',
      'approving',
      'versions',
      'scheduledDeletionTime',
    ];

    const albumIncludedFields =
      'versions,recipients,userPermission&fields[userPermission]=canUpdate,canDestroy,canApprove,canAddPhotos,canDownload';

    const urlToFetch = `fields[album]=${albumEditFields}&include=${albumIncludedFields}`;

    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/albums/${id}.json?${urlToFetch}`
    );

    yield put({
      type: actions.FETCH_ALBUM_DETAILS_SUCCESS,
      album: data,
      albumIncluded: included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:list.fetch_album_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchAlbumDetailsMediasSaga({
  id,
  nextLink,
}: FetchAlbumDetailsMediaSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data,
      included,
      history: { links },
    } = yield call(
      fetchApi,
      nextLink || `/${dataArea}/albums/${id}/photos.json?include=userPermission`
    );

    yield put({
      type: actions.FETCH_ALBUM_DETAILS_MEDIAS_SUCCESS,
      data,
      included,
      links,
      isPagination: !!nextLink,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('albums:album_details.list_medias_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchPhotoVisibilityStudentsByHeadquarterSaga({
  albumId,
  photoId,
}: PhotoVisibilityStudentsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/albums/${albumId}/photos/${photoId}/student_profiles_by_headquarter.json`
    );

    yield put({
      type: actions.FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER_SUCCESS,
      data,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER_ERROR,
      error,
      toast: buildToast(
        i18n.t(
          'albums:album_details.photo_details_modal.list_photo_visibility_students_error'
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchPhotoVisibilityStudentsSaga({
  albumId,
  photoId,
}: PhotoVisibilityStudentsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/albums/${albumId}/photos/${photoId}/student_profiles.json`
    );

    yield put({
      type: actions.FETCH_PHOTO_VISIBILITY_STUDENTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_PHOTO_VISIBILITY_STUDENTS_ERROR,
      error,
      toast: buildToast(
        i18n.t(
          'albums:album_details.photo_details_modal.list_photo_visibility_students_error'
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* albumsSaga() {
  yield all([takeLatest(actions.FETCH_ALBUMS, fetchAlbumsSaga)]);
  yield all([debounce(500, actions.SET_FILTER, fetchAlbumsSaga)]);
  yield all([takeLatest(actions.DELETE_ALBUM, deleteAlbumSaga)]);
  yield all([takeLatest(actions.RECOVER_ALBUM, recoverAlbumSaga)]);
  yield all([takeLatest(actions.APPROVE_PENDINGS, approvePendingsAlbumSaga)]);
  yield all([takeLatest(actions.DOWNLOAD_ALL_MEDIAS, downloadAlbumSaga)]);
  yield all([
    takeLatest(actions.APPROVE_PENDING_MEDIA, approvePendingMediaSaga),
  ]);
  yield all([takeLatest(actions.DELETE_MEDIA, deleteMediaSaga)]);
  yield all([takeLatest(actions.SET_MEDIA_COVER, setMediaCoverSaga)]);
  yield all([takeLatest(actions.ADD_MEDIA, addMediaSaga)]);
  yield all([takeLatest(actions.UPDATE_MEDIA, updateMediaSaga)]);
  yield all([
    takeLatest(actions.SET_SIDE_FILTERS_DATA, setSideFiltersInitialData),
  ]);
  yield all([
    takeLatest(actions.FETCH_FORM_CLASSROOMS, fetchFormClassroomsSaga),
  ]);
  yield all([takeLatest(actions.REQUEST_SAVE_ALBUM, requestSaveAlbumSaga)]);
  yield all([takeLatest(actions.FETCH_ALBUM, fetchAlbumSaga)]);
  yield all([takeLatest(actions.FETCH_ALBUM_DETAILS, fetchAlbumDetailsSaga)]);
  yield all([
    takeLatest(actions.FETCH_ALBUM_DETAILS_MEDIAS, fetchAlbumDetailsMediasSaga),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER,
      fetchPhotoVisibilityStudentsByHeadquarterSaga
    ),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_PHOTO_VISIBILITY_STUDENTS,
      fetchPhotoVisibilityStudentsSaga
    ),
  ]);
}

export default albumsSaga;
