import { all, call, put, takeLatest } from 'redux-saga/effects';
import i18n from 'config/i18n';

import actions from './actions';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { patchApi } from 'core/utils/api';

export function* buildToastMessage({ message, typeToast }) {
  yield put({
    type: actions.BUILD_TOAST_SUCCESS,
    toast: buildToast(message, typeToast, toastCss(typeToast)),
  });
}

export function* startPaymentProcessing({ webCheckoutId, params }) {
  try {
    yield call(
      patchApi,
      `/payments/web_checkout/${webCheckoutId}/orders/processing`,
      params
    );

    yield put({
      type: actions.START_PAYMENT_PROCESSING_SUCCESS,
      toast: buildToast(
        i18n.t('payments:common.toast.processing_payment'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    // We don't need to display an error to the user in this case. The back-end will save the error
    // in the DB and we'll be notified about it in the Slack channel #edupay_status.
  }
}

function* webCheckoutSagas() {
  yield all([
    takeLatest(actions.BUILD_TOAST, buildToastMessage),
    takeLatest(actions.START_PAYMENT_PROCESSING_REQUEST, startPaymentProcessing)
  ]);
}

export default webCheckoutSagas;
