import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

type ColoredTextProps = {
  textColor: 'info' | 'success' | 'danger' | 'default';
};

export const ColoredText = styled.p<ColoredTextProps>`
  ${({
    theme: { font, colors },
    textColor,
  }: ThemeProps & ColoredTextProps) => css`
    font-family: ${font.family.brand};
    font-weight: ${font.weight.medium};
    font-size: ${font.size.md};
    color: ${textColor === 'default'
      ? colors.neutral.gray1
      : colors.context[textColor].default};
  `}
`;

export const Container = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    display: grid;
    grid-template-columns: ${space.md} minmax(50px, auto) repeat(5, 1fr);
    grid-gap: ${space.sm};
    border-radius: ${border.radius.md};
    padding: ${space.md} ${space.md};

    @media screen and (max-width: 768px) {
      grid-template-columns: minmax(80px, auto) repeat(2, 1fr);
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    div.information-content > div {
      height: 100%;
    }
  `}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
