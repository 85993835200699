import styled, { css } from 'styled-components';

import { theme, DefaultThemeProps } from '@agendaedu/ae-web-components';

const {
  colors: { context, neutral },
} = theme;

export const colorMapping = {
  anxious: context.danger.default,
  sad: context.warning.default,
  neutral: neutral.gray2,
  happy: context.info.default,
  excited: context.success.default,
};

export const MoodCardContainer = styled.div<{
  theme: DefaultThemeProps;
}>`
  ${({ theme: { colors, space, border, shadow } }) => css`
    padding: ${space.sm};
    background-color: ${colors.neutral.white};
    border-radius: ${border.radius.md};
    box-shadow: ${shadow.card};
    float: left;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    img {
      float: right;
    }
  `}
`;

export const TextContainer = styled.div<{
  theme: DefaultThemeProps;
  mood: string;
}>`
  ${({ theme: { typography, space }, mood }) => css`
    h3 {
      ${typography.subtitle.Medium14};
      margin: ${space.xs4};
    }

    h5 {
      ${typography.headline.H2Bold24};
      margin: ${space.xs4};
      color: ${colorMapping[mood]};
    }
  `}
`;
