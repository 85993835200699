import styled, { css } from 'styled-components';
import { WrapperStyles } from './types';

export const Wrapper = styled.div<WrapperStyles>`
  ${({ height }) => css`
    display: flex;
    flex-direction: row;
    height: ${height}px;
  `}
`;
