import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withFormContext from 'core/hoc/withFormContext';
import storeActions from 'store/editDynamicModules/actions';
import Toast from 'components/Toast';
import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import Field from 'components/Form/Field';
import GroupSelector from 'components/Form/GroupSelector';
import { Box, Text } from '@agendaedu/ae-web-components';

import { Props } from './types';
import * as S from './styles';

const FormEdit = ({ formContext: { updateAttribute, form } }: Props) => {
  const [selectedModule, setSelectedModule] = useState({});
  const [selectClassroomIds, setSelectClassroomIds] = useState([]);
  const [nameModule, setNameModule] = useState('');

  const dispatch = useDispatch();

  const displayToast = useCallback(
    async (message, type) => {
      await dispatch(buildToast(message, type));
    },
    [dispatch, buildToast]
  );

  useEffect(() => {
    dispatch(fetchDynamicModules());
    dispatch(fetchClassroomsModules(1));
  }, []);

  const {
    fetchDynamicModules,
    fetchClassroomsModules,
    updateDynamicModules,
    buildToast,
  } = storeActions;

  const {
    dynamicModules,
    classroomsModules,
    isLoading,
    idsClassroomsOnlySchool,
  } = useSelector((state) => state.editDynamicModules);

  const onSubmit = () => {
    if (Object.keys(selectedModule).length <= 0) {
      displayToast('Você deve selecionar ao menos um módulo', 'error');
      return;
    }

    dispatch(
      updateDynamicModules(
        selectedModule.id,
        selectClassroomIds,
        form.add_classroom_automatically === 'Automatically' ? true : false
      )
    );
  };

  const renderEmptyState = (
    <div>
      <AgendaIcon name="user-group" size="large" />
      <div>
        {Object.keys(selectedModule).length > 0
          ? 'Selecione uma turma'
          : 'Selecione uma módulo primeiro'}
      </div>
    </div>
  );

  const radios = [
    {
      labelText: 'Automaticamente',
      value: 'Automatically',
      icon: 'history ',
    },
    {
      labelText: 'Manualmente',
      value: 'Manually',
      icon: 'user-group',
    },
  ];

  const parseBooleanToAction = {
    true: 'Automatically',
    false: 'Manually',
  };

  const handleSelectModule = (e) => {
    setSelectedModule({
      id: e.value,
      classroomIds: e.classroomIds,
    });
    setNameModule(e.attributes.name);
    updateAttribute(
      'add_classroom_automatically',
      parseBooleanToAction[e.attributes.add_classroom_automatically]
    );

    const currentIdSelecteds = idsClassroomsOnlySchool.filter((element) =>
      e.classroomIds.includes(element)
    );

    setSelectClassroomIds(currentIdSelecteds);
  };

  return (
    <S.Page
      variation="centered"
      isLoading={isLoading}
      id="dynamic-modules"
      title="Selecione quais são os módulos ativos para cada turma."
      subtitle="ATENÇÃO: Os usuários não terão acesso aos módulos não selecionados."
      footer={
        <div className="pull-right">
          <ButtonRow>
            <Button
              type="submit"
              variation="primary"
              className="save-button"
              onClick={() => onSubmit()}
            >
              Salvar
            </Button>
          </ButtonRow>
        </div>
      }
    >
      <Field
        className="input-dynaminc-module"
        mobileSize="extraLarge"
        placeholder="Selecione o módulo"
        attributeName="dynamicmodule"
        pointerEvents="none"
        type="selectWithIcon"
        value={selectedModule?.id}
        options={dynamicModules}
        withArrowSelect
        onChange={handleSelectModule}
      />

      <GroupSelector
        key={selectedModule?.id}
        options={
          Object.keys(selectedModule).length > 0 ? classroomsModules : []
        }
        emptyState={renderEmptyState}
        attributeName="dynamicmoduleSchool"
        selectedValues={selectedModule?.classroomIds?.map(String) || []}
        onChange={(data) => setSelectClassroomIds(data)}
      />

      {Object.keys(selectedModule).length > 0 && (
        <Box>
          <Box className="col-md-10">
            <Text variant="title-bold-12" color="neutral.black">
              Configuração do módulo {nameModule}:
            </Text>
            <Text variant="subtitle-medium-12" color="neutral.gray2">
              ao criar novas turmas, desejo liberá-las:
            </Text>
          </Box>
          <Field
            key={selectedModule?.id}
            type="radio"
            attributeName="add_classroom_automatically"
            radios={radios}
          />
        </Box>
      )}
      <Toast />
    </S.Page>
  );
};

export default withFormContext(FormEdit);
