import createModuleReducer from '../storeConfig/createModuleReducer';

// actions
import actions from './actions';

export const INITIAL_STATE = {
  isLogin: true,
  dynamicModules: [],
  classroomsModules: [],
  idsClassroomsOnlySchool: [],
};

export const updateLoding = (state) => {
  return {
    ...state,
    isLoading: !state.isLoading,
  };
};

export const fecthDynamicModulesSuccess = (state, action) => {
  const { data } = action;

  const dynamicModules = data.map((module) => {
    module.value = module.id;
    module.label = module.attributes.name;
    module.classroomIds = module.attributes.classroom_ids;

    return module;
  });

  return {
    ...state,
    dynamicModules: dynamicModules,
    isLoading: false,
  };
};

export const fecthClassroomsModulesSuccess = (state, action) => {
  const { data } = action;

  const classroomsModulesList = [];
  const onlyIdClassrooms = [];

  data.map((headquarter) => {
    if (
      classroomsModulesList.length <= 0 ||
      !classroomsModulesList.find(
        (o) => o.id === headquarter.attributes.headquarter_id
      )
    ) {
      classroomsModulesList.push({
        label: headquarter.attributes.headquarter_name,
        id: headquarter.attributes.headquarter_id,
        options: [],
      });
    }

    classroomsModulesList.map((headquarterItem) => {
      if (headquarterItem.id === headquarter.attributes.headquarter_id) {
        headquarterItem.options.push({
          id: headquarter.id,
          type: headquarter.type,
          attributes: {
            name: headquarter.attributes.name,
          },
          relationships: {},
          label: headquarter.attributes.name,
          value: headquarter.attributes.id,
        });
      }
    });
  });

  data.map((item) => {
    onlyIdClassrooms.push(Number(item.id));
  });

  return {
    ...state,
    classroomsModules: classroomsModulesList,
    idsClassroomsOnlySchool: onlyIdClassrooms,
    isLoading: false,
  };
};

export const updateDynamicModulesSuccess = (state, action) => {
  const { data } = action;

  return {
    ...state,
    dynamicModules: state.dynamicModules.map((module) =>
      module.id === data.id
        ? {
            ...module,
            classroomIds: data.attributes.classroom_ids,
            attributes: {
              ...module.attributes,
              add_classroom_automatically:
                data.attributes.add_classroom_automatically,
            },
          }
        : module
    ),
    isLoading: false,
  };
};

const HANDLERS = {
  [actions.IS_LOADING_UPDATE]: updateLoding,
  [actions.FETCH_DYNAMIC_MODULES_SUCCESS]: fecthDynamicModulesSuccess,
  [actions.FETCH_CLASSROOMS_MODULES_SUCCESS]: fecthClassroomsModulesSuccess,
  [actions.UPDATE_DYNAMIC_MODULES_SUCCESS]: updateDynamicModulesSuccess,
};

const dynamicModules = createModuleReducer(INITIAL_STATE, HANDLERS);

export default dynamicModules;
