import { Chat, ChatTicket, ChatUser } from 'store/messages/omniChannel/types';

export const formatDataToOmniHeader = (
  activeChat: Chat,
  activeChatUser: ChatUser,
  activeTicket: ChatTicket
) => {
  const id = activeChat?.id || activeChat?.id;
  const ticketId = activeTicket?.id;
  const name = activeChatUser?.attributes.name || activeChat?.attributes.name;
  const subject =
    activeTicket?.attributes.title || activeTicket?.attributes.subject;
  const classroomsNames =
    activeChatUser?.attributes.classroom_names ||
    activeChat?.attributes.classrooms_names;
  const roleMessage = activeChat?.attributes?.role_message;
  const mainUserRole =
    activeChatUser?.attributes.role || activeChat?.attributes.main_user_role;
  const kind = activeChatUser?.attributes.kind || activeChat?.attributes.kind;
  const readStatus =
    activeTicket?.attributes.status || activeChat?.attributes.read_status;
  const avatarColor =
    activeChatUser?.attributes.avatar_color ||
    activeChat?.included.attributes.avatar_color;
  const avatarUrl =
    activeChatUser?.attributes.avatar_url ||
    activeChat?.included.attributes.avatar_url;
  const nameInitials =
    activeChatUser?.attributes.name_initials ||
    activeChat?.included.attributes.name_initials;
  const isTypeTicket = kind === 'ticket';
  const isTypeFamily = kind === 'family';
  const currentType = isTypeTicket ? 'ticket' : 'privateorfamily';
  const isDisabledBtn = readStatus === 'done';

  return {
    id,
    ticketId,
    name,
    subject,
    classroomsNames,
    mainUserRole,
    roleMessage,
    kind,
    readStatus,
    avatarColor,
    avatarUrl,
    nameInitials,
    isTypeTicket,
    isTypeFamily,
    currentType,
    isDisabledBtn,
  };
};
