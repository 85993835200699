import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import autobind from 'autobind-decorator';
import { io } from 'socket.io-client';

import appContextProvider from 'core/hoc/appContextProvider';
import toastMessageProvider from 'core/hoc/toastMessageProvider';
import withAppContext from 'core/hoc/withAppContext';
import withErrorBoundary from 'core/hoc/withErrorBoundary';
import composeFunctions from 'core/utils/composeFunctions';
import { getViewport, isMobile } from 'core/utils/viewport';
import OtherUsersMessagesService from 'core/services/OtherUsers/Messages';

import ResponsivePanels from 'components/ResponsivePanels';
import MessagesOtherUsersChannelSideBar from 'components/Messages/OtherUsers/ChannelSideBar';
import MessagesEmptyChatDisplay from 'components/Messages/EmptyChatDisplay';
import MessagesOtherUsersChatDisplay from 'components/Messages/OtherUsers/ChatDisplay';

import './style.scss';

class MessagesOtherUsersMainScreen extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
      realTime: PropTypes.bool.isRequired,
    }).isRequired,
  };

  state = {
    activeChannelKind: 'private',
    activeChat: null,
    errorFetchingChannels: null,
    isLoadingChannels: true,
    channels: [],
    filteredChannels: [],
    userChats: [],
    width: window.innerWidth,
    socket: null,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.connectToWebsocketServer();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    this.closeSocket();
  }

  @autobind
  async loadChannels() {
    const { dataArea } = this.props.appContext;

    const otherUsersMessagesService = new OtherUsersMessagesService(dataArea);

    try {
      const { channels, userChats, privateCount, familyCount } =
        await otherUsersMessagesService.fetchChannels();

      this.setState({
        channels,
        userChats,
        filteredChannels: channels.filter(this.channelsFilterBy('private')),
        privateCount,
        familyCount,
      });
    } catch (error) {
      this.setState({ errorFetchingChannels: error });
    } finally {
      this.setState({ isLoading: false, isLoadingChannels: false });
    }
  }

  @autobind
  selectChannelKind(typeName) {
    this.setState((prevState) => ({
      activeChannelKind: typeName,
      filteredChannels: prevState.channels.filter(
        this.channelsFilterBy(typeName)
      ),
    }));
  }

  connectToWebsocketServer = () => {
    const {
      appContext: { realTime, dataArea },
    } = this.props;

    if (dataArea === 'students' || !realTime) return;

    const socket = io(
      process.env.WEBSOCKETS_SERVER_URL || 'ws://localhost:8080'
    );

    this.setState({ socket });
  };

  closeSocket = () => {
    const { socket } = this.state;

    if (!socket) return;

    socket.disconnect();
  };

  @autobind
  selectChat(channel) {
    this.setState((prevState) => {
      const { activeChannelKind, userChats } = prevState;
      const activeChat = userChats.find(
        ({ attributes }) =>
          attributes.kind === activeChannelKind &&
          attributes.channel_id === channel.id
      );
      return { activeChat };
    });
  }

  channelsFilterBy(kind) {
    if (kind === 'family') {
      return (channel) => channel.attributes.kind === 'family';
    } else {
      return (channel) => !channel.attributes.only_family;
    }
  }

  @autobind
  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  }

  @autobind
  renderChannelSidebar() {
    const {
      activeChannelKind,
      errorFetchingChannels,
      familyCount,
      isLoadingChannels,
      channels,
      filteredChannels,
      privateCount,
      userChats,
    } = this.state;

    return (
      <MessagesOtherUsersChannelSideBar
        activeChannelKind={activeChannelKind}
        channels={channels}
        errorFetchingChannels={errorFetchingChannels}
        familyCount={familyCount}
        filteredChannels={filteredChannels}
        isLoading={isLoadingChannels}
        loadChannels={this.loadChannels}
        onChannelSelect={this.selectChat}
        onFilterChannelClick={this.selectChannelKind}
        privateCount={privateCount}
        userChats={userChats}
      />
    );
  }

  @autobind
  renderChatDisplay(match) {
    const { activeChannelKind, activeChat, width, socket } = this.state;

    const mobile = isMobile(width);

    return (
      <MessagesOtherUsersChatDisplay
        activeChannelKind={activeChannelKind}
        activeChat={activeChat}
        showBackButton={mobile}
        match={match}
        key={match.url}
        socket={socket}
      />
    );
  }

  render() {
    const {
      appContext: { dataArea },
    } = this.props;
    const {
      activeChannelKind,
      filteredChannels: [firstChannel],
      previousChannelKind,
      width,
    } = this.state;

    const mobile = isMobile(width);

    return (
      <div
        className={`MessagesOtherUsersMainScreen ${mobile ? 'mobile' : ''}`}
        id="hide-intercom"
      >
        <div className="messages-wrapper">
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path={`/${dataArea}/messages`}
                render={(props) => {
                  if (!mobile && firstChannel) {
                    return (
                      <Redirect
                        to={`/${dataArea}/messages/channels/${firstChannel.id}/messages/private`}
                      />
                    );
                  }

                  return (
                    <ResponsivePanels viewport={getViewport(width)}>
                      {this.renderChannelSidebar()}
                      {!isMobile(width) && (
                        <MessagesEmptyChatDisplay {...props} />
                      )}
                    </ResponsivePanels>
                  );
                }}
              />
              <Route
                exact
                path={`/${dataArea}/messages/channels/:channelId/messages/:kind`}
                render={(props) => {
                  const {
                    match: {
                      params: { kind },
                    },
                  } = props;

                  return (
                    <ResponsivePanels viewport={getViewport(width)}>
                      {!isMobile(width) && this.renderChannelSidebar()}
                      {this.renderChatDisplay(props.match)}
                    </ResponsivePanels>
                  );
                }}
              />
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default composeFunctions(
  toastMessageProvider,
  withErrorBoundary,
  withAppContext,
  appContextProvider
)(MessagesOtherUsersMainScreen);
