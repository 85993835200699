import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Route, Switch } from 'react-router';

import composeFunctions from 'core/utils/composeFunctions';
import EventFormProvider from 'core/contexts/Calendar/Forms/Event';
import reduxProvider from 'core/hoc/reduxProvider';
import UploadFilesProvider from 'core/contexts/UploadFiles';
import withAppContext from 'core/hoc/withAppContext';

import CalendarList from '../../pages/Calendar';
import NewEvent from '../../pages/Calendar/Forms/Event';
import Details from 'pages/Calendar/Details/Event';

import { Props } from './types';
const CalendarRoutes: React.FC<Props> = ({
  appContext: { dataArea },
}): JSX.Element => {
  useEffect(() => {
    const mainContent = document.getElementById('main-content');

    if (mainContent) {
      mainContent.style.minHeight = '100vh';
      mainContent.style.backgroundColor = '#ffffff';
    }
  }, []);

  return (
    <div id="calendar-router" style={{ marginBottom: -90 }}>
      <CookiesProvider>
        <Switch>
          <Route
            exact
            path={`/${dataArea}/calendar_events`}
            component={CalendarList}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={`/${dataArea}/calendar_events/new`}
            component={() => (
              <UploadFilesProvider shouldConcatFiles>
                <EventFormProvider>
                  <NewEvent />
                </EventFormProvider>
              </UploadFilesProvider>
            )}
          />
          <Switch>
            <Route
              exact
              path={`/${dataArea}/calendar_events/:id`}
              component={Details}
            />
          </Switch>
        </Switch>
      </CookiesProvider>
    </div>
  );
};

export default composeFunctions(withAppContext, reduxProvider)(CalendarRoutes);
