import React from 'react';

import AgendaIcon from '../AgendaIcon';

import './style.scss';

type ButtonProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  testId?: string;
  outline?: boolean;

  onClick?: () => void;
  type?: 'submit' | 'button' | 'reset';
  variation?:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'success'
    | 'danger'
    | 'white'
    | 'link'
    | 'info';
  size?: '' | 'btn-lg' | 'btn-sm' | 'btn-xs';
  icon?: string;
  style?: object;
};
/**
 * @deprecated This component has been deprecated.
 * Use the component from `@agendaedu/ae-web-components` instead.
 */
const Button = ({
  id,
  testId,
  size = '',
  className = '',
  type = 'button',
  variation = 'primary',
  disabled = false,
  outline,
  style,
  onClick,
  icon,
  children,
}: ButtonProps) => {
  const getVariation = (): string =>
    disabled === true ? 'default' : variation;

  const getTag = () => (type === 'submit' ? 'button' : 'a');

  const Tag = getTag();

  return (
    <Tag
      className={`Button btn btn-${getVariation()} ${className} ${size} ${
        outline ? 'outline' : ''
      }`}
      id={id}
      data-testid={testId}
      disabled={disabled}
      type={type}
      onClick={onClick}
      data-toggle={className.includes('dropdown-toggle') ? 'dropdown' : ''}
      style={style}
    >
      {!!icon && (
        <div className="button-icon">
          <AgendaIcon name={icon} size="small" />
        </div>
      )}
      {children}
    </Tag>
  );
};

export default Button;
