import React from 'react';
import { Box, Icon, Text } from '@agendaedu/ae-web-components';

import { useDownloadFile } from 'core/hooks/useDownloadFile';
import { normalizeDownloadURL } from 'core/helper/albums';
import { normalizeFileSize } from 'core/utils/formatBytes';
import truncateName from 'core/utils/truncateName';

import * as S from './styles';

import { Props } from './types';

const AttachmentPreview: React.FC<Props> = ({
  attachment,
}): React.ReactElement => {
  const { requestDownloadFileWithFetch } = useDownloadFile({
    getFileURL: normalizeDownloadURL(attachment.url),
    getFileName: attachment?.name,
  });

  return (
    <S.Wrapper
      data-testid={`attachment-${attachment.name}`}
      onClick={requestDownloadFileWithFetch}
    >
      <Box display="flex" alignItems="center" gap="xs">
        <Box>
          <Icon name="attachment" />
        </Box>

        <Text mb={0} variant="label-regular-14">
          {truncateName(attachment.name, 30)}
        </Text>
      </Box>

      <Box display="flex" justifyContent="end">
        <Text mb={0} variant="label-medium-14">
          {normalizeFileSize(attachment.size)}
        </Text>
      </Box>
    </S.Wrapper>
  );
};

export default AttachmentPreview;
