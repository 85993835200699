import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import FormContainer from 'components/Form/FormContainer';
import WizardContainer from 'components/WizardForm/WizardContainer';
import HeadquartersTab from './headquarters';
import CoursesTab from './courses';
import SchoolTermsTab from './school_terms';

const FiltersForm = (props) => {
  const {
    handleSubmit,
    handlers,
    filters,
    initialValues,
    canChange,
    provider,
  } = props;

  let wizardSteps;

  switch (provider) {
    case 'active_soft':
      wizardSteps = [HeadquartersTab];
      break;
    case 'proesc':
      wizardSteps = [HeadquartersTab, SchoolTermsTab];
      break;
    case 'sponte':
      wizardSteps = [SchoolTermsTab];
      break;
    case 'galileu':
      wizardSteps = [HeadquartersTab, CoursesTab];
      break;
    case 'qi_solution':
      wizardSteps = [SchoolTermsTab, CoursesTab];
      break;
    default:
      wizardSteps = [HeadquartersTab, CoursesTab, SchoolTermsTab];
  }

  return (
    <FormContainer
      initialAttributes={initialValues}
      form={filters}
      action="update"
      provider={provider}
      formMeta={handlers}
    >
      <WizardContainer
        steps={wizardSteps}
        onSubmit={handleSubmit}
        isSubmitting={canChange}
      />
    </FormContainer>
  );
};

FiltersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handlers: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  initialValues: PropTypes.func.isRequired,
  sent: PropTypes.bool.isRequired,
  canChange: PropTypes.bool.isRequired,
  provider: PropTypes.string.isRequired,
};

const IntegrationFiltersForm = reduxForm({
  form: 'IntegrationFiltersForm',
  enableReinitialize: true,
})(FiltersForm);

export default IntegrationFiltersForm;
