import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@agendaedu/ae-web-components';

import CenterModal from 'pages/storage/Modals/CenterModal';

import { Props } from './types';

const DiscardModal: React.FC<Props> = ({
  onClick,
  onClose,
  show,
}): React.ReactElement => {
  const { t } = useTranslation(['messages']);

  const tBase = useCallback(
    (key: string) => t(`omni_channel.modals.${key}`),
    [t]
  );

  return (
    <CenterModal
      isOpen={show}
      onClose={onClose}
      body={
        <Text variant="body-regular-16" lineHeight="lg" color="neutral.gray1">
          {tBase('discard_description')}
        </Text>
      }
      footer={
        <Box display="flex" flexDirection="row-reverse" gap="sm">
          <Button
            data-testid="discard-submit-button"
            variant="secondary"
            contextVariant="danger"
            onClick={onClick}
          >
            {tBase('discard_submit_button')}
          </Button>

          <Button
            data-testid="close-button"
            variant="secondary"
            onClick={onClose}
          >
            {tBase('discard_back_button')}
          </Button>
        </Box>
      }
      title={tBase('discard_title')}
    />
  );
};

export default DiscardModal;
