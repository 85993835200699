const actions = {
  BUILD_TOAST: 'BUILD_TOAST/webCheckout',
  BUILD_TOAST_SUCCESS: 'BUILD_TOAST_SUCCESS/webCheckout',
  START_PAYMENT_PROCESSING_REQUEST: 'START_PAYMENT_PROCESSING_REQUEST/webCheckout',
  START_PAYMENT_PROCESSING_SUCCESS: 'START_PAYMENT_PROCESSING_SUCCESS/webCheckout',
  buildToast: (message, typeToast) => ({
    type: actions.BUILD_TOAST,
    message,
    typeToast,
  }),
  startPaymentProcessing: (webCheckoutId, params) => ({
    type: actions.START_PAYMENT_PROCESSING_REQUEST,
    webCheckoutId,
    params
  })
};

export default actions;
