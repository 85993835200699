import axios from 'axios';
import { useRef, useState, MutableRefObject } from 'react';

interface DownloadFileProps {
  getFileURL?: string;
  getFileName?: string;
}

interface DownloadedFileInfo {
  requestDownloadFile: () => Promise<void>;
  requestDownloadFileWithFetch: () => Promise<void>;
  ref: MutableRefObject<HTMLAnchorElement | null>;
  name: string | undefined;
  url: string | undefined;
  hasErrorMessage: boolean | undefined;
  isLoadingFile: boolean | undefined;
}

export const useDownloadFile = ({
  getFileURL,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();
  const [hasErrorMessage, setErrorMessage] = useState<boolean>(false);
  const [isLoadingFile, setLoadingFile] = useState<boolean>(false);

  const requestDownloadFileWithFetch = async () => {
    try {
      preDownloading();

      const file = await fetch(getFileURL, {
        cache: 'no-cache',
      });
      const fileBlog = await file.blob();
      const fileURL = URL.createObjectURL(fileBlog);

      setFileUrl(getFileURL);
      setFileName(getFileName);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = getFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      postDownloading();
    } catch (error) {
      onErrorDownloadFile();
    }
  };

  const requestFile = () => {
    return axios.get(getFileURL, {
      responseType: 'blob',
    });
  };

  const preDownloading = () => {
    return setLoadingFile(true);
  };

  const postDownloading = () => {
    return setLoadingFile(false);
  };

  const onErrorDownloadFile = () => {
    setErrorMessage(true);
    setLoadingFile(false);
    setTimeout(() => {
      setErrorMessage(false);
    }, 4000);
  };

  const requestDownloadFile = async () => {
    try {
      preDownloading();
      const { data } = await requestFile();
      const url = URL.createObjectURL(data);
      setFileUrl(url);
      setFileName(getFileName);
      if (ref.current) {
        ref.current.href = url;
        ref.current.download = getFileName;
        ref.current.click();
      }
      postDownloading();
      URL.revokeObjectURL(url);
    } catch (error) {
      onErrorDownloadFile();
    }
  };

  return {
    ref,
    url,
    name,
    requestDownloadFile,
    requestDownloadFileWithFetch,
    hasErrorMessage,
    isLoadingFile,
  };
};
