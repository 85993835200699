import React from 'react';
import { theme, DefaultThemeProps, Box } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';
import { StepLoading } from '../StepLoading';

export const LoadingSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box display="flex" flexDirection="column" data-testid="loading-skeleton">
      <Box
        display="flex"
        flexDirection="row"
        borderBottomColor={neutral.gray5}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        padding="lg"
        paddingBottom={0}
        paddingLeft={0}
        gap="lg"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="lg"
          justifyContent="center"
          alignItems="center"
        >
          <Skeleton
            width={50}
            height={12}
            primaryColor={neutral.gray5}
            borderRadius={radius.sm}
          />

          <Skeleton
            width={100}
            height={4}
            primaryColor={neutral.gray4}
            borderRadius={radius.sm}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="lg"
          justifyContent="center"
          alignItems="center"
        >
          <Skeleton
            width={50}
            height={12}
            primaryColor={neutral.gray5}
            borderRadius={radius.sm}
          />

          <Skeleton
            width={0}
            height={4}
            primaryColor={neutral.gray4}
            borderRadius={radius.sm}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="lg"
          justifyContent="center"
          alignItems="center"
          ml="lg"
        >
          <Skeleton
            width={50}
            height={12}
            primaryColor={neutral.gray5}
            borderRadius={radius.sm}
          />

          <Skeleton
            width={0}
            height={4}
            primaryColor={neutral.gray4}
            borderRadius={radius.sm}
          />
        </Box>
      </Box>

      <StepLoading />
    </Box>
  );
};
