import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  buildDiscount,
  formattedDiscounts,
  formattedPenality,
} from 'core/utils/edupay/functions';
import { DISCOUNT_KINDS } from 'core/constants/index';

import Loader from 'components/Loader';
import RecurrentPlanForm from 'components/Payments/Recurrent/RecurrentPlanForm';
import RecurrentPlanFormInformationsTab from 'components/Payments/Recurrent/RecurrentPlanForm/RecurrentPlanFormInformationsTab';
import RecurrentPlanFormRecipientsTab from 'components/Payments/Recurrent/RecurrentPlanForm/RecurrentPlanFormRecipientsTab';
import selectOptions from 'components/Payments/Recurrent/RecurrentPlanForm/SelectOptions';
import Toast from 'components/Toast';

import actionRecurrentForms from 'store/edupay/recurrentForms/actions';
import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';
import actionRecurrentProducts from 'store/edupay/recurrentProducts/actions';

import './style.scss';

const WIZARD_STEPS = [
  RecurrentPlanFormInformationsTab,
  RecurrentPlanFormRecipientsTab,
];

const CloneRecurrenPlan = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  const {
    sending,
    current,
    recipients,
    recipientsWallet,
    student_profiles: studentProfiles,
    isLoadingPlan,
    isLoadingWallet,
  } = useSelector((state) => state.recurrentPlans);

  const { recurrentProducts, isLoading: isLoadingProducts } = useSelector(
    (state) => state.recurrentProducts
  );

  const {
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
  } = useSelector((state) => state.root);

  const {
    setPlanRequest,
    addRecurrentPlan,
    fetchRecipients,
    fetchRequestStudents,
  } = actionRecurrentPlans;
  const { fetchRecurrentProducts } = actionRecurrentProducts;
  const { formAction } = actionRecurrentForms;

  const enableFormAction = useCallback(() => {
    dispatch(formAction('edit'));
  }, [formAction, dispatch]);

  const setRecurrentPlan = useCallback(() => {
    dispatch(setPlanRequest(id));
  }, [setPlanRequest, dispatch, id]);

  const getRecurrentProducts = useCallback(() => {
    dispatch(fetchRecurrentProducts({ per_page: 'all' }));
  }, [fetchRecurrentProducts, dispatch]);

  const createRecurrentPlan = useCallback(
    (params) => {
      dispatch(addRecurrentPlan(params));
    },
    [addRecurrentPlan, dispatch]
  );

  const getRecipients = useCallback(() => {
    dispatch(fetchRecipients());
  }, [fetchRecipients, dispatch]);

  const getStudents = useCallback(() => {
    dispatch(fetchRequestStudents(id));
  }, [fetchRequestStudents, dispatch, id]);

  useEffect(() => {
    enableFormAction();
    setRecurrentPlan();
    getRecurrentProducts();
    getRecipients();
    getStudents();
  }, []);

  const formattedRecurrentItems = (recurrentItems) => {
    const formattedItems = [];

    recurrentItems.forEach((item) => {
      const formattedItem = {
        ...item,
        recurrent_product_id: item.recurrent_product_id,
        price_cents: item.price,
      };

      if (formattedItem.discount_kind === 'percent') {
        formattedItem.discount_percent = formattedItem.discount_value;
      } else {
        formattedItem.discount_price_cents = formattedItem.discount_value;
      }

      formattedItems.push(formattedItem);
    });

    return formattedItems;
  };

  const formatRecipients = () =>
    recipients &&
    recipients.map((recipient) => ({
      label: recipient.label,
      options: recipient.options.map((option) => ({
        value: option.id,
        label: option.attributes.name,
      })),
    }));

  const handleOnSubmit = (formContext) => {
    const { form } = formContext;

    createRecurrentPlan({
      ...form,
      parent_id: id,
      recurrent_items_attributes: formattedRecurrentItems(form.recurrentItems),
      edupay_penality_attributes: formattedPenality(form),
      edupay_discounts: formattedDiscounts(form),
    });
  };

  const recurrentProductIds = () => {
    return recurrentProducts
      .filter(
        (recurrentProducts) =>
          recurrentProducts.attributes.product_status === 'inactive'
      )
      .map((recurrentProduct) => ({
        name: recurrentProduct.attributes.name,
        value: recurrentProduct.id,
      }));
  };

  const makeClassroomIds = () => {
    const classroom_with_student_profile_ids = {};

    studentProfiles &&
      Object.keys(studentProfiles).forEach((key) => {
        classroom_with_student_profile_ids[key] = studentProfiles[key].data.map(
          (profile) => profile.id
        );
      });

    return classroom_with_student_profile_ids;
  };

  const extractStudents = () => {
    const arrayStudents = [];

    studentProfiles &&
      Object.keys(studentProfiles).forEach((key) => {
        studentProfiles[key].data.map((profile) => arrayStudents.push(profile));
      });

    return arrayStudents;
  };

  const extractDiscount = (recurrentPlan) => {
    const discounts = [];
    const { edupay_discounts: edupayDiscounts } = recurrentPlan;

    if (edupayDiscounts) {
      edupayDiscounts.data.map(({ attributes }) => {
        discounts.push({
          kind: attributes.kind,
          discount_value:
            attributes.kind === 'percent'
              ? attributes.discount_percent
              : attributes.discount_price_cents,
          days_before_expire: attributes.days_before_expire,
          key: Math.random().toString(36).substring(7),
        });
      });
    }

    if (!discounts.length) {
      discounts.push(buildDiscount());
    }

    return discounts;
  };

  const extractPenality = (recurrentPlan) => {
    const {
      edupay_penality: { data },
    } = recurrentPlan;

    let penality = {
      fine_percent: 2.0,
      interest_percent: 1.0,
    };

    if (data) {
      penality = data.attributes;
    }

    return penality;
  };

  const formatAttributes = () => ({
    ...current.recurrentPlan,
    first_bill_due_date: null,
    classroom_with_student_profile_ids: makeClassroomIds(),
    allowed_payment_method: current.recurrentPlan.allowed_payment_method,
    send_financial_responsible: current.attributes.send_financial_responsible,
    recipient_wallet_id: current.attributes.recipient_wallet_id,
    recurrentItems: current.recurrentPlan.recurrent_items.data
      .filter(({ attributes }) => attributes.product_status === 'active')
      .map(({ attributes }) => ({
        discount_kind: attributes.discount_kind,
        discount_value:
          attributes.discount_kind === 'percent'
            ? attributes.discount_percent
            : attributes.discount_price_cents,
        recurrent_product_id: parseInt(attributes.product_id, 10),
        price: attributes.price_cents,
        product_name: attributes.product_name,
        status: attributes.product_status,
        key: Math.random().toString(36).substring(7),
      })),
    toggle_discount: current.recurrentPlan.has_discount,
    edupay_discounts: extractDiscount(current.recurrentPlan),
    toggle_penality: current.recurrentPlan.has_penality,
    edupay_penality: extractPenality(current.recurrentPlan),
    allow_credit_card_discount:
      current?.recurrentPlan?.edupay_discounts?.data[0]?.attributes?.allowed_payment_methods?.includes(
        'credit_card'
      ),
  });

  if ((isLoadingPlan && recipients) || isLoadingProducts || isLoadingWallet)
    return <Loader />;

  return (
    <div className="CloneRecurrenPlan">
      <Loader isLoading={isLoadingPlan}>
        <RecurrentPlanForm
          steps={WIZARD_STEPS}
          form={{ ...formatAttributes() }}
          formMeta={{
            billetTax: current.attributes.billet_tax,
            pixTax: current.attributes.pix_tax,
            select_options: {
              ...selectOptions(),
              classroom_id: formatRecipients(),
              recurrent_product_id: recurrentProductIds(),
              recipientWalletOptions: recipientsWallet,
              kind: DISCOUNT_KINDS,
            },
            students: extractStudents(),
            status: 'pending',
          }}
          isSubmitting={sending}
          onSubmit={handleOnSubmit}
          backTo={`/schools/recurrent/recurrent_plans/${id}`}
          titlePage="Duplicar recorrência"
          titleModal="Descartar preenchimento"
          contentModal="A duplicação do plano será cancelada e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
        />
        <Toast />
      </Loader>
    </div>
  );
};

CloneRecurrenPlan.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default CloneRecurrenPlan;
