import {
  Box,
  FileUploader as FileUploaderComponent,
} from '@agendaedu/ae-web-components';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadFilesContext } from 'core/contexts/UploadFiles';
import { validFilesTypes } from 'core/constants/storage';
import { LIMIT_SIZE_40_MB } from 'core/contexts/UploadFiles/constants';

import { FileUpload } from 'store/messages/omniChannel/types';

import { FileItem } from '../FileItem';

const FileUploader: React.FC = (): React.ReactElement => {
  const { t } = useTranslation(['calendar']);

  const { uploadFile, destroyFile, openSelectLocalFiles, selectedFiles } =
    useContext(UploadFilesContext);

  const validateSelectedFiles: FileUpload[] = selectedFiles.reduce(
    (prev, file: FileUpload) => {
      const invalidType =
        !validFilesTypes.includes(file.type) &&
        t('common.file_errors.invalid_file');

      const invalidSize =
        file.size > LIMIT_SIZE_40_MB && t('common.file_errors.invalid_size');

      const invalidReasons = [invalidType, invalidSize].filter(Boolean);

      return [...prev, Object.assign(file, { invalidReasons })];
    },
    []
  );

  return (
    <FileUploaderComponent
      options={[
        {
          label: t('forms.event.steps.informations.file_uploader_local'),
          onClick: () => openSelectLocalFiles(validFilesTypes),
        },
      ]}
      files={
        validateSelectedFiles.length > 0 && (
          <Box display="flex" flexDirection="column" gap="xs2" mt="xs2">
            {validateSelectedFiles.map((file) => (
              <FileItem
                key={file.fileId}
                file={file}
                uploadFile={uploadFile}
                destroyFile={destroyFile}
                isRemoveDisabled={file?.isForm}
              />
            ))}
          </Box>
        )
      }
      label={t('forms.event.steps.informations.file_uploader_attachment_label')}
      tooltip={t('forms.event.steps.informations.file_uploader_tooltip')}
    />
  );
};

export default FileUploader;
