import { Box, Button } from '@agendaedu/ae-web-components';
import React from 'react';
import { Props } from './types';

export const ActionsButtons = ({
  index,
  isEmpty,
  canAddSectionCard = true,
  addSectionCard,
  removeSectionCard,
  clearSectionAction,
}: Props) => {
  return (
    <Box display="flex" gap="xs">
      {canAddSectionCard && (
        <Button
          testId="add-section"
          isOnlyIcon
          icon="plus"
          size="sm"
          variant="secondary"
          onClick={() => addSectionCard(index)}
        />
      )}

      <Button
        testId="clear-values"
        isOnlyIcon
        icon="eraser"
        size="sm"
        variant="secondary"
        disabled={isEmpty}
        onClick={() => clearSectionAction(index)}
      />

      <Button
        testId="remove-section"
        isOnlyIcon
        icon="trash-bin"
        size="sm"
        variant="secondary"
        contextVariant="danger"
        disabled={index === 0}
        onClick={() => removeSectionCard(index)}
      />
    </Box>
  );
};
