import React, { useCallback, useEffect, useRef, useState } from 'react';
import formatDatetime from 'core/utils/formatDatetime';

import withAppContext from 'core/hoc/withAppContext';

import * as S from './styles';

import { Props } from './types';
import {
  Box,
  Button,
  DefaultThemeProps,
  Icon,
  Tag,
  Text,
  TextField,
  Tooltip,
} from '@agendaedu/ae-web-components';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import AlbumActions from 'store/albums/actions';
import { AlbumDetailsMediasIncluded, RootState } from 'store/albums/types';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import ApprovePendingMediaModal from '../ApprovePendingMediaModal';
import ChangeMediaConfirmModal from '../ChangeMediaConfirmModal';
import DeleteMediaModal from '../DeleteMediaModal';
import {
  DescriptionMediaDetailsSkeleton,
  MediaDetailsSkeleton,
} from './skeleton';
import { useDownloadFile } from 'core/hooks/useDownloadFile';
import { normalizeDownloadURL } from 'core/helper/albums';

const { fetchAlbumDetailsMedias, updateMedia, setMediaCover } = AlbumActions;

export const PhotoDetailsModal = ({
  album,
  isOpen,
  onClose,
  onEditVisibility,
  activePhotoDetails,
  setActivePhotoDetails,
  appContext: { isWebView },
}: Props) => {
  const { border, colors, space } = useTheme() as DefaultThemeProps;
  const { t } = useTranslation(['albums', 'common']);
  const dispatch = useDispatch();
  const {
    albumDetails,
    albumDetailsMedias,
    isLoadingAlbumDetailsMedias,
    isUpdatingAlbumMedia,
    albumDetailsMediasPaginationLinks,
    albumDetailsMediasIncluded,
  } = useSelector((state: RootState) => state.albums);
  const loadedSrcImages = useRef<Set<string>>(new Set());
  const [showInfoForm, setShowInfoForm] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeMediaConfirmModal, setShowChangeMediaConfirmModal] =
    useState({ show: false, action: null });
  const [imageState, setImageState] = useState<'loaded' | 'error' | 'loading'>(
    'loading'
  );
  const [activePhoto, setActivePhoto] = useState(
    albumDetailsMedias[activePhotoDetails.index]
  );

  const { requestDownloadFileWithFetch, isLoadingFile } = useDownloadFile({
    getFileURL: normalizeDownloadURL(activePhoto?.attributes?.originalUrl),
    getFileName: activePhoto?.attributes.name,
  });

  const initialValuesInfo = {
    description: activePhoto?.attributes?.description || '',
  };

  const formValidationSchema = yup.object().shape({
    description: yup
      .string()
      .max(
        1500,
        t('album_details.photo_details_modal.description_message_error_text')
      ),
  });

  const onSubmitInfo = (
    values: FormikProps<typeof initialValuesInfo>['values']
  ) => {
    dispatch(
      updateMedia({
        id: activePhoto.id,
        albumId: album.id,
        description: values.description,
      })
    );
    setShowInfoForm(false);
  };

  const { isValid, values, errors, setFieldValue, submitForm } = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: onSubmitInfo,
    validationSchema: formValidationSchema,
    validateOnChange: true,
    isInitialValid: Object.values(initialValuesInfo).some(Boolean),
  });

  const userPermission = activePhoto
    ? albumDetailsMediasIncluded.find(
        (include) =>
          include.type === 'userPermission' &&
          include.id === activePhoto.relationships.userPermission.data.id
      )
    : ({} as AlbumDetailsMediasIncluded);

  const goToPrevPhoto = useCallback(() => {
    const action = () => setActivePhotoDetails(activePhotoDetails.index - 1);

    if (activePhotoDetails.index > 0 && albumDetailsMedias.length - 1) {
      if (
        !!values.description &&
        values.description !== activePhoto?.attributes.description
      ) {
        setShowChangeMediaConfirmModal({
          show: true,
          action,
        });
      } else {
        action();
      }
    }
  }, [
    activePhoto?.attributes.description,
    activePhotoDetails.index,
    albumDetailsMedias.length,
    setActivePhotoDetails,
    values.description,
  ]);

  const showPrevButton = activePhotoDetails.index > 0;

  const goToNextPhoto = useCallback(() => {
    const action = () => setActivePhotoDetails(activePhotoDetails.index + 1);

    if (activePhotoDetails.index < albumDetailsMedias.length - 1) {
      if (
        !!values.description &&
        values.description !== activePhoto?.attributes.description
      ) {
        setShowChangeMediaConfirmModal({
          show: true,
          action,
        });
      } else {
        action();
      }
    }
  }, [
    activePhoto?.attributes.description,
    activePhotoDetails.index,
    albumDetailsMedias.length,
    setActivePhotoDetails,
    values.description,
  ]);

  const showNextButton =
    activePhotoDetails.index < albumDetailsMedias.length - 1;

  const setCover = () => {
    dispatch(setMediaCover({ id: activePhoto.id, albumId: albumDetails.id }));
  };

  const fetchMoreAlbums = useCallback(() => {
    !isLoadingAlbumDetailsMedias &&
      albumDetailsMediasPaginationLinks?.next &&
      dispatch(
        fetchAlbumDetailsMedias({
          id: album.id,
          nextLink: albumDetailsMediasPaginationLinks?.next,
        })
      );
  }, [
    album.id,
    albumDetailsMediasPaginationLinks?.next,
    dispatch,
    isLoadingAlbumDetailsMedias,
  ]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const keyActions = {
        ArrowLeft: goToPrevPhoto,
        ArrowRight: goToNextPhoto,
        Escape: onClose,
      };

      const action = keyActions[e.key];

      if (action) action();
    },
    [goToNextPhoto, goToPrevPhoto, onClose]
  );

  const renderInfos = activePhoto && (
    <>
      <Box display="flex" zIndex={3}>
        {activePhoto.attributes.status === 'not_approved' && (
          <Tag
            mr="sm"
            size="small"
            variant="warning"
            name={t('album_details.photo_details_modal.pending')}
          />
        )}
        <Box position="relative">
          <Tooltip
            align="top-start"
            content={t(
              'album_details.photo_details_modal.selected_students_count_info'
            )}
            elementRef={
              <Tag
                size="small"
                variant="neutral"
                name={t(
                  'album_details.photo_details_modal.selected_students_count',
                  {
                    count: activePhoto.attributes.totalStudents,
                  }
                )}
              />
            }
          />
        </Box>
      </Box>

      <Box display="flex" mt="sm">
        <Box
          display="flex"
          size={40}
          justifyContent="center"
          alignItems="center"
          mr="sm"
          borderRadius={border.radius.md}
          backgroundColor={colors.brand.primary.op20}
        >
          <Icon name="calendar" color={colors.brand.primary.default} />
        </Box>
        <Box>
          <Text variant="subtitle-medium-14" color="neutral.gray2">
            {t('album_details.photo_details_modal.sent_at_label')}
          </Text>
          <Text variant="label-regular-16" color="neutral.black">
            {formatDatetime(
              activePhoto.attributes.createdAt,
              t('album_details.date_pattern')
            )}
          </Text>
        </Box>
      </Box>

      {isUpdatingAlbumMedia && <DescriptionMediaDetailsSkeleton />}

      {!isUpdatingAlbumMedia && !!activePhoto.attributes.description && (
        <Box my="sm">
          <Text variant="subtitle-medium-14">
            {t('album_details.photo_details_modal.description_label_text')}
          </Text>
          <Text variant="body-regular-16" color="neutral.gray1" mt="xs2">
            {activePhoto.attributes.description}
          </Text>
        </Box>
      )}

      <S.ButtonsWrapper>
        <S.ActionBtnWrapper>
          <Button
            onClick={() => setShowApproveModal(true)}
            disabled={
              !(
                activePhoto.attributes.status === 'not_approved' &&
                userPermission?.attributes.canApprove
              )
            }
          >
            {t('common:button.approve')}
          </Button>

          <Box position="relative">
            <Tooltip
              align="top-end"
              disabled={
                !activePhoto.attributes.isCover &&
                activePhoto.attributes.status === 'approved'
              }
              content={t(
                activePhoto.attributes.status === 'not_approved'
                  ? 'album_details.photo_details_modal.set_cover_info_pending'
                  : 'album_details.photo_details_modal.set_cover_info'
              )}
              elementRef={
                <Button
                  onClick={setCover}
                  disabled={
                    activePhoto.attributes.isCover ||
                    !userPermission.attributes.canSetCover
                  }
                >
                  {t('album_details.photo_details_modal.set_cover')}
                </Button>
              }
            />
          </Box>

          <S.IconBtnWrapper>
            <Button
              isOnlyIcon
              variant="secondary"
              icon="pencil"
              disabled={!userPermission?.attributes.canUpdate}
              onClick={() => setShowInfoForm(true)}
            />

            {!isWebView && (
              <Button
                isOnlyIcon
                variant="secondary"
                icon="income"
                onClick={requestDownloadFileWithFetch}
                disabled={isLoadingFile}
              />
            )}
          </S.IconBtnWrapper>
        </S.ActionBtnWrapper>

        <S.IconBtnWrapper>
          <Button
            icon="trash-bin"
            ml="auto"
            variant="secondary"
            isOnlyIcon
            isNegativeAction
            disabled={!userPermission?.attributes.canDestroy}
            onClick={() => setShowDeleteModal(true)}
          />
        </S.IconBtnWrapper>
      </S.ButtonsWrapper>
    </>
  );

  const renderInfoForm = activePhoto && (
    <>
      <Text variant="title-bold-20" color="neutral.black">
        {t('album_details.photo_details_modal.edit_infos_title')}
      </Text>
      <TextField
        data-testid="description"
        mt="md"
        fullWidth
        multiline
        rows={3}
        label={t('album_details.photo_details_modal.description_label_text')}
        onChange={(e) => setFieldValue('description', e.target.value)}
        value={values.description}
        error={!!errors.description}
        errorMessage={`${errors.description}`}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        mt="md"
      >
        <Button variant="secondary" onClick={() => setShowInfoForm(false)}>
          {t('common:button.cancel')}
        </Button>
        <Button disabled={!isValid} onClick={submitForm}>
          {t('common:button.save')}
        </Button>
      </Box>
    </>
  );

  useEffect(() => {
    if (activePhotoDetails.index >= albumDetailsMedias.length - 5)
      fetchMoreAlbums();
  }, [activePhotoDetails.index, albumDetailsMedias.length, fetchMoreAlbums]);

  useEffect(() => {
    const removeKeyboardListener = () =>
      window.removeEventListener('keydown', handleKeyPress);

    window.addEventListener('keydown', handleKeyPress);

    if (!activePhoto) removeKeyboardListener();

    return () => {
      removeKeyboardListener();
    };
  }, [activePhoto, handleKeyPress]);

  useEffect(() => {
    setActivePhoto(albumDetailsMedias[activePhotoDetails.index]);
  }, [activePhotoDetails, albumDetailsMedias]);

  useEffect(() => {
    setShowInfoForm(activePhotoDetails.isEditAction);
  }, [activePhotoDetails?.isEditAction]);

  useEffect(() => {
    setFieldValue('description', activePhoto?.attributes.description || '');
  }, [activePhoto, setFieldValue]);

  useEffect(() => {
    if (
      activePhoto &&
      !loadedSrcImages.current.has(activePhoto.attributes.originalUrl)
    )
      setImageState('loading');
  }, [activePhoto]);

  if (!activePhoto) return <></>;

  return (
    <S.Backdrop data-testid="modal-back-drop" isOpen={isOpen} onClick={onClose}>
      <S.ModalContent
        data-testid="modal-content"
        isOpen={isOpen}
        onClick={(e) => e.stopPropagation()}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          position="relative"
          overflow="hidden"
        >
          <Box
            position="absolute"
            top={0}
            right={0}
            alignItems="center"
            zIndex={2}
          >
            <Box m="md" backgroundColor="white" borderRadius={border.radius.sm}>
              <Button
                isOnlyIcon
                size="sm"
                icon="multiply"
                variant="secondary"
                onClick={onClose}
              />
            </Box>
          </Box>

          <Box
            backgroundColor="white"
            borderRadius={border.radius.sm}
            position="absolute"
            bottom={space.lg}
            left={space.lg}
            alignItems="center"
            zIndex={2}
          >
            <Button
              id="edit-visibility-media-button"
              size="sm"
              icon="user-group"
              variant="secondary"
              onClick={() => onEditVisibility(activePhoto)}
            >
              {t('album_details.photo_details_modal.who_to_visible')}
            </Button>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            minWidth={{ desktopLG: '10%' }}
          >
            <Box
              m="md"
              backgroundColor="white"
              borderRadius={border.radius.sm}
              zIndex={2}
            >
              {showPrevButton && (
                <Button
                  id="prev-media-button"
                  isOnlyIcon
                  size="sm"
                  icon="chevron-left"
                  variant="secondary"
                  onClick={goToPrevPhoto}
                />
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            position="relative"
            justifyContent="center"
            minHeight="30vh"
            maxHeight="60vh"
          >
            {imageState === 'error' && (
              <Text variant="body-bold-14" my="xl5">
                {t('album_details.photo_details_modal.load_media_error')}
              </Text>
            )}
            {imageState !== 'error' && (
              <S.Image
                loading="lazy"
                {...(imageState !== 'loaded' && { style: { width: '0px' } })}
                src={activePhoto.attributes.originalUrl}
                alt={activePhoto.attributes.description}
                onLoad={() => {
                  setImageState('loaded');
                  loadedSrcImages.current.add(
                    activePhoto.attributes.originalUrl
                  );
                }}
                onError={() => setImageState('error')}
              />
            )}
            {imageState === 'loading' && <MediaDetailsSkeleton />}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            minWidth={{ desktopLG: '10%' }}
          >
            <Box
              m="md"
              backgroundColor="white"
              borderRadius={border.radius.sm}
              zIndex={2}
            >
              {showNextButton && (
                <Button
                  id="next-media-button"
                  isOnlyIcon
                  size="sm"
                  icon="chevron-right"
                  variant="secondary"
                  onClick={goToNextPhoto}
                />
              )}
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          overflowY="scroll"
          flexDirection="column"
          backgroundColor="white"
        >
          <Box p={{ desktopLG: 'xl', _: 'sm' }}>
            {showInfoForm ? renderInfoForm : renderInfos}
          </Box>
        </Box>
      </S.ModalContent>

      <ApprovePendingMediaModal
        media={activePhoto}
        isOpen={showApproveModal}
        onClose={() => setShowApproveModal(false)}
      />
      <DeleteMediaModal
        media={activePhoto}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
      <ChangeMediaConfirmModal
        isOpen={showChangeMediaConfirmModal.show}
        onConfirm={() => showChangeMediaConfirmModal.action?.()}
        onClose={() =>
          setShowChangeMediaConfirmModal({ show: false, action: null })
        }
      />
    </S.Backdrop>
  );
};

export default withAppContext(PhotoDetailsModal);
