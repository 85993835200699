import {
  Box,
  Skeleton as SkeletonComponent,
} from '@agendaedu/ae-web-components';
import React from 'react';

const Skeleton = () => {
  return (
    <Box
      data-testid="skeleton-loading"
      display="flex"
      padding={{ _: 'xl', desktopLG: 'xl2' }}
      flexDirection="column"
    >
      <Box display="flex" flexDirection="row" gap="sm">
        <SkeletonComponent
          height={32}
          width={77}
          primaryColor="neutral.gray4"
        />
        <SkeletonComponent
          height={32}
          width={116}
          primaryColor="neutral.gray4"
        />
        <SkeletonComponent
          height={32}
          width={147}
          primaryColor="neutral.gray4"
        />
      </Box>

      <Box display="flex" mt="xl">
        <SkeletonComponent
          height={20}
          width={77}
          primaryColor="neutral.gray4"
          borderRadius={6}
        />
      </Box>

      <Box
        display="flex"
        mt="md"
        gap="xl"
        flexDirection={{ TABLETMD: 'column', desktopLG: 'row' }}
      >
        <Box
          display="flex"
          flexDirection={{
            TABLETMD: 'column',
            desktopXL: 'row',
            desktopLG: 'column',
          }}
          gap="xs"
        >
          <Box display="flex" flexDirection="row" gap="xs">
            <SkeletonComponent
              height={40}
              width={40}
              primaryColor="neutral.gray4"
              borderRadius={6}
            />

            <Box display="flex" gap="sm" flexDirection="column">
              <SkeletonComponent
                height={10}
                width={116}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
              <SkeletonComponent
                height={10}
                width={116}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" gap="xs">
            <SkeletonComponent
              height={40}
              width={40}
              primaryColor="neutral.gray4"
              borderRadius={6}
            />

            <Box display="flex" gap="sm" flexDirection="column">
              <SkeletonComponent
                height={10}
                width={116}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
              <SkeletonComponent
                height={10}
                width={116}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={{
            TABLETMD: 'column',
            desktopXL: 'row',
            desktopLG: 'column',
          }}
          gap="xs"
        >
          <Box display="flex" flexDirection="row" gap="xs">
            <SkeletonComponent
              height={40}
              width={40}
              primaryColor="neutral.gray4"
              borderRadius={6}
            />

            <Box display="flex" gap="sm" flexDirection="column">
              <SkeletonComponent
                height={10}
                width={116}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
              <SkeletonComponent
                height={10}
                width={116}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" gap="xs">
            <SkeletonComponent
              height={40}
              width={40}
              primaryColor="neutral.gray4"
              borderRadius={6}
            />

            <Box display="flex" gap="sm" flexDirection="column">
              <SkeletonComponent
                height={10}
                width={116}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
              <SkeletonComponent
                height={10}
                width={116}
                primaryColor="neutral.gray4"
                borderRadius={6}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <SkeletonComponent
        height={88}
        width="100%"
        mt="xl"
        primaryColor="neutral.gray4"
      />

      <SkeletonComponent
        height={1}
        width="100%"
        mt="xl"
        primaryColor="neutral.gray4"
      />

      <Box
        display="flex"
        mt="xs"
        justifyContent="space-between"
        gap="md"
        flexDirection={{
          TABLETMD: 'column',
          desktopLG: 'row',
        }}
      >
        <SkeletonComponent
          height={32}
          width={200}
          primaryColor="neutral.gray4"
        />

        <Box display="flex" mr={{ _: 'xs4', desktopLG: 'xl2' }}>
          <SkeletonComponent
            height={32}
            width={125}
            primaryColor="neutral.gray4"
          />
        </Box>
      </Box>

      <SkeletonComponent
        height={1}
        width="100%"
        mt="sm"
        primaryColor="neutral.gray4"
      />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt="xl"
      >
        <Box display="flex" flexDirection="row" gap="xs">
          <SkeletonComponent
            height={40}
            width={100}
            primaryColor="neutral.gray4"
          />

          <SkeletonComponent
            height={40}
            width={40}
            primaryColor="neutral.gray4"
          />
        </Box>

        <SkeletonComponent
          height={40}
          width={40}
          primaryColor="neutral.gray4"
        />
      </Box>
    </Box>
  );
};

export default Skeleton;
