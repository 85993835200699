import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export type LinkWrapperProps = {
  theme: ThemeProps;
  copySuccess: boolean;
};

export const ModalWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    max-width: 364px;
    padding: 0 ${space.xs3};
  `}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResponsibleWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    margin: 28px 0 ${space.xl};
  `}
`;

export const LinkWrapper = styled.div<LinkWrapperProps>`
  ${({ theme: { space, colors, border }, copySuccess }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${space.lg};
    border: ${border.width.sm} ${border.style.solid}
      ${copySuccess ? colors.context.success.default : colors.neutral.gray5};
    border-radius: ${border.radius.md};
    background-color: ${copySuccess
      ? colors.context.success.op10
      : colors.neutral.gray5};
  `}
`;

export const Link = styled.input`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    display: inline-block;
    width: 100%;
    margin-right: ${space.xs3};
    background-color: transparent;
    border: none;

    &::selection {
      background: transparent;
      color: ${colors.context.success.default};
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-top: ${space.xl};
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `}
`;
