import * as Sentry from '@sentry/browser';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { deleteApi, fetchApi, patchApi, postApi, putApi } from 'core/utils/api';
import i18n from 'config/i18n';

import {
  ChangeVisibilitySagaProps,
  SaveSectionSagaProps,
  FetchDiarySectionsFormProps,
  DeleteDiarySectionProps,
  DuplicateDiarySectionProps,
  FetchActivesDiarySectionsProps,
  SaveDiarySagaParams,
  FetchStudentDiaryFormProps,
  FetchDiaryReportStudentsProps,
  DiaryReportProps,
  FetchStudentsDiariesFormProps,
  SaveDiariesPerSectionsSagaParams,
} from './types';
import actions from './actions';

function* fetchClassroom(action) {
  try {
    const { id, date } = action;
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, included, meta } = yield call(
      fetchApi,
      `/${dataArea}/daily_summaries/classrooms/${id}.json?date=${date}`
    );

    yield put({
      type: actions.FETCH_CLASSROOM_DETAILS_SUCCESS,
      classroom: data,
      classroomIncluded: included,
      occurrences: meta.occurrence_categories || [],
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de unidades.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchStudentSummary(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { student, classroom, date } = action;

    const getDate = () => date || new Date();

    const config = {
      params: {
        date: getDate().format('YYYY-MM-DD'),
        student_profile_id: student,
      },
    };

    const { data, included, meta } = yield call(
      fetchApi,
      `/${dataArea}/daily_summaries/classrooms/${classroom}/details.json?include=answers,versions`,
      config
    );

    yield put({
      type: actions.FETCH_STUDENT_SUMMARY_SUCCESS,
      summary: data.length ? data[0] : data,
      student,
      meta,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de turmas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchClassroomsListRequest(action) {
  try {
    const { name: filtersName, date: filtersDate, page } = action.filters;

    const dataArea = yield select((state) => state.root.dataArea);
    const dailySummariesStore = yield select((state) => state.dailySummaries);

    const {
      date: selectedDate,
      selectedName,
      selectedHeadquarter,
      selectedEducationalStage,
    } = dailySummariesStore;

    const getSearchName =
      filtersName !== undefined ? filtersName : selectedName;

    const getDate = filtersDate || selectedDate;

    const config = {
      params: {
        name: getSearchName,
        date: getDate.format('YYYY-MM-DD'),
        headquarter: selectedHeadquarter,
        educational_stage: selectedEducationalStage,
        page,
      },
    };

    const { data, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/daily_summaries.json`,
      config
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_LIST_SUCCESS,
      data,
      pagination: {
        totalItemsCount,
        currentPage: page || 1,
        itemsCountPerPage,
      },
      filters: config.params,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de turmas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* addDailySummary(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { classroom_id, date } = action.params;

    yield call(
      postApi,
      `/${dataArea}/daily_summaries/classrooms/${classroom_id}/upsert`,
      action.params
    );
    yield put({
      type: actions.ADD_DAILY_SUMMARY_SUCCESS,
      toast: buildToast(
        'Diário enviado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
    yield call(fetchClassroom, { id: classroom_id, date });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao enviar diário. Por favor, tente novamente.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });

    console.info(JSON.stringify(action.params));
    Sentry.captureException(error);
  }
}

function* fetchDailySummaryDetails(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { classroomId, id } = action;

    const date = new Date().toISOString().substring(0, 10);

    const config = {
      params: {
        date,
        student_profile_id: id,
      },
    };

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/daily_summaries/classrooms/${classroomId}/fill`,
      config
    );

    yield put({
      type: actions.FETCH_DAILY_SUMMARY_DETAILS_SUCCESS,
      data: data.length ? data[0] : null,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter detalhes do Diário.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchHeadquartersRequest() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/with_active_school_term`
    );

    yield put({
      type: actions.FETCH_HEADQUARTERS_SUCCESS,
      headquarters: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de unidades.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchEducationalStagesRequest(action) {
  try {
    const { headquarter } = action;

    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarter}/educational_stages.json`
    );

    yield put({
      type: actions.FETCH_EDUCATIONAL_STAGES_SUCCESS,
      educationalStages: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de unidades.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchSettings() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/diary_sections.json`
    );

    yield put({
      type: actions.FETCH_SETTINGS_SUCCESS,
      headquarters: meta.classrooms,
      sections: data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* saveSettings(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    yield call(
      postApi,
      `/${dataArea}/diary_sections/manage_sections`,
      action.data
    );

    yield call(fetchSettings);

    yield put({
      type: actions.SAVE_SETTINGS_SUCCESS,
      toast: buildToast(
        'Configurações salvas com sucesso!',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao salvar as configurações. Por favor, tente novamente.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchDiarySections() {
  try {
    const sectionsFields = [
      'name',
      'slug',
      'custom',
      'enable',
      'classrooms',
      'user_permission',
    ];

    const { data, included } = yield call(
      fetchApi,
      `/schools/diary_sections.json?fields[diary_section]=${sectionsFields}&include=classrooms,userPermission`
    );

    yield put({
      type: actions.FETCH_DIARY_SECTIONS_SUCCESS,
      sections: data,
      sectionsIncluded: included,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_DIARY_SECTIONS_ERROR,
      error,
      toast: buildToast(
        i18n.t('diary_sections:sections.request_sections_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* changeVisibility(action: ChangeVisibilitySagaProps) {
  try {
    const { id, enable } = action;

    yield call(
      patchApi,
      `/schools/diary_sections/${id}/change_visibility.json?`,
      { enable }
    );
  } catch (error) {
    action.onFail();
    Sentry.captureException(error);
  }
}

function* fetchRecipientsClassroomsSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.FETCH_RECIPIENTS_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* saveSectionSaga(action: SaveSectionSagaProps) {
  const { diarySection, diarySectionId, onSuccess } = action;

  const requestType = diarySectionId ? 'edit' : 'new';

  try {
    if (diarySectionId) {
      yield call(putApi, `/schools/diary_sections/${diarySectionId}.json?`, {
        diary_section: diarySection,
      });
    } else {
      yield call(postApi, `/schools/diary_sections.json?`, {
        diary_section: diarySection,
      });
    }

    yield call(onSuccess);

    yield put({
      type: actions.SAVE_SECTION_REQUEST_SUCCESS,
      toast: buildToast(
        i18n.t(`diary_sections:sections.form.${requestType}_section_success`),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_SECTION_REQUEST_ERROR,
      error,
      toast: buildToast(
        i18n.t(`diary_sections:sections.form.${requestType}_section_error`),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
    Sentry.captureException(error);
  }
}

function* fetchDiarySectionFormSaga(action: FetchDiarySectionsFormProps) {
  try {
    const { data, included } = yield call(
      fetchApi,
      `/schools/diary_sections/${action.diarySectionId}.json?include=classrooms,questions`
    );

    yield put({
      type: actions.FETCH_DIARY_SECTION_FORM_REQUEST_SUCCESS,
      data,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_DIARY_SECTION_FORM_REQUEST_ERROR,
      error,
      toast: buildToast(
        i18n.t('diary_sections:sections.request_show_section_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* deleteDiarySectionSaga(action: DeleteDiarySectionProps) {
  try {
    yield call(
      deleteApi,
      `/schools/diary_sections/${action.diarySectionId}.json`
    );

    yield put({
      type: actions.DELETE_DIARY_SECTION_SUCCESS,
      diarySectionId: action.diarySectionId,
      toast: buildToast(
        i18n.t('diary_sections:sections.delete_section_modal.delete_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_DIARY_SECTION_ERROR,
      error,
      toast: buildToast(
        i18n.t('diary_sections:sections.delete_section_modal.delete_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* duplicateDiarySectionSaga(action: DuplicateDiarySectionProps) {
  try {
    yield call(
      postApi,
      `/schools/diary_sections/${action.diarySectionId}/duplicate.json`
    );

    yield put({
      type: actions.DUPLICATE_DIARY_SECTION_SUCCESS,
      diarySectionId: action.diarySectionId,
      toast: buildToast(
        i18n.t('diary_sections:sections.duplicate_section_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({ type: actions.FETCH_DIARY_SECTIONS });
  } catch (error) {
    yield put({
      type: actions.DUPLICATE_DIARY_SECTION_ERROR,
      error,
      toast: buildToast(
        i18n.t('diary_sections:sections.duplicate_section_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchActivesDiarySections(action: FetchActivesDiarySectionsProps) {
  try {
    const { classroomId } = action;
    const sectionsFields = ['name', 'slug', 'custom', 'enable', 'questions'];

    const { data, included } = yield call(
      fetchApi,
      `/schools/diary_sections.json?fields[diary_section]=${sectionsFields}&filter[situation]=active&filter[classroomIds][]=${classroomId}&include=questions`
    );

    yield put({
      type: actions.FETCH_ACTIVES_DIARY_SECTIONS_SUCCESS,
      data,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ACTIVES_DIARY_SECTIONS_ERROR,
      error,
      toast: buildToast(
        i18n.t('diary_sections:sections.request_sections_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* saveDiary(action: SaveDiarySagaParams) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { classroom_id, date } = action.params;

    yield call(
      postApi,
      `/${dataArea}/daily_summaries/classrooms/${classroom_id}/upsert`,
      action.params
    );
    yield put({
      type: actions.SAVE_DIARY_SUCCESS,
      toast: buildToast(
        i18n.t('diary:form.save_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    action.onSuccess();

    yield call(fetchClassroom, { id: classroom_id, date });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('diary:form.save_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });

    action.onFail();

    console.info('V3', JSON.stringify(action.params));
    Sentry.captureException(error);
  }
}

function* fetchStudentDiaryForm(action: FetchStudentDiaryFormProps) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const {
      params: { studentProfileId, classroomId, date },
    } = action;

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/daily_summaries/classrooms/${classroomId}/fill.json?include=answers`,
      { params: { student_profile_id: studentProfileId, classroomId, date } }
    );

    yield put({
      type: actions.FETCH_STUDENT_DIARY_FORM_SUCCESS,
      data,
      included,
    });
  } catch (error) {
    action.onFail();

    yield put({
      type: actions.FETCH_STUDENT_DIARY_FORM_ERROR,
      error,
      toast: buildToast(
        i18n.t('diary:form.fetch_student_diary_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchDiaryReportInitialRecipientsSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: headquarters } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/with_active_school_term.json`
    );

    const { history: classrooms } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.FETCH_DIARY_REPORT_INITIAL_RECIPIENTS_SUCCESS,
      headquarters,
      classrooms,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* fetchDiaryReportStudentsSaga({
  classroomId,
}: FetchDiaryReportStudentsProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: students } = yield call(
      fetchApi,
      `/${dataArea}/classrooms/${classroomId}/student_profiles.json?with_responsibles=false`
    );

    yield put({
      type: actions.FETCH_DIARY_REPORT_STUDENTS_SUCCESS,
      students,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* fetchDiaryReportSections() {
  try {
    const { data: sections } = yield call(
      fetchApi,
      `/schools/diary_sections.json?filter[deleted]=true`
    );

    yield put({
      type: actions.FETCH_DIARY_REPORT_SECTIONS_SUCCESS,
      sections,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* exportDiaryReportSaga({ params }: DiaryReportProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const normalizedParams = Object.entries(params).reduce(
      (prev, [key, value]) => {
        if (Array.isArray(value)) {
          return value.some(Boolean)
            ? prev.concat(value.map((id) => `&${key}[]=${id}`).join())
            : prev;
        }

        return value ? prev.concat(`&${key}=${value}`) : prev;
      },
      ''
    );

    const url = `/${dataArea}/daily_summaries/report.xls?${normalizedParams}`;

    yield call(window.open, url, '_blank');

    yield put({
      type: actions.EXPORT_DIARY_REPORT_SUCCESS,
      toast: buildToast(
        i18n.t('diary:report.export_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('diary:report.export_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* requestHideDiaryInfoSaga() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    yield call(postApi, `/${dataArea}/daily_summaries/disable_modal`);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('diary:form.info_confirm_modal.confirm_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchStudentsDiariesFormSaga(action: FetchStudentsDiariesFormProps) {
  try {
    const { classroomId, date } = action;
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/daily_summaries/classrooms/${classroomId}/fill_by_sections.json?include=answers`,
      { params: { date } }
    );

    yield put({
      type: actions.FETCH_STUDENTS_DIARIES_FORM_SUCCESS,
      data,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_STUDENTS_DIARIES_FORM_ERROR,
      error,
      toast: buildToast(
        i18n.t('diary:form.fetch_students_diaries_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* saveDiariesPerSectionsSaga(action: SaveDiariesPerSectionsSagaParams) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { classroom_id, date } = action.params;

    yield call(
      postApi,
      `/${dataArea}/daily_summaries/classrooms/${classroom_id}/upsert_by_sections`,
      action.params
    );
    yield put({
      type: actions.SAVE_DIARIES_PER_SECTION_SUCCESS,
      toast: buildToast(
        i18n.t('diary:form.save_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    action.onSuccess();

    yield call(fetchStudentsDiariesFormSaga, {
      classroomId: classroom_id,
      date,
    } as FetchStudentsDiariesFormProps);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('diary:form.save_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });

    action.onFail();

    console.info('V3_per_sections', JSON.stringify(action.params));
    Sentry.captureException(error);
  }
}

function* dailySummaries() {
  yield all([
    takeLatest(actions.FETCH_CLASSROOMS_LIST, fetchClassroomsListRequest),
    takeLatest(actions.FETCH_CLASSROOM_DETAILS, fetchClassroom),
    takeLatest(actions.FETCH_HEADQUARTERS, fetchHeadquartersRequest),
    takeLatest(actions.FETCH_EDUCATIONAL_STAGES, fetchEducationalStagesRequest),
    takeLatest(actions.ADD_DAILY_SUMMARY_REQUEST, addDailySummary),
    takeLatest(actions.FETCH_STUDENT_SUMMARY_REQUEST, fetchStudentSummary),
    takeLatest(
      actions.FETCH_DAILY_SUMMARY_DETAILS_REQUEST,
      fetchDailySummaryDetails
    ),
    takeLatest(actions.FETCH_SETTINGS_REQUEST, fetchSettings),
    takeLatest(actions.SAVE_SETTINGS_REQUEST, saveSettings),
    takeLatest(actions.FETCH_DIARY_SECTIONS, fetchDiarySections),
    takeLatest(actions.CHANGE_SECTION_VISIBILITY, changeVisibility),
    takeLatest(
      actions.FETCH_RECIPIENTS_CLASSROOMS,
      fetchRecipientsClassroomsSaga
    ),
    takeLatest(actions.SAVE_SECTION_REQUEST, saveSectionSaga),
    takeLatest(
      actions.FETCH_DIARY_SECTION_FORM_REQUEST,
      fetchDiarySectionFormSaga
    ),
    takeLatest(actions.DELETE_DIARY_SECTION_REQUEST, deleteDiarySectionSaga),
    takeLatest(
      actions.DUPLICATE_DIARY_SECTION_REQUEST,
      duplicateDiarySectionSaga
    ),
    takeLatest(
      actions.FETCH_ACTIVES_DIARY_SECTIONS_REQUEST,
      fetchActivesDiarySections
    ),
    takeLatest(actions.SAVE_DIARY_REQUEST, saveDiary),
    takeLatest(
      actions.SAVE_DIARIES_PER_SECTION_REQUEST,
      saveDiariesPerSectionsSaga
    ),
    takeLatest(actions.FETCH_STUDENT_DIARY_FORM, fetchStudentDiaryForm),
    takeLatest(
      actions.FETCH_DIARY_REPORT_INITIAL_RECIPIENTS,
      fetchDiaryReportInitialRecipientsSaga
    ),
    takeLatest(
      actions.FETCH_DIARY_REPORT_STUDENTS,
      fetchDiaryReportStudentsSaga
    ),
    takeLatest(actions.FETCH_DIARY_REPORT_SECTIONS, fetchDiaryReportSections),
    takeLatest(actions.EXPORT_DIARY_REPORT_REQUEST, exportDiaryReportSaga),
    takeLatest(actions.HIDE_DIARY_INFO_MODAL_REQUEST, requestHideDiaryInfoSaga),
    takeLatest(
      actions.FETCH_STUDENTS_DIARIES_FORM,
      fetchStudentsDiariesFormSaga
    ),
  ]);
}

export default dailySummaries;
