import React from 'react';
import FormContainer from 'components/Form/FormContainer';
import FormEdit from './component/FormEdit';

const EditDynamicModules = () => {
  return (
    <FormContainer variation="vertical" form={{}}>
      <FormEdit />
    </FormContainer>
  );
};

export default EditDynamicModules;
