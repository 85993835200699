import React, { useReducer } from 'react';
import { Box, Radio, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';
import * as S from './styles';

import useWindowDimensions from 'core/hooks/useWindowDimensions';

export const SelectQuestion = ({
  question,
  onChange,
  value,
}: Props): React.ReactElement => {
  const [_selectedOptionIdValue, _setSelectedOptionIdValue] = useReducer(
    (_, newValue: string) => {
      onChange({
        type: 'select',
        value: newValue,
        questionId: question.id,
      });

      return newValue;
    },
    value
  );

  const selectedOptionIdValue = value || _selectedOptionIdValue;

  const { screenWidth } = useWindowDimensions();

  const isSingleLongString = (text: string) =>
    text.length > 46 && !text.includes(' ');

  return (
    <Box display="flex" flexDirection="column" gap="md">
      <Text variant="label-regular-14" color="neutral.gray1" marginBottom="0">
        {question.title}
      </Text>

      <Box display="flex" flexDirection="column" gap="md">
        {question.answerOptionsAttributes.map((option, index) => (
          <S.QuestionWrapper key={`${question.id}-option-${index}`}>
            <Radio
              onChange={() => _setSelectedOptionIdValue(String(option.id))}
              checked={selectedOptionIdValue === String(option.id)}
            >
              <S.StyledText
                variant="label-regular-16"
                color="neutral.black"
                marginBottom="0"
                enableEllipsis={isSingleLongString(option.title)}
                widthDimensions={`${screenWidth - 250}px`}
              >
                {option.title}
              </S.StyledText>
            </Radio>
          </S.QuestionWrapper>
        ))}
      </Box>
    </Box>
  );
};
