const actions = {
  ERROR: 'ERROR/dynamicModules',
  IS_LOADING_UPDATE: 'IS_LOADING_UPDATE/dynamicModules',
  FETCH_DYNAMIC_MODULES: 'FETCH_DYNAMIC_MODULES/dynamicModules',
  FETCH_DYNAMIC_MODULES_SUCCESS: 'FETCH_DYNAMIC_MODULES_SUCCESS/dynamicModules',
  FETCH_CLASSROOMS_MODULES: 'FETCH_CLASSROOMS_MODULES/dynamicModules',
  FETCH_CLASSROOMS_MODULES_SUCCESS:
    'FETCH_CLASSROOMS_MODULES_SUCCESS/dynamicModules',
  UPDATE_DYNAMIC_MODULES: 'UPDATE_DYNAMIC_MODULES/dynamicModules',
  UPDATE_DYNAMIC_MODULES_SUCCESS:
    'UPDATE_DYNAMIC_MODULES_SUCCESS/dynamicModules',

  BUILD_TOAST: 'BUILD_TOAST/dynamicModules',
  BUILD_TOAST_SUCCESS: 'BUILD_TOAST_SUCCESS/dynamicModules',

  buildToast: (message, typeToast) => ({
    type: actions.BUILD_TOAST,
    message,
    typeToast,
  }),

  fetchDynamicModules: () => ({
    type: actions.FETCH_DYNAMIC_MODULES,
  }),

  fetchClassroomsModules: (id) => ({
    type: actions.FETCH_CLASSROOMS_MODULES,
    id: id,
  }),

  updateDynamicModules: (id, classroomIds, addClassroomAutomatically) => ({
    type: actions.UPDATE_DYNAMIC_MODULES,
    id,
    classroomIds,
    addClassroomAutomatically,
  }),
};

export default actions;
