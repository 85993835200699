import styled, { css } from 'styled-components';
import { StyleProps } from './types';

export const Wrapper = styled.div<StyleProps>`
  ${({ theme: { colors, border }, isSelected }) => css`
    .accordion__panel {
      border-width: 0px !important;
      border-radius: 0px !important;
      padding: 0px !important;
    }

    .accordion__button {
      background-color: ${isSelected
        ? colors.brand.primary.op10
        : 'transparent'} !important;
      border-radius: 0px !important;
      border-color: ${colors.neutral.gray4} !important;
      border-width: 1px 0px 0px 0px !important;
      border-bottom: ${border.style.solid} ${border.width.sm}
        ${colors.neutral.gray4} !important;
      border-top: 0px !important;

      ::before {
        color: ${isSelected
          ? colors.brand.primary.default
          : colors.neutral.gray1} !important;
        width: 12px !important;
        height: 12px !important;
      }
      border: none;
    }

    label {
      margin-top: 0px !important;
    }
  `}
`;
