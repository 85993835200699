import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    border-radius: ${border.radius.md};
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
    padding: ${space.sm} ${space.xs4};
  `}
`;

export const TabsWrapper = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
  `}
`;
