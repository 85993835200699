import React, { useCallback, useEffect } from 'react';
import { Text } from '@agendaedu/ae-web-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import OmniChannelActions from 'store/messages/omniChannel/actions';

import * as S from './styles';

import { EmptyState } from 'components/RecipientSelectList/emptyState';
import { StepLoading } from 'components/RecipientSelectList/Skeleton/StepLoading';
import { Section } from './Section';

import {
  HeadquarterRecipient,
  OmniChannelState,
} from 'store/messages/omniChannel/types';

export const Classrooms: React.FC = (): JSX.Element => {
  const { fetchNewChatClassroomsRequest } = OmniChannelActions;
  const dispatch = useDispatch();

  const {
    chat: {
      form: {
        channel,
        filters,
        classrooms: {
          data: classroomsData,
          isLoading: isLoadingTab,
          pagination: { page, totalPages },
        },
      },
    },
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const handleFetchChatClassroomsRequest = useCallback(
    (currentPage: number) => {
      dispatch(fetchNewChatClassroomsRequest(channel.id, currentPage));
    },
    [channel?.id, dispatch, fetchNewChatClassroomsRequest]
  );

  useEffect(() => {
    channel?.id && handleFetchChatClassroomsRequest(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.name]);
  return (
    <S.Wrapper id="scroll">
      {!classroomsData?.length && !isLoadingTab && (
        <EmptyState tabName="classroom" hasFilter={!!filters?.name} />
      )}
      <InfiniteScroll
        dataLength={classroomsData?.length || 0}
        next={() => handleFetchChatClassroomsRequest(page + 1)}
        hasMore={page < totalPages}
        loader={<StepLoading count={1} />}
        scrollableTarget="scroll"
      >
        {isLoadingTab ? (
          <StepLoading count={4} />
        ) : (
          classroomsData?.map((headquarter: HeadquarterRecipient, index) => (
            <div data-testid="headquarter" key={index}>
              <S.HeadquarterWrapper key={headquarter.id}>
                <Text
                  mb={0}
                  paddingX="sm"
                  paddingY="lg"
                  color="neutral.gray3"
                  variant="title-bold-12"
                >
                  {headquarter.name}
                </Text>
              </S.HeadquarterWrapper>

              {headquarter.classrooms.map((classroom, index) => (
                <Section
                  key={index}
                  recipient={classroom}
                  data={classroom.students}
                  classroomId={classroom.id}
                  kind={channel?.attributes.kind}
                />
              ))}
            </div>
          ))
        )}
      </InfiniteScroll>
    </S.Wrapper>
  );
};
