import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import {
  Box,
  Button,
  Grid,
  Select,
  TextField,
  RangeDate,
} from '@agendaedu/ae-web-components';

import { HandoutSideFilters } from 'components/Handouts/Filters/HandoutSideFilters';
import Tooltip from 'components/Tooltip';
import Toast from 'components/Toast';

import { formatRangeDate } from 'core/utils/date';
import { handoutsGrid } from 'core/constants/handouts';
import { HANDOUTS_STATUS } from 'core/constants';
import withAppContext from 'core/hoc/withAppContext';

import actionFilters from 'store/filters/actions';
import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import { HandoutsFiltersSkeleton } from './skeleton';

import { HandoutsFiltersProps } from './types';

import * as S from './styles';

const HandoutsFilters = ({
  history,
  handleSubmitFilters,
  appContext: {
    dataArea,
    policies: {
      can_create_handout: canCreateHandout,
      can_write_handout_category: canWriteHandoutCategory,
      can_mass_resend_handout: canMassResendHandout,
      can_show_handout_template: canShowHandoutTemplate,
    },
  },
}: HandoutsFiltersProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['handouts', 'common']);

  const [startRangeDate, setStartRangeDate] = useState<Moment>(null);
  const [endRangeDate, setEndRangeDate] = useState<Moment>(null);

  const { filters, isInitialLoading } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const { fetchHeadquartersRequest, fetchActiveSchoolTermsRequest } =
    actionFilters;

  const {
    resendMassHandoutsRequest,
    fetchCategoriesRequest,
    toggleNewHandoutModal,
  } = actionHandouts;

  const handleRangeDateChange = ({ startDate, endDate }) => {
    setStartRangeDate(startDate);
    setEndRangeDate(endDate);
  };

  const debouncedFetchHandouts = _.debounce((value: string) => {
    handleSubmitFilters({ ...filters, title: value });
  }, 500);

  const handleTitleChange = ({ target }) =>
    debouncedFetchHandouts(target.value);

  const isMinimalMobileDevice = (): boolean => {
    return window.innerWidth < 380;
  };

  const handleResendMassHandouts = useCallback(() => {
    dispatch(resendMassHandoutsRequest());
  }, [dispatch, resendMassHandoutsRequest]);

  const handleExtraActions = () => [
    {
      as: 'button',
      title: t('actions.mass_resend_button_text'),
      onClick: handleResendMassHandouts,
    },
  ];

  const handleExtraSettingsActions = () =>
    [
      canWriteHandoutCategory && {
        id: 'handout-category',
        as: 'button',
        title: t('actions.category_button_text'),
        onClick: () => history.push(`/${dataArea}/handout_categories`),
      },
      canShowHandoutTemplate && {
        id: 'handout-template',
        as: 'button',
        title: t('actions.template_button_text'),
        onClick: () => history.push(`/${dataArea}/handouts/templates`),
      },
    ].filter(Boolean);

  const handleTooltipText = () => {
    if (canWriteHandoutCategory && canShowHandoutTemplate)
      return t('filters.settings_tooltip_text');
    if (canWriteHandoutCategory) return t('filters.category_tooltip_text');
    return t('filters.template_tootlip_text');
  };

  const handleSetHeadquarters = useCallback(() => {
    dispatch(fetchHeadquartersRequest());
  }, [dispatch, fetchHeadquartersRequest]);

  const handleSetCategories = useCallback(() => {
    dispatch(fetchCategoriesRequest());
  }, [dispatch, fetchCategoriesRequest]);

  const handleSetActiveSchoolTerm = useCallback(() => {
    dispatch(fetchActiveSchoolTermsRequest());
  }, [dispatch, fetchActiveSchoolTermsRequest]);

  useEffect(() => {
    handleSetHeadquarters();
    handleSetCategories();
    handleSetActiveSchoolTerm();
  }, []);

  const handleToggleNewHandoutModal = useCallback(() => {
    dispatch(toggleNewHandoutModal());
  }, [dispatch, toggleNewHandoutModal]);

  useEffect(() => {
    if (isInitialLoading) return;

    if (Boolean(startRangeDate) === Boolean(endRangeDate)) {
      handleSubmitFilters({
        ...filters,
        startDate: formatRangeDate(startRangeDate),
        endDate: formatRangeDate(endRangeDate),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmitFilters, endRangeDate, startRangeDate]);

  if (isInitialLoading)
    return (
      <S.HandoutFiltersWrapper>
        <HandoutsFiltersSkeleton />
      </S.HandoutFiltersWrapper>
    );

  return (
    <S.HandoutFiltersWrapper>
      <Toast />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <S.HandoutTitle>{t('screen_title_text')}</S.HandoutTitle>
        {canCreateHandout && (
          <S.NewHandoutButtonWrapper>
            <Button
              id="new-handout"
              data-testid="new-handout"
              size={isMinimalMobileDevice() ? 'sm' : 'md'}
              onClick={handleToggleNewHandoutModal}
            >
              {t('create_handout_button_text')}
            </Button>
          </S.NewHandoutButtonWrapper>
        )}
      </Box>

      <Grid
        mb="xl"
        gutter="md"
        gridTemplateAreas={handoutsGrid.filtersTemplateAreas}
        gridTemplateColumns={handoutsGrid.filtersTemplateColumns}
      >
        <Grid item gridArea="dates">
          <RangeDate
            id="date-range"
            value={{
              startDate: startRangeDate,
              endDate: endRangeDate,
            }}
            handleChange={handleRangeDateChange}
            handleOutsideRange={(day: Moment) => day.isAfter(moment(), 'day')}
          />
        </Grid>

        <Grid item gridArea="situation">
          <Select
            placeholder={t('filters.situation_field_default_value_text')}
            value={filters.situation || null}
            options={HANDOUTS_STATUS}
            onChange={({ value }) => {
              handleSubmitFilters({ ...filters, situation: value });
            }}
            fullWidth
          />
        </Grid>

        <Grid item gridArea="title">
          <TextField
            title={filters.title}
            placeholder={t('filters.search_field_placeholder_text')}
            icon="search"
            onChange={handleTitleChange}
            fullWidth
          />
        </Grid>

        <Grid item gridArea="buttons">
          <Box display="flex" justifyContent="flex-end">
            {(canWriteHandoutCategory || canShowHandoutTemplate) && (
              <Tooltip
                on="hover"
                position="left center"
                tooltipStyle={S.SettingsTooltip}
                content={handleTooltipText()}
              >
                <S.WrapperSettingsButton>
                  <S.ExtraActionsButton
                    actions={handleExtraSettingsActions()}
                    align="right"
                    icon="cog"
                    data-testid="settings-actions-button"
                  />
                </S.WrapperSettingsButton>
              </Tooltip>
            )}

            {canMassResendHandout && (
              <Box mr="sm" data-testid="resend-handout">
                <S.ExtraActionsButton
                  actions={handleExtraActions()}
                  align="right"
                />
              </Box>
            )}

            <HandoutSideFilters />
          </Box>
        </Grid>
      </Grid>
    </S.HandoutFiltersWrapper>
  );
};

export default withRouter(withAppContext(HandoutsFilters));
