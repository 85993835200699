export const IMAGES_URLS = {
  emptyStateProgressUrl:
    'https://static.agendaedu.com/images/tasks-progress.png',
  emptyStateDoneUrl: 'https://static.agendaedu.com/images/tasks-done.png',
  cardIllustrationTopLeft:
    'https://static.agendaedu.com/images/pixels-top-left.png',
  cardIllustrationBottomRight:
    'https://static.agendaedu.com/images/pixels-bottom-right.png',
  cardIllustrationDoneTopLeft:
    'https://static.agendaedu.com/images/pixels-done-top-left.png',
  cardIllustrationDoneBottomRight:
    'https://static.agendaedu.com/images/pixels-done-bottom-right.png',
  taskThumbUrl: {
    school_user: {
      in_progress: 'https://static.agendaedu.com/images/task-team.png',
      finished: 'https://static.agendaedu.com/images/task-done.png',
    },
    student_profile: {
      in_progress: 'https://static.agendaedu.com/images/task-students.png',
      unavailable: 'https://static.agendaedu.com/images/task-blocked-2.png',
      finished: 'https://static.agendaedu.com/images/task-done.png',
    },
    message: {
      in_progress: 'https://static.agendaedu.com/images/task-messages.png',
      unavailable: 'https://static.agendaedu.com/images/task-blocked-3.png',
      finished: 'https://static.agendaedu.com/images/task-done.png',
    },
    background:
      'https://static.agendaedu.com/empty_state/task-done-background.png',
  },
};

export const tasks = [
  {
    profile: 'school_user',
    heading: '1. Cadastrar profissionais',
    buttonText: 'Novo profissional',
  },
  {
    profile: 'student_profile',
    heading: '2. Cadastrar alunos',
    buttonText: 'Novo aluno',
  },
  {
    profile: 'message',
    heading: '3. Enviar mensagem',
    buttonText: 'Nova mensagem',
  },
];

export const taskProfile = {
  school_user: 'responsavel',
  student_profile: 'aluno',
  message: 'mensagens',
};

export const taskText = {
  school_user: {
    in_progress:
      'Cadastre sua equipe escolar e traga o dia a dia da sala de aula para a Agenda.',
    finished:
      'Parabéns!! Você conseguiu cadastrar o 1º profissional na Agenda Edu.',
  },
  student_profile: {
    in_progress:
      'Ajude seus alunos a acessar os recursos educacionais da Agenda Edu.',
    unavailable:
      'Essa tarefa está indisponível no momento. Realize a tarefa anterior para desbloqueá-la.',
    finished: 'Parabéns! Você conseguiu cadastrar o 1º aluno na Agenda Edu.',
  },
  message: {
    in_progress:
      'Crie e gerencie as conversas com a sua equipe, professores, pais e alunos.',
    unavailable:
      'Essa tarefa está indisponível no momento. Realize a tarefa anterior para desbloqueá-la.',
    finished: 'Parabéns! Você acabe de enviar a 1ª mensagem na Agenda Edu.',
  },
};

export const taskContextText = {
  onboarding: ' Finalize as etapas iniciais e continue sua jornada',
  web: ' Ainda restam tarefas à fazer. Vamos lá?!',
  default: '',
};

export const taskRedirectUrl = {
  school_user: '/schools/teams/new?task_context=onboarding',
  student_profile: '/schools/student_profiles/new?task_context=onboarding',
  message: '/schools/messages',
};
