import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: ${border.radius.md};
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    height: 450px;
  `}
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme: { space, colors } }: ThemeProps) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: ${space.xs};

    .ae-user-group-icon {
      width: 48px;
      height: 48px;

      path {
        fill: ${colors.neutral.gray3};
      }
    }
  `}
`;
