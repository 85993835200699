import React, { useEffect, lazy, useMemo, Suspense, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import Toast from 'components/Toast';

import SectionSettingsTab from './SectionSettingsTab';
import ConfirmModal from './ConfirmModal';

import DailySummariesActions from 'store/dailySummaries/actions';

import * as S from './styles';
import { useState } from 'react';

const DiarySections = () => {
  const { classroomsDiarySections, sections, isSending } = useSelector(
    (state) => state.dailySummaries
  );
  const dispatch = useDispatch();

  const formValueRef = useRef(null);

  const [showModal, setShowModal] = useState(false);

  const { fetchSettings, saveSettings } = DailySummariesActions;

  const formAttributes = useMemo(
    () => ({
      classroomsDiarySections,
      sections,
      draftSections: sections.reduce(
        (prev, current) => ({
          ...prev,
          [current.value]: current.classrooms,
        }),
        {}
      ),
    }),
    [classroomsDiarySections, sections]
  );

  const formMeta = {
    ignoreFieldsOnUpdatesCheck: ['activeSection', 'canSave'],
  };

  const onConfirmModal = ({ form }) => {
    formValueRef.current = form;
    setShowModal(true);
  };

  const onSubmit = () => {
    const { draftSections, sections, activeSection } = formValueRef.current;
    dispatch(saveSettings(draftSections, sections, activeSection));
    setShowModal(false);
  };

  const FormFullScreen = useMemo(
    () => lazy(() => import('components/FormFullScreen')),
    [formAttributes]
  );

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch, fetchSettings]);

  return (
    <S.DiarySectionContainer id="DiarySectionsScreen">
      <Suspense fallback={null}>
        <FormFullScreen
          action="new"
          initialAttributes={formAttributes}
          form={formAttributes}
          formMeta={formMeta}
          onSubmit={onConfirmModal}
          isSubmitting={isSending}
          backTo="/schools/daily_summaries"
          backToExternal
          steps={[SectionSettingsTab]}
          titlePage="Configurações do Diário"
          titleModal="Descartar alterações"
          contentModal="As informações alteradas não serão salvas até que você as envie. Tem certeza que deseja descartar?"
          toggleModalWithFormUpdates
        />
      </Suspense>
      <Toast />
      <ConfirmModal
        isOpen={showModal}
        toggleModal={() => setShowModal(false)}
        confirmAction={onSubmit}
      />
    </S.DiarySectionContainer>
  );
};

export default withRouter(DiarySections);
