import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { useTheme } from 'styled-components';

import DailySummariesActions from 'store/dailySummaries/actions';
import { DiaryState } from 'store/dailySummaries/types';

import withAppContext from 'core/hoc/withAppContext';

import {
  Box,
  Button,
  DefaultThemeProps,
  FullScreenModal,
  Text,
} from '@agendaedu/ae-web-components';
import Toast from 'components/Toast';
import SectionCard from './components/SectionCard';
import { SectionsListSkeleton } from './skeleton';
import { Props } from './types';

const Sections = ({
  appContext: {
    policies: { can_create_sections: canCreateSections },
  },
  history: { replace, goBack },
}: Props) => {
  const { fetchDiarySections } = DailySummariesActions;

  const { t } = useTranslation('diary_sections');
  const dispatch = useDispatch();
  const { breakpoints } = useTheme() as DefaultThemeProps;

  const {
    diarySections: { sections, isLoadingSections },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const onNewSection = () => replace('/schools/diary_sections/new');

  const redirectToEdit = useCallback(
    (id: string) => replace(`/schools/diary_sections/${id}/edit`),
    [replace]
  );

  const renderList = useMemo(() => {
    if (isLoadingSections) return <SectionsListSkeleton />;

    return sections.map((section) => (
      <SectionCard
        key={section.id}
        section={section}
        redirectToEdit={redirectToEdit}
      />
    ));
  }, [isLoadingSections, redirectToEdit, sections]);

  useEffect(() => {
    dispatch(fetchDiarySections());
  }, []);

  return (
    <FullScreenModal isOpen title={t('title')} onClose={goBack}>
      <Box overflow="auto" width="100%" px="xs">
        <Box mt="xl5" maxWidth={breakpoints.tabletMD} mx="auto">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              data-testid="title"
              variant="headline-h2-bold-24"
              color="neutral.black"
              mr="xl"
              mb={0}
            >
              {t('sections.title')}
            </Text>

            {canCreateSections && (
              <Button
                data-testid="new-section-button"
                aria-label={t('sections.new_section')}
                onClick={onNewSection}
              >
                {t('sections.new_section')}
              </Button>
            )}
          </Box>
          <Text
            data-testid="subtitle"
            variant="body-regular-16"
            color="neutral.gray1"
            my="xl"
          >
            {t('sections.subtitle')}
          </Text>
        </Box>

        <Box width="100%" maxWidth={breakpoints.tabletMD} pb="xl5" mx="auto">
          {renderList}
        </Box>
      </Box>

      <Toast />
    </FullScreenModal>
  );
};

export default withRouter(withAppContext(Sections));
