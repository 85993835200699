import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import formatDatetime from 'core/utils/formatDatetime';
import { getTotalCount, hasActiveFilter } from 'core/helper/calendar/event';
import withAppContext from 'core/hoc/withAppContext';

import CalendarActions from 'store/calendar/actions';
import { CalendarState } from 'store/calendar/types/store';
import { Event } from 'store/calendar/types/models';

import EventCard from './components/Event/List/Card';
import EventDeleteModal from './components/Event/Modals/Delete';
import EventFilters from './components/Event/List/Filters';
import { EmptyStateFilter, EmptyStatePage } from './emptyState';
import { SkeletonFilter, SkeletonLoadingMore, SkeletonPage } from './skeleton';

import * as S from './styles';

import { Props } from './types';
import Toast from 'components/Toast';

const CalendarList: React.FC<Props> = ({ history }): React.ReactElement => {
  const { t } = useTranslation(['calendar']);
  const dispatch = useDispatch();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { fetchEventsRequest, resetEvents } = CalendarActions;

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`list.${key}`, params),
    [t]
  );

  const { dataArea } = useSelector((state: RootStateOrAny) => state.root);

  const {
    list: { events, filters, isLoading, pagination },
  } = useSelector((state: CalendarState) => state.calendar.event);

  const isLoadingFilter = filters.isLoading;

  const totalCountLabel = getTotalCount(pagination.count);

  const showEmptyState =
    !isLoadingFilter && events?.length === 0 && !pagination.isLoading;

  const activeFilter = hasActiveFilter(filters);

  const handleFetchMoreEvents = useCallback(() => {
    if (!isLoading && !!pagination.next && !pagination.isLoading)
      dispatch(fetchEventsRequest(pagination.next));
  }, [dispatch, fetchEventsRequest, isLoading, pagination]);

  const actions = useCallback(
    (event: Event) => {
      const {
        id: eventId,
        attributes: { userPermissions },
      } = event;

      return () => [
        {
          as: 'button',
          title: tBase('card.actions.show'),
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            history.push(`/${dataArea}/calendar_events/${eventId}`);
          },
        },
        userPermissions.edit && {
          as: 'button',
          title: tBase('card.actions.edit'),
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            history.push(`/${dataArea}/calendar_events/${eventId}/edit`);
          },
        },
        userPermissions.delete && {
          as: 'button',
          title: tBase('card.actions.delete'),
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            setCurrentEvent(event);
            setShowDeleteModal(true);
          },
        },
      ];
    },
    [dataArea, history, tBase]
  );

  useEffect(() => {
    dispatch(fetchEventsRequest(1, filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    fetchEventsRequest,
    filters.name,
    filters.status,
    filters?.activeSchoolTermId,
    filters?.classroomId,
    filters?.educationalStageId,
    filters?.headquarterId,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetEvents());
    };
  }, [dispatch, resetEvents]);

  if (!events && isLoading) return <SkeletonPage />;

  return (
    <Box id="event-card-list">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        paddingTop="sm"
        paddingX={{ _: 'xs2', desktopLG: 'xl3' }}
        mb="lg"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            variant="headline-h2-bold-24"
            color="neutral.black"
            fontWeight={700}
            mb={0}
          >
            {tBase('title_label')}
          </Text>

          <Link to={`/${dataArea}/calendar_events/new`}>
            <Button testId="new-event-button">
              {tBase('new_event_button_label')}
            </Button>
          </Link>
        </Box>

        <EventFilters />

        <Box display="flex" mt="lg" flexDirection="row" width="100%" gap="lg">
          <Box
            display="flex"
            flexDirection="column"
            gap="lg"
            width="100%"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                {!activeFilter && (
                  <Text
                    variant="title-bold-20"
                    color="neutral.black"
                    mb={0}
                    fontWeight={700}
                  >
                    {formatDatetime(currentDate)}
                  </Text>
                )}
              </Box>

              {Boolean(!!pagination.count || !activeFilter) && (
                <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                  {totalCountLabel}
                </Text>
              )}
            </Box>

            <S.ListCard
              style={{ width: '100%' }}
              pageStart={1}
              hasMore={!!pagination.next}
              loader={
                pagination.isLoading &&
                !isLoadingFilter && <SkeletonLoadingMore />
              }
              loadMore={handleFetchMoreEvents}
              threshold={10}
            >
              <Box display="flex" gap="sm" flexDirection="column" width="100%">
                {isLoadingFilter && <SkeletonFilter />}

                {!isLoadingFilter &&
                  events?.map((event) => (
                    <EventCard
                      actions={actions(event)}
                      event={event}
                      history={history}
                      key={event.id}
                    />
                  ))}

                {showEmptyState &&
                  (activeFilter ? <EmptyStateFilter /> : <EmptyStatePage />)}
              </Box>
            </S.ListCard>
          </Box>
        </Box>
      </Box>

      <EventDeleteModal
        action="list"
        eventId={currentEvent?.id}
        eventName={currentEvent?.attributes?.title}
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setCurrentEvent(null);
        }}
      />

      <Toast />
    </Box>
  );
};
export default withRouter(withAppContext(CalendarList));
