import React, { useCallback, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  MESSAGE_CHAT_TABS,
  TICKET_CHAT_TABS,
  TICKET_SEARCH_CHAT_TABS,
} from 'core/constants';
import useWindowDimensions from 'core/hooks/useWindowDimensions';

import { Chat, OmniChannelState } from 'store/messages/omniChannel/types';
import omniChannelActions from 'store/messages/omniChannel/actions';

import * as S from './styles';

import { ChatCard } from '../ChatCard';
import { ChatCardSkeleton } from '../ChatCard/skeleton';
import { EmptyState } from './emptyState';

import { ChatDetails } from '../ChatCard/types';

export const ChatList = (): JSX.Element => {
  const dispatch = useDispatch();

  const { screenHeight } = useWindowDimensions();

  const infinityScrollRef = useRef<HTMLDivElement | null>(null);

  const {
    activeChannel,
    chats,
    chatFilters,
    chatCurrentTab,
    isLoadingSendMessage,
    chatIsSearchTab,
    chatSearchCurrentTab,
    pagination: { page, itemsPerPage, totalItemsCount, totalPages },
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const { fetchChatsRequest, fetchTicketsRequest } = omniChannelActions;

  const getChatDetails = (chat: Chat): ChatDetails => {
    return {
      avatarColor: chat.included?.attributes.avatar_color,
      avatarUrl: chat.included?.attributes.avatar_url,
      classroomsName: chat.attributes?.classrooms_names,
      kind: chat.attributes?.kind,
      lastMessagePreview: chat.attributes?.last_message_preview,
      lastMessageSentAt: chat.attributes?.last_message_sent_at,
      name: chat.attributes?.name,
      nameInitials: chat.included?.attributes.name_initials,
      readStatus: chat.attributes?.read_status,
      roleMessage: chat.attributes?.role_message,
      senderMessage: chat.relationships?.sender,
      subject: chat.attributes.subject,
    };
  };

  const channelType = activeChannel?.kind;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentTabs = {
    private_and_family: MESSAGE_CHAT_TABS,
    family: MESSAGE_CHAT_TABS,
    private: MESSAGE_CHAT_TABS,
    ticket: TICKET_CHAT_TABS,
  };

  const hasMoreChats = (): boolean => {
    const totalChats = page * itemsPerPage;
    return totalChats < totalItemsCount;
  };

  const hasMoreTickets = (): boolean => {
    return totalPages !== page;
  };

  const fetchMoreChats = useCallback(() => {
    const params = {
      chatStatus: MESSAGE_CHAT_TABS[chatCurrentTab].tabName,
      channelId: activeChannel.id,
      filters: chatFilters,
      page: page + 1,
    };

    dispatch(fetchChatsRequest(params));
  }, [
    chatCurrentTab,
    activeChannel,
    chatFilters,
    page,
    dispatch,
    fetchChatsRequest,
  ]);

  const fetchMoreTickets = useCallback(() => {
    const params = chatIsSearchTab
      ? {
          channelId: activeChannel.id,
          page: page + 1,
          filters: chatFilters,
          requesterName: chatFilters.searchName,
          requesterType: TICKET_SEARCH_CHAT_TABS[chatSearchCurrentTab].tabName,
        }
      : {
          status: TICKET_CHAT_TABS[chatCurrentTab].tabName,
          channelId: activeChannel.id,
          filters: chatFilters,
          page: page + 1,
        };

    dispatch(fetchTicketsRequest(params));
  }, [
    chatCurrentTab,
    activeChannel,
    page,
    dispatch,
    fetchTicketsRequest,
    chatIsSearchTab,
    chatSearchCurrentTab,
    chatFilters,
  ]);

  const fetchRequest = {
    private_and_family: fetchMoreChats,
    family: fetchMoreChats,
    private: fetchMoreChats,
    ticket: fetchMoreTickets,
  };

  const hasMore = {
    private_and_family: hasMoreChats,
    family: hasMoreChats,
    private: hasMoreChats,
    ticket: hasMoreTickets,
  };

  useEffect(() => {
    if (
      infinityScrollRef?.current?.scrollTo &&
      !isLoadingSendMessage &&
      !chatIsSearchTab
    ) {
      infinityScrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isLoadingSendMessage, chatIsSearchTab]);

  return (
    <S.Wrapper
      id="chat-content-wrapper"
      ref={infinityScrollRef}
      height={screenHeight}
    >
      {chats ? (
        chats.length > 0 ? (
          <InfiniteScroll
            dataLength={chats.length}
            next={fetchRequest[channelType]}
            hasMore={hasMore[channelType]()}
            loader={<ChatCardSkeleton amount={1} />}
            scrollableTarget="chat-content-wrapper"
          >
            {chats?.map((chat, index) => (
              <ChatCard
                key={index}
                chatId={chat.id}
                chatDetails={getChatDetails(chat)}
                ticket={chat.relationships.ticket}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyState
            tabName={currentTabs[channelType][chatCurrentTab].tabName}
          />
        )
      ) : (
        <ChatCardSkeleton amount={3} />
      )}
    </S.Wrapper>
  );
};
