import React, { useMemo, useReducer } from 'react';
import {
  Box,
  Modal,
  Select,
  TextField,
  Text,
  Button,
} from '@agendaedu/ae-web-components';

import { useTranslation } from 'react-i18next';

import { lengthOptions, styleOptions, toneVoiceOptions } from './constants';

import { FormReducerAction, FormReducerState, Props } from './types';
import { useAIHandoutForm } from 'core/contexts/Handout/AIHandoutModal';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';

import * as S from './styles';

const initialForm: FormReducerState = {
  messagePurpose: '',
  voiceTone: '',
  messageStyle: '',
  messageLength: '',
};

const MESSAGE_PURPOSE_LIMIT = 255;

const formReducer = (
  state: FormReducerState,
  action: FormReducerAction
): FormReducerState => {
  if (action.key === 'reset_form') return initialForm;

  return { ...state, [action.key]: action.value };
};

export const AIFormModal = ({ isOpen, onClose, onInsert }: Props) => {
  const { t } = useTranslation(['handouts', 'common']);
  const [formValues, setFormValues] = useReducer(formReducer, initialForm);
  const { generatedText, setGeneratedText, generateText, isGeneratingText } =
    useAIHandoutForm();

  const isValidParams = Object.values(formValues).every(Boolean);

  const shouldDisableInsert = !generatedText || !isValidParams;

  const shouldDisableGenerateButton = !isValidParams || isGeneratingText;

  const onResetForm = () => {
    setFormValues({ key: 'reset_form' });
    setGeneratedText('');
  };

  const onBack = () => {
    onClose();
    onResetForm();
  };

  const onClickInsert = () => {
    setGeneratedText('');

    onInsert(generatedText);
    onClose();
    onResetForm();
  };

  const onGenerateText = () => {
    const formParams = {
      messagePurpose: formValues.messagePurpose,
      voiceTone: toneVoiceOptions.find(
        (option) => option.value === formValues.voiceTone
      ).label,
      messageStyle: styleOptions.find(
        (option) => option.value === formValues.messageStyle
      ).label,
      messageLength: lengthOptions.find(
        (option) => option.value === formValues.messageLength
      ).label,
    };

    generateText({
      ...formParams,
      enableEmojiGuide: ['friendly', 'fun'].includes(formValues.messageStyle),
    });
  };

  const getGenerateButtonText = useMemo(() => {
    if (isGeneratingText) return t('ai_modal.generating_text');

    return t(
      generatedText ? 'ai_modal.regenerate_text' : 'ai_modal.generate_text'
    );
  }, [generatedText, isGeneratingText, t]);

  return (
    <Modal title={t('ai_modal.title')} isOpen={isOpen} onClose={onBack}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={{ desktopLG: '100vw' }}
        minHeight="60vh"
        maxHeight="85vh"
        maxWidth="600px"
        overflowY="auto"
        mx="xs3"
      >
        <Box>
          <Box>
            <TextField
              fullWidth
              label={t('ai_modal.message_purpose')}
              multiline
              rows={3}
              placeholder={t('ai_modal.message_purpose_placeholder')}
              value={formValues.messagePurpose}
              onChange={(e) => {
                if (e.target.value.length <= MESSAGE_PURPOSE_LIMIT) {
                  setFormValues({
                    key: 'messagePurpose',
                    value: e.target.value,
                  });
                }
              }}
            />

            <Text
              variant="label-regular-14"
              textAlign="end"
              mt="xs"
              color="neutral.gray1"
            >
              {formValues.messagePurpose.length}/{MESSAGE_PURPOSE_LIMIT}
            </Text>
          </Box>

          <Box>
            <Select
              fullWidth
              mt="sm"
              label={t('ai_modal.voice_tone')}
              value={formValues.voiceTone}
              options={toneVoiceOptions}
              onChange={({ value }) => {
                setFormValues({ key: 'voiceTone', value });
              }}
            />

            <Select
              fullWidth
              mt="sm"
              label={t('ai_modal.message_style')}
              value={formValues.messageStyle}
              options={styleOptions}
              onChange={({ value }) => {
                setFormValues({ key: 'messageStyle', value });
              }}
            />

            <Select
              fullWidth
              mt="sm"
              label={t('ai_modal.message_length')}
              value={formValues.messageLength}
              options={lengthOptions}
              onChange={({ value }) => {
                setFormValues({ key: 'messageLength', value });
              }}
            />
          </Box>

          <Box>
            <Button
              onClick={onGenerateText}
              mt="lg"
              disabled={shouldDisableGenerateButton}
            >
              {getGenerateButtonText}
            </Button>

            {generatedText && (
              <>
                <Text variant="label-regular-14" mt="sm" color="neutral.gray1">
                  {t('ai_modal.text_generated')}
                </Text>
                <S.Quill
                  quillProps={{
                    onChange: (value: string) =>
                      generatedText !== value &&
                      setGeneratedText(
                        validateTextAreaPresence(value) ? value : ''
                      ),
                    value: generatedText,
                  }}
                  type="textArea"
                  useImage
                />
                <Text variant="label-regular-14" mt="xs" color="neutral.gray1">
                  {t('ai_modal.regenerate_info')}
                </Text>
              </>
            )}
          </Box>
        </Box>

        <Box
          position="sticky"
          bottom={0}
          backgroundColor="white"
          display="flex"
          flexDirection="row"
          justifyContent="end"
          gap="sm"
          mt="md"
        >
          <Button onClick={onBack} variant="secondary">
            {t('common:button.back')}
          </Button>

          <Button onClick={onClickInsert} disabled={shouldDisableInsert}>
            {t('common:button.insert')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
