import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MESSAGE_PRIVATE_AND_FAMILY_CHAT_TABS,
  MESSAGE_SEARCH_CHANNEL_FAMILY_CHAT_TABS,
  MESSAGE_SEARCH_CHAT_TABS,
  TICKET_SEARCH_CHAT_TABS,
} from 'core/constants';

import omniChannelActions from 'store/messages/omniChannel/actions';
import {
  OmniChannelState,
  RequesterType,
} from 'store/messages/omniChannel/types';

import * as S from './styles';

import Tabs from '../../Tabs';
import { ChatSearchList } from '../ChatSearchList';
import { ChatList } from '../ChatList';

export const ChatSearchTabs = (): React.ReactElement => {
  const dispatch = useDispatch();

  const {
    fetchChatSearchUsersRequest,
    fetchTicketsRequest,
    setChatSearchCurrentTab,
  } = omniChannelActions;

  const { activeChannel, chatFilters, chatPendingCount, chatSearchCurrentTab } =
    useSelector((state: OmniChannelState) => state.omniChannel);

  const channelType = activeChannel.kind;

  const currentTabs = {
    private_and_family: MESSAGE_PRIVATE_AND_FAMILY_CHAT_TABS,
    family: MESSAGE_SEARCH_CHANNEL_FAMILY_CHAT_TABS,
    private: MESSAGE_SEARCH_CHAT_TABS,
    ticket: TICKET_SEARCH_CHAT_TABS,
  };

  const handleOnChangeTab = (tab: number) =>
    dispatch(setChatSearchCurrentTab(tab));

  const fetchChatSearchUsers = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      filters: chatFilters,
      page: 1,
      tabName: currentTabs[channelType][chatSearchCurrentTab].tabName,
    };

    dispatch(fetchChatSearchUsersRequest(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeChannel,
    chatFilters,
    chatSearchCurrentTab,
    dispatch,
    fetchChatSearchUsersRequest,
  ]);

  const fetchTicketSearchUsers = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      page: 1,
      requesterName: chatFilters.searchName,
      requesterType: currentTabs[channelType][chatSearchCurrentTab]
        .tabName as RequesterType,
    };

    dispatch(fetchTicketsRequest(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeChannel,
    dispatch,
    fetchTicketsRequest,
    chatFilters,
    chatSearchCurrentTab,
    channelType,
  ]);

  const renderChatList = {
    private_and_family: <ChatSearchList />,
    family: <ChatSearchList />,
    private: <ChatSearchList />,
    ticket: <ChatList />,
  };

  const currentFetchSearchUser = {
    private_and_family: fetchChatSearchUsers,
    family: fetchChatSearchUsers,
    private: fetchChatSearchUsers,
    ticket: fetchTicketSearchUsers,
  };

  useEffect(() => {
    currentFetchSearchUser[channelType]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelType, chatSearchCurrentTab, chatFilters]);

  return (
    <S.Wrapper>
      <Tabs
        badges={chatPendingCount}
        currentTab={chatSearchCurrentTab}
        onChangeTab={handleOnChangeTab}
        tabs={currentTabs[channelType]}
      />
      {renderChatList[channelType]}
    </S.Wrapper>
  );
};
