import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  classrooms: [],
  recipients: [],
  studentsProfile: [],
  history: [],
  enrollmentPlans: [],
  contractModels: [],
  filters: {
    range: {
      startDate: null,
      endDate: null,
    },
    contract_status: null,
    status: null,
    school_product_title: null,
    headquarter_id: null,
    educational_stage_id: null,
    classroom_id: null,
  },
  activeFiltersCount: 0,
  paginate: {
    activePage: 1,
  },
  hasEnrollmentPlans: false,
  currentEnrollmentPlan: null,
  currentSignedFile: null,
  enrollmentPlanEdit: null,
  currentResponsibleProfile: [],
  sending: false,
  errorMessage: null,
  isLoading: false,
  isLoadingEnrollmentModal: false,
  isLoadingContractModels: false,
  modalOpen: false,
  manuallyPaidModal: false,
  showModalCheckout: false,
  showModalCheckoutLink: false,
  cancelModal: false,
  showManualDealModal: false,
  modelsPaginate: {
    activePage: 0,
  },
};

const fetchEnrollmentPlansRequest = (state) => ({
  ...state,
  isLoading: true,
});

const fetchEnrollmentPlansSuccess = (state, action) => {
  let amount = 0;
  const sideFilter = [
    action.filters.headquarter_id,
    action.filters.educational_stage_id,
    action.filters.classroom_id,
  ];

  sideFilter.forEach((filter) => {
    if (filter) amount += 1;
  });

  return {
    ...state,
    enrollmentPlans: action.data,
    paginate: action.paginate,
    hasEnrollmentPlans: action.hasEnrollmentPlans,
    filters: action.filters,
    activeFiltersCount: amount,
    isLoading: false,
  };
};

export const addEnrollmentPlanRequest = (state) => ({
  ...state,
  sending: true,
});

export const addEnrollmentPlanSuccess = (state) => ({
  ...state,
  sending: false,
  studentsProfile: [],
  enrollmentPlans: [],
  currentEnrollmentPlan: null,
});

export const addMassEnrollmentPlanRequest = (state) => ({
  ...state,
  sending: true,
});

export const addMassEnrollmentPlanSuccess = (state) => ({
  ...state,
  sending: false,
  studentsProfile: [],
  enrollmentPlans: [],
  contractModels: [],
  currentEnrollmentPlan: null,
});

export const updateEnrollmentPlanRequest = (state) => ({
  ...state,
  sending: true,
});

export const updateEnrollmentPlanSuccess = (state) => ({
  ...state,
  sending: false,
  studentsProfile: [],
});

const setCurrentEnrollmentPlanRequest = (state) => ({
  ...state,
  isLoadingEnrollmentModal: true,
});

const fetchEditEnrollmentRequest = (state) => ({
  ...state,
  enrollmentPlanEdit: null,
  classrooms: [],
  studentsProfile: [],
  recipients: [],
  isLoading: true,
});

const fetchEditEnrollmentSuccess = (state, action) => {
  const classrooms = [...action.classrooms];
  classrooms.unshift({ value: null, label: 'Selecione uma turma' });

  const studentsProfile = action.studentProfiles.map((student) => ({
    value: student.id,
    label: student.attributes.name,
  }));

  studentsProfile.unshift({ value: null, label: 'Selecione um(a) aluno(a)' });

  const recipients = action.responsibleProfiles.map((recipient) => ({
    ...recipient,
    checked: false,
    disabled: false,
  }));

  return {
    ...state,
    isLoading: false,
    enrollmentPlanEdit: action.enrollmentPlan,
    contractModels: action.contractModels,
    classrooms,
    studentsProfile,
    recipients,
  };
};

const setClassroomsRequest = (state) => ({
  ...state,
  classrooms: [],
  recipients: [],
  studentsProfile: [],
  isLoading: true,
});

const setClassroomsSuccess = (state, action) => {
  const classrooms = [...action.classrooms];
  classrooms.unshift({ value: null, label: 'Selecione uma turma' });

  return {
    ...state,
    classrooms,
    isLoading: false,
  };
};

const setContractModelRequest = (state, action) => {
  return {
    ...state,
    isLoadingContractModels: true,
  };
};

const setContractModelSuccess = (state, action) => {
  const { contractModels, paginate } = action;
  return {
    ...state,
    contractModels: [...state.contractModels, ...contractModels],
    modelsPaginate: paginate,
    isLoadingContractModels: false,
  };
};

const setActiveContractModelRequest = (state) => ({
  ...state,
  contractModels: [],
  isLoadingContractModels: true,
});

const setActiveContractModelSuccess = (state, action) => {
  return {
    ...state,
    contractModels: action.contractModels,
    isLoadingContractModels: false,
  };
};

const setToggleContractModelSuccess = (state, action) => {
  return {
    ...state,
    contractModels: state.contractModels.map((contract) =>
      contract.id === action.data.id ? { ...action.data } : contract
    ),
  };
};

const fetchStudentsSuccess = (state, action) => {
  const studentsProfile = action.data.map((student) => ({
    value: student.id,
    label: student.attributes.name,
  }));

  studentsProfile.unshift({ value: null, label: 'Selecione um(a) aluno(a)' });

  return {
    ...state,
    studentsProfile,
    recipients: [],
  };
};

const fetchStudentsResponsiblesRequest = (state) => {
  return {
    ...state,
    isLoadingStudentsResponsibles: true,
    studentsProfile: [],
  };
};

const fetchStudentsResponsiblesSuccess = (state, action) => {
  const studentsProfile = action.data.map((student) => ({
    id: student.id,
    label: student.attributes.name,
    options: [
      ...student.attributes.responsibles?.data.map((responsible) => ({
        id: responsible.id,
        name: responsible.attributes.name,
        avatar_color: responsible.attributes.avatar_color,
        role: responsible.attributes.role,
      })),
    ],
  }));

  return {
    ...state,
    isLoadingStudentsResponsibles: false,
    studentsProfile: studentsProfile,
  };
};

const fetchRecipientsSuccess = (state, action) => {
  const recipients = action.data.map((recipient) => ({
    ...recipient,
    checked: false,
    disabled: false,
  }));

  return {
    ...state,
    recipients,
    isLoading: false,
  };
};

const fetchHistoryEnrollmentRequest = (state) => ({
  ...state,
  isLoading: true,
});

const approveEnrollmentPlanRequest = (state) => ({
  ...state,
  isLoading: true,
});

const fetchHistoryEnrollmentSuccess = (state, action) => ({
  ...state,
  history: action.history,
  paginate: action.paginate,
  isLoading: false,
});

const approveEnrollmentPlanSuccess = (state, action) => ({
  ...state,
  currentEnrollmentPlan: action.data,
  isLoading: false,
});

const handleError = (state, action) => ({
  ...state,
  error: action.error,
  sending: false,
  isLoading: false,
  manuallyPaidModal: false,
});

export const manuallyPayEnrollmentRequest = (state) => ({
  ...state,
  isLoadingEnrollmentModal: true,
});

export const manuallyPayEnrollmentSuccess = (state, action) => {
  const index = state.enrollmentPlans.findIndex(
    (enrollment) => enrollment.id === action.id.toString()
  );

  const enrollmentPlans = [
    ...state.enrollmentPlans.slice(0, index),
    {
      ...state.enrollmentPlans[index],
      attributes: {
        ...state.enrollmentPlans[index].attributes,
        status: 'manually_paid',
      },
    },
    ...state.enrollmentPlans.slice(index + 1),
  ];

  return {
    ...state,
    isLoadingEnrollmentModal: false,
    manuallyPaidModal: false,
    enrollmentPlans,
    current: undefined,
  };
};

export const setPaidAtSchoolOrderSuccess = (state, action) => {
  const index = state.enrollmentPlans.findIndex(
    (enrollment) => enrollment.id === action.id.toString()
  );

  const enrollmentPlans = [
    ...state.enrollmentPlans.slice(0, index),
    {
      ...state.enrollmentPlans[index],
      attributes: {
        ...state.enrollmentPlans[index].attributes,
        status: 'manually_paid',
      },
    },
    ...state.enrollmentPlans.slice(index + 1),
  ];

  return {
    ...state,
    isLoadingEnrollmentModal: false,
    showManualDealModal: false,
    enrollmentPlans,
    current: undefined,
  };
};

export const checkout = (state) => ({
  ...state,
  isLoadingEnrollmentModal: true,
});

export const checkoutSuccess = (state, action) => {
  const index = state.enrollmentPlans.findIndex(
    (enrollment) => enrollment.id === action.id.toString()
  );

  const enrollmentPlans = [
    ...state.enrollmentPlans.slice(0, index),
    {
      ...state.enrollmentPlans[index],
      attributes: {
        ...state.enrollmentPlans[index].attributes,
        status: action.event,
      },
    },
    ...state.enrollmentPlans.slice(index + 1),
  ];

  return {
    ...state,
    isLoadingEnrollmentModal: false,
    showModalCheckout: false,
    enrollmentPlans,
    currentEnrollmentPlan: {
      ...state.currentEnrollmentPlan,
      ...action.data,
    },
  };
};

const paymentMethodLinkEnrollmentRequest = (state) => ({
  ...state,
  isLoadingEnrollmentModal: true,
});

const paymentMethodLinkEnrollmentSuccess = (state, action) => ({
  ...state,
  isLoadingEnrollmentModal: false,
  currentEnrollmentPlan: action.currentEnrollmentPlan,
});

export const handleErrorCheckout = (state, action) => {
  const {
    error: {
      data: { errors },
    },
  } = action;

  return {
    ...state,
    errorMessage: errors,
    isLoading: false,
    isLoadingEnrollmentModal: false,
  };
};

const setCurrentEnrollmentPlanSuccess = (state, action) => ({
  ...state,
  isLoadingEnrollmentModal: false,
  currentEnrollmentPlan: action.currentEnrollmentPlan,
  currentSignedFile: action.currentSignedFile,
  currentResponsibleProfile: action.currentResponsibleProfile,
  recipients: action.recipients,
});

const resetFilters = (state) => ({
  ...state,
  activeFiltersCount: 0,
});

const toggleModal = (state) => ({
  ...state,
  modalOpen: !state.modalOpen,
});

export const toggleManuallyPaidModal = (state) => ({
  ...state,
  manuallyPaidModal: !state.manuallyPaidModal,
});

export const togglePaymentMethodModal = (state) => ({
  ...state,
  errorMessage: null,
  showModalCheckout: !state.showModalCheckout,
});

export const togglePaymentMethodLink = (state) => ({
  ...state,
  showModalCheckoutLink: !state.showModalCheckoutLink,
});

export const toggleCancelModal = (state) => ({
  ...state,
  cancelModal: !state.cancelModal,
});

export const toggleCancelModalLD = (state) => ({
  ...state,
  showManualDealModal: !state.showManualDealModal,
});

export const toggleManualDealModal = (state) => ({
  ...state,
  showManualDealModal: !state.showManualDealModal,
});

export const cancelEnrollmentSuccess = (state, action) => {
  let enrollmentPlans = [];
  if (state.enrollmentPlans.length > 0) {
    const index = state.enrollmentPlans.findIndex(
      (enrollment) => enrollment.id === action.data.id.toString()
    );

    enrollmentPlans = [
      ...state.enrollmentPlans.slice(0, index),
      {
        ...state.enrollmentPlans[index],
        attributes: {
          ...state.enrollmentPlans[index].attributes,
          status: action.data.attributes.status,
          document_status: action.data.attributes.document_status,
        },
      },
      ...state.enrollmentPlans.slice(index + 1),
    ];
  }

  const currentEnrollmentPlan = {
    ...state.currentEnrollmentPlan,
    attributes: {
      ...state.currentEnrollmentPlan.attributes,
      status: action.data.attributes.status,
      document_status: action.data.attributes.document_status,
    },
  };

  return {
    ...state,
    enrollmentPlans,
    currentEnrollmentPlan,
  };
};

export const cancelEnrollmentSuccessLD = (state, action) => {
  let enrollmentPlans = [];
  if (state.enrollmentPlans.length > 0) {
    const index = state.enrollmentPlans.findIndex(
      (enrollment) => enrollment.id === action.data.id.toString()
    );

    enrollmentPlans = [
      ...state.enrollmentPlans.slice(0, index),
      {
        ...state.enrollmentPlans[index],
        attributes: {
          ...state.enrollmentPlans[index].attributes,
          status: action.data.attributes.status,
          document_status: action.data.attributes.document_status,
        },
      },
      ...state.enrollmentPlans.slice(index + 1),
    ];
  }

  const currentEnrollmentPlan = {
    ...state.currentEnrollmentPlan,
    attributes: {
      ...state.currentEnrollmentPlan.attributes,
      status: action.data.attributes.status,
      document_status: action.data.attributes.document_status,
    },
  };

  return {
    ...state,
    showManualDealModal: !state.showManualDealModal,
    enrollmentPlans,
    currentEnrollmentPlan,
  };
};

export const addContractModelRequest = (state) => ({
  ...state,
  sending: true,
});

export const addContractModelSuccess = (state, action) => ({
  ...state,
  sending: false,
  contractModels: [action.data, ...state.contractModels],
});

const HANDLERS = {
  [actions.FETCH_ENROLLMENT_PLANS_REQUEST]: fetchEnrollmentPlansRequest,
  [actions.FETCH_ENROLLMENT_PLANS_SUCCESS]: fetchEnrollmentPlansSuccess,
  [actions.ADD_ENROLLMENT_PLAN_REQUEST]: addEnrollmentPlanRequest,
  [actions.ADD_ENROLLMENT_PLAN_SUCCESS]: addEnrollmentPlanSuccess,
  [actions.UPDATE_ENROLLMENT_PLAN_REQUEST]: updateEnrollmentPlanRequest,
  [actions.UPDATE_ENROLLMENT_PLAN_SUCCESS]: updateEnrollmentPlanSuccess,
  [actions.SET_CURRENT_ENROLLMENT_PLAN_REQUEST]:
    setCurrentEnrollmentPlanRequest,
  [actions.SET_CURRENT_ENROLLMENT_PLAN_SUCCESS]:
    setCurrentEnrollmentPlanSuccess,
  [actions.FETCH_EDIT_ENROLLMENT_REQUEST]: fetchEditEnrollmentRequest,
  [actions.FETCH_EDIT_ENROLLMENT_SUCCESS]: fetchEditEnrollmentSuccess,
  [actions.SET_CLASSROOMS_REQUEST]: setClassroomsRequest,
  [actions.SET_CLASSROOMS_SUCCESS]: setClassroomsSuccess,
  [actions.SET_ACTIVE_CONTRACT_MODEL_REQUEST]: setActiveContractModelRequest,
  [actions.SET_ACTIVE_CONTRACT_MODEL_SUCCESS]: setActiveContractModelSuccess,
  [actions.SET_TOGGLE_CONTRACT_MODEL_SUCCESS]: setToggleContractModelSuccess,
  [actions.SET_CONTRACT_MODEL_REQUEST]: setContractModelRequest,
  [actions.SET_CONTRACT_MODEL_SUCCESS]: setContractModelSuccess,
  [actions.FETCH_STUDENTS_SUCCESS]: fetchStudentsSuccess,

  [actions.FETCH_STUDENT_RESPONSIBLES_REQUEST]:
    fetchStudentsResponsiblesRequest,
  [actions.FETCH_STUDENT_RESPONSIBLES_SUCCESS]:
    fetchStudentsResponsiblesSuccess,
  [actions.FETCH_RECIPIENTS_SUCCESS]: fetchRecipientsSuccess,
  [actions.FETCH_HISTORY_ENROLLMENT_REQUEST]: fetchHistoryEnrollmentRequest,
  [actions.FETCH_HISTORY_ENROLLMENT_SUCCESS]: fetchHistoryEnrollmentSuccess,
  [actions.APPROVE_ENROLLMENT_PLAN_REQUEST]: approveEnrollmentPlanRequest,
  [actions.APPROVE_ENROLLMENT_PLAN_SUCCESS]: approveEnrollmentPlanSuccess,
  [actions.MANUALLY_PAY_ENROLLMENT_REQUEST]: manuallyPayEnrollmentRequest,
  [actions.MANUALLY_PAY_ENROLLMENT_SUCCESS]: manuallyPayEnrollmentSuccess,
  [actions.SET_PAID_AT_SCHOOL_ORDER_SUCCESS]: setPaidAtSchoolOrderSuccess,
  [actions.CHECKOUT]: checkout,
  [actions.CHECKOUT_SUCCESS]: checkoutSuccess,
  [actions.PAYMENT_METHOD_LINK_ENROLLMENT_REQUEST]:
    paymentMethodLinkEnrollmentRequest,
  [actions.PAYMENT_METHOD_LINK_ENROLLMENT_SUCCESS]:
    paymentMethodLinkEnrollmentSuccess,
  [actions.RESET_FILTERS]: resetFilters,
  [actions.CANCEL_ENROLLMENT_SUCCESS]: cancelEnrollmentSuccess,
  [actions.CANCEL_ENROLLMENT_SUCCESS_LD]: cancelEnrollmentSuccessLD,

  [actions.ERROR]: handleError,
  [actions.ERROR_CHECKOUT]: handleErrorCheckout,
  [actions.TOGGLE_MODAL]: toggleModal,
  [actions.TOGGLE_MANUALLY_PAID_MODAL]: toggleManuallyPaidModal,
  [actions.TOGGLE_PAYMENT_METHOD_MODAL]: togglePaymentMethodModal,
  [actions.TOGGLE_PAYMENT_METHOD_LINK_MODAL]: togglePaymentMethodLink,
  [actions.TOGGLE_CANCEL_MODAL]: toggleCancelModal,
  [actions.TOGGLE_MANUAL_DEAL_MODAL]: toggleManualDealModal,
  [actions.ADD_CONTRACT_MODEL_REQUEST]: addContractModelRequest,
  [actions.ADD_CONTRACT_MODEL_SUCCESS]: addContractModelSuccess,
  [actions.ADD_MASS_ENROLLMENT_PLAN_REQUEST]: addContractModelRequest,
  [actions.ADD_MASS_ENROLLMENT_PLAN_SUCCESS]: addContractModelSuccess,
};

const enrollmentPlans = createModuleReducer(INITIAL_STATE, HANDLERS);

export default enrollmentPlans;
