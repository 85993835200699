import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAppContext from 'core/hoc/withAppContext';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import RecipientsStep from 'components/Messages/OmniChannel/Forms/MessageChat/RecipientsStep';
import { RecipientsStepSkeleton } from './skeleton';
import SendMessageStep from 'components/Messages/OmniChannel/Forms/MessageChat/SendMessageStep';
import Toast from 'components/Toast';
import FormFullScreen from 'components/FormFullScreen';

import { NewMessageChannelProps } from './types';

const WIZARD_STEPS = [RecipientsStep, SendMessageStep];

const NewMessageChatForm = ({
  match,
  appContext: { dataArea },
}: NewMessageChannelProps): JSX.Element => {
  const { channelId } = match?.params;

  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const {
    fetchChatClassroomsRequest,
    setCreateOldNewChatRequest,
    fetchChannelRequest,
  } = omniChannelActions;

  const { isLoading, chatClassrooms, isSubmittingChatForm, channel } =
    useSelector((state: OmniChannelState) => state.omniChannel);

  const handleCreateNewChat = useCallback(
    ({ form }) => {
      const params = {
        channelId,
        ...form,
      };
      dispatch(setCreateOldNewChatRequest(params));
    },
    [channelId, dispatch, setCreateOldNewChatRequest]
  );

  useEffect(() => {
    dispatch(fetchChatClassroomsRequest(channelId));
    dispatch(fetchChannelRequest(channelId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchChatClassroomsRequest, dispatch, channelId]);

  return (
    <S.MessageChannelFormWrapper>
      <FormFullScreen
        action="new"
        id="NewMessageChannel"
        form={{
          recipients: [],
          message: '',
          attachment: null,
        }}
        initialAttributes={{}}
        formMeta={{
          nextStepDisabled: true,
        }}
        backTo={`/${dataArea}/messages`}
        backToExternal
        isSubmitting={isSubmittingChatForm}
        steps={
          chatClassrooms && !isLoading && channel
            ? WIZARD_STEPS
            : [RecipientsStepSkeleton]
        }
        onSubmit={handleCreateNewChat}
        titlePage={t('omni_channel.forms.message_chat.steps.first')}
        titleModal={t('omni_channel.forms.message_channel.create.cancel_title')}
        contentModal={t(
          'omni_channel.forms.message_channel.create.cancel_description'
        )}
      />

      <Toast />
    </S.MessageChannelFormWrapper>
  );
};

export default withAppContext(NewMessageChatForm);
