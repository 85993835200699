import { Box, Breadcrumb, Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import * as S from './styles';

import withAppContext from 'core/hoc/withAppContext';
import { DETAILS_TABS_OPTIONS } from 'core/constants/calendar/event';

import CalendarRouters from 'store/calendar/actions';

import Engagement from 'pages/Calendar/components/Event/Details/Tabs/Engagement';
import Informations from 'pages/Calendar/components/Event/Details/Tabs/Informations';
import Tabs from 'components/Messages/OmniChannel/Tabs';

import { Props } from './types';

const Details: React.FC<Props> = ({
  history,
  match: {
    params: { id: eventId },
  },
}): React.ReactElement => {
  const { t } = useTranslation(['calendar']);
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(0);

  const tBase = useCallback((key: string) => t(`details.${key}`), [t]);

  const { resetEventDetails } = CalendarRouters;

  const { dataArea } = useSelector((state: RootStateOrAny) => state.root);

  const renderCurrentTab = useMemo(() => {
    const tabIndex = {
      0: <Informations eventId={eventId} />,
      1: <Engagement />,
    };

    return tabIndex[currentTab];
  }, [currentTab, eventId]);

  useEffect(() => {
    return () => {
      dispatch(resetEventDetails());
    };
  }, [dispatch, eventId, resetEventDetails]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      id="details-event"
      gap="xl"
      paddingX={{ _: 'xs2', desktopLG: 'xl3' }}
      paddingY="sm"
    >
      <Box display="flex" flexDirection="column" gap="xs2">
        <Breadcrumb
          alias={tBase('breadcrumb_title')}
          icon="calendar"
          onClick={(path: string) => history.push(path)}
          path={`/${dataArea}/calendar_events`}
          testId="breadcrumb"
        />

        <Text
          color="neutral.black"
          data-testid="title"
          fontWeight={700}
          mb={0}
          variant="headline-h2-bold-24"
        >
          {tBase('title_text')}
        </Text>
      </Box>

      <S.Wrapper>
        <S.TabsWrapper>
          <Box width={252}>
            <Tabs
              currentTab={currentTab}
              hasBorder={false}
              onChangeTab={(tab: number) => setCurrentTab(tab)}
              tabs={DETAILS_TABS_OPTIONS}
            />
          </Box>
        </S.TabsWrapper>

        {renderCurrentTab}
      </S.Wrapper>
    </Box>
  );
};

export default withRouter(withAppContext(Details));
