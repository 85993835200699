import React, { useCallback } from 'react';
import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import messagesActions from 'store/messages/actions';
import { MessageState } from 'store/messages/types';

export const GroupDeleteMessageModal: React.FC = () => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const { toggleDeleteMessageModal, setDeleteMessageRequest } = messagesActions;

  const { showDeleteMessageModal, currentGroup, currentMessage } = useSelector(
    (state: MessageState) => state.messages
  );

  const handleToggleModal = useCallback((): void => {
    dispatch(toggleDeleteMessageModal());
  }, [dispatch, toggleDeleteMessageModal]);

  const handleDeleteGroupMessage = useCallback((): void => {
    dispatch(setDeleteMessageRequest(currentGroup?.id, currentMessage?.id));
  }, [currentGroup, currentMessage, dispatch, setDeleteMessageRequest]);

  return (
    <Modal
      isOpen={showDeleteMessageModal}
      title={t('groups.modals.delete_message.title')}
      onClose={handleToggleModal}
    >
      <Box maxWidth="336px" paddingLeft="xs3">
        <Text variant="body-regular-16" mb="xl" mt="xs" lineHeight="lg">
          {t('groups.modals.delete_message.description')}
        </Text>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="end"
          mt="xl"
          gap="sm"
        >
          <Button
            data-testid="cancel-delete-button"
            variant="secondary"
            onClick={handleToggleModal}
          >
            {t('groups.modals.delete_group.back_button_label')}
          </Button>
          <Button
            data-testid="submit-delete-button"
            contextVariant="danger"
            onClick={handleDeleteGroupMessage}
          >
            {t('groups.modals.delete_group.submit_button_label')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
