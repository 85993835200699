import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import { Box, Button, DefaultThemeProps } from '@agendaedu/ae-web-components';

import {
  isEmptyMeal,
  isEmptyOccurrence,
  MealCard,
  OccurrenceCard,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import { DiarySectionsFormContext } from 'core/contexts/DiaryForm/SectionsForm';
import { DiaryFormStructure } from 'store/dailySummaries/types';
import { useTheme } from 'styled-components';
import { defaultSectionGroupValues } from 'core/contexts/DiaryForm/constants';

import { Props } from './types';

type SectionValue =
  DiaryFormStructure['sectionsForm']['studentsDiaries'][string][Props['field']];

type StudentForm =
  DiaryFormStructure['sectionsForm']['studentsDiaries'][string];

export const SectionGroup = ({ student, field }: Props) => {
  const { border, colors } = useTheme() as DefaultThemeProps;

  const {
    form: { values },
    updateStudentDiary,
  } = useContext(DiarySectionsFormContext);

  const studentValues = _.cloneDeep(values.studentsDiaries[student.id]);

  const fieldValues = _.cloneDeep(studentValues[field]) as SectionValue;

  const updateValues = useCallback(
    () =>
      updateStudentDiary(student.id, {
        [field]: fieldValues,
        ...(field === 'occurrences' && {
          occurrences_to_destroy: studentValues.occurrences_to_destroy,
        }),
      }),
    [
      field,
      fieldValues,
      student.id,
      studentValues.occurrences_to_destroy,
      updateStudentDiary,
    ]
  );

  const handleSectionChanges = (value: SectionValue[0], index: number) => {
    fieldValues[index] = value;

    updateValues();
  };

  const addSectionCard = useCallback(
    (index: number) => {
      const newValues = defaultSectionGroupValues[
        field
      ]() as DiaryFormStructure['occurrence'] & DiaryFormStructure['meal'];

      if (index >= 0 && index < fieldValues.length) {
        fieldValues.splice(index + 1, 0, newValues);
      } else {
        fieldValues.push(newValues);
      }

      updateValues();
    },
    [field, fieldValues, updateValues]
  );

  const removeSectionCard = useCallback(
    (index: number) => {
      if (
        field === 'occurrences' &&
        (fieldValues[index] as DiaryFormStructure['occurrence']).id
      ) {
        const occurrences_to_destroy = [
          ...studentValues.occurrences_to_destroy,
          (fieldValues[index] as DiaryFormStructure['occurrence']).id,
        ];

        studentValues.occurrences_to_destroy = occurrences_to_destroy;
      }

      fieldValues.splice(index, 1);
      updateValues();
    },
    [field, fieldValues, studentValues, updateValues]
  );

  const getSectionCard = (value: typeof fieldValues[0], index: number) =>
    ({
      meals: (
        <MealCard
          data-testid="meals-card"
          meal={value as StudentForm['meals'][0]}
          onChange={(meal) => handleSectionChanges(meal, index)}
          student={student}
        />
      ),
      occurrences: (
        <OccurrenceCard
          id={`${student.id}-${index}`}
          data-testid="occurrence-card"
          occurrence={value as StudentForm['occurrences'][0]}
          onChange={(occurrence) => handleSectionChanges(occurrence, index)}
          student={student}
        />
      ),
    }[field]);

  const canAddSectionCard = {
    meals: true,
    occurrences: fieldValues.length <= 5,
  }[field];

  const clearSectionAction = {
    meals: (index: number) => {
      const currentValue = fieldValues[index] as StudentForm['meals'][0];

      const emptyValue = {
        ...currentValue,
        ...defaultSectionGroupValues.meals(),
      };

      handleSectionChanges(emptyValue, index);
    },
    occurrences: (index: number) => {
      const currentValue = fieldValues[index] as StudentForm['occurrences'][0];

      const emptyValue = {
        ...currentValue,
        ...defaultSectionGroupValues.occurrences(),
      };

      handleSectionChanges(emptyValue, index);
    },
  };

  const isEmptySection = {
    meals: (index: number) => {
      const currentValue = fieldValues[index] as StudentForm['meals'][0];

      return isEmptyMeal(currentValue);
    },
    occurrences: (index: number) => {
      const currentValue = fieldValues[index] as StudentForm['occurrences'][0];

      return isEmptyOccurrence(currentValue);
    },
  };

  return (
    <Box display="flex" flexDirection="column" gap="lg">
      {fieldValues.map((value, index) => (
        <Box width="100%" key={value.key} display="flex">
          <Box display="flex" width="100%">
            {getSectionCard(value, index)}

            <Box
              ml="sm"
              p="xs"
              gap="xs"
              display="flex"
              flexDirection="column"
              height="fit-content"
              borderRadius={border.radius.md}
              border={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
            >
              {canAddSectionCard && (
                <Button
                  testId="add-section"
                  isOnlyIcon
                  icon="plus"
                  size="sm"
                  variant="secondary"
                  onClick={() => addSectionCard(index)}
                />
              )}

              <Button
                testId="clear-values"
                isOnlyIcon
                icon="eraser"
                size="sm"
                variant="secondary"
                disabled={isEmptySection[field](index)}
                onClick={() => clearSectionAction[field](index)}
              />

              <Button
                testId="remove-section"
                isOnlyIcon
                icon="trash-bin"
                size="sm"
                variant="secondary"
                contextVariant="danger"
                disabled={index === 0}
                onClick={() => removeSectionCard(index)}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
