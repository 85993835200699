import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const TagWrapper = styled.div`
  ${({ theme: { colors, space, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    padding: ${space.sm};
    flex-wrap: wrap;
    gap: ${space.xs};
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }
  `}
`;
