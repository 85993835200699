import createModuleReducer from 'store/storeConfig/createModuleReducer';

import actions from './actions';

import _ from 'lodash';
import { CalendarState } from './types/store';
import { ActionsPayload } from './types/actions';
import { Event } from './types/models';

export const INITIAL_STATE: CalendarState['calendar'] = {
  event: {
    details: {
      current: null,
    },
    form: {
      isLoading: false,
      isSubmitting: false,
      recipients: {
        formMeta: {
          canEditClassrooms: true,
          classrooms: [],
          hideRadio: false,
          hideSendTo: false,
          select_options: {
            classroom_id: [],
          },
          student_profiles: null,
        },
      },
    },
    list: {
      events: null,
      filters: {
        activeSchoolTermId: null,
        classroomId: null,
        educationalStageId: null,
        headquarterId: null,
        isLoading: false,
        name: '',
        status: '',
      },
      isLoading: true,
      pagination: {
        count: 0,
        isLoading: false,
        next: null,
        page: 1,
      },
    },
  },
};

export const error = (state: CalendarState) => {
  const newState = _.cloneDeep(state);

  newState.event.form.isSubmitting = false;
  newState.event.form.isLoading = false;
  newState.event.list.isLoading = false;
  newState.event.list.filters.isLoading = false;
  newState.event.list.pagination.isLoading = false;

  return newState;
};

export const fetchClassroomsRequest = (state: CalendarState) => {
  const newState = _.cloneDeep(state);

  newState.event.form.isLoading = true;

  return newState;
};

export const fetchClassroomsSuccess = (
  state: CalendarState,
  action: ActionsPayload['fetchClassroomsSuccess']
) => {
  const newState = _.cloneDeep(state);

  const classrooms = action.classrooms.map((classroom) => ({
    ...classroom,
    options: classroom.options.map((option) => ({
      ...option,
      label: option.attributes.name,
      value: option.id,
    })),
  }));

  newState.event.form.isLoading = false;
  newState.event.form.recipients.formMeta.classrooms = classrooms;
  newState.event.form.recipients.formMeta.select_options.classroom_id =
    classrooms;

  return newState;
};

export const fetchEventDetailsRequest = (state: CalendarState) => {
  const newState = _.cloneDeep(state);

  newState.event.details.current = null;

  return newState;
};

export const fetchEventDetailsSuccess = (
  state: CalendarState,
  action: ActionsPayload['fetchEventDetailsSuccess']
) => {
  const newState = _.cloneDeep(state);

  newState.event.details.current = action.event;

  return newState;
};

export const fetchEventsRequest = (
  state: CalendarState['calendar'],
  action: ActionsPayload['fetchEventsRequest']
) => {
  const newState = _.cloneDeep(state);

  const isFetchMore = !!state.event.list.pagination.next;
  const hasFilters = !!action.filters;

  if (hasFilters) {
    newState.event.list.filters.isLoading = true;
  }

  if (isFetchMore) {
    newState.event.list.pagination.isLoading = true;
  } else {
    newState.event.list.events = null;
    newState.event.list.pagination = INITIAL_STATE.event.list.pagination;
  }

  return newState;
};

export const fetchEventsSuccess = (
  state: CalendarState['calendar'],
  action: ActionsPayload['fetchEventsSuccess']
) => {
  const newState = _.cloneDeep(state);

  const isFetchMore = action.pagination.page !== 1;

  const events = isFetchMore
    ? [...state.event.list.events, ...action.events]
    : action.events;

  if (isFetchMore) {
    newState.event.list.pagination.isLoading = false;
  }

  newState.event.list.events = events;
  newState.event.list.isLoading = false;
  newState.event.list.pagination = {
    ...action.pagination,
    isLoading: false,
  };
  newState.event.list.filters.isLoading = false;

  return newState;
};

export const resetEventDetails = (state: CalendarState) => {
  const newState = _.cloneDeep(state);

  newState.event.details.current = null;

  return newState;
};

export const resetEvents = (state: CalendarState) => {
  const newState = _.cloneDeep(state);

  newState.event.list.events = null;
  newState.event.list.pagination = INITIAL_STATE.event.list.pagination;
  newState.event.list.filters = INITIAL_STATE.event.list.filters;

  return newState;
};

export const setApproveEventRequest = (state: CalendarState) => {
  const newState = _.cloneDeep(state);

  return newState;
};

export const setApproveEventSuccess = (
  state: CalendarState,
  action: ActionsPayload['setApproveEventSuccess']
) => {
  const newState = _.cloneDeep(state);

  if (action.actionContext === 'approve')
    newState.event.details.current = {
      ...newState.event.details.current,
      attributes: {
        ...newState.event.details.current.attributes,
        status: 'processing',
      },
    };

  return newState;
};

export const setDeleteEventRequest = (state: CalendarState) => {
  const newState = _.cloneDeep(state);

  return newState;
};

export const setDeleteEventSuccess = (
  state: CalendarState,
  action: ActionsPayload['setDeleteEventSuccess']
) => {
  const newState = _.cloneDeep(state);

  if (action.actionContext === 'list') {
    const events = newState.event.list.events.filter(
      (event: Event) => event.id !== action.eventId
    );
    newState.event.list.events = events;
  }

  if (action.actionContext === 'details') {
    newState.event.details.current = {
      ...newState.event.details.current,
      attributes: {
        ...newState.event.details.current?.attributes,
        status: 'canceled',
      },
    };
  }

  return newState;
};

export const setEventFilters = (
  state: CalendarState,
  action: ActionsPayload['setEventFilters']
) => {
  const newState = _.cloneDeep(state);

  newState.event.list.filters = action.filters;

  return newState;
};

const HANDLERS = {
  [actions.ERROR]: error,
  [actions.FETCH_CLASSROOMS_REQUEST]: fetchClassroomsRequest,
  [actions.FETCH_CLASSROOMS_SUCCESS]: fetchClassroomsSuccess,
  [actions.FETCH_EVENT_DETAILS_REQUEST]: fetchEventDetailsRequest,
  [actions.FETCH_EVENT_DETAILS_SUCCESS]: fetchEventDetailsSuccess,
  [actions.FETCH_EVENTS_REQUEST]: fetchEventsRequest,
  [actions.FETCH_EVENTS_SUCCESS]: fetchEventsSuccess,
  [actions.RESET_EVENT_DETAILS]: resetEventDetails,
  [actions.RESET_EVENTS]: resetEvents,
  [actions.SET_APPROVE_EVENT_REQUEST]: setApproveEventRequest,
  [actions.SET_APPROVE_EVENT_SUCCESS]: setApproveEventSuccess,
  [actions.SET_DELETE_EVENT_REQUEST]: setDeleteEventRequest,
  [actions.SET_DELETE_EVENT_SUCCESS]: setDeleteEventSuccess,
  [actions.SET_EVENT_FILTERS]: setEventFilters,
};

const calendar = createModuleReducer(INITIAL_STATE, HANDLERS);

export default calendar;
