import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';
import * as S from './styles';

import omniChannelActions from 'store/messages/omniChannel/actions';

import withAppContext from 'core/hoc/withAppContext';
import NewTicketContext from './constext';

import FormFullScreen from 'components/FormFullScreen';
import Information from './FormSteps/Information';
import RecipientsStep from './FormSteps/Recipients';
import Toast from 'components/Toast';

const WIZARD_STEPS = [Information, RecipientsStep];

const NewTicketBySchoolForm = ({ match, appContext: { dataArea } }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['messages']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.forms.new_ticket_by_school.${key}`, params),
    [t]
  );

  const { isActiveFlag: isChannelSubjectFieldActive } = useLaunchDarkly({
    flag: 'CHANNEL_SUBJECT',
  });

  const channelId = match?.params.channelId;

  const initialForm = {
    channelId: channelId,
    title: '',
    description: '',
    recipients: [],
  };

  const {
    fetchChatClassroomsRequest,
    fetchChannelRequest,
    fetchChannelSubjectsRequest,
    setCreateNewTicketBySchoolRequest,
  } = omniChannelActions;

  const handleSubmit = useCallback(
    ({ form }) => {
      const recipientsFormatted = form.recipients.map((recipient) => ({
        student_profile_id: recipient.studentProfileId,
        requester_id: recipient.id,
        requester_type: recipient.type,
      }));

      const current = {
        channelId: form.channelId,
        description: form.description,
        recipients: recipientsFormatted,
      };

      const requestParams = {
        ...current,
        ...(isChannelSubjectFieldActive
          ? {
              title: '',
              subjectId: form.title,
            }
          : { title: form.title }),
      };

      dispatch(setCreateNewTicketBySchoolRequest(requestParams));
    },
    [dispatch, isChannelSubjectFieldActive, setCreateNewTicketBySchoolRequest]
  );

  useEffect(() => {
    dispatch(fetchChannelRequest(channelId));
    dispatch(fetchChatClassroomsRequest(channelId));
    dispatch(fetchChannelSubjectsRequest({ channelId }));
  }, []);

  return (
    <S.NewTicketBySchoolModalWrapper>
      <NewTicketContext.Provider value={null}>
        <FormFullScreen
          id="newTicketBySchool"
          initialAttributes={{}}
          form={initialForm}
          formMeta={{
            nextStepDisabled: true,
          }}
          titlePage={tBase('title')}
          backTo={`/${dataArea}/messages`}
          backToExternal
          isSubmitting={false}
          steps={WIZARD_STEPS}
          onSubmit={handleSubmit}
          titleModal={tBase('discard_modal.title')}
          contentModal={tBase('discard_modal.description')}
        />
        <Toast />
      </NewTicketContext.Provider>
    </S.NewTicketBySchoolModalWrapper>
  );
};

export default withAppContext(NewTicketBySchoolForm);
