import React from 'react';

import { StudentRecipient } from 'store/messages/omniChannel/types';

import { RecipientCard } from 'components/RecipientSelectList/RecipientCard';

import { Props } from './types';

export const SectionListComponent = ({
  data,
  selectedRecipientsList,
  recipient,
  handleAddRecipient,
  handleRemoveRecipient,
  classroomId,
}: Props) => {
  return (
    <>
      {data?.map((item: StudentRecipient, index: number) => (
        <RecipientCard
          key={index}
          recipient={item}
          paddingLeft="48px"
          isSelected={
            !!selectedRecipientsList.classroomTab[recipient.id]?.students?.[
              item.id
            ]
          }
          toggleCheckbox={(isSelected: boolean) =>
            isSelected
              ? handleRemoveRecipient(item, recipient.id)
              : handleAddRecipient(item, classroomId)
          }
        />
      ))}
    </>
  );
};

export const SectionList = React.memo(
  SectionListComponent,
  (prev, next) =>
    prev.selectedRecipientsList.classroomTab[prev.recipient.id] ===
    next.selectedRecipientsList.classroomTab[prev.recipient.id]
);
