import styled, { css } from 'styled-components';

import FormFieldset from 'components/Form/Fieldset';

import { ThemeProps } from 'config/themes/types';

export const StepWrapper = styled(FormFieldset)``;

export const RowWrapper = styled.div`
  ${({ theme: { border, space, typography, colors } }: ThemeProps) => css`
    padding: ${space.xs4};
    display: flex;
    flex-direction: column;
    gap: ${space.lg};

    .Field {
      padding: ${space.xs4};
      margin: ${space.xs4} !important;

      .Label {
        ${typography.label.Regular14};
      }

      .TextArea {
        .FormValidationErrors {
          margin-top: ${space.xs};
          color: ${colors.context.danger.default};
          ${typography.body.Regular14}
        }

        .quill {
          border: ${border.width.sm} ${border.style.solid}
            ${colors.neutral.gray4};
          border-radius: ${border.radius.md};

          &:hover {
            border-color: ${colors.brand.primary.default};
          }

          &:focus-within {
            border-color: ${colors.brand.primary.default};
          }
        }

        .ql-toolbar {
          ${typography.label.Regular14};
          border-top-left-radius: ${border.radius.md};
          border-top-right-radius: ${border.radius.md};

          span {
            color: ${colors.neutral.gray1};
          }
        }

        .ql-toolbar,
        .ql-container.ql-snow {
          border: ${border.width.none};
        }

        .ql-toolbar {
          padding: ${space.xs2} ${space.xs};
          ${typography.label.Regular14};
          color: ${colors.neutral.gray1};

          display: flex;
          gap: ${space.xs3};
          flex-wrap: wrap;

          .ql-formats {
            margin-right: ${space.xs4};
            display: flex;
            gap: ${space.xs3};
          }

          button {
            margin: ${space.xs4};
          }
        }

        .ql-editor {
          padding: ${space.xs2} ${space.sm} ${space.sm};
          ${typography.body.Regular14};
          color: ${colors.neutral.gray1};
        }
      }
    }
  `}
`;

export const RequiredFields = styled.span`
  ${({ theme: { typography } }: ThemeProps) => css`
    ${typography.body.Regular12};
  `}
`;

export const FormSkeletonWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    height: 100%;
    max-width: 100%;
    padding: ${space.xs4} 0;
    margin: ${space.xl3} 0;
    background-color: ${colors.neutral.white};

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin: ${space.xl3} 0;
    }

    @media only screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs4} 0;
    }
  `}
`;

export const HandoutTemplateFormContainer = styled.main`
  .WizardForm .wizard-content .wizard-taps-header .PageTitle {
    min-width: fit-content;
  }
`;
