import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isInclusivelyAfterDay } from 'react-dates-custom-month-year-navigation';
import moment from 'moment';
import composeFunctions from 'core/utils/composeFunctions';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withFormInputFocused from 'core/hoc/withFormInputFocused';
import withAppContext from 'core/hoc/withAppContext';
import FormFieldset from 'components/Form/Fieldset';
import { RIGHT_CENTER } from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';
import Field from 'components/Form/Field';
import ProfileChecker from 'pages/ResponsibleProfiles/FormSections/ProfileChecker';
import AvatarUploader from 'components/Form/AvatarUploader';
import StudentAddress from '../../FormSections/StudentAddress';
import validations from './validations';
import * as S from './styles';

const StudentProfilesInformationsTab = ({ appContext, formContext }) => {
  const [mainClassroomOptions, setMainClassroomOptions] = useState([]);
  const { policies } = appContext;
  const {
    can_use_legacy_id: canUseLegacyId,
    can_edit_user: canEditUser,
    can_show_financial_students: canShowFinancialStudents,
  } = policies;

  const {
    form,
    formMeta: { select_options },
    updateAttribute,
    action,
  } = formContext;

  const { t } = useTranslation(['student_profiles']);

  const onSelectPeriod = (period) => {
    updateAttribute('period', period.value);
  };

  const onClassroomsSelect = (classrooms) => {
    const value = classrooms.map((classroom) => classroom.value);

    setMainClassroomOptions(classrooms);

    updateAttribute('classroom_ids', value);
  };

  const onMainClassroomSelect = (classroom) => {
    updateAttribute('main_classroom_id', classroom.value);
  };

  const isOutsideRange = (day) =>
    isInclusivelyAfterDay(day, moment().add(1, 'days'));

  const getMainClassroomSelectOptions = useCallback(() => {
    let options = [];

    form.classroom_ids.map((classroom) => {
      let option = select_options.classrooms.find(
        (option) => option.value === classroom
      );

      if (option) {
        options.push({
          label: option.name,
          value: option.value,
        });
      }
    });

    setMainClassroomOptions(options);
  }, [form.classroom_ids, select_options.classrooms]);

  useEffect(() => {
    if (form.classroom_ids) getMainClassroomSelectOptions();
  }, [form, getMainClassroomSelectOptions]);

  const selectMainClassroom = () => {
    if (action === 'recover') { return false; }

    return mainClassroomOptions.length > 0;
  };

  const legacyIdLabel = () => (
    <S.CustomLabel>
      <span>{t('form.legacy_id.label')}</span>
      <S.CustomTooltip
        content={t('form.legacy_id_tooltip')}
        position={RIGHT_CENTER}
        tooltipStyle={{
          maxWidth: '285px',
        }}
      >
        <AgendaIcon name="help" size="small" />
      </S.CustomTooltip>
    </S.CustomLabel>
  );

  const singleRegisterAcademy = () => (
    <S.CustomLabel>
      <span>{t('form.ra_id.label')}</span>
      <S.CustomTooltip
        content={t('form.ra_id.tooltip')}
        position={RIGHT_CENTER}
        tooltipStyle={{
          maxWidth: '285px',
        }}
      >
        <AgendaIcon name="help" size="small" />
      </S.CustomTooltip>
    </S.CustomLabel>
  );

  const profileCheckerTitle = () => (
    <S.CheckerTitle>
      <h6>{t('form.profile_section_title')}</h6>
      <S.CustomTooltip
        content={t('form.profile_tooltip')}
        position={RIGHT_CENTER}
        tooltipStyle={{
          maxWidth: '230px',
        }}
      >
        <AgendaIcon name="help" size="small" />
      </S.CustomTooltip>
    </S.CheckerTitle>
  );

  return (
    <div
      className="StudentProfilesInformationsTab"
      data-testid="StudentProfilesInformationsTab"
    >
      <S.Wrapper>
        <FormFieldset>
          {action == 'review' && <S.FormAlert>{t('form.alert')}</S.FormAlert>}
          {canUseLegacyId && (
            <div className="row">
              <Field
                label={legacyIdLabel()}
                placeholder=""
                attributeName="legacy_id"
              />
            </div>
          )}
          <div className="row">
            <Field
              label={t('form.name.label')}
              placeholder=""
              attributeName="name"
            />
          </div>
          <div className="row">
            <Field
              label={singleRegisterAcademy()}
              placeholder=""
              attributeName="ra"
            />
          </div>

          <div className="row">
            <S.DoubleInputWrapper>
              <Field
                type="date"
                label={t('form.date_of_birth.label')}
                attributeName="date_of_birth"
                showAllDays
                withYearMonthSelect
                isOutsideRange={isOutsideRange}
              />

              <Field
                fullWidth
                withArrowSelect
                label={t('form.period.label')}
                type="selectWithIcon"
                attributeName="period"
                value={form.period}
                defaultValue={form.period}
                options={[
                  {
                    label: t('form.period.default_option_label'),
                    value: '',
                  },
                  ...select_options.periods?.map((period) => ({
                    label: period.name,
                    value: period.value,
                  })),
                ]}
                onChange={onSelectPeriod}
                placeholder={t('form.period.placeholder')}
              />
            </S.DoubleInputWrapper>
          </div>

          <div className="row">
            <Field
              isMulti
              fullWidth
              withArrowSelect
              label={t('form.classroom_ids.label')}
              type="selectWithIcon"
              attributeName="classroom_ids"
              value={form.classroom_ids}
              defaultValue={form.classroom_ids}
              options={select_options.classrooms?.map((classroom) => ({
                label: classroom.name,
                value: classroom.value,
              }))}
              onChange={onClassroomsSelect}
              placeholder={t('form.classroom_ids.placeholder')}
              isSearchable={true}
            />
          </div>

          {selectMainClassroom() && (
            <div className="row">
              <S.DoubleInputWrapper>
                <Field
                  fullWidth
                  withArrowSelect
                  label={t('form.main_classroom_id.label')}
                  type="selectWithIcon"
                  attributeName="main_classroom_id"
                  defaultValue=" "
                  value={form.main_classroom_id}
                  options={[
                    {
                      label: t('form.main_classroom_id.default_option_label'),
                      value: '',
                    },
                    ...mainClassroomOptions,
                  ]}
                  placeholder={t('form.main_classroom_id.placeholder')}
                  onChange={onMainClassroomSelect}
                />
              </S.DoubleInputWrapper>
            </div>
          )}

          <ProfileChecker
            profileType="student_profiles"
            title={profileCheckerTitle()}
            emailLabel={t('form.email.label')}
            usernameLabel={t('form.username.label')}
            canEditUser={canEditUser}
          />

          {canShowFinancialStudents && <StudentAddress />}

          <AvatarUploader
            label={t('form.avatar.label')}
            attributeName="avatar"
            avatar={form.avatar}
          />
        </FormFieldset>
      </S.Wrapper>
    </div>
  );
};

StudentProfilesInformationsTab.propTypes = {
  ...formPropTypes,
};

export default composeFunctions(
  withFormInputFocused,
  withFormContext,
  withAppContext,
  tabifyForWizard({
    title: '',
    subTitle: 'Informações gerais',
    validations,
  })
)(StudentProfilesInformationsTab);
