import { ThemeProps } from 'config/themes/types';
import { UploadStatus } from 'core/contexts/UploadFiles/types';
import styled, { css } from 'styled-components';

export const UploaderFile = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    background: #f6f6f6;
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};
    border-radius: 6px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const LoadSpinner = styled.div<{ uploadStatus?: UploadStatus }>`
  ${({ theme: { space, colors }, uploadStatus }) => css`
    width: ${space.sm};
    height: ${space.sm};
    border: 2px solid
      ${uploadStatus === 'deleting'
        ? colors.context.danger.default
        : colors.brand.primary.default};
    border-top-color: ${uploadStatus === 'deleting'
      ? colors.context.danger.op20
      : colors.brand.primary.op20};
  `}

  display: inline-block;

  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export const DeleteFileButton = styled.button`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    border: transparent;
    background-color: transparent;
    margin-top: ${space.xs3};
    :hover {
      path {
        fill: ${colors.context.danger.dark};
      }
    }
  `}
`;
