import i18n from 'config/i18n';

export const toneVoiceOptions = [
  {
    value: '',
    label: i18n.t(
      'handouts:ai_modal.voice_tone_options.voice_tone_placeholder'
    ),
  },
  {
    value: 'formal',
    label: i18n.t('handouts:ai_modal.voice_tone_options.formal'),
  },
  {
    value: 'informal',
    label: i18n.t('handouts:ai_modal.voice_tone_options.informal'),
  },
  {
    value: 'welcoming',
    label: i18n.t('handouts:ai_modal.voice_tone_options.welcoming'),
  },
  {
    value: 'motivational',
    label: i18n.t('handouts:ai_modal.voice_tone_options.motivational'),
  },
  {
    value: 'objective',
    label: i18n.t('handouts:ai_modal.voice_tone_options.objective'),
  },
];

export const styleOptions = [
  {
    value: '',
    label: i18n.t(
      'handouts:ai_modal.message_style_options.message_style_placeholder'
    ),
  },
  {
    value: 'empathetic',
    label: i18n.t('handouts:ai_modal.message_style_options.empathetic'),
  },
  {
    value: 'friendly',
    label: i18n.t('handouts:ai_modal.message_style_options.friendly'),
  },
  {
    value: 'fun',
    label: i18n.t('handouts:ai_modal.message_style_options.fun'),
  },
  {
    value: 'serious_humor',
    label: i18n.t('handouts:ai_modal.message_style_options.serious_humor'),
  },
  {
    value: 'casual_professional',
    label: i18n.t(
      'handouts:ai_modal.message_style_options.casual_professional'
    ),
  },
];

export const lengthOptions = [
  {
    value: '',
    label: i18n.t(
      'handouts:ai_modal.message_length_options.message_length_placeholder'
    ),
  },
  {
    value: 'short',
    label: i18n.t('handouts:ai_modal.message_length_options.short'),
  },
  {
    value: 'medium',
    label: i18n.t('handouts:ai_modal.message_length_options.medium'),
  },
  {
    value: 'long',
    label: i18n.t('handouts:ai_modal.message_length_options.long'),
  },
];
