import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const InformationCard = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${space.xl2};
    width: ${space.xl2};
    background-color: ${colors.brand.primary.op10};
    border-radius: ${border.radius.md};
  `}
`;

export const CoverImagePreview = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    max-width: 617px;
    max-height: 349px;
    padding: ${space.xs2};
    background-color: ${colors.neutral.gray6};
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    overflow: hidden;

    img {
      width: 100%;
      max-height: 317px;
      border: ${border.width.sm} ${border.style.solid}
        ${colors.brand.primary.op20};
      border-radius: inherit;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  `}
`;

export const ConferenceLinkWrapper = styled.div`
  ${({ theme: { colors, space, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs2};
    align-items: center;
    margin-top: ${space.xs};
    border-radius: 6px;
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    background-color: ${colors.neutral.gray6};
    width: max-content;
    padding: ${space.xs} ${space.xs};
  `}
`;

export const ConferenceLink = styled.a`
  ${({ theme: { colors } }: ThemeProps) => css`
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 0px;
    color: ${colors.brand.primary.default};

    :hover {
      color: ${colors.brand.primary.dark};
    }
  `}
`;

export const HistoryWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    width: 100%;
    margin-top: ${space.lg};
    p {
      width: max-content;
    }
  `}
`;

export const AttachmentsWrapper = styled.div`
  display: flex;
  width: 100%;

  div:first-of-type {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 617px;
  }
`;
