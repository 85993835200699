import React from 'react';
import { Skeleton as SkeletonComponent } from '@agendaedu/ae-web-components';

import useMobileScreen from 'core/hooks/useMobileScreen';

const Skeleton = () => {
  const isMobileScreen = useMobileScreen();

  return (
    <SkeletonComponent
      height={isMobileScreen ? 158 : 80}
      width="100%"
      primaryColor="neutral.gray4"
      borderRadius={8}
    />
  );
};

export default Skeleton;
