import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import reduxProvider from 'core/hoc/reduxProvider';
import WebCheckout from 'pages/charges/WebCheckout';

const WebCheckoutRoutes = ({ iframe_link }) => {
  return (
    <div className="WebCheckoutRoutes">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={`/payments/web_checkout/:id`}
            component={() => <WebCheckout iframeLink={iframe_link} />}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default reduxProvider(WebCheckoutRoutes);
