import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  DefaultThemeProps,
  Icon,
  Text,
  theme,
} from '@agendaedu/ae-web-components';

import useOutsideClick from 'core/hooks/useOutsideClick';

import * as S from './styles';
import { SelectAvatarFieldProps } from './types';

export const SelectAvatarField = ({
  icons,
  selectedIconName,
  label,
  onChange,
}: SelectAvatarFieldProps): JSX.Element => {
  const {
    colors: { neutral },
  }: DefaultThemeProps = theme;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showOptions, setShowOptions] = useState(false);

  const currentIcon = icons.find((icon) => icon.name === selectedIconName);

  useOutsideClick(buttonRef, () => {
    setShowOptions(false);
  });

  const handleOnPressButton = (): void => setShowOptions((show) => !show);

  const handleChangeIcon = useCallback(
    (iconName: string): void => {
      onChange(iconName);
      setShowOptions(false);
    },
    [onChange]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Text
        color="neutral.gray1"
        marginBottom="xs"
        variant="label-regular-14"
        lineHeight="lg"
      >
        {label}
      </Text>
      <S.SelectAvatar
        ref={buttonRef}
        showOptions={showOptions}
        onClick={handleOnPressButton}
      >
        <S.OptionButtonImage size="sm" src={currentIcon?.image_url} />
        <Icon name="caret-down" color={neutral.gray1} />
      </S.SelectAvatar>
      {showOptions && (
        <S.OptionsWrapper data-testid="options" ref={buttonRef}>
          {icons?.map((icon, index) => (
            <S.OptionButton
              data-testid="icon"
              onClick={(): void => handleChangeIcon(icon?.name)}
              key={index}
              selected={currentIcon?.name === icon?.name}
            >
              <S.OptionButtonImage size="md" src={icon?.image_url} />
            </S.OptionButton>
          ))}
        </S.OptionsWrapper>
      )}
    </Box>
  );
};
