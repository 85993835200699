import React, { useCallback, useState } from 'react';
import { Box, Button, Text, TextField } from '@agendaedu/ae-web-components';
import CenterModal from 'pages/storage/Modals/CenterModal';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CalendarActions from 'store/calendar/actions';

import { Props } from './types';

const Delete: React.FC<Props> = ({
  action,
  eventId,
  eventName,
  isOpen,
  onClose,
}): React.ReactElement => {
  const { t } = useTranslation(['calendar']);
  const dispatch = useDispatch();

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`modals.delete.${key}`, params),
    [t]
  );

  const [value, setValue] = useState('');

  const disabled = value !== tBase('message_input_placeholder');

  const { setDeleteEventRequest } = CalendarActions;

  const handleOnClose = useCallback(() => {
    onClose();
    setValue('');
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    dispatch(setDeleteEventRequest(eventId, action));
    handleOnClose();
  }, [action, dispatch, eventId, handleOnClose, setDeleteEventRequest]);

  return (
    <CenterModal
      isOpen={isOpen}
      onClose={handleOnClose}
      body={
        <Box
          display="flex"
          flexDirection="column"
          mt="xs"
          data-testid="delete-event-modal"
        >
          <Trans>
            <Text
              color="neutral.gray1"
              data-testid="content-text"
              lineHeight="lg"
              mb={0}
              variant="body-regular-16"
            >
              {tBase('content', {
                eventName,
              })}
            </Text>

            <Text
              color="neutral.gray1"
              lineHeight="lg"
              mb="xs"
              mt="md"
              variant="body-regular-14"
            >
              {tBase('message_input_label')}
            </Text>
          </Trans>
          <TextField
            data-testid="text-field"
            onChange={({ target: { value } }) => setValue(value)}
            placeholder={tBase('message_input_placeholder')}
            value={value}
          />
        </Box>
      }
      footer={
        <Box display="flex" flexDirection="row-reverse" gap="sm">
          <Button
            data-testid="submit-button"
            disabled={disabled}
            variant="secondary"
            contextVariant="danger"
            onClick={handleSubmit}
          >
            {tBase('submit_button_text')}
          </Button>

          <Button
            data-testid="cancel-button"
            variant="secondary"
            onClick={handleOnClose}
          >
            {tBase('cancel_button_text')}
          </Button>
        </Box>
      }
      title={tBase('title')}
    />
  );
};

export default Delete;
