/* eslint-disable no-extra-boolean-cast */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, putApi, deleteApi } from 'core/utils/api';
import actions from './actions';

export function* fetchDynamicModules() {
  yield put({
    type: actions.IS_LOADING_UPDATE,
  });

  try {
    const { data } = yield call(
      fetchApi,
      `/schools/dynamic_module/configurations`
    );

    yield put({
      type: actions.FETCH_DYNAMIC_MODULES_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Ops, ocorreu um erro e não conseguimos completar a requisição. Tente novamente!',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchClassroomsModules(action) {
  yield put({
    type: actions.IS_LOADING_UPDATE,
  });

  try {
    const { data } = yield call(
      fetchApi,
      `/schools/dynamic_module/configurations/classrooms`
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_MODULES_SUCCESS,
      data: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Ops, ocorreu um erro e não conseguimos completar a requisição. Tente novamente!',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* updateDynamicModules(action) {
  const { id, classroomIds, addClassroomAutomatically } = action;

  yield put({
    type: actions.IS_LOADING_UPDATE,
  });

  try {
    const { data } = yield call(
      putApi,
      `/schools/dynamic_module/configurations/${id}`,
      {
        classroom_ids: classroomIds,
        add_classroom_automatically: addClassroomAutomatically,
      }
    );

    yield put({
      type: actions.UPDATE_DYNAMIC_MODULES_SUCCESS,
      data: data,
      toast: buildToast(
        'As modificações foram salvas com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Ops, ocorreu um erro e não conseguimos completar a requisição. Tente novamente!',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export const updateIsLoading = (state) => ({
  ...state,
  isLoading: !state.isLoading,
});

export function* buildToastMessage({ message, typeToast }) {
  const type = typeToast === 'success' ? ToastTypes.success : ToastTypes.error;

  yield put({
    type: actions.BUILD_TOAST_SUCCESS,
    toast: buildToast(message, type, toastCss(type)),
  });
}

function* dynamicModulesSagas() {
  yield all([
    takeLatest(actions.FETCH_DYNAMIC_MODULES, fetchDynamicModules),
    takeLatest(actions.FETCH_CLASSROOMS_MODULES, fetchClassroomsModules),
    takeLatest(actions.UPDATE_DYNAMIC_MODULES, updateDynamicModules),
    takeLatest(actions.BUILD_TOAST, buildToastMessage),

    // takeLatest(actions.UPDATE_IS_LOADING, updateIsLoading),
  ]);
}

export default dynamicModulesSagas;
