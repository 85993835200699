import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '@agendaedu/ae-web-components';

import AttachmentEntity from 'core/entities/Attachment';
import useOutsideClick from 'core/hooks/useOutsideClick';
import withAppContext from 'core/hoc/withAppContext';

import { OmniChannelState } from 'store/messages/omniChannel/types';
import omniChannelActions from 'store/messages/omniChannel/actions';

import FooterToast from 'components/Messages/TS/Animations/FooterToast';
import Loader from 'components/Loader';
import { SelectorEmotes } from 'components/Messages/EmojiPicker';

import * as S from './styles';

import ErrorAttachmentModal from '../Modals/ErrorAttachmentModal';
import { AttachemntModal } from '../Modals/AttachmentModal';

import { TextAreaInputProps } from './types';

const TextAreaInput = ({
  onSubmit,
  onChange,
  message,
  isLoading,
  disabled,
  disabledText,
  appContext: { currentUserType, currentSchool },
}: TextAreaInputProps): JSX.Element => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const emojiRef = useRef(null);
  const [attachmentError, setAttachmentError] = useState(null);
  const [attachmentFile, setAttachmentFile] = useState<AttachmentEntity>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const { currentMessage, isEditingMessage, isReplyMessage } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const { toggleAttachmentModal, toggleEditMessage, toggleReplyMessage } =
    omniChannelActions;

  const disabledSubmitButton =
    !message ||
    /^[\n]+$/.test(message) ||
    isLoading ||
    (isEditingMessage && message === currentMessage.attributes.text);

  const footerVariant = isEditingMessage ? 'edit' : 'reply';

  useOutsideClick(emojiRef, () => showEmojiPicker && setShowEmojiPicker(false));

  const handleKeyboardSendMensage = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && event.shiftKey) {
      event.preventDefault();
      !disabledSubmitButton && onSubmit();
    }
  };

  const handleCloseFooterToast = useCallback(() => {
    dispatch(isEditingMessage ? toggleEditMessage() : toggleReplyMessage());
  }, [dispatch, isEditingMessage, toggleEditMessage, toggleReplyMessage]);

  const handleToggleAttachmentModal = useCallback(() => {
    dispatch(toggleAttachmentModal());
  }, [dispatch, toggleAttachmentModal]);

  const handleToggleAttachmentMessage = useCallback(
    (event) => {
      const currentAttachment = new AttachmentEntity(event.target.files[0]);

      if (
        currentAttachment.isTypeValid(currentUserType) &&
        currentAttachment.isSizeValid(currentUserType, currentSchool)
      ) {
        setAttachmentFile(currentAttachment);
        setAttachmentError([]);
      } else {
        const errors = [];
        if (!currentAttachment.isTypeValid(currentUserType))
          errors.push('type');
        if (!currentAttachment.isSizeValid(currentUserType, currentSchool))
          errors.push('size');

        setAttachmentError(errors);
      }

      handleToggleAttachmentModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleToggleAttachmentModal, setAttachmentFile, setAttachmentError]
  );

  const handleToggleOnCloseAttachment = useCallback(() => {
    setAttachmentFile(null);
    handleToggleAttachmentModal();
  }, [handleToggleAttachmentModal, setAttachmentFile]);

  const onAddEmoji = ({ native }) => {
    const newMessage = message.concat(native);
    onChange(newMessage);
  };

  const onChangeMessage = useCallback(
    ({ target }) => {
      onChange(target.value);
    },
    [onChange]
  );

  return (
    <>
      <S.TextAreaInputWrapper data-testid="text-area-input">
        {(isEditingMessage || isReplyMessage) && (
          <FooterToast
            handleOnClose={handleCloseFooterToast}
            variant={footerVariant}
            message={currentMessage}
          />
        )}
        <S.Wrapper>
          <S.EmoteButton
            data-testid="emote-button"
            disabled={disabled}
            onClick={() => setShowEmojiPicker(true)}
            ref={emojiRef}
          >
            <Icon name="emoji" />
            <div>
              <SelectorEmotes
                onSelectEmoji={onAddEmoji}
                disabled={!showEmojiPicker}
              />
            </div>
          </S.EmoteButton>

          <S.InputField
            data-testid="input-field"
            disabled={disabled}
            maxRows={2}
            onKeyPress={handleKeyboardSendMensage}
            placeholder={
              disabled
                ? disabledText
                : t('omni_channel.messages.new_message_chat_input_text')
            }
            onChange={onChangeMessage}
            value={message}
          />

          {!isEditingMessage && (
            <S.AttachmentWrapper>
              <S.AttachmentButton
                data-testid="attachment-button"
                disabled={disabled}
                htmlFor="attachment-message-input"
              >
                <Icon name="attachment" size="md" />
              </S.AttachmentButton>

              <input
                disabled={disabled}
                id="attachment-message-input"
                type="file"
                data-testid="attachment-input"
                onChange={handleToggleAttachmentMessage}
              />
            </S.AttachmentWrapper>
          )}

          <S.SubmitButton
            data-testid="submit-button"
            onClick={onSubmit}
            disabled={disabledSubmitButton}
          >
            <S.SubmitButtonWrapper>
              {isLoading ? (
                <Loader isLoading={true} variation="centered" color="white" />
              ) : (
                <Icon name="send-filled" size="sm" />
              )}
            </S.SubmitButtonWrapper>
          </S.SubmitButton>
        </S.Wrapper>
      </S.TextAreaInputWrapper>

      {!attachmentError?.length ? (
        <AttachemntModal
          attachmentFile={attachmentFile}
          handleOnClose={handleToggleOnCloseAttachment}
        />
      ) : (
        <ErrorAttachmentModal
          attachmentError={attachmentError}
          currentUserType={currentUserType}
          handleOnClose={handleToggleOnCloseAttachment}
        />
      )}
    </>
  );
};

export default withAppContext(TextAreaInput);
