import React from 'react';

import Currency from 'components/Currency';
import {
  CAN_NEGOTIATE_RECURRENT_BILL_BY_STATUS,
  CAN_ENROLLMENT_CANCEL_BY_STATUS,
  DISPLAY_STATUS_PAID,
  ALLOWED_PAYMENT_METHOD,
} from 'core/constants';

export const formattedDiscounts = ({
  toggle_discount: toggleDiscount,
  edupay_discounts: edupayDiscounts,
}) => {
  const formattedDiscount = [];

  if (toggleDiscount) {
    edupayDiscounts.forEach((discount) => {
      const formatted = {
        ...discount,
      };

      if (formatted.kind === 'percent') {
        formatted.discount_percent = discount.discount_value;
      } else {
        formatted.discount_price_cents = discount.discount_value;
      }

      formattedDiscount.push(formatted);
    });
  }

  return formattedDiscount;
};

export const formattedPenality = ({
  toggle_penality: togglePenality,
  edupay_penality: edupayPenality,
}) => {
  return togglePenality ? edupayPenality : null;
};

export const buildDiscount = () => ({
  kind: 'money',
  discount_value: 0,
  days_before_expire: undefined,
  key: Math.random().toString(36).substring(7),
});

export const renderPenalityMessage = (penality) => (
  <>
    {'Multa de '}
    <Currency value={penality.fine_price} />
    {` (${penality.fine_percent}%) e juros de ${penality.interest_percent}% a.m.`}
  </>
);

export const translateMethod = (method) => {
  const methodTranslations = {
    billet: 'boleto',
    pix: 'PIX',
    credit_card: 'cartão de crédito',
  };

  return methodTranslations[method] || '';
};

export const renderDiscountMessage = (attributes) => {
  let methodsMessage = '';
  const allowedMethods = attributes.allowed_payment_methods;
  if (
    allowedMethods.includes('billet') &&
    allowedMethods.includes('pix') &&
    allowedMethods.includes('credit_card')
  ) {
    methodsMessage = 'em todos os métodos de pagamento, ';
  } else if (allowedMethods.length === 2) {
    methodsMessage = `no ${translateMethod(
      allowedMethods[0]
    )} e ${translateMethod(allowedMethods[1])}, `;
  } else if (allowedMethods.length === 1) {
    methodsMessage = `no ${translateMethod(allowedMethods[0])}, `;
  }

  let expirationMessage;
  if (attributes.days_before_expire === 0) {
    expirationMessage = 'se pago até o dia do vencimento';
  } else if (attributes.days_before_expire === 1) {
    expirationMessage = `se pago até ${attributes.days_before_expire} dia antes do vencimento`;
  } else {
    expirationMessage = `se pago até ${attributes.days_before_expire} dias antes do vencimento`;
  }

  if (attributes.kind === 'money') {
    return (
      <li>
        Desconto de <Currency value={attributes.discount_price_cents} />{' '}
        {methodsMessage} {expirationMessage}
      </li>
    );
  }

  return (
    <li>
      Desconto de {attributes.discount_percent}% {methodsMessage}{' '}
      {expirationMessage}
    </li>
  );
};

export const makeClassroomIds = (students) => {
  const classroom_with_student_profile_ids = {};

  Object.keys(students).forEach((key) => {
    classroom_with_student_profile_ids[key] = students[key].data.map(
      (student) => student.id
    );
  });

  return classroom_with_student_profile_ids;
};

export const extractDiscount = (schoolProduct) => {
  const discounts = [];
  const {
    attributes: { edupay_discounts: edupayDiscounts },
  } = schoolProduct;

  if (edupayDiscounts) {
    edupayDiscounts.data.map(({ attributes }) => {
      discounts.push({
        kind: attributes.kind,
        discount_value:
          attributes.kind === 'percent'
            ? attributes.discount_percent
            : attributes.discount_price_cents,
        days_before_expire: attributes.days_before_expire,
        key: Math.random().toString(36).substring(7),
      });
    });
  }

  if (!discounts.length) {
    discounts.push(buildDiscount());
  }

  return discounts;
};

export const extractStudents = (classrooms) => {
  const studentProfiles = [];

  Object.keys(classrooms).forEach((key) => {
    classrooms[key].data.map((profile) => studentProfiles.push(profile));
  });

  return studentProfiles;
};

export const isRecurrentBillNonNegotiable = (status, isTableEdit) => {
  return (
    !CAN_NEGOTIATE_RECURRENT_BILL_BY_STATUS.includes(status) || isTableEdit
  );
};

export const canCancelEnrollment = (invoiceStatus, documentStatus) => {
  if (
    (documentStatus === 'finished' || documentStatus === 'canceled') &&
    DISPLAY_STATUS_PAID.includes(invoiceStatus)
  ) {
    return false;
  }

  return CAN_ENROLLMENT_CANCEL_BY_STATUS.includes(invoiceStatus);
};

export const getAllowedPaymentMethods = (
  invoiceDetails,
  allowedPaymentMethods = []
) => {
  if (invoiceDetails?.credit_card_enabled) {
    return allowedPaymentMethods;
  }
  const filteredMethods = allowedPaymentMethods.filter(
    (method) => method !== 'credit_card'
  );
  return filteredMethods.length > 0 ? filteredMethods : ALLOWED_PAYMENT_METHOD;
};
