import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import { Button, Text } from '@agendaedu/ae-web-components';
import { Header } from '../components/Header';
import { Props } from './types';

export const DiaperCard = ({
  diaper,
  onChange,
  student,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);
  const [_quantity, _setQuantity] = useReducer((_, newDiaper: number) => {
    onChange(newDiaper);

    return newDiaper;
  }, diaper);

  const quantity = diaper || _quantity;

  return (
    <S.DiaperWrapper data-testid="diaper-card">
      <Header
        sectionTitle={t('sections.card.diaper.title')}
        student={student}
      />

      <Text variant="label-regular-16" marginBottom="0">
        {t('sections.card.diaper.label')}
      </Text>

      <S.ButtonWrapper>
        <Button
          testId="minus-button"
          isOnlyIcon
          icon="minus"
          variant="secondary"
          disabled={quantity <= 0}
          onClick={() => _setQuantity(quantity - 1)}
        />

        <Text
          variant="body-medium-16"
          color="neutral.gray2"
          marginBottom="0"
          width="32px"
          textAlign="center"
        >
          {quantity}
        </Text>

        <Button
          testId="plus-button"
          isOnlyIcon
          icon="plus"
          variant="secondary"
          onClick={() => _setQuantity(quantity + 1)}
        />
      </S.ButtonWrapper>
    </S.DiaperWrapper>
  );
};
